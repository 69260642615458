import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { selectUser } from "app/store/user/userSlice";
import { NavLink } from "react-router-dom";

const Root = styled("div")(({ theme }) => ({
  "& .username, & .email": {
    transition: theme.transitions.create("opacity", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },

  "& .avatar": {
    // background: theme.palette.primary.dark,
    transition: theme.transitions.create("all", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
    bottom: 0,
    "& > img": {
      borderRadius: "50%",
    },
  },
}));

/**
 * The user navbar header.
 */
function UserNavbarHeader() {
  const user = useSelector(selectUser);

  if (!user.data || !user.data.displayName || !user.data.email) {
    return null;
  }

  return (
    <NavLink to="/user" style={{ textDecoration: "none" }}>
      <Root className="user relative flex flex-col items-center justify-center p-16 pb-14 shadow-0">
        <div className="mb-24 flex items-center justify-center">
          <Avatar
            sx={{
              backgroundColor: "text.disabled",
              // color: 'text.secondary'
            }}
            className="avatar h-96 w-96 text-32 font-bold"
            src={user.data.photoURL}
            alt={user.data.displayName}
          >
            {user.data.displayName.charAt(0)}
          </Avatar>
        </div>
        <Typography
          className="username whitespace-nowrap text-14 font-medium"
          color="text.primary"
        >
          {user.data.displayName}
        </Typography>
        <Typography
          className="email whitespace-nowrap text-13 font-medium"
          color="text.secondary"
        >
          {user.data.email}
        </Typography>
      </Root>
    </NavLink>
  );
}

export default UserNavbarHeader;

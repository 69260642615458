import { Typography } from "@mui/material";
import { styled } from "@mui/system";

const StyledTypographyGrey = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
}));

const DetailItem = ({ label, value }) => {
  return (
    <>
      <StyledTypographyGrey variant="caption">
        {label}
      </StyledTypographyGrey>
      <StyledTypography variant="body1" className="text-16">{value || "--"}</StyledTypography>
    </>
  );
};

export default DetailItem;


import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { FeaturePermissions } from 'src/app/common/FeaturePermissions';

export const pages = [
  {
    id: 1,
    icon: DashboardOutlinedIcon,
    title: 'DASHBOARD',
    url: 'dashboard',
    slug: ['customer-user', 'agent', 'mini-agent'],
  },
  {
    id: 2,
    icon: PersonOutlineOutlinedIcon,
    title: 'PERSONAL_INFO',
    url: 'personal-info',
    permission: FeaturePermissions.User.View,
    slug: ['customer-user', 'agent', 'mini-agent'],
  },
  {
    id: 3,
    icon: LocationOnOutlinedIcon,
    title: 'ADDRESS_INFO',
    url: 'address-information',
    permission: FeaturePermissions.UserAddress.List,
    slug: ['customer-user'],
  },
  {
    id: 4,
    icon: GroupsOutlinedIcon,
    title: 'MEMBER',
    url: 'member',
    permission: FeaturePermissions.User.List,
    slug: ['agent', 'mini-agent'],
  },
  {
    id: 5,
    icon: ChecklistOutlinedIcon,
    title: 'MY_APPLICATION',
    url: 'my-application',
    permission: FeaturePermissions.FinanceApplication.List,
    slug: ['customer-user', 'agent', 'mini-agent'],
  },
  {
    id: 6,
    icon: PeopleAltOutlinedIcon,
    title: 'MINI_AGENT',
    url: 'mini-agent',
    permission: FeaturePermissions.MiniAgent.List,
    slug: ['agent'],
  },
];

import React, { useEffect } from "react";
import PersonalInfo from "./PersonalInfo";
import UserAdditionalInfo from "./UserAdditionalInfo";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import Can from "src/app/services/FeaturePermission/Can";
import { getSlug, getUserData, getUserDetail, getUserId } from "app/store/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { CommonBreadcrumbs } from "app/shared-components/Breadcrumbs/CommonBreadcrumbs";
import { useTranslation } from "react-i18next";
import { Container } from "@mui/material";
import AgentQRCode from "./AgentQRCode";
import { hasPermission } from "src/app/services/utils";

const PersonalDetailPage = () => {
  const { t } = useTranslation("user");
  const dispatch: any = useDispatch();
  const slug = useSelector(getSlug);
  const userData = useSelector(getUserData);
  const userId = useSelector(getUserId);
  const userDetailPermission = hasPermission(FeaturePermissions?.User?.View);

  useEffect(() => {
    if (!!userId && userDetailPermission) {
      dispatch(getUserDetail(userId));
    }
  }, [userId, dispatch]);

  return (
    <>
      <Container
        maxWidth="lg"
        className={"pl-10 mt-10 md:pl-76 pr-10 md:pr-76"}
      >
        <CommonBreadcrumbs currentPageName={t("PERSONAL_INFO")} />
      </Container>
      <Can
        permission={FeaturePermissions.User.View}
        errorMessage="NOT_ALLOWED_VIEW_DETAIL"
      >
        <PersonalInfo isEdit={!["agent", "mini-agent"].includes(slug)} agencyCodeShow={!["agent", "mini-agent"].includes(slug)} personalInfo={userData} />
        {!["agent", "mini-agent"].includes(slug) ? (
          <UserAdditionalInfo additionalData={userData?.userAdditionalInformation}/>
        ) : (
          <AgentQRCode />
        )}
      </Can>
    </>
  );
};

export default PersonalDetailPage;

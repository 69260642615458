export const FeaturePermissions = {
    Tenant: {
      View: "Tenant:View",
      Create: "Tenant:Create",
      Update: "Tenant:Update",
      Delete: "Tenant:Delete",
    },
    Role: {
      View: "Role:View",
      Create: "Role:Create",
      Update: "Role:Update",
      Delete: "Role:Delete",
    },
    RoleFeature: {
      View: "RoleFeature:View",
      Create: "RoleFeature:Create",
      Update: "RoleFeature:Update",
      Delete: "RoleFeature:Delete",
    },
    FeaturePermission: {
      View: "FeaturePermission:View",
      Create: "FeaturePermission:Create",
      Update: "FeaturePermission:Update",
      Delete: "FeaturePermission:Delete",
    },
    Category: {
      View: "Category:View",
      Create: "Category:Create",
      Update: "Category:Update",
      Delete: "Category:Delete",
      List: "Category:List",
      ChangeStatus: "Category:ChangeStatus",
    },
    Product: {
      View: "Product:View",
      Create: "Product:Create",
      Update: "Product:Update",
      Delete: "Product:Delete",
      List: "Product:List",
      ChangeStatus: "Product:ChangeStatus",
    },
    ProductSpecification: {
      View: "ProductSpecification:View",
      Create: "ProductSpecification:Create",
      Update: "ProductSpecification:Update",
      Delete: "ProductSpecification:Delete",
      List: "ProductSpecification:List",
    },
    ProductVariant: {
      View: "ProductVariant:View",
      Create: "ProductVariant:Create",
      Update: "ProductVariant:Update",
      Delete: "ProductVariant:Delete",
      List: "ProductVariant:List",
    },
    DocumentType: {
      View: "DocumentType:View",
      Create: "DocumentType:Create",
      Update: "DocumentType:Update",
      Delete: "DocumentType:Delete",
      List: "DocumentType:List",
    },
    ContactUsInquiry: {
      View: "ContactUsInquiry:View",
      List: "ContactUsInquiry:List",
    },
    User: {
      View: "User:View",
      Create: "User:Create",
      Update: "User:Update",
      Delete: "User:Delete",
      List: "User:List",
      MarkAsApproved: "User:MarkAsApproved",
      AddDocuments: "User:AddDocuments",
      UpdateDocuments: "User:UpdateDocuments",
      UpdateAddress: "User:UpdateAddress",
      UpdateAdditionalInformation: "User:UpdateAdditionalInformation",
      ListDocument: "User:ListDocument",
      ChangeStatus: "User:ChangeStatus",
      DeleteDocument: "User:DeleteDocument",
    },
    UserNotification: {
      View: "UserNotification:View",
      List: "UserNotification:List",
      Delete: "UserNotification:Delete",
      MarkAsRead: "UserNotification:MarkAsRead",
    },
    FinanceApplication: {
      View: "FinanceApplication:View",
      Create: "FinanceApplication:Create",
      Update: "FinanceApplication:Update",
      Delete: "FinanceApplication:Delete",
      List: "FinanceApplication:List",
      DownloadApplicationPdf: "FinanceApplication:DownloadApplicationPdf",
      AdminDiscussion: "FinanceApplication:AdminDiscussion",
      ChangeStatus: "FinanceApplication:ChangeStatus",
      ProcessLoan: "FinanceApplication:ProcessLoan",
      ViewActivity: "FinanceApplication:ViewActivity",
      AssignApplication: "FinanceApplication:AssignApplication",
    },
    FinanceApplicationSectionRemark: {
      View: "FinanceApplicationSectionRemark:View",
      Create: "FinanceApplicationSectionRemark:Create",
      Update: "FinanceApplicationSectionRemark:Update",
      Delete: "FinanceApplicationSectionRemark:Delete",
      List: "FinanceApplicationSectionRemark:List",
      ActionTaken: "FinanceApplicationSectionRemark:ActionTaken",
    },
    FinanceApplicationProgress: {
      View: "FinanceApplicationProgress:View",
      Create: "FinanceApplicationProgress:Create",
      Update: "FinanceApplicationProgress:Update",
      Delete: "FinanceApplicationProgress:Delete",
      List: "FinanceApplicationProgress:List",
    },
    FinanceApplicationProcessComment: {
      View: "FinanceApplicationProgress:View",
      Create: "FinanceApplicationProgress:Create",
      Update: "FinanceApplicationProgress:Update",
      Delete: "FinanceApplicationProgress:Delete",
    },
    FinanceApplicationCommunication: {
      View: "FinanceApplicationCommunication:View",
      Create: "FinanceApplicationCommunication:Create",
      Update: "FinanceApplicationCommunication:Update",
      Delete: "FinanceApplicationCommunication:Delete",
      List: "FinanceApplicationCommunication:List",
    },
    UserAdditionalInformation: {
      View: "UserAdditionalInformation:View",
      Create: "UserAdditionalInformation:Create",
      Update: "UserAdditionalInformation:Update",
      Delete: "UserAdditionalInformation:Delete",
      List: "UserAdditionalInformation:List",
      MarkAsApproved: "UserAdditionalInformation:MarkAsApproved",
    },
    UserAddress: {
      View: "UserAddress:View",
      Create: "UserAddress:Create",
      Update: "UserAddress:Update",
      Delete: "UserAddress:Delete",
      List: "UserAddress:List",
      MarkAsApproved: "UserAddress:MarkAsApproved",
    },
    UserDocuments: {
      View: "UserDocuments:View",
      Create: "UserDocuments:Create",
      Update: "UserDocuments:Update",
      Delete: "UserDocuments:Delete",
      List: "UserDocuments:List",
      MarkAsApproved: "UserDocuments:MarkAsApproved",
    },
    UserIncomeDetail: {
      View: "UserIncomeDetail:View",
      Create: "UserIncomeDetail:Create",
      Update: "UserIncomeDetail:Update",
      Delete: "UserIncomeDetail:Delete",
      List: "UserIncomeDetail:List",
      MarkAsApproved: "UserIncomeDetail:MarkAsApproved",
    },
    UserExistingFinance: {
      View: "UserExistingFinance:View",
      Create: "UserExistingFinance:Create",
      Update: "UserExistingFinance:Update",
      Delete: "UserExistingFinance:Delete",
      List: "UserExistingFinance:List",
      MarkAsApproved: "UserExistingFinance:MarkAsApproved",
    },
    UserRelativeDetail: {
      View: "UserRelativeDetail:View",
      Create: "UserRelativeDetail:Create",
      Update: "UserRelativeDetail:Update",
      Delete: "UserRelativeDetail:Delete",
      List: "UserRelativeDetail:List",
      MarkAsApproved: "UserRelativeDetail:MarkAsApproved",
    },
    UserBankingDetail: {
      View: "UserBankingDetail:View",
      Create: "UserBankingDetail:Create",
      Update: "UserBankingDetail:Update",
      Delete: "UserBankingDetail:Delete",
      List: "UserBankingDetail:List",
      MarkAsApproved: "UserBankingDetail:MarkAsApproved",
    },
    UserEmploymentDetail: {
      View: "UserEmploymentDetail:View",
      Create: "UserEmploymentDetail:Create",
      Update: "UserEmploymentDetail:Update",
      Delete: "UserEmploymentDetail:Delete",
      List: "UserEmploymentDetail:Delete",
      MarkAsApproved: "UserEmployment:MarkAsApproved",
    },
    UserNextofkin: {
      View: "UserNextofkin:View",
      Create: "UserNextofkin:Create",
      Update: "UserNextofkin:Update",
      Delete: "UserNextofkin:Delete",
      List: "UserNextofkin:List",
    },
    UserReferralDetail: {
      View: "UserReferralDetail:View",
      Create: "UserReferralDetail:Create",
      Update: "UserReferralDetail:Update",
      Delete: "UserReferralDetail:Delete",
      List: "UserReferralDetail:List",
    },
    AuditTrailLog: {
      View: "AuditTrailLog:View",
      List: "AuditTrailLog:List",
    },
    MiniAgent: {
      List: "MiniAgent:List",
      View: "MiniAgent:View",
      Create: "MiniAgent:Create",
      Update: "MiniAgent:Update",
      Delete: "MiniAgent:Delete",
      MarkAsApproved: "MiniAgent:MarkAsApproved",
      ChangeStatus: "MiniAgent:ChangeStatus",
    },
    Agent: {
      List: "Agent:List",
      View: "Agent:View",
      Create: "Agent:Create",
      Update: "Agent:Update",
      Delete: "Agent:Delete",
      MarkAsApproved: "Agent:MarkAsApproved",
      ChangeStatus: "Agent:ChangeStatus",
    },
    Staff: {
      List: "Staff:List",
      View: "Staff:View",
      Create: "Staff:Create",
      Update: "Staff:Update",
      Delete: "Staff:Delete",
      MarkAsApproved: "Staff:MarkAsApproved",
      ChangeStatus: "Staff:ChangeStatus",
    },
    Admin: {
      List: "Admin:List",
      View: "Admin:View",
      Create: "Admin:Create",
      Update: "Admin:Update",
      Delete: "Admin:Delete",
      MarkAsApproved: "Admin:MarkAsApproved",
      ChangeStatus: "Admin:ChangeStatus",
    },
  };
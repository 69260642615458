import React from "react";
import { Chip } from "@mui/material";
import { styled } from "@mui/system";
import themesConfig from "app/configs/themesConfig";

interface StatusChipProps {
  status: string;
  label: string;
}

interface AdditionalChipProps {
  [key: string]: any;
}

const StyledChip = styled(Chip)<StatusChipProps>(({ status }) => ({
  ...getStatusStyles(status),
  "& .MuiChip-icon": {
    ...getStatusStyles(status),
  },
  "&:hover": {
    ...getStatusStyles(status),
  },
}));

const getStatusStyles = (status: string) => {
  const statusStyles = themesConfig.status.statusColors[status] || {
    backgroundColor: themesConfig.default.palette.primary.light,
    color: themesConfig.default.palette.common.white,
  };
  return statusStyles;
};

const StatusChip: React.FC<StatusChipProps & AdditionalChipProps> = ({
  status,
  label,
  ...props
}) => <StyledChip label={label} status={status?.toLowerCase()} size="small" {...props} />;

export default StatusChip;

import i18next from 'i18next';
import { lazy } from 'react';
import en from "./i18n/en";
import ms from "./i18n/ms";
import { authRoles } from 'src/app/auth';

i18next.addResourceBundle('en', 'faqs', en);
i18next.addResourceBundle('ms', 'faqs', ms);

const Faqs = lazy(() => import('./Faqs'));

/**
 * The Home page config.
 */
const FaqsConfig = {
	settings: {
		layout: {
		}
	},
	auth: authRoles.public,
	routes: [
		{
			path: 'faqs',
			element: <Faqs />,
		}
	]
};

export default FaqsConfig;

import i18next from 'i18next';
import { lazy } from 'react';
import NotificationPage from './NotificationPage';
import { authRoles } from 'src/app/auth';

import en from './i18n/en';
import ms from './i18n/ms';
import { t } from 'i18next';

i18next.addResourceBundle('en', 'Notification', en);
i18next.addResourceBundle('ms', 'Notification', ms);

const NotificationConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.admin,
	routes: [
		{
			path: '/notifications',
			title: "NOTIFICATION",
			element: <NotificationPage/>,
            isBackButton:true
		}
	]
};

export default NotificationConfig;

/**
 * Configuration object containing the authentication service API endpoints
 */
const jwtServiceConfig = {
  sendOtp: "/v1/User/GenerateOTP",
  verifyOTP: "/v1/User/VerifyOTP",
  signIn: "/v1/User/Login",
  signUp: "/v1/User/Register",
  accessToken: "/v1/User/RefreshToken",
  updateUser: "/v1/User/UpdateUser",
};

export default jwtServiceConfig;

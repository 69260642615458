import React, { useState, ReactElement } from "react";
import { Dialog, DialogContent, IconButton, useMediaQuery, useTheme } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PropTypes from "prop-types";
import { decodeUrlAndGetFileType } from "src/app/services/utils";

interface PreviewMediaProps {
  children: ReactElement;
  filePath?: string;
  showDefaultView?: boolean;
}

const PreviewMedia: React.FC<PreviewMediaProps> = ({ children, filePath = "", showDefaultView = true }) => {
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const fileType = decodeUrlAndGetFileType(filePath);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      {["mp3", "wav"].includes(fileType) ? (
        <IconButton onClick={handleOpen} className="cursor-pointer w-24 h-24">
          <PlayCircleOutlineIcon fontSize="large" />
        </IconButton>
      ) : ["mp4"].includes(fileType) ? (
        <video width="100" className="cursor-pointer w-24 h-24 object-cover" onClick={handleOpen}>
          <source src={filePath} type={`video/${fileType}`} />
        </video>
      ) : (
        <div
          className={`cursor-pointer ${showDefaultView ? "w-[100px] h-[100px] object-cover" : "object-cover"}`}
          onClick={handleOpen}
        >
          {children}
        </div>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={fileType === "pdf" ? "lg" : undefined}
        fullWidth={fileType === "pdf"}
      >
        <DialogContent className={`p-0 ${fileType === "pdf" ? (isMobile ? "h-[30vh]" : "h-[80vh]") : "h-auto"}`}>
          {fileType === "pdf" ? (
            <embed src={filePath} type="application/pdf" width="100%" height="100%" />
          ) : ["mp4"].includes(fileType) ? (
            <video controls className="w-full h-auto" src={filePath}></video>
          ) : ["mp3", "wav"].includes(fileType) ? (
            <audio controls>
              <source src={filePath} type={`audio/${fileType}`} />
            </audio>
          ) : (
            <img src={filePath} alt="Preview" className="w-full h-auto" />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

PreviewMedia.propTypes = {
  children: PropTypes.element.isRequired,
};

export default PreviewMedia;

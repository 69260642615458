import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import DetailItem from "app/shared-components/DetailItem/DetailItem";
import { StyledTypography } from "app/shared-components/Styles/CustomStyle";
import CommonAccordion from "app/shared-components/Accordion/CommonAccordion";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsEmploymentDetailEditDialogOpen,
  setEmploymentDetailDialogOpen,
} from "app/store/application-form/applicationFormSlice";
import { useParams } from "react-router";
import CustomDialog from "app/shared-components/Dialog/customDialog";
import EmploymentDetails from "src/app/main/application-form/component/EmploymentDetails";
import ChangeDetectDialog from "../form/ChangeDetectDialog";
import PhoneNumberLink from "app/shared-components/Link/PhoneNumberLink";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import { isEmpty } from "lodash";
import { getCurrentFinanceApplicationStatus } from "app/store/user/userSlice";

const EmploymentDetailAccordion = ({ employmentDetail, remarksList }) => {
  const { t } = useTranslation("user");
  const dispatch: any = useDispatch();
  const isOpen = useSelector(getIsEmploymentDetailEditDialogOpen);
  const currentFinanceStatus = useSelector(getCurrentFinanceApplicationStatus);
  const { applicationId } = useParams<{ applicationId: string }>();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const handleClose = () => {
    dispatch(setEmploymentDetailDialogOpen(false));
  };
  const handleEditSectionOpen = () => {
    dispatch(setEmploymentDetailDialogOpen(true));
  };

  const handleDialogClose = () => {
    if (isDirty) {
      setConfirmOpen(true);
    } else {
      handleClose();
    }
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    handleClose();
  };

  const employmentDetailContent = (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={9}>
          <DetailItem
            label={t("EMPLOYER_NAME")}
            value={employmentDetail?.employerName}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DetailItem label={t("JOB")} value={employmentDetail?.job} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DetailItem
            label={t("OFFICE_PHONE")}
            value={
              <PhoneNumberLink
                phoneNumber={employmentDetail?.officeContactNumber}
              ></PhoneNumberLink>
            }
          />
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <DetailItem
            label={t("DEPARTMENT")}
            value={employmentDetail?.department}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <DetailItem
            label={t("RETIREMENT_AGE")}
            value={employmentDetail?.retirementAge}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DetailItem label={t("GRADE")} value={employmentDetail?.grade} />
        </Grid>
      </Grid>
      <StyledTypography variant="body1" className="mt-32 mb-6 text-lg font-600">
        {t("EMPLOYMENT_DATE")}
      </StyledTypography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <DetailItem
            label={t("START_DATE_OF_WORK")}
            value={employmentDetail?.startServiceDate}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DetailItem
            label={t("END_DATE_OF_WORK")}
            value={employmentDetail?.endServiceDate}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DetailItem
            label={t("PERIOD_OF_SERVICE")}
            value={employmentDetail?.periodOfService}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <DetailItem
            label={t("EMPLOYMENT_STATUS")}
            value={employmentDetail?.employmentStatus}
          />
        </Grid>
      </Grid>
      <StyledTypography variant="body1" className="mt-32 mb-6 text-lg font-600">
        {t("OFFICE_ADDRESS")}
      </StyledTypography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <DetailItem
            label={t("OFFICE_ADDRESS")}
            value={employmentDetail?.officeAddress}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DetailItem
            label={t("CITY")}
            value={employmentDetail?.officeCity}
          ></DetailItem>
        </Grid>
        <Grid item xs={12} sm={4}>
          <DetailItem
            label={t("STATE")}
            value={employmentDetail?.officeState}
          ></DetailItem>
        </Grid>
        <Grid item xs={12} sm={4}>
          <DetailItem
            label={t("POST_CODE")}
            value={employmentDetail?.officePostalCode}
          ></DetailItem>
        </Grid>
      </Grid>
    </>
  );
  return (
    <>
      <CommonAccordion
        title={t("EMPLOYMENT_DETAILS")}
        content={employmentDetailContent}
        remarksList={remarksList}
        isApproved={employmentDetail?.isApproved}
        handleEditOpen={handleEditSectionOpen}
        defaultExpanded={currentFinanceStatus === 'draft' && isEmpty(employmentDetail)}
        permission={FeaturePermissions.UserEmploymentDetail.Update}
      />

      <CustomDialog
        open={isOpen}
        onClose={handleDialogClose}
        content={{
          title: t("EMPLOYMENT_DETAILS"),
          component: (
            <EmploymentDetails
              financeApplicationId={applicationId}
              isEdit={true}
              setIsDirty={setIsDirty}
            />
          ),
        }}
      ></CustomDialog>
      <ChangeDetectDialog
        open={confirmOpen}
        onClose={handleConfirmClose}
        onButtonClick={() => setConfirmOpen(false)}
      />
    </>
  );
};

export default EmploymentDetailAccordion;

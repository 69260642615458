import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import DetailItem from "app/shared-components/DetailItem/DetailItem";
import { StyledTypography } from "app/shared-components/Styles/CustomStyle";
import CommonAccordion from "app/shared-components/Accordion/CommonAccordion";
import CustomDialog from "app/shared-components/Dialog/customDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsReferralDetailEditDialogOpen,
  setReferralDetailEditDialogOpen,
} from "app/store/application-form/applicationFormSlice";
import { useParams } from "react-router";
import ChangeDetectDialog from "../form/ChangeDetectDialog";
import ReferralDetails from "src/app/main/application-form/component/ReferralDetails";
import EmailLink from "app/shared-components/Link/EmailLink";
import PhoneNumberLink from "app/shared-components/Link/PhoneNumberLink";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import { formatDate } from "src/app/services/utils";
import { isEmpty } from "lodash";
import { getCurrentFinanceApplicationStatus } from "app/store/user/userSlice";

const ReferralDetailAccordion = ({ referralDetail, remarksList }) => {
  const { t } = useTranslation("user");

  const dispatch: any = useDispatch();
  const isOpen = useSelector(getIsReferralDetailEditDialogOpen);
  const currentFinanceStatus = useSelector(getCurrentFinanceApplicationStatus);
  const { applicationId } = useParams<{ applicationId: string }>();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const handleClose = () => {
    dispatch(setReferralDetailEditDialogOpen(false));
  };
  const handleEditSectionOpen = () => {
    dispatch(setReferralDetailEditDialogOpen(true));
  };

  const handleDialogClose = () => {
    if (isDirty) {
      setConfirmOpen(true);
    } else {
      handleClose();
    }
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    handleClose();
  };

  const nameWithTitle = referralDetail?.title + referralDetail?.name;

  const referralDetailContent = (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <DetailItem label={t("NAME")} value={nameWithTitle} />
        </Grid>

        <Grid item xs={12} sm={3} md={3}>
          <DetailItem label={t("SEX")} value={referralDetail?.sex} />
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <DetailItem
            label={t("DATE_OF_BIRTH")}
            value={referralDetail?.dateOfBirth}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <DetailItem
            label={t("NATIONAL_ID")}
            value={referralDetail?.nationalId}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <DetailItem
            label={t("RELATIONSHIP")}
            value={referralDetail?.relationship}
          />
        </Grid>
      </Grid>
      <StyledTypography variant="body1" className="mt-32 mb-6 text-lg font-600">
        {t("CONTACT_DETAILS")}
      </StyledTypography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <DetailItem
            label={t("MOBILE_NUMBER")}
            value={
              <PhoneNumberLink
                phoneNumber={referralDetail?.homeMobileNumber}
              ></PhoneNumberLink>
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <DetailItem
            label={t("HOME_PHONE_NUMBER")}
            value={
              <PhoneNumberLink
                phoneNumber={referralDetail?.homePhoneNumber}
              ></PhoneNumberLink>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <DetailItem
            label={t("PERSONAL_EMAIL")}
            value={<EmailLink email={referralDetail?.emailAddress}></EmailLink>}
          />
        </Grid>
      </Grid>
      <StyledTypography variant="body1" className="mt-32 mb-6 text-lg font-600">
        {t("ADDRESS")}
      </StyledTypography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DetailItem
            label={t("HOUSE_ADDRESS")}
            value={referralDetail?.houseAddress}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <DetailItem
            label={t("HOUSE_CITY")}
            value={referralDetail?.houseCity}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DetailItem
            label={t("HOUSE_STATE")}
            value={referralDetail?.houseState}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <DetailItem
            label={t("POST_CODE")}
            value={referralDetail?.housePostalCode}
          />
        </Grid>
      </Grid>
    </>
  );
  return (
    <>
      <CommonAccordion
        title={t("DETAILS_0F_REFERRAL")}
        content={referralDetailContent}
        isApproved={referralDetail?.isApproved}
        remarksList={remarksList}
        handleEditOpen={handleEditSectionOpen}
        defaultExpanded={currentFinanceStatus === 'draft' && isEmpty(referralDetail)}
        permission={FeaturePermissions.UserReferralDetail.Update}
      />

      <CustomDialog
        open={isOpen}
        onClose={handleDialogClose}
        content={{
          title: t("DETAILS_0F_REFERRAL"),
          component: (
            <ReferralDetails
              financeApplicationId={applicationId}
              isEdit={true}
              setIsDirty={setIsDirty}
            />
          ),
        }}
      ></CustomDialog>
      <ChangeDetectDialog
        open={confirmOpen}
        onClose={handleConfirmClose}
        onButtonClick={() => setConfirmOpen(false)}
      />
    </>
  );
};

export default ReferralDetailAccordion;

import FilePreview from "../preview-file/FilePreview";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import SignatureComponent from "./SignatureComponent";
import ColoredSubmitButton from "../Button/ColoredSubmitButton";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsButtonLoading,
  updateUserFinanceDetailsInForm,
} from "app/store/application-form/applicationFormSlice";

const SignatureForm = ({ financeId }) => {
  const { t } = useTranslation("signature");
  const dispatch: any = useDispatch();
  const isSubmitSignatureLoading = useSelector(selectIsButtonLoading);

  const schema = yup.object().shape({
    signature: yup.string().required(),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      signature: "",
    },
  });

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    trigger,
    formState: { isValid },
  } = methods;

  const onSubmit = (data) => {
    const request_data = {
      id: financeId,
      signature: data.signature,
    };
    dispatch(
      updateUserFinanceDetailsInForm(request_data, financeId, false, true)
    );
  };

  const handleSaveSignature = (signatureData) => {
    setValue("signature", signatureData);
    trigger("signature");
  };

  const clearSignature = () => {
    setValue("signature", "");
    trigger("signature");
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-20 ">
        <div className="border border-grey-500 h-full">
          <Controller
            name="signature"
            control={control}
            render={({ field }) => (
              <Box className="flex flex-col gap-10 h-full min-h-200 p-10">
                {getValues("signature") ? (
                  <>
                    <div className="border-dashed  border-2 border-black h-full">
                      <FilePreview fileUrl={getValues("signature")} />
                    </div>
                    <Box className="flex justify-end items-center">
                      <ColoredSubmitButton
                        variant="outlined"
                        onClick={clearSignature}
                        text={t("RETAKE_SIGN")}
                      ></ColoredSubmitButton>
                    </Box>
                  </>
                ) : (
                  <SignatureComponent onSave={handleSaveSignature} />
                )}
              </Box>
            )}
          />
        </div>
        <div className="flex justify-end mt-10">
          <ColoredSubmitButton
            className="capitalize"
            text={t("SUBMIT_SIGN")}
            disabled={!isValid}
            isLoading={isSubmitSignatureLoading}
          ></ColoredSubmitButton>
        </div>
      </form>
    </FormProvider>
  );
};

export default SignatureForm;

const locale = {
  ABOUT_PAGE_TITLE: "About Us",
  ABOUT_PAGE_DESCRIPTION:
    'Koperasi FK Capital Berhad ("fkc.coop")has a rich history that spans several years. It was established on July 15, 2019, with the aim of providing financial empowerment and support to its members. Since its inception, the cooperative has grown steadily and gained recognition as a trusted cooperative.',
  IMAGE_DESCRIPTION_ABOUT_PAGE:
    "The cooperative was formed based on the principles of cooperation and mutual support. Its founders recognized the need for a platform that would enable individuals to access a wide range of financial services and opportunities to improve their financial well-being. Over the years, fkc.coop has focused on building a strong foundation rooted in trust, transparency, and integrity. It has strived to create an inclusive and supportive environment where members can benefit from various financial products, services, and educational resources.",
  STATUS_DESC:
    "Through its commitment to empowering members, fkc.coop has played a significant role in enhancing language literacy, improved socio economy and financial decision-making capabilities among its members. By providing access to savings accounts, loans and financing options, investment opportunities, and education programs, fkc.coop has helped its members achieve their financial goals.<br><br> fkc.coop has also fostered strategic partnerships with reputable organizations in various industries. These partnerships have allowed the cooperative to offer additional value and benefits to its members, ensuring their demands are met.",
  OUR_MISSION_TITLE: "Our Mission",
  OUR_MISSION_DESCRIPTION:
    "To provide comprehensive financial services and solutions that empower individuals and businesses to achieve their financial goals, fostering sustainable economic growth and prosperity.",
  OUR_VISION_TITLE: "Our Vision",
  OUR_VISION_DESCRIPTION:
    "To be a trusted and innovative leader in the financial industry, driving positive change and creating lasting value for our members and communities.",
  VALUE: "Values",
  OBJECTIVE: "Objectives",
  VALUES: [
    "Responsibility Upholding accountability and ethical conduct in operations.",
    "Democracy Embracing democratic principles with member participation in decision-making.",
    "Equality Providing equal opportunities and benefits for all members.",
    "Unity Fostering collaboration for shared goals and prosperity.",
    "Transparency Maintaining openness through clear information sharing.",
    "Social Responsibility Actively engaging in community initiatives for well-being and sustainability.",
  ],
  OBJECTIVES: [
    "Drive Financial Inclusion Expand access to affordable financial services, promoting economic equality and empowerment",
    "Deliver Superior Returns Generate attractive and consistent investment returns for members, ensuring long-term financial stability and growth",
    "Foster Innovation Embrace technological advancements and innovative approaches to enhance products, services, and operational efficiency",
    "Build Member Relationships Cultivate collaborative relationships with members, delivering personalized financial solutions tailored to their needs",
    "Champion Ethical Practices Uphold the highest standards of ethics, integrity, and transparency, fostering trust and confidence in our operations",
  ],
  STATS_DATA: [
    { Key: "START_SINCE", value: "2019", label: "Start Since" },
    { Key: "INSURANCE_COVERAGE", value: "2900++", label: "Insurance Coverage" },
    { Key: "PARTNERS", value: "15", label: "Partners" },
    { Key: "MEMBERSHIP", value: "Nationwide", label: "Membership" },
  ],
};

export default locale;

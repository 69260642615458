import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { StyledTypography } from "app/shared-components/Styles/CustomStyle";
import { FormProvider, useForm, Controller } from "react-hook-form";
import {
  Grid,
  Button,
  styled,
  ButtonProps,
  Box,
  Typography,
} from "@mui/material";
import CustomTextField from "app/shared-components/Form/CustomTextField";
import * as yup from "yup";
import CustomDatePicker from "app/shared-components/Form/CustomDatePicker";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getCurrentStep,
  getEmploymentDetails,
  getEmploymentDetailsData,
  selectIsButtonLoading,
  setCurrentStep,
  updateUserEmploymentDetailsInForm,
  userEmploymentDetailsInForm,
} from "app/store/application-form/applicationFormSlice";
import moment from "moment";
import { tenantId } from "src/app/common/constant";
import ColoredSubmitButton from "app/shared-components/Button/ColoredSubmitButton";
import commonPhoneNumberInputProps from "app/shared-components/Form/commonPhoneNumberInputProps";
import { removeLeadingZero } from "src/app/services/utils";
import { differenceBetweenTwoDateInYear } from "src/app/services/utils";
import { phoneNumberValidation } from "src/app/services/ValidationHelper/validationHelpers";
interface StyledButtonToggleProps extends ButtonProps {
  selected?: boolean;
  theme?: any;
}
const StyledButtonToggle = styled(Button, {
  shouldForwardProp: (prop) => prop !== "selected",
})<StyledButtonToggleProps>((props) => ({
  backgroundColor: props.selected
    ? props.theme.palette.secondary.main
    : "transparent",
  color: props.selected
    ? props.theme.palette.common.white
    : props.theme.palette.common.black,
  border: "1px solid" + props.theme.palette.secondary.contrastText,
  textTransform: "capitalize",
  fontWeight: "400",

  "&:hover": {
    backgroundColor: props.selected
      ? props.theme.palette.secondary.main
      : props.theme.palette.primary.main,
  },
}));

export default function EmploymentDetails({
  financeApplicationId = null,
  isEdit = false,
  setIsDirty = (value) => {},
}) {
  const { t } = useTranslation("applicationForm");
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const activeStep = useSelector(getCurrentStep);
  const [search] = useSearchParams();
  const employmentDetail = useSelector(getEmploymentDetailsData);
  const isLoading = useSelector(selectIsButtonLoading);
  const financeId = search.get("id") || null;
  const userId = search.get("userId") || null;
  useEffect(() => {
    const idToUse = financeId || financeApplicationId;
    if (idToUse) {
      dispatch(getEmploymentDetails(idToUse));
    }
  }, [dispatch, financeId, financeApplicationId]);

  const schema = yup.object().shape({
    employerName: yup
      .string()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("EMPLOYER_NAME") })
      ),
    job: yup
      .string()
      .required(t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("JOB") })),
    grade: yup.string().nullable(),
    officeContactNumber: phoneNumberValidation(true),
    department: yup
      .string()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("DEPARTMENT") })
      ),
    retirementAge: yup
      .string()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("RETIREMENT_AGE") })
      ),
    startServiceDate: yup
      .date()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("START_DATE") })
      )
      .typeError(t("VALID_DATE_VALIDATION"))
      .max(moment().toDate(), "DATE_FUTURE")
      .min(moment("1900-01-01", "YYYY-MM-DD").toDate(), "DATE_PAST_VALIDATION"),
    endServiceDate: yup
      .date()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("END_DATE") })
      )
      .typeError(t("VALID_DATE_VALIDATION"))
      .test({
        name: "end-date-not-before-start-date",
        message: "END_DATE_NOT_SMALL_THAN_START_DATE",
        test: function (value) {
          const startServiceDate = this.parent.startServiceDate;
          return moment(value, "YYYY-MM-DD").isSameOrAfter(startServiceDate);
        },
      }),
    periodOfService: yup
      .string()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("SERVICE_PERIOD") })
      ),
    employmentStatus: yup.string().required(),
    officeAddress: yup
      .string()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("OFFICE_ADDRESS") })
      ),
    officePostalCode: yup
      .string()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("POST_CODE") })
      ),
    officeCity: yup
      .string()
      .required(t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("CITY") })),
    officeState: yup
      .string()
      .required(t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("STATE") })),
  });

  const methods = useForm();
  const {
    handleSubmit,
    formState,
    control,
    reset,
    getValues,
    setValue,
    clearErrors,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const { errors, isValid, isDirty } = formState;

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty, setIsDirty]);

  useEffect(() => {
    if (employmentDetail) {
      setValue("employerName", employmentDetail?.employerName);
      setValue("job", employmentDetail?.job);
      setValue("grade", employmentDetail?.grade);
      setValue("officeContactNumber", employmentDetail?.officeContactNumber);
      setValue("department", employmentDetail?.department);
      setValue("periodOfService", employmentDetail?.periodOfService);
      setValue("officeAddress", employmentDetail?.officeAddress);
      setValue("officePostalCode", employmentDetail?.officePostalCode || "");
      setValue("officeCity", employmentDetail?.officeCity || "");
      setValue("officeState", employmentDetail?.officeState || "");
      setValue("retirementAge", employmentDetail?.retirementAge);
      setValue("employmentStatus", employmentDetail?.employmentStatus);

      const parseDate = (dateString) => {
        const date = moment(dateString, "DD/MMM/YYYY");
        return date.isValid() ? date.toDate() : null;
      };

      const startDate = parseDate(employmentDetail?.startServiceDate);
      const endDate = parseDate(employmentDetail?.endServiceDate);

      setValue("startServiceDate", startDate);
      setValue("endServiceDate", endDate);
      reset(getValues());
    }
  }, [setValue, employmentDetail]);

  function onSubmit(model, event) {
    event.preventDefault();

    const request_data = {
      ...model,
      tenantId: tenantId,
      financeApplicationId: financeId || financeApplicationId,
      startServiceDate: model.startServiceDate.toISOString(),
      endServiceDate: model.endServiceDate.toISOString(),
    };

    const update_data = {
      ...model,
      id: employmentDetail?.id,
      startServiceDate: model.startServiceDate.toISOString(),
      endServiceDate: model.endServiceDate.toISOString(),
    };

    if (
      Object.keys(employmentDetail).length !== 0 &&
      employmentDetail?.isDefault !== true
    ) {
      dispatch(
        updateUserEmploymentDetailsInForm(
          update_data,
          employmentDetail?.financeApplicationId,
          isEdit
        )
      );
    } else {
      dispatch(userEmploymentDetailsInForm(request_data, isEdit));
    }
  }

  const handleBack = () => {
    dispatch(setCurrentStep(activeStep - 1));
    navigate(
      `/application-form/${activeStep - 1}?userId=${userId}&id=${financeId}`
    );
  };
  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-10"
          data-test-id="employment-detail-form"
        >
          <StyledTypography variant="body1" className="mt-20">
            {t("EMPLOYMENT_DETAILS")}
          </StyledTypography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={9} className="mb-8">
                  <Controller
                    name="employerName"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("EMPLOYER_NAME")}
                        fullWidth
                        required
                        onChange={field.onChange}
                        value={field.value || ""}
                        error={
                          errors.employerName && t(errors.employerName.message)
                        }
                        inputProps={{
                          "data-test-id": "employment-form-name-field"
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3} className="mb-8">
                  <Controller
                    name="job"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("JOB")}
                        fullWidth
                        required
                        onChange={field.onChange}
                        value={field.value || ""}
                        error={errors.job && t(errors.job.message)}
                        inputProps={{
                          "data-test-id": "employment-form-job-field"
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} className="mb-8">
                  <Controller
                    name="officeContactNumber"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        type="number"
                        label={t("OFFICE_PHONE")}
                        value={field.value || ""}
                        required
                        fullWidth
                        onChange={(value: any) => {
                          field.onChange(removeLeadingZero(value));
                        }}
                        InputProps={commonPhoneNumberInputProps}
                        error={
                          errors.officeContactNumber &&
                          errors.officeContactNumber.message
                        }
                        inputProps={{
                          "data-test-id": "employment-form-office-phone-field"
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3} className="mb-8">
                  <Controller
                    name="department"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("DEPARTMENT")}
                        onChange={field.onChange}
                        value={field.value || ""}
                        fullWidth
                        required
                        error={
                          errors.department && t(errors.department.message)
                        }
                        inputProps={{
                          "data-test-id": "employment-form-department-field"
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3} className="mb-8">
                  <Controller
                    name="retirementAge"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("RETIREMENT_AGE")}
                        onChange={field.onChange}
                        value={field.value || ""}
                        required
                        fullWidth
                        error={
                          errors.retirementAge &&
                          t(errors.retirementAge.message)
                        }
                        inputProps={{
                          "data-test-id": "employment-form-retirement-age-field"
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3} className="mb-8">
                  <Controller
                    name="grade"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("GRADE")}
                        fullWidth
                        onChange={field.onChange}
                        value={field.value || ""}
                        error={errors.grade && t(errors.grade.message)}
                        inputProps={{
                          "data-test-id": "employment-form-grade-field"
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <StyledTypography variant="body1" className="mt-20">
            {t("EMPLOYMENT_DATE")}
          </StyledTypography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} className="mb-8">
                  <Controller
                    name="startServiceDate"
                    control={control}
                    render={({ field }) => (
                      <CustomDatePicker
                        label={t("START_DATE")}
                        onChange={(date) => {
                          field.onChange(date);

                          if (!date || !moment(date, "DD/MMM/YYYY").isValid()) {
                            setValue("endServiceDate", null);
                            setError("endServiceDate", {
                              type: "manual",
                              message: t("START_DATE_REQUIRED"),
                            });
                            setValue("periodOfService", "");
                          } else {
                            clearErrors("endServiceDate");

                            // Calculate the period of service between the start date and current date
                            const difference = differenceBetweenTwoDateInYear(
                              date,
                              moment().toDate()
                            );
                            setValue(
                              "periodOfService",
                              difference.toFixed(2).toString()
                            );
                          }

                          const endDate = getValues("endServiceDate");

                          // Check if the start date is greater than the end date
                          if (
                            endDate &&
                            moment(date, "DD/MMM/YYYY").isAfter(endDate)
                          ) {
                            setError("endServiceDate", {
                              type: "manual",
                              message: t("END_DATE_NOT_SMALL_THAN_START_DATE"),
                            });
                          } else {
                            clearErrors("endServiceDate");
                          }
                        }}
                        value={field.value}
                        maxDate={moment().toDate()} // set maxDate to today's date
                        required
                        error={
                          errors.startServiceDate &&
                          t(errors.startServiceDate.message)
                        }
                        datatestid="employment-detail-form-start-service-date-picker"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={4} className="mb-8">
                  <Controller
                    name="endServiceDate"
                    control={control}
                    render={({ field }) => (
                      <CustomDatePicker
                        label={t("END_DATE")}
                        onChange={(date) => {
                          field.onChange(date);
                        }}
                        value={field.value}
                        required
                        disabled={!getValues("startServiceDate")}
                        minDate={getValues("startServiceDate")}
                        error={
                          errors.endServiceDate &&
                          t(errors.endServiceDate.message)
                        }
                        datatestid="employment-detail-form-end-service-date-picker"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={4} className="mb-8">
                  <Controller
                    name="periodOfService"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("SERVICE_PERIOD")}
                        onChange={field.onChange}
                        value={field.value || ""}
                        required
                        fullWidth
                        error={
                          errors.periodOfService &&
                          t(errors.periodOfService.message)
                        }
                        inputProps={{
                          "data-test-id":"employment-form-period-of-service-field"
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <StyledTypography variant="body1" className="mt-20">
            {t("JOB_STATUS")}* :
          </StyledTypography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Controller
                name="employmentStatus"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <StyledButtonToggle
                    selected={field.value === "Temporary"}
                    fullWidth
                    onClick={() => field.onChange("Temporary")}
                    data-test-id="employment-form-job-status-temporary"
                  >
                    {t("TEMPORARY")}
                  </StyledButtonToggle>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="employmentStatus"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <StyledButtonToggle
                    selected={field.value === "Contract"}
                    fullWidth
                    onClick={() => field.onChange("Contract")}
                    data-test-id="employment-form-job-status-contract"
                  >
                    {t("CONTRACT")}
                  </StyledButtonToggle>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="employmentStatus"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <StyledButtonToggle
                    selected={field.value === "Permanent"}
                    fullWidth
                    onClick={() => field.onChange("Permanent")}
                    data-test-id="employment-form-job-status-permanent"
                  >
                    {t("PERMANENT")}
                  </StyledButtonToggle>
                )}
              />
            </Grid>
          </Grid>
          {errors.employmentStatus && (
            <Typography variant="subtitle2" fontSize={"1.2rem"} color="error">
              {errors.employmentStatus && t(errors.employmentStatus.message)}
            </Typography>
          )}
          <StyledTypography variant="body1" className="mt-20">
            {t("OFFICE_ADDRESS")}
          </StyledTypography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} className="mb-8">
                  <Controller
                    name="officeAddress"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("OFFICE_ADDRESS")}
                        fullWidth
                        required
                        onChange={field.onChange}
                        value={field.value || ""}
                        error={
                          errors.officeAddress &&
                          t(errors.officeAddress.message)
                        }
                        inputProps={{
                          "data-test-id": "employment-form-office-address-field",
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} className="mb-8">
                  <Controller
                    name="officeCity"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("CITY")}
                        onChange={field.onChange}
                        value={field.value || ""}
                        fullWidth
                        required
                        error={
                          errors.officeCity && t(errors.officeCity.message)
                        }
                        inputProps={{
                          "data-test-id": "employment-form-office-city-field",
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4} className="mb-8">
                  <Controller
                    name="officeState"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("STATE")}
                        value={field.value || ""}
                        onChange={field.onChange}
                        fullWidth
                        required
                        error={
                          errors.officeState && t(errors.officeState.message)
                        }
                        inputProps={{
                          "data-test-id": "employment-form-office-state-field",
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4} className="mb-8">
                  <Controller
                    name="officePostalCode"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("POST_CODE")}
                        onChange={field.onChange}
                        value={field.value || ""}
                        fullWidth
                        required
                        error={
                          errors.officePostalCode &&
                          t(errors.officePostalCode.message)
                        }
                        inputProps={{
                          "data-test-id": "employment-form-office-postal-code-field",
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box>
            <Box className="flex justify-end items-end gap-20 mt-10">
              {isEdit ? (
                <ColoredSubmitButton
                  className="w-120"
                  disabled={!isValid}
                  text={t("SAVE")}
                  isLoading={isLoading}
                  data-test-id="employment-detail-form-submit-btn"
                ></ColoredSubmitButton>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    className="capitalize w-120"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {t("PREVIOUS")}
                  </Button>
                  <ColoredSubmitButton
                    className="w-120"
                    disabled={!isValid}
                    text={t("NEXT")}
                    isLoading={isLoading}
                    data-test-id="employment-detail-form-submit-btn"
                  ></ColoredSubmitButton>
                </>
              )}
            </Box>
          </Box>
        </form>
      </FormProvider>
    </>
  );
}

import React from "react";
import { Tooltip, TooltipProps, Zoom } from "@mui/material";

interface CustomTooltipProps extends TooltipProps {
  title: string;
  children: React.ReactElement;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ title, children, ...props }) => {
  return (
    <Tooltip
      title={title}
      arrow
      TransitionComponent={Zoom}
      leaveDelay={500}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;

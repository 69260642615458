import React from "react";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import { IconButton, Typography } from "@mui/material";
import { copyToClipBoard, formatPhoneNumber } from "src/app/services/utils";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import EmailLink from "../Link/EmailLink";
import PhoneNumberLink from "../Link/PhoneNumberLink";
import CustomTooltipWithWhitePaper from "./CustomTooltipWithWhitePaper";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const MemberDetailTooltip = ({ memberDetail }) => {
  const { t } = useTranslation("tooltip");
  const dispatch: any = useDispatch();

  const memberDetailContent = (
    <>
      <div className="flex gap-5 justify-center flex-col px-8 py-5">
        <div className="flex items-center justify-start gap-5">
          <div className="w-20">
            <PersonOutlineOutlinedIcon color="action" fontSize="medium" />
          </div>
          <Typography
            variant="body1"
            className="text-14 !font-normal"
            color="GrayText"
          >
            {memberDetail?.fullName || "--"}
          </Typography>
        </div>
        <div className="flex items-center justify-start gap-5">
          <div className="w-20">
            <EmailOutlinedIcon color="action" fontSize="medium" />
          </div>

          <Typography variant="body1" className="text-14 !font-normal">
            <EmailLink email={memberDetail?.email} />
          </Typography>
          {memberDetail?.email && (
            <IconButton
              className="!p-0 ml-5"
              onClick={() => copyToClipBoard(memberDetail?.email, t, dispatch)}
            >
              <ContentCopyIcon color="action" fontSize="small" />
            </IconButton>
          )}
        </div>
        <div className="flex items-center justify-start gap-5">
          <div className="w-20">
            <PhoneOutlinedIcon color="action" fontSize="small" />
          </div>
          <Typography variant="body1" className="text-14 !font-normal">
            <PhoneNumberLink phoneNumber={memberDetail?.phoneNumber} />
          </Typography>

          {memberDetail?.phoneNumber && (
            <IconButton
              className="!p-0 ml-5"
              onClick={() =>
                copyToClipBoard(
                  formatPhoneNumber(memberDetail?.phoneNumber),
                  t,
                  dispatch
                )
              }
            >
              <ContentCopyIcon color="action" fontSize="small" />
            </IconButton>
          )}
        </div>
        <div className="flex items-center justify-start gap-5">
          <div className="w-20">
            <BadgeOutlinedIcon color="action" fontSize="small" />
          </div>
          <Typography
            variant="body1"
            className="text-14 !font-normal"
            color="GrayText"
          >
            {memberDetail?.nationalId || "--"}
          </Typography>
        </div>
      </div>
    </>
  );

  return (
    <>
      <CustomTooltipWithWhitePaper
        tooltipContent={memberDetailContent}
        title={memberDetail?.fullName}
      />
    </>
  );
};

export default MemberDetailTooltip;

import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import DetailItem from "app/shared-components/DetailItem/DetailItem";
import { StyledTypography } from "app/shared-components/Styles/CustomStyle";
import { formatAmount } from "src/app/services/utils";
import CommonAccordion from "app/shared-components/Accordion/CommonAccordion";
import CustomDialog from "app/shared-components/Dialog/customDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsIncomeEditDialogOpen,
  setIncomeDetailDialogOpen,
} from "app/store/application-form/applicationFormSlice";
import { useParams } from "react-router";
import IncomeDetails from "src/app/main/application-form/component/IncomeDetails";
import ChangeDetectDialog from "../form/ChangeDetectDialog";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import { isEmpty } from "lodash";
import { getCurrentFinanceApplicationStatus } from "app/store/user/userSlice";

const IncomeDetailAccordion = ({ incomeDetail, remarksList }) => {
  const { t } = useTranslation("user");
  const dispatch: any = useDispatch();
  const isOpen = useSelector(getIsIncomeEditDialogOpen);
  const currentFinanceStatus = useSelector(getCurrentFinanceApplicationStatus);
  const { applicationId } = useParams<{ applicationId: string }>();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const handleClose = () => {
    dispatch(setIncomeDetailDialogOpen(false));
  };
  const handleEditSectionOpen = () => {
    dispatch(setIncomeDetailDialogOpen(true));
  };

  const handleDialogClose = () => {
    if (isDirty) {
      setConfirmOpen(true);
    } else {
      handleClose();
    }
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    handleClose();
  };

  const incomeDetailContent = (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <DetailItem
            label={t("BASIC_SALARY")}
            value={formatAmount(incomeDetail?.basicSalary)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <DetailItem
            label={t("SALARY_INCREMENT")}
            value={formatAmount(incomeDetail?.salaryIncrement)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DetailItem
            label={t("FIXED_ALLOWANCES")}
            value={formatAmount(incomeDetail?.fixedAllowances)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <DetailItem
            label={t("DATE_OF_INCREMENT")}
            value={incomeDetail?.dateOfIncrement}
          />
        </Grid>
      </Grid>
      <StyledTypography variant="body1" className="mt-32 mb-6 text-lg font-600">
        {t("SALARY_DETAIL")}
      </StyledTypography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <DetailItem
            label={t("TOTAL_DEDUCTION")}
            value={formatAmount(incomeDetail?.totalDeduction)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <DetailItem
            label={t("NET_SALARY")}
            value={formatAmount(incomeDetail?.netSalary)}
          />
        </Grid>
      </Grid>
      <StyledTypography variant="body1" className="mt-32 mb-6 text-lg font-600">
        {t("REMARKS")}
      </StyledTypography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DetailItem label={t("REMARKS")} value={incomeDetail?.remarks} />
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      <CommonAccordion
        title={t("INCOME_DETAILS")}
        content={incomeDetailContent}
        remarksList={remarksList}
        isApproved={incomeDetail?.isApproved}
        handleEditOpen={handleEditSectionOpen}
        defaultExpanded={currentFinanceStatus === 'draft' && isEmpty(incomeDetail)}
        permission={FeaturePermissions.UserIncomeDetail.Update}
      />
      <CustomDialog
        open={isOpen}
        onClose={handleDialogClose}
        content={{
          title: t("INCOME_DETAILS"),
          component: (
            <IncomeDetails
              financeApplicationId={applicationId}
              isEdit={true}
              setIsDirty={setIsDirty}
            />
          ),
        }}
      ></CustomDialog>
      <ChangeDetectDialog
        open={confirmOpen}
        onClose={handleConfirmClose}
        onButtonClick={() => setConfirmOpen(false)}
      />
    </>
  );
};

export default IncomeDetailAccordion;

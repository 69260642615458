import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import WidgetItem from "./WidgetItem";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { formatAmount } from "src/app/services/utils";
import { getWidgetData, getWidgetDataFromApi } from "./store/dashboardSlice";

interface TitleAndColor {
  title: string;
  color: string;
}

const Widget = () => {
  const { t } = useTranslation("dashboard");
  const dispatch: any = useDispatch();
  const data = useSelector(getWidgetData);

  const addTitleAndColor = (data: any): Record<string, TitleAndColor> => {
    const titlesAndColors = {
      totalFinancedAmount: {
        title: t("FINANCED_AMOUNT"),
        color: "text-blue-600",
      },
      totalCustomers: { title: t("CUSTOMERS"), color: "text-green-600" },
      totalApplications: { title: t("APPLICATIONS"), color: "text-amber-600" },
    };

    const transformedData: Record<string, any> = {};

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        let transformedValue = data[key];
        if (key === "totalFinancedAmount") {
          transformedValue = {
            ...data[key],
            today: formatAmount(data[key]?.today),
            lastWeek: formatAmount(data[key]?.lastWeek),
            lastMonth: formatAmount(data[key]?.lastMonth),
            total: formatAmount(data[key]?.total),
          };
        }
        transformedData[key] = {
          ...transformedValue,
          title: titlesAndColors[key]?.title,
          color: titlesAndColors[key]?.color,
        };
      }
    }

    return transformedData;
  };

  useEffect(() => {
    dispatch(getWidgetDataFromApi());
  }, [dispatch]);

  const widgetData = addTitleAndColor(data);

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        className="flex items-center"
      >
        {Object.keys(widgetData)?.map((key, index) => (
          <Grid key={index} item xs={12} sm={4} md={4}>
            <WidgetItem data={widgetData[key]} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Widget;

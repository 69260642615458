import { useLocation, useNavigate } from "react-router-dom";

export const useQueryParams = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    const pageIndex = parseInt(searchParams.get("pageIndex") || "0");
    const pageSize = parseInt(searchParams.get("pageSize") || "10");
    const sortField = searchParams.get("sortField") || null;
    const sortOrder = searchParams.get("sortOrder") || null;
    const searchKeyword = searchParams.get("searchKeyword") || "";
    const status = searchParams.get("status") || "";
    const startDate = searchParams.get("startDate") || "";
    const endDate = searchParams.get("endDate") || "";

    return {
      pageIndex,
      pageSize,
      sortField,
      sortOrder,
      searchKeyword,
      status,
      startDate,
      endDate,
    };
  };

  const updateQueryParams = (params) => {
    const {
      pageIndex = 0,
      pageSize = 10,
      sortField = "",
      sortOrder = "",
      searchKeyword = "",
      status = "",
      startDate = "",
      endDate = "",
      miniAgentSearchKeyword = "",
      selectedAgent = "",
    } = params;

    const searchParams = new URLSearchParams(location.search);

    // Always set pageIndex and pageSize
    searchParams.set("pageIndex", pageIndex.toString());
    searchParams.set("pageSize", pageSize.toString());

    // Handle other parameters
    if (sortField) searchParams.set("sortField", sortField);
    else searchParams.delete("sortField");

    if (sortOrder) searchParams.set("sortOrder", sortOrder);
    else searchParams.delete("sortOrder");

    if (searchKeyword) searchParams.set("searchKeyword", searchKeyword);
    else searchParams.delete("searchKeyword");

    if (miniAgentSearchKeyword)
      searchParams.set("miniAgentSearchKeyword", miniAgentSearchKeyword);
    else searchParams.delete("miniAgentSearchKeyword");

    if (status) searchParams.set("status", status);
    else searchParams.delete("status");

    if (startDate) searchParams.set("startDate", startDate);
    else searchParams.delete("startDate");

    if (endDate) searchParams.set("endDate", endDate);
    else searchParams.delete("endDate");

    if (selectedAgent) searchParams.set("selectedAgent", selectedAgent);
    else searchParams.delete("selectedAgent");

    navigate({ search: searchParams.toString() }, { replace: true });
  };

  return { getQueryParams, updateQueryParams };
};

export const handleQueryParamsAndFilters = ({
  dispatch,
  updateQueryParams,
  filter = {} as any,
  pageSize,
  pageIndex,
  sortField,
  sortOrder,
  setPaginationAction,
  setFilters = null,
}) => {
  const queryParams = new URLSearchParams(location.search);
  const keyword = queryParams.get("searchKeyword") || "";
  const status = queryParams.get("status") || "";
  const startDate = queryParams.get("startDate") || "";
  const endDate = queryParams.get("endDate") || "";
  const selectedAgent = queryParams.get("selectedAgent") || "";
  const miniAgentSearchKeyword =
    queryParams.get("miniAgentSearchKeyword") || "";

  if (setFilters) {
    if (!filter.status) {
      dispatch(setFilters({ status }));
    }
    if (!filter.searchKeyword) {
      dispatch(setFilters({ searchKeyword: keyword }));
    }
    if (!filter.startDate) {
      dispatch(setFilters({ startDate }));
    }
    if (!filter.endDate) {
      dispatch(setFilters({ endDate }));
    }
    if (!filter.selectedAgent) {
      dispatch(setFilters({ selectedAgent }));
    }
    if (!filter.miniAgentSearchKeyword) {
      dispatch(setFilters({ miniAgentSearchKeyword }));
    }
  }

  updateQueryParams({
    startDate,
    endDate,
    searchKeyword: keyword,
    status,
    pageSize,
    pageIndex,
    sortField,
    sortOrder,
    miniAgentSearchKeyword,
    selectedAgent,
  });

  dispatch(
    setPaginationAction({
      pageSize,
      pageIndex,
      sortField,
      sortOrder,
    })
  );
};

const locale = {
  BANNER_TITLE: "FKC.COOP",
  BANNER_DESCRIPTION:
    "Sebuah koperasi Malaysia yang ditubuhkan pada tahun 2019 di bawah Suruhanjaya Koperasi Malaysia (SKM), komited untuk menyediakan kemudahan kredit, memacu digitalisasi, dan menyokong e-dagang. Misi kami adalah untuk meningkatkan taraf hidup golongan yang kurang berkemampuan, membolehkan digitalisasi untuk semua, dan menjadi antara gerakan koperasi terbaik di Malaysia dan dunia.",
  BANNER_BUTTON_TEXT: "Memohon Pembiayaan",
  OURPRODUCT_TITLE: "Produk Kami",
  OURPRODUCT_DESCRIPTION:
    "Produk/Perkhidmatan dengan pilihan bantuan kewangan yang fleksibel tersedia.",
  COMMONHEADER_BUTTON: "Lihat Semua",
  OURSERVICE_TITLE: "Perkhidmatan Kami",
  OURSERVICE_DESCRIPTION:
    "Perkhidmatan yang komprehensif dengan tumpuan komuniti.",
  FINANCE_SERVICE_TITLE: "Pembiayaan",
  FINANCE_SERVICE_DESCRIPTION:
    "Kami menawarkan perkhidmatan kewangan yang boleh dipercayai kepada ahli koperasi yang direka khas untuk keperluan mereka.",
  INSURANCE_SERVICE_TITLE: "Insurans",
  INSURANCE_SERVICE_DESCRIPTION:
    "Koperasi kami menyediakan pakej insurans Islam dan bukan Islam kepada pelbagai pilihan ahli kami.",
  WASIAT_SERVICE_TITLE: "Wasiat, Wasiat & Hibah",
  WASIAT_SERVICE_DESCRIPTION:
    "Koperasi kami menyediakan pakej insurans Islam dan bukan Islam kepada pelbagai pilihan ahli kami.",
  DIGITALIZATION_SERVICE_TITLE: "Digitalisasi",
  DIGITALIZATION_SERVICE_DESCRIPTION:
    "Kami menyediakan perkhidmatan digital kepada ahli koperasi kami, termasuk pelayan awan dan banyak lagi.",
  CONSUMER_SERVICE_TITLE: "Produk Pengguna",
  CONSUMER_SERVICE_DESCRIPTION:
    "Kami menyediakan ahli koperasi kami dengan pelbagai barangan pengguna seperti Huawei, Samsung, Apple, Perhiasan Tomei, Rancangan Prepaid dan Posbayar Yes5G, rancangan posbayar dan prabayar mudah alih dan banyak lagi!",
  MEMBERSHIP_TITLE: "Faedah Keahlian",
  MEMBERSHIP_DESCRIPTION:
    "Sertai keahlian koperasi kami untuk pelbagai faedah, termasuk perkhidmatan kewangan dan sokongan komuniti.",
  DIVIDEND_BENEFIT_NAME: "Dividen yang kompetitif",
  DIVIDEND_BENEFIT_DESCRIPTION:
    "Koperasi kami memberikan ganjaran kepada ahli dengan pelbagai perniagaan yang hebat dengan dividen yang kompetitif dan sokongan berterusan.",
  EXCLUSIVE_INSURANCE_BENEFIT_NAME: "Diskaun Insurans Eksklusif & Wasiat",
  EXCLUSIVE_INSURANCE_BENEFIT_DESCRIPTION:
    "Diskaun khas kepada ahli\nInsurans Kenderaan (5%)\nPakej Insurans CCM\nInsurans Hayat dan banyak lagi",
  SPECIAL_FUND_BENEFIT_NAME: "Dana Khas untuk ahli",
  SPECIAL_FUND_BENEFIT_DESCRIPTION:
    "Dana Darurat\nBantuan Pendidikan\nPerbelanjaan Perubatan Dan banyak lagi.",
  WHY_KOPERASI_TITLE: "Mengapa Koperasi FK Capital Berhad?",
  DIGITAL_COOPERATIVE_WHY_KOPERASI: "Koperasi Digital",
  SHARIAH_COMPLIANT_WHY_KOPERASI: "Patuh Shariah",
  REGISTERED_ICON_WHY_KOPERASI: "Berdaftar dengan SKM Malaysia",
  MEMBERSHIP_ICON_WHY_KOPERASI: "Keahlian di seluruh negara",
  MARKETACCESS_ICON_WHY_KOPERASI: "Akses Pasaran yang Diperkukuh",
  PERSON_ICON_WHY_KOPERASI: "Faedah Hebat Ahli",
  HANDHOLDINGDOLLAR_ICON_WHY_KOPERASI: "Akses kepada Perkhidmatan Kewangan",
  FEEDBACK_TITLE: "Apa Kata Pelanggan Tentang Kami",
};

export default locale;

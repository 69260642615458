import { Badge, Box, Button, Card, Paper, Typography } from "@mui/material";
import history from "@history";
import  { useEffect } from "react";
import { hasPermission, notificationRedirect } from "src/app/services/utils";
import { useDispatch, useSelector } from "react-redux";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { formatDistanceToNow } from "date-fns";
import moment from "moment";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import themesConfig from "app/configs/themesConfig";
import { useTranslation } from "react-i18next";
import {
  dashboardNotificationReadAsMark,
  getAllDashboardNotificationFromApi,
  getAllDashboardNotificationList,
} from "./store/dashboardSlice";
import { isEmpty } from "lodash";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";

const DashboardNotification = () => {
  const dispatch: any = useDispatch();
  const { t } = useTranslation("dashboard");

  const notificationDetail = useSelector(getAllDashboardNotificationList);
  const isPermissionToReadNotification = hasPermission(FeaturePermissions.UserNotification.MarkAsRead)

  useEffect(() => {
    dispatch(getAllDashboardNotificationFromApi());
  }, [dispatch]);

  const handleRedirectFromNotification = (notification) => {
    if(isPermissionToReadNotification){
			dispatch(dashboardNotificationReadAsMark(notification.id))
		}
    notificationRedirect(notification);
  };

  return (
    <Paper className="flex flex-col flex-auto px-24 py-20 shadow rounded-2xl overflow-hidden h-[max-content] w-full">
      {/* Header with "View All" button */}
      <div className="flex justify-between items-center h-32">
        <Typography className="text-lg font-medium tracking-tight leading-6">
          {t("NOTIFICATION")}
        </Typography>
        {!isEmpty(notificationDetail) && (
          <Button
            variant="text"
            color="secondary"
            onClick={() => {
              history.push("/notifications");
            }}
          >
            {t("VIEW_ALL")}
          </Button>
        )}
      </div>
      {/* Notification Card */}
      <div className=" flex flex-col flex-grow content-between bg-white ">
        {notificationDetail && notificationDetail.length > 0 ? (
          <div className="w-full flex flex-col content-between gap-6 py-10 flex-grow">
            {notificationDetail.slice(0, 5).map((notification, index) => (
              <Card
                className={`relative flex min-h-64 w-full items-center space-x-8 rounded-16 p-20 shadow`}
                elevation={2}
                component="div"
                role="button"
                key={index}
                sx={{
                  backgroundColor:
                    themesConfig.greyDark.palette.warning.contrastText,
                }}
                onClick={() => handleRedirectFromNotification(notification)}
              >
                <Box
                  sx={{ backgroundColor: "background.default" }}
                  className="mr-12 flex h-32 w-32 shrink-0 items-center justify-center rounded-full"
                >
                  <Badge
                    className="text-xl"
                    color="secondary"
                    variant="dot"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  >
                    <FuseSvgIcon className="opacity-75" color="inherit">
                      heroicons-solid:star
                    </FuseSvgIcon>
                  </Badge>
                </Box>
                <div className="flex flex-auto flex-col">
                  {notification.title && (
                    <Typography className="line-clamp-1 font-semibold">
                      {notification.title}
                    </Typography>
                  )}

                  {notification.description && (
                    <div
                      className="text-justify mr-5 line-clamp-3 overflow-hidden text-ellipsis"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: notification.description,
                      }}
                    />
                  )}

                  {notification.lastModified && (
                    <Typography
                      className="mt-8 text-sm leading-none "
                      color="text.secondary"
                    >
                      {formatDistanceToNow(
                        moment(
                          notification.lastModified,
                          "DD/MMM/YYYY HH:mm:ss"
                        ).toDate(),
                        { addSuffix: true }
                      )}
                    </Typography>
                  )}
                </div>
              </Card>
            ))}
          </div>
        ) : (
          <div className="flex flex-col items-center bg-grey-200 justify-center rounded-16 flex-grow p-16 gap-8">
            <NotificationsOffIcon color="disabled" className="text-9xl" />
            <Typography className="text-center  text-18" color="text.secondary">
              {t("THERE_ARE_NO_NOTIFICATION_FOR_NOW")}
            </Typography>
          </div>
        )}
      </div>
    </Paper>
  );
};

export default DashboardNotification;

import React from "react";
import { green, grey } from "@mui/material/colors";
import { styled } from "@mui/system";
import { Verified } from "@mui/icons-material";

const VerifiedIcon = styled(Verified)(({ theme }) => ({
  color: green[500],
  fontSize: 24,
  borderRadius: "50%",
  backgroundColor: grey[50],
  padding: 3,
}));

const VerifiedBadge = () => {
  return <VerifiedIcon />;
};

export default VerifiedBadge;

import React from "react";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { styled } from "@mui/system";
import { darkPaletteText } from "app/configs/themesConfig";

const StyledEditOutlinedIcon = styled(EditOutlinedIcon)(({ theme }) => ({
  color: theme.palette.secondary.main,
  "&.Mui-disabled": {
    color: darkPaletteText.disabled,
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const CustomIconButton = ({
  onClick,
  className = "",
  children = null,
  disabled = false,
}) => {
  const combinedClassName = `pointer-events-auto ${className}`.trim();

  return (
    <>
      <StyledIconButton
        className={combinedClassName}
        onClick={onClick}
        disabled={disabled}
      >
        {children ? (
          children
        ) : (
          <StyledEditOutlinedIcon className={disabled ? "Mui-disabled" : ""} />
        )}
      </StyledIconButton>
    </>
  );
};

export default CustomIconButton;

import FuseUtils from "@fuse/utils/FuseUtils";
import axios, { AxiosError, AxiosResponse } from "axios";
import jwtDecode, { JwtPayload } from "jwt-decode";
import UserType from "app/store/user/UserType";
import { PartialDeep } from "type-fest";
import jwtServiceConfig from "./jwtServiceConfig";
import api, { setSession } from "src/app/services/apiService";
import history from "@history";
import { access } from "fs";
/* eslint-disable camelcase, class-methods-use-this */

/**
 * The JwtService class is a utility class for handling JSON Web Tokens (JWTs) in the Fuse application.
 * It provides methods for initializing the service, setting interceptors, and handling authentication.
 */
class JwtService extends FuseUtils.EventEmitter {
  /**
   * Initializes the JwtService by setting interceptors and handling authentication.
   */
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  /**
   * Sets the interceptors for the Axios instance.
   */
  setInterceptors = () => {
    axios.interceptors.response.use(
      (response: AxiosResponse<unknown>) => response,
      (err: AxiosError) =>
        new Promise(() => {
          if (
            (err?.response?.status === 401 || err?.response?.status === 440) &&
            err.config
          ) {
            // if you ever get an unauthorized response, logout the user
            this.emit("onAutoLogout", "Invalid access_token");
            setSession(null);
          }
          throw err;
        })
    );
  };

  /**
   * Handles authentication by checking for a valid access token and emitting events based on the result.
   */
  handleAuthentication = () => {
    const access_token = getAccessToken();

    if (!access_token) {
      this.emit("onNoAccessToken");

      return;
    }

    if (isAuthTokenValid(access_token)) {
      setSession(access_token);
      this.emit("onAutoLogin", true);
    } else {
      setSession(null);
      this.emit("onAutoLogout");
    }
  };

  /**
   * Creates a new user account.
   */
  createUser = (data: any) =>
    new Promise((resolve, reject) => {
      api
        .post(jwtServiceConfig.signUp, data)
        .then((response: any) => {
          if (response.status === "success") {
            resolve(response);
          } else {
            reject(response.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  sendOTP = (request_data) =>
    new Promise((resolve, reject) => {
      api
        .post(jwtServiceConfig.sendOtp, request_data)
        .then((response: any) => {
          if (response.status === "success") {
            resolve(response);
          } else {
            reject(response.message);
          }
        })
        .catch((error) => {
          const errorMessage =
            error.response?.data?.message || error.message || "Unknown error";
          console.error("sendOTP error:", error);
          reject(errorMessage);
        });
    });

  verifyOTP = (request_data) =>
    new Promise((resolve, reject) => {
      const url = `${jwtServiceConfig.verifyOTP}?Mobile=${request_data.mobile}&OTP=${request_data.otp}&OTPType=${request_data.OTPType}`;
      api
        .get(url)
        .then((response: any) => {
          if (response.status === "success") {
            resolve(response);
          } else {
            reject(response.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  /**
   * Signs in with the provided email and password.
   */
  signInWithEmailAndPassword = (response_data) => {
    if (
      response_data &&
      response_data.result &&
      response_data.result.authResponse
    ) {
      const response = {
        user: {
          role: "admin",
          data: {
            id: response_data.result.authResponse.user.id,
            displayName: response_data.result.authResponse.user.fullName,
            photoURL: response_data.result.authResponse.user.profileImage,
            email: response_data.result.authResponse.user.email,
            extraData: response_data.result.authResponse.user,
          },
          featurePermission:
            response_data.result.authResponse.featurePermission,
          slug: response_data.result.authResponse.slug,
        },
        refresh_token: response_data.result.authResponse.refreshToken,
        access_token: response_data.result.authResponse.token,
      };

      if (response.user) {
        setSession(response.access_token, response.refresh_token);
        this.emit("onLogin", response.user);
      }
    } else {
      console.error("Unexpected response structure", response_data);
    }
  };

  /**
   * Signs in with the provided provider.
   */
  signInWithToken = () =>
    new Promise<UserType>((resolve, reject) => {
      api
        .post(jwtServiceConfig.accessToken, {
          // access_token: getAccessToken(),
          refreshToken: getRefreshToken(),
        })
        .then((response_data: any) => {
          const response = {
            user: {
              role: "admin",
              data: {
                id: response_data.user.id,
                displayName: response_data.user.fullName,
                photoURL: response_data.user.profileImage,
                email: response_data.user.email,
                extraData: response_data.user,
              },
              featurePermission: response_data.featurePermission,
              slug: response_data.slug,
            },
            refresh_token: response_data.refreshToken,
            access_token: response_data.token,
          };
          if (response.user) {
            // _setSession(response.refresh_token);
            setSession(response.access_token, response.refresh_token);
            resolve(response.user);
          } else {
            this.logout();
            reject(new Error("Please Login Again"));
            history.push("/sign-in");
          }
        })
        .catch(() => {
          this.logout();
          reject(new Error("Please Login Again"));
          history.push("/sign-in");
        });
    });

  /**
   * Updates the user data.
   */
  updateUserData = (data: any) => api.put(jwtServiceConfig.updateUser, data);

  /**
   * Signs out the user.
   */
  logout = () => {
    setSession(null);
    this.emit("onLogout", "Logged out");
  };
}

/**
 * Sets the session by storing the access token in the local storage and setting the default authorization header.
 */

// function _setSession(access_token: string | null) {
//   if (access_token) {
//     setAccessToken(access_token);
//     axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
//   } else {
//     removeAccessToken();
//     delete axios.defaults.headers.common.Authorization;
//   }
// }

/**
 * Checks if the access token is valid.
 */
function isAuthTokenValid(access_token: string) {
  if (!access_token) {
    return false;
  }
  const decoded = jwtDecode<JwtPayload>(access_token);
  const currentTime = Date.now() / 1000;

  if (decoded.exp < currentTime) {
    // eslint-disable-next-line no-console
    console.warn("access token expired");
    return false;
  }

  return true;
}

/**
 * Gets the access token from the local storage.
 */
function getAccessToken() {
  return window.localStorage.getItem("jwt_access_token");
}

/**
 * Sets the access token in the local storage.
 */
function setAccessToken(access_token: string) {
  return window.localStorage.setItem("jwt_access_token", access_token);
}

const getRefreshToken = () => {
  return window.localStorage.getItem("refresh_token");
};
/**
 * Removes the access token from the local storage.
 */
function removeAccessToken() {
  return window.localStorage.removeItem("jwt_access_token");
}

const instance = new JwtService();

export default instance;

import { makeStyles } from "@mui/styles";
import themesConfig from "app/configs/themesConfig";
import { darken } from "@mui/system";
import { Button } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const useStyles = makeStyles(() => ({
  editBtn: {
    backgroundColor: themesConfig.default.palette.info.dark,
    color: themesConfig.default?.palette.common.white,
    "&:hover": {
      backgroundColor: darken(themesConfig.default.palette.info.dark, 0.2),
    },
  },
}));

function EditButton({ handleClick, title, disabled = false }) {
  const classes = useStyles();
  return (
    <Button
      className={`rounded ${classes.editBtn}`}
      variant="contained"
      color="primary"
      startIcon={<EditOutlinedIcon />}
      size="small"
      onClick={handleClick}
      disabled={disabled}
    >
      {title}
    </Button>
  );
}

export default EditButton;

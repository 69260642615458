import * as React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Button } from "@mui/material";
import history from "@history";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Link } from "react-router-dom";
import i18next from "i18next";
import en from "./i18n/en";
import ms from "./i18n/ms";
import { useTranslation } from "react-i18next";

i18next.addResourceBundle("en", "Breadcrumbs", en);
i18next.addResourceBundle("ms", "Breadcrumbs", ms);

const handleBack = () => {
  history.back();
};

export const CommonBreadcrumbs = ({ pages = [], currentPageName }) => {
  const { t } = useTranslation("Breadcrumbs");

  return (
    <div
      role="presentation"
      className="mb-10 flex justify-between items-center"
    >
      <div>
        <Button
          onClick={handleBack}
          startIcon={<KeyboardArrowLeftIcon />}
          className="text-sm sm:text-base capitalize"
        >
          {t("BACK")}
        </Button>
      </div>
      <Breadcrumbs aria-label="breadcrumb" className="text-sm sm:text-base">
        <Link color="inherit" to="/home">
          {t("HOME")}
        </Link>
        {pages &&
          pages.length > 0 &&
          pages.map((page, index) => (
            <Link key={index} color="inherit" to={page.to}>
              {page.name}
            </Link>
          ))}

        <Typography color="text.primary" className="text-sm sm:text-base">
          {currentPageName}
        </Typography>
      </Breadcrumbs>
    </div>
  );
};

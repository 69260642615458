import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Typography, Button } from "@mui/material";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import Cancel from "@mui/icons-material/Cancel";
import { styled } from "@mui/material/styles";
import {
  getIseKYCButtonLoading,
  getIseKYCResultSuccess,
  getKYCErrorMessage,
  getKYCSuccessMessage,
} from "app/store/application-form/applicationFormSlice";
import themesConfig from "app/configs/themesConfig";
import FuseLoading from "@fuse/core/FuseLoading";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import history from "@history";

const AnimatedIcon = styled(VerifiedOutlinedIcon)({
  fontSize: "8rem",
  display: "block",
  margin: "16px auto",
  animation: "bounce 2s infinite",
  "@keyframes bounce": {
    "0%, 20%, 50%, 80%, 100%": {
      transform: "translateY(0)",
    },
    "40%": {
      transform: "translateY(-30px)",
    },
    "60%": {
      transform: "translateY(-15px)",
    },
  },
});

const ErrorIcon = styled(Cancel)({
  fontSize: "8rem",
  display: "block",
  margin: "16px auto",
  color: themesConfig.default.palette.error.main,
});

const Heading = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontWeight: "bold",
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const KYCResult = ({ onRetry, isMobile = false }) => {
  const { t } = useTranslation("user");
  const isLoading = useSelector(getIseKYCButtonLoading);
  const isSuccess = useSelector(getIseKYCResultSuccess);
  const kycErrorMessage = useSelector(getKYCErrorMessage);
  const kycSuccessMessage = useSelector(getKYCSuccessMessage);
  const [countdown, setCountdown] = useState(5);
  const { applicationId } = useParams();
  const searchParams = new URLSearchParams(location.search);

  // If Success then redirect detail page
  useEffect(() => {
    let timer;
    if (!isMobile && isSuccess) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    }

    // Redirect after countdown ends
    if (countdown === 0) {
      history.push(`/user/my-application/${applicationId}`);
    }

    return () => {
      clearInterval(timer); // Cleanup interval when component unmounts
    };
  }, [isSuccess, countdown, applicationId]);

  // For Mobile Device Set URL KYC Status Success
  useEffect(() => {
    if (isMobile) {
      if (isSuccess) {
        searchParams.set("ekycStatus", "success");
      } else {
        searchParams.set("ekycStatus", "failed");
      }
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
      window.history.pushState(null, "", newUrl);
    }
  }, [isMobile, isSuccess]);

  if (isLoading) {
    return (
      <Container
        maxWidth="sm"
        className="flex flex-col items-center justify-center h-full max-h-sm mt-20"
      >
        <FuseLoading />
        <Typography variant="h6" className="mt-4 text-center">
          {t("WAIT_MESSAGE")}
        </Typography>
        <SubHeading variant="body2" className="mt-2 text-center">
          {t("WAIT_DIC")}
        </SubHeading>
      </Container>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center mt-40">
      {isSuccess ? (
        <div className="text-center">
          <AnimatedIcon color="success" />
          <Heading className="text-lg sm:text-2xl mt-10 font-bold">
            {t("KYC_SUCCESS")}
          </Heading>
          <SubHeading className="text-base">
            {kycSuccessMessage || t("KYC_SUCCESS_DIC")}
          </SubHeading>
          {!isMobile && (
            <Typography variant="body1" className="mt-4">
              {t("REDIRECT_MESSAGE", { seconds: countdown })}
            </Typography>
          )}
        </div>
      ) : (
        <div className="text-center">
          <ErrorIcon />
          <Heading className="font-medium text-lg sm:text-2xl">
            {t("KYC_FAILED")}
          </Heading>
          <SubHeading variant="body1" className="mt-2">
            {kycErrorMessage || t("KYC_FAILED_DIC")}
          </SubHeading>
          <Button
            variant="contained"
            color="error"
            className="mt-10"
            onClick={onRetry}
          >
            {t("RETRY")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default KYCResult;

import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Typography } from "@mui/material";
import CustomTextField from "app/shared-components/Form/CustomTextField";
import CustomCheckbox from "app/shared-components/Form/CustomCheckbox";
import ColoredSubmitButton from "app/shared-components/Button/ColoredSubmitButton";
import FileUpload from "app/shared-components/Upload/CommonFileUpload";
import {
  fileUpload,
  getSelectedDocumentPath,
  resetSelectedDocumentPath,
  selectIsImageLoading,
} from "app/store/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  addExistingFinanceDetailsInForm,
  getIsExistingFinanceButtonLoading,
  updateExistingFinanceDetailsInForm,
} from "app/store/application-form/applicationFormSlice";


const ExistingFinanceForm = ({
  financeApplicationId = null,
  isEdit = false,
  setIsDirty = (value) => {},
  financeDetail = null,
}) => {
  const { t } = useTranslation("applicationForm");
  const dispatch: any = useDispatch();
  const filePath = useSelector(getSelectedDocumentPath);
  const isImageLoading = useSelector(selectIsImageLoading);
  const isButtonLoading = useSelector(getIsExistingFinanceButtonLoading);
  const [existingFinancePath, setExistingFinancePath] = useState("");


  const schema = yup.object().shape({
    nameOfFinanceInstitution: yup.string().required(t("TEXT_FIELD_REQUIRED_VALIDATION", {fieldName: t("FINANCE_NAME")})),
    balanceOfLoan: yup
      .number()
      .required(t("TEXT_FIELD_REQUIRED_VALIDATION", {fieldName: t("LOAN_TENURE")}))
      .typeError(t("NUMBER_INVALID_VALIDATION"))
      .positive(t("NUMBER_INVALID_VALIDATION")),
    monthlyDeduction: yup
      .number()
      .nullable()
      .transform((value, originalValue) => (originalValue === "" ? null : value))
      .typeError(t("NUMBER_INVALID_VALIDATION"))
      .positive(t("NUMBER_INVALID_VALIDATION")),
    balanceLoanTenure: yup
      .number()
      .nullable()
      .transform((value, originalValue) => (originalValue === "" ? null : value))
      .typeError(t("NUMBER_INVALID_VALIDATION"))
      .positive(t("NUMBER_INVALID_VALIDATION")),
    hasSettlementLetter: yup.boolean().required("SETTLEMENT_VALIDATION"),
    validityPeriod: yup
      .string()
      .test("validity-period", t("TEXT_FIELD_REQUIRED_VALIDATION", {fieldName: t("VALIDITY_PERIOD")}), function (value) {
        const hasSettlementLetter = this.parent.hasSettlementLetter;
        if (hasSettlementLetter) {
          if (!value) {
            return this.createError({ message: t("TEXT_FIELD_REQUIRED_VALIDATION", {fieldName: t("VALIDITY_PERIOD")}) });
          }
        }
        return true;
      }),
    existingFinanceFilePath: yup.mixed().nullable(),
  });

  useEffect(() => {
    return () => {
      dispatch(resetSelectedDocumentPath());
      reset();
    };
  }, [dispatch]);

  const methods = useForm();
  const {
    handleSubmit,
    formState,
    control,
    reset,
    getValues,
    setValue,
    watch,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      hasSettlementLetter: false,
    },
    mode: "onChange",
  });
  const { errors, isValid, isDirty } = formState;

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty, setIsDirty]);

  useEffect(() => {
    if (filePath) {
      setExistingFinancePath(filePath);
    }
  }, [filePath]);

  useEffect(() => {
    if (financeDetail) {
      setValue("hasSettlementLetter", financeDetail.hasSettlementLetter);
      setValue("balanceLoanTenure", financeDetail.balanceLoanTenure);
      setValue("balanceOfLoan", financeDetail.balanceOfLoan);
      setValue("monthlyDeduction", financeDetail.monthlyDeduction);
      setValue(
        "nameOfFinanceInstitution",
        financeDetail.nameOfFinanceInstitution
      );
      setValue("validityPeriod", financeDetail.validityPeriod);
      if (financeDetail.existingFinanceFilePath) {
        setExistingFinancePath(financeDetail.existingFinanceFilePath);
      }
    }
  }, [financeDetail]);

  function onSubmit(model) {
    const formData = {
      ...model,
    };

    let existingFinanceFilePath;

    if (existingFinancePath === "") {
      existingFinanceFilePath = "";
    } else if (existingFinancePath !== financeDetail?.existingFinanceFilePath) {
      existingFinanceFilePath = existingFinancePath;
    } else {
      existingFinanceFilePath = null;
    }

    if (financeDetail) {
      const updateData = {
        ...formData,
        id: financeDetail.id,
        existingFinanceFilePath,
      };
      dispatch(
        updateExistingFinanceDetailsInForm(
          updateData,
          financeApplicationId,
          isEdit
        )
      );
    } else {
      const addData = {
        ...formData,
        financeApplicationId: financeApplicationId,
        existingFinanceFilePath: existingFinancePath || null,
      };
      dispatch(
        addExistingFinanceDetailsInForm(addData, financeApplicationId, isEdit)
      );
    }
  }

  const handleCheckboxChange = (isChecked) => {
    setValue(`hasSettlementLetter`, isChecked, { shouldDirty: true });
    setValue("validityPeriod", "", { shouldDirty: true });
    trigger(["validityPeriod"]);
  };

  const onRemove = () => {
    setValue("existingFinanceFilePath", "", { shouldDirty: true });
    setExistingFinancePath("");
    trigger("existingFinanceFilePath");
  };

  const onChange = (file: File | null) => {
    if (file) {
      dispatch(fileUpload(file));
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-10" data-test-id="existing-finance-add-new-finance">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} className="mb-8">
                <Controller
                  name="nameOfFinanceInstitution"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      label={t("FINANCE_NAME")}
                      fullWidth
                      required
                      onChange={field.onChange}
                      value={field.value || ""}
                      error={
                        errors.nameOfFinanceInstitution &&
                        t(errors.nameOfFinanceInstitution.message)
                      }
                      inputProps={{
                        "data-test-id": "existing-finance-name-of-institution"
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="mb-8">
                <Controller
                  name="balanceOfLoan"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      label={t("LOAN_BALANCE")}
                      fullWidth
                      required
                      type="number"
                      onChange={field.onChange}
                      value={field.value || ""}
                      InputProps={{
                        inputProps: { min: 1, step: "0.01", "data-test-id": "existing-finance-balance-of-loan" },
                      }}
                      error={
                        errors.balanceOfLoan && t(errors.balanceOfLoan.message)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="mb-8">
                <Controller
                  name="monthlyDeduction"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      label={t("MONTHLY_COMMITMENTS")}
                      fullWidth
                      type="number"
                      onChange={field.onChange}
                      value={field.value || ""}
                      InputProps={{
                        inputProps: { min: 1, step: "0.01" , "data-test-id": "existing-finance-monthly-deduction"},
                      }}
                      error={
                        errors.monthlyDeduction &&
                        t(errors.monthlyDeduction.message)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="mb-8">
                <Controller
                  name="balanceLoanTenure"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      label={t("LOAN_TENURE")}
                      fullWidth
                      type="number"
                      onChange={field.onChange}
                      value={field.value || ""}
                      InputProps={{
                        inputProps: { min: 1, step: "0.01" , "data-test-id": "existing-finance-loan-tenure"},
                      }}
                      error={
                        errors.balanceLoanTenure &&
                        t(errors.balanceLoanTenure.message)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="mb-8">
                <Controller
                  name="hasSettlementLetter"
                  control={control}
                  render={({ field }) => (
                    <CustomCheckbox
                      label={t("SETTLEMENT_LETTER")}
                      checked={field.value}
                      onChange={(isChecked) => handleCheckboxChange(isChecked)}
                      data-test-id="existing-finance-settlement-letter"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="mb-8">
                <Controller
                  name="validityPeriod"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      label={t("VALIDITY_PERIOD")}
                      fullWidth
                      required={watch("hasSettlementLetter")}
                      disabled={!watch("hasSettlementLetter")}
                      onChange={field.onChange}
                      value={field.value || ""}
                      error={
                        errors.validityPeriod &&
                        t(errors.validityPeriod.message)
                      }
                      inputProps={{
                        "data-test-id": "existing-finance-validity-period"
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className="mb-10">{t("FINANCE_FILE")}</Typography>
                <Controller
                  name="existingFinanceFilePath"
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <FileUpload
                      dataTestId="existing-finance-file-upload"
                      onRemove={onRemove}
                      onChange={(file: File | null) => {
                        field.onChange(file);
                        onChange(file);
                      }}
                      existingFile={existingFinancePath}
                      isImageLoading={isImageLoading}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ColoredSubmitButton
          className="w-120 mt-10 ml-auto"
          disabled={!isValid || !isDirty}
          text={t("SAVE")}
          isLoading={isButtonLoading}
          data-test-id="existing-finance-new-finance-submit-btn"
        />
      </form>
    </FormProvider>
  );
};

export default ExistingFinanceForm;

import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import themesConfig from "app/configs/themesConfig";

const TypeBadge = styled(Box)(({ ...props }) => ({
  backgroundColor: {
    PDF: themesConfig.status.palette.primary.main,
    PNG: themesConfig.status.palette.primary.light,
    JPEG: themesConfig.status.palette.primary.dark,
    DOC: themesConfig.status.palette.primary.main,
    JPG: themesConfig.status.palette.primary.light,
  }[props.color as string],
}));

type ItemIconProps = {
  type: string;
};

/**
 * The item icon component.
 */
function ItemIcon(props: ItemIconProps) {
  const { type } = props;

  return (
    <div className="relative">
      <FuseSvgIcon size={56} color="disabled">
        heroicons-outline:document
      </FuseSvgIcon>
      <TypeBadge
        color={type?.toUpperCase()}
        className="absolute left-0 bottom-0 px-6 rounded text-12 font-semibold leading-20 text-white"
      >
        {type?.toUpperCase()}
      </TypeBadge>
    </div>
  );
}

export default ItemIcon;

import { useParams } from "react-router";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMemberDetail,
  getMemberDetailsById,
  setMemberData,
  setMemberUserId,
} from "app/store/user/userSlice";
import PersonalInfo from "../PersonalInfo";
import AddressInfo from "../AddressInfo";
import { Container, Paper } from "@mui/material";
import UserAdditionalInfo from "../UserAdditionalInfo";
import { CommonBreadcrumbs } from "app/shared-components/Breadcrumbs/CommonBreadcrumbs";
import { useTranslation } from "react-i18next";
import { hasPermission } from "src/app/services/utils";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import ErrorMessage from "src/app/services/FeaturePermission/ErrorMessage";

function MemberDetailPage() {
  const { t } = useTranslation("user");
  const dispatch: any = useDispatch();
  const params = useParams();
  const { id } = params;
  const memberDetail = useSelector(getMemberDetail);
  const userDetailPermission = hasPermission(FeaturePermissions?.User?.View);

  if (!hasPermission(FeaturePermissions.User.View)) {
    return <ErrorMessage errorMessage="ACCESS_DENIED_VIEW_MEMBER" />;
  }

  useEffect(() => {
    if (!!id && userDetailPermission) {
      dispatch(setMemberUserId(id));
      dispatch(getMemberDetailsById(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setMemberUserId(null));
      dispatch(setMemberData(null));
    };
  }, []);

  return (
    <div>
      <Container
        maxWidth="lg"
        className="pl-10 mt-10 md:pl-76 pr-10 md:pr-76 mb-64"
      >
        <CommonBreadcrumbs currentPageName={t("MEMBER_DETAILS")} />
        {/* personal info */}
        <Paper className="mb-20 p-20" variant="outlined">
          <PersonalInfo personalInfo={memberDetail} styleContainer={false} />
        </Paper>

        {/* Additional Info */}
        <Paper className="mb-20 p-20" variant="outlined">
          <UserAdditionalInfo additionalData={memberDetail?.userAdditionalInformation} styleContainer={false} />
        </Paper>

        {/* address details */}
        <Paper className="mb-20 p-20" variant="outlined">
          <AddressInfo memberId={id} styleContainer={false} />
        </Paper>

      </Container>
    </div>
  );
}

export default MemberDetailPage;

import i18next from "i18next";
import en from "./i18n/en";
import ms from "./i18n/ms";
import { calculateMinimumDOBForAdult } from "../utils";
import moment from "moment";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

i18next.addResourceBundle("en", "validation", en);
i18next.addResourceBundle("ms", "validation", ms);

// Common Date Of Birth - YUP validation
export const dateOfBirthValidation = () => {
  const { t } = useTranslation("validation");
  return yup
    .date()
    .required(t("VALID_DATE_VALIDATION"))
    .typeError(t("VALID_DATE_VALIDATION"))
    .max(calculateMinimumDOBForAdult(), t("DOB_FUTURE")) // Restrict future dates
    .min(moment("1900-01-01", "YYYY-MM-DD").toDate(), t("DOB_PAST_VALIDATION")); // Restrict too old dates
};

export const phoneNumberValidation = (isRequired = false) => {
  const { t } = useTranslation("validation");
  return isRequired
    ? yup
        .string()
        .required(t("MOBILE_NUMBER_REQUIRED_VALIDATION"))
        .matches(/^\d{8,10}$/, t("NUMBER_INVALID_VALIDATION"))
    : yup
        .string()
        .notRequired()
        .nullable()
        .test(
          "phone-valid",
          t("NUMBER_INVALID_VALIDATION"),
          (value) => !value || /^\d{8,10}$/.test(value) // Ensures the value is valid if not null
        );
};

export const emailValidation = (isRequired = false) => {
  const { t } = useTranslation("validation");
  return isRequired
    ? yup
        .string()
        .required(t("EMAIL_REQUIRED_VALIDATION"))
        .email(t("EMAIL_INVALID_VALIDATION"))
        .test(
          "valid-domain",
          t("EMAIL_INVALID_VALIDATION"),
          (value) => value && /\.[A-Za-z]{2,}$/.test(value.split("@")[1]) // Ensures valid domain
        )
    : yup
        .string()
        .notRequired()
        .nullable()
        .email(t("EMAIL_INVALID_VALIDATION"))
        .test(
          "valid-format",
          t("EMAIL_INVALID_VALIDATION"),
          (value) => !value || /^[^\s@]+@[^\s@]+\.[A-Za-z]{2,}$/.test(value) // Checks for valid format if not null
        );
};

const locale = {
    THERE_ARE_NO_NOTIFICATION_FOR_NOW:"There are no notification for now.",
    SHOW_MORE:"Show More",
    MARK_ALL_AS_READ:"Mark All As Read",
    NOTIFICATION:"Notification",
    DELETE_ALL:"Delete All",
    LISTS_ALL_NOTIFICATIONS:"Lists All Notifications",
    NOTIFICATIONS:"Notifications",
    ARE_YOU_SURE_TO_DELETE:"Are you sure to delete?",
    ARE_YOU_SURE_TO_DELETE_ALL_NOTIFICATIONS:"Are you sure to delete all notifications?",
    CANCEL:"Cancel",
    OK:"Ok",
    NOTIFICATION_WITH_WHITE_BACKGROUND_AND_BADGES_INDICATE_UNREAD_NOTIFICATION:"Notification with Lavender background and badges indicate unread Notifications",
  };
  export default locale;
  
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getPaginationData,
  setPagination,
  getMiniAgentList,
  getMiniAgentListData,
  getMiniAgentListLoading,
} from "app/store/user/userSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactTableCustom from "app/shared-components/ReactTable/ReactTableCustom";
import { Box, Container, MenuItem } from "@mui/material";
import {
  StyledButton,
  StyledTypography,
} from "app/shared-components/Styles/CustomStyle";
import { Link } from "react-router-dom";
import history from "@history";
import { CommonBreadcrumbs } from "app/shared-components/Breadcrumbs/CommonBreadcrumbs";
import Can from "src/app/services/FeaturePermission/Can";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import StatusChip from "app/shared-components/statusChip/StatusChip";
import EmailLink from "app/shared-components/Link/EmailLink";
import PhoneNumberLink from "app/shared-components/Link/PhoneNumberLink";
import {
  handleQueryParamsAndFilters,
  useQueryParams,
} from "src/app/services/queryParamsUtils";

const MiniAgentListTable = () => {
  const { t } = useTranslation("user");
  const dispatch: any = useDispatch();
  const AgentList = useSelector(getMiniAgentListData);
  const pagination = useSelector(getPaginationData);
  const loading = useSelector(getMiniAgentListLoading);
  const { getQueryParams, updateQueryParams } = useQueryParams();

  const fetchData = useCallback(
    ({ pageSize, pageIndex, sortField, sortOrder }) => {
      handleQueryParamsAndFilters({
        dispatch,
        updateQueryParams,
        pageSize,
        pageIndex,
        sortField,
        sortOrder,
        setPaginationAction: setPagination,
      });
      dispatch(getMiniAgentList({ pageIndex }));
    },
    []
  );

  const redirectToDetailPage = (id) => {
    history.push(`/user/mini-agent/${id}`);
  };

  const columns = React.useMemo<any[]>(
    () => [
      {
        accessorKey: "fullName",
        header: () => t("NAME"),
        cell: (value) => (
          <a
            className="cursor-pointer"
            onClick={() => redirectToDetailPage(value.row.original.id)}
          >
            {value.getValue()}
          </a>
        ),
      },
      {
        accessorKey: "email",
        header: () => t("EMAIL"),
        cell: (value) => <EmailLink email={value.getValue()} />,
      },
      {
        accessorKey: "phoneNumber",
        header: () => t("MOBILE_NUMBER"),
        cell: (value) => <PhoneNumberLink phoneNumber={value.getValue()} />,
      },{
        accessorKey: "userUniqueCode",
        header: () => t("MANAGER_CODE")
      },
      {
        accessorKey: "created",
        header: () => t("CREATED_AT"),
      },
      {
        accessorKey: "status",
        header: () => t("STATUS"),
        cell: (value) => (
          <StatusChip
            status={value.getValue()}
            label={value.getValue()}
          ></StatusChip>
        ),
      },
    ],
    []
  );
  return (
    <Can
      permission={FeaturePermissions.MiniAgent.List}
      errorMessage="ACCESS_DENIED_VIEW_MINI_AGENT"
    >
      <Container maxWidth="lg" className="pl-10 md:pl-76 pr-10 md:pr-76 mb-64 mt-10">
        <CommonBreadcrumbs currentPageName={t("MINI_AGENT")} />
        <>
          <Box
            className="mt-10 mb-20"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <StyledTypography variant="h5" fontWeight={"bold"}>
              {t("MINI_AGENT")}
            </StyledTypography>
            <Can permission={FeaturePermissions.MiniAgent.Create}>
              <Link to="/user/mini-agent/add-mini-agent">
                <StyledButton>{t("ADD_MINI_AGENT")} </StyledButton>
              </Link>
            </Can>
          </Box>

          <ReactTableCustom
            fetchData={fetchData}
            columns={columns}
            data={AgentList}
            isLoading={loading}
            pageCount={pagination?.totalCount}
            paginationState={getQueryParams()}
          />
        </>
      </Container>
    </Can>
  );
};

export default MiniAgentListTable;

import React from "react";
import { styled } from "@mui/system";
import {
  Box,
  Hidden,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  stepConnectorClasses,
} from "@mui/material";
import { formatDate } from "src/app/services/utils";
import CommonAccordion from "app/shared-components/Accordion/CommonAccordion";

const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontWeight: "bold",
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
}));

const StyledBoxForStepper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  paddingTop: "20px",
  paddingBottom: "20px",
  overflowX: "auto",
  maxWidth: "100%",
}));

const StyledStepper = styled(Stepper)<{
  orientation?: "horizontal" | "vertical";
}>(({ theme, orientation }) => ({
  "& .MuiStepConnector-root": {
    left: "calc(-50% + 11px)",
    right: "calc(50% + 11px)",
  },
  "& .MuiStepIcon-root.Mui-active": {
    color: theme.palette.secondary.main,
  },
  "& .MuiStepIcon-root.Mui-completed": {
    color: theme.palette.secondary.main,
  },
  "& .MuiStepIcon-root": {
    color: theme.palette.primary.contrastText,
  },
  "& .MuiStepIcon-text": {
    fill: theme.palette.common.white,
  },
  "& .MuiStepLabel-label.Mui-completed": {
    color: theme.palette.primary.dark,
    fontWeight: 400,
  },
  ...(orientation === "vertical" && {
    "& .MuiStepConnector-root": {
      marginLeft: "10px",
      marginTop: "-16px",
      marginBottom: "-16px",
    },
  }),
}));

const StyledStepConnector = styled(StepConnector)<{
  orientation?: "horizontal" | "vertical";
}>(({ theme, orientation }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.secondary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.secondary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.primary.contrastText,
    borderTopWidth: orientation === "vertical" ? 0 : 3,
    borderLeftWidth: orientation === "vertical" ? 3 : 0,
    marginTop: orientation === "vertical" ? 0 : "20px",
    height: orientation === "vertical" ? "100%" : "2px",
  },
}));

const ApplicationStatusStepper = ({ applicationStatus }) => {
  return (
    <>
      <Hidden smDown>
        <StyledBoxForStepper className="mb-20">
          <StyledStepper alternativeLabel connector={<StyledStepConnector />}>
            {applicationStatus?.map((step) => (
              <Step key={step?.id} completed={step?.isCompleted}>
                <StyledTypography
                  variant="body2"
                  fontWeight={"bold"}
                  align="center"
                  className="text-nowrap"
                >
                  {step?.applicationStatus}
                </StyledTypography>
                <StyledStepLabel>{`${formatDate(
                  step?.created
                )}`}</StyledStepLabel>
              </Step>
            ))}
          </StyledStepper>
        </StyledBoxForStepper>
      </Hidden>
      <Hidden smUp>
        <div className="border-black border my-10">
          <CommonAccordion
            title="Status"
            icon={false}
            editIcon={false}
            className="border-0"
            content={
              <>
                <StyledBoxForStepper className="flex flex-col justify-center items-start pl-10">
                  <StyledStepper
                    orientation="vertical"
                    connector={<StyledStepConnector orientation="vertical" />}
                  >
                    {applicationStatus?.map((step) => (
                      <Step key={step?.id} completed={step?.isCompleted}>
                        <StyledStepLabel>
                          <StyledTypography variant="body2" fontWeight={"bold"}>
                            {step?.applicationStatus}
                          </StyledTypography>
                          {`${formatDate(
                            step?.created,
                            "DD/MMM/YYYY",
                            "DD/MMM/YYYY HH:mm:ss"
                          )}`}
                        </StyledStepLabel>
                      </Step>
                    ))}
                  </StyledStepper>
                </StyledBoxForStepper>
              </>
            }
          />
        </div>
      </Hidden>
    </>
  );
};

export default ApplicationStatusStepper;

import React from "react";
import {
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Zoom,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  StyledAccordion,
  StyledTypography,
} from "app/shared-components/Styles/CustomStyle";
import VerifiedBadge from "app/shared-components/Icon/VerifiedBadge";
import RemarkDialog from "app/shared-components/Dialog/RemarkDialog";
import CustomIconButton from "../Button/CustomIconButton";
import Can from "src/app/services/FeaturePermission/Can";
import i18next from "i18next";
import en from "./i18n/en";
import ms from "./i18n/ms";
import { useTranslation } from "react-i18next";
import CustomTooltip from "../Tooltip/CustomTooltip";
import { getCurrentFinanceApplicationStatus } from "app/store/user/userSlice";
import { useSelector } from "react-redux";

i18next.addResourceBundle("en", "commonAccordion", en);
i18next.addResourceBundle("ms", "commonAccordion", ms);

const CommonAccordion = ({
  title,
  content,
  remarksList = [],
  isApproved = false,
  icon = true,
  defaultExpanded = false,
  handleEditOpen = () => {},
  className = "",
  editIcon = true,
  permission = "",
}) => {
  const { t } = useTranslation("commonAccordion");
  const currentFinanceStatus = useSelector(getCurrentFinanceApplicationStatus);

  const handleEditDialog = (e) => {
    e.stopPropagation();
    handleEditOpen();
  };

  return (
    <StyledAccordion
      disableGutters
      square
      className={className}
      defaultExpanded={defaultExpanded}
    >
      <AccordionSummary
        className="pointer-events-auto"
        expandIcon={<ExpandMoreIcon className="pointer-events-auto" />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <div className="w-full flex justify-between mr-10">
          <StyledTypography
            variant="body1"
            className="text-16 font-700 pointer-events-auto"
          >
            {title}
            {isApproved === true && <VerifiedBadge />}
          </StyledTypography>
          <div className="flex gap-5">
            {icon && (
              <RemarkDialog sectionTitle={title} remarksList={remarksList} />
            )}
            {editIcon && (
              <Can permission={permission}>
                <CustomTooltip
                  title={
                    currentFinanceStatus === "rejected"
                      ? t("REJECTED_FINANCE_TOOLTIP")
                      : isApproved
                      ? t("DISABLED_EDIT_TOOLTIP")
                      : ""
                  }
                >
                  <div>
                    <CustomIconButton
                      className="m-0 p-3 ml-10"
                      onClick={handleEditDialog}
                      disabled={
                        isApproved || currentFinanceStatus === "rejected"
                      }
                    />
                  </div>
                </CustomTooltip>
              </Can>
            )}
          </div>
        </div>
      </AccordionSummary>

      <AccordionDetails className="flex flex-col w-full overflow-auto">
        {content}
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default CommonAccordion;

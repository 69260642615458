import { useParams } from "react-router";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMemberDetail, getMemberDetailsById, setMemberData, setMemberUserId } from "app/store/user/userSlice";
import PersonalInfo from "../PersonalInfo";
import { Container, Paper } from "@mui/material";
import { CommonBreadcrumbs } from "app/shared-components/Breadcrumbs/CommonBreadcrumbs";
import { useTranslation } from "react-i18next";
import { hasPermission } from "src/app/services/utils";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import ErrorMessage from "src/app/services/FeaturePermission/ErrorMessage";
import DocumentPage from "app/shared-components/Document/DocumentPage";
import { documentSelectType } from "src/app/common/constant";
import { setDocumentsList } from "app/shared-components/Document/store/documentCardSlice";

function MiniAgentDetailPage() {
  const { t } = useTranslation("user");
  const dispatch: any = useDispatch();
  const params = useParams();
  const { id } = params;
  const memberDetail = useSelector(getMemberDetail);
  const userDetailPermission = hasPermission(FeaturePermissions?.User?.View);

  if (!hasPermission(FeaturePermissions.MiniAgent.View)) {
    return <ErrorMessage errorMessage="ACCESS_DENIED_VIEW_MINI_AGENT" />;
  }

  useEffect(() => {
    if(!!id && userDetailPermission){
      dispatch(setMemberUserId(id));
      dispatch(getMemberDetailsById(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setMemberData(null));
      dispatch(setMemberUserId(null));
      dispatch(setDocumentsList(null));
    };
  }, []);

  return (
    <div>
      <Container
        maxWidth="lg"
        className="pl-10 mt-10 md:pl-76 pr-10 md:pr-76 mb-64"
      >
        <CommonBreadcrumbs currentPageName={t("MINI_AGENT_DETAIL")} />
        <Paper className="mb-20 p-20" variant="outlined">
          <PersonalInfo personalInfo={memberDetail} styleContainer={false} showUserUniqueCode={true}/>
        </Paper>

        <Paper className="mb-20 px-20" variant="outlined">
          <DocumentPage
            {...{
              userId: id,
              documentType: documentSelectType.agent,
            }}
          />
        </Paper>
      </Container>
    </div>
  );
}

export default MiniAgentDetailPage;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "src/app/services/apiService";

interface emailVerificationState {
  status: "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: emailVerificationState = {
  status: "loading",
  error: null,
};

export const getEmailVerification = (request_data) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const response: any = await api.put(`/v1/User/ConfirmEmail`, request_data);
    dispatch(setSucceeded());
  } catch (error) {
    dispatch(setFailed(error.message));
    console.error(error);
  }
};

export const getStatus = (state) => state.email.status;
export const getError = (state) => state.email.error;

const emailVerificationSlice = createSlice({
  name: "email",
  initialState,
  reducers: {
    setLoading(state) {
      state.status = "loading";
      state.error = null;
    },
    setSucceeded(state) {
      state.status = "succeeded";
      state.error = null;
    },
    setFailed(state, action: PayloadAction<string>) {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

export const { setLoading, setSucceeded, setFailed } =
  emailVerificationSlice.actions;
export default emailVerificationSlice.reducer;

import i18next from 'i18next';
import { FuseNavigationType } from '@fuse/core/FuseNavigation/types/FuseNavigationType';
import en from './navigation-i18n/en';
import ms from './navigation-i18n/ms';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('ms', 'navigation', ms);

/**
 * The navigationConfig object is an array of navigation items for the Fuse application.
 */
const navigationConfig: FuseNavigationType = [
	{
		id: 'home',
		title: 'Home',
		translate: 'HOME',
		type: 'item',
		url: 'home'
	},
	{
		id: 'product',
		title: 'Product',
		translate: 'PRODUCT',
		type: 'item',
		url: 'product'
	},
	// {
	// 	id: 'services',
	// 	title: 'Services',
	// 	translate: 'SERVICES',
	// 	type: 'item',
	// 	url: 'services'
	// },
	{
		id: 'calculator',
		title: 'Calculator',
		translate: 'CALCULATOR',
		type: 'item',
		url: 'calculator'
	},
	{
		id: 'about-us',
		title: 'About Us',
		translate: 'ABOUTUS',
		type: 'item',
		url: 'about-us'
	},
	{
		id: 'contact-us',
		title: 'Contact Us',
		translate: 'CONTACTUS',
		type: 'item',
		url: 'contact-us'
	},
	{
		id: 'faq',
		title: 'FAQs',
		translate: 'FAQS',
		type: 'item',
		url: 'faqs'
	},
];

export default navigationConfig;

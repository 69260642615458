import { FuseRouteConfigType } from '@fuse/utils/FuseUtils';
import SignInPage from './SignInPage';
import authRoles from '../../auth/authRoles';
import i18next from 'i18next';
import en from './i18n/en';
import ms from './i18n/ms';

i18next.addResourceBundle('en', 'signInPage', en);
i18next.addResourceBundle('ms', 'signInPage', ms);

const SignInConfig: FuseRouteConfigType = {
	settings: {
		layout: {
		}
	},
	routes: [
		{
			path: 'sign-in',
			element: <SignInPage tabIndex={0}/>
		},
		{
			path: 'sign-up',
			element: <SignInPage tabIndex={1}/>
		}
	]
};

export default SignInConfig;

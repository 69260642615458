import React, { useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  getExpandedRowModel,
} from "@tanstack/react-table";
import {
  TableCell,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// interface in typescript is a way to define the structure of an object
interface Column {
  id: string;
  header: string;
  accessor: string;
  // Add more properties as needed
}

interface Row {
  id: string;
  // Add more properties as needed
}

interface ReactTableSimpleProps {
  columns: Column[];
  data: Row[];
  isLoading?: boolean;
  customStyle?: React.CSSProperties;
  renderRowSubComponent?: (row: Row) => React.ReactNode;
}

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  ".headerCell": {
    borderBottom: `2px solid ${theme.palette.secondary.main}`, // Apply secondary color to header border
  },
  ".bodyCell": {
    borderBottom: `1px solid ${theme.palette.primary.dark}`, // Apply primary color to body border
  },
  ".progress-overlay": {
    minHeight: "50px",
    verticalAlign: "middle",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function ReactTableSimple({
  columns,
  data,
  isLoading = false,
  customStyle,
  renderRowSubComponent,
}: ReactTableSimpleProps): JSX.Element {
  const [sorting, setSorting] = useState<any[]>([]);
  const [expanded, setExpanded] = useState<any>({});
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  });

  const tableInstance = useReactTable({
    columns,
    data,
    state: {
      sorting,
      expanded,
      pagination,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <StyledTableContainer>
      <Paper elevation={0}>
        <Table className={`react-table`}>
          <TableHead>
            {tableInstance.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableCell
                    key={header.id}
                    colSpan={header.colSpan}
                    className="headerCell"
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? "cursor-pointer"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: " ↑",
                          desc: " ↓",
                        }[header.column.getIsSorted() ? 1 : 0] ?? null}
                      </div>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {!isLoading && data.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length}>No data found.</TableCell>
              </TableRow>
            )}
            {!isLoading &&
              tableInstance.getRowModel().rows.map((row) => {
                return (
                  <React.Fragment key={row.id}>
                    <TableRow
                      key={row.id}
                      {...{
                        ...(renderRowSubComponent
                          ? {
                              onClick: () => row.toggleExpanded(),
                              style: { cursor: "pointer" },
                            }
                          : {}),
                        ...(row.getIsExpanded() && renderRowSubComponent
                          ? { className: "selected" }
                          : {}),
                      }}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <TableCell key={cell.id} className="bodyCell">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    {row.getIsExpanded() && renderRowSubComponent ? (
                      <TableRow className="expanded-row">
                        <TableCell colSpan={row.getVisibleCells().length}>
                          {renderRowSubComponent(row)}
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </React.Fragment>
                );
              })}
            {isLoading && (
              <TableRow>
                <TableCell colSpan={columns.length} style={{ padding: 0 }}>
                  <div className="progress-overlay">
                    <CircularProgress
                      size={24}
                      className="button-progress"
                      color="secondary"
                    />
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    </StyledTableContainer>
  );
}

export default ReactTableSimple;

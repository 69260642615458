import { Avatar } from "@mui/material";
import { StyledTypography, StyledTypographyGrey } from "../Styles/CustomStyle";
import { useSelector } from "react-redux";
import { getLoginUserData } from "app/store/user/userSlice";
import i18next from "i18next";
import en from "./i18n/en";
import ms from "./i18n/ms";
import { useTranslation } from "react-i18next";

i18next.addResourceBundle("en", "dashboard", en);
i18next.addResourceBundle("ms", "dashboard", ms);

const DashboardWelcomePage = () => {
  const loginData = useSelector(getLoginUserData);
  const { t } = useTranslation("dashboard");

  return (
    <>
      <div className="w-full flex gap-10 items-center my-10 ml-5">
        {/* Avatar with the first letter */}
        <Avatar
          className="text-white text-3xl w-40 h-40 font-bold"
          sx={{
            bgcolor: "secondary.main",
          }}
        >
          {loginData?.fullName?.charAt(0)}
        </Avatar>

        <div className="flex flex-col gap-3 items-start">
          {/* Welcome message */}
          <StyledTypography variant="h6" className="text-xl !leading-none">
            {t("DASHBOARD_WELCOME_MESSAGE", { name: loginData?.fullName })}
          </StyledTypography>
          <StyledTypographyGrey
            variant="body1"
            className="text-md !leading-none !font-normal"
          >
            {t("DASHBOARD_DESC")}
          </StyledTypographyGrey>
        </div>
      </div>
    </>
  );
};

export default DashboardWelcomePage;

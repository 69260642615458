const locale = {
  ACCESS_DENIED_MINI_AGENT:
    "Anda tidak membenarkan orang untuk menambah Pengurus Wilayah.",
  ACCESS_DENIED_ADD_MEMBER: "Anda tidak membenarkan orang untuk menambah ahli.",
  ACCESS_DENIED_VIEW_MEMBER: "Anda tidak membenarkan orang untuk melihat ahli.",
  ACCESS_DENIED_VIEW_MINI_AGENT:
    "Anda bukan orang yang dibenarkan untuk melihat Pengurus Wilayah.",
  ACCESS_DENIED_VIEW_APPLICATION:
    "Anda tidak membenarkan orang untuk melihat permohonan kewangan.",
  ACCESS_DENIED:
    "Anda bukan orang yang diberi kuasa untuk menambah permohonan kewangan.",
  NOT_ALLOWED_NOTIFICATION:
    "Anda bukan orang yang dibenarkan untuk melihat pemberitahuan.",
  NOT_ALLOWED_VIEW_DETAIL: "Anda tidak dibenarkan melihat butiran.",
  NOT_ALLOWED_VIEW_ADDRESS_DETAIL:
    "Anda tidak dibenarkan melihat butiran alamat.",
  NOT_ALLOWED_VIEW_DOCUMENTS: "Anda tidak dibenarkan melihat dokumen.",
  NOT_ALLOW_VIEW_COMMUNICATION: "Anda tidak dibenarkan melihat komunikasi.",
};
export default locale;

import { FuseRouteConfigType } from "@fuse/utils/FuseUtils";
import authRoles from "../../auth/authRoles";
import i18next from "i18next";
import en from "./i18n/en";
import ms from "./i18n/ms";
import TermsAndConditions from "./TermsAndConditions";

i18next.addResourceBundle("en", "termsAndConditions", en);
i18next.addResourceBundle("ms", "termsAndConditions", ms);

const TermsAndConditionsConfig: FuseRouteConfigType = {
  settings: {
    layout: {},
  },
  auth: authRoles.public,
  routes: [
    {
      path: "terms-and-conditions",
      element: <TermsAndConditions />,
    },
  ],
};

export default TermsAndConditionsConfig;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { showMessage } from "app/store/fuse/messageSlice";
import api from "src/app/services/apiService";

interface contactUsState {
  loading: {
    isButtonLoading: boolean;
  };
}

const initialState: contactUsState = {
  loading: {
    isButtonLoading: false,
  },
};

export const getContactUs = (request_data) => async (dispatch) => {
  dispatch(setIsButtonLoading(true));
  try {
    const response: any = await api.post(`/v1/ContactUsInquiry`, request_data);
    if (response) {
      dispatch(setIsButtonLoading(false));
      dispatch(
        showMessage({
          message:
            "Thank you for your inquiry. We will get back to you shortly.",
            variant : "success"
        })
      );
      return true;
    }
  } catch (error) {
    dispatch(setIsButtonLoading(false));
    dispatch(showMessage({ message: error.message }));
    console.error(error);
    return false;
  }
};

const setLoadingState = (key, value) => async (dispatch) => {
  dispatch(setLoading({ key, value }));
};

export const setIsButtonLoading = (value) =>
  setLoadingState("isButtonLoading", value);

export const getLoadingStateFactory = (loader) => (state) => {
  return state.contactUs.loading[loader] || false;
};
export const selectIsButtonLoading = getLoadingStateFactory("isButtonLoading");

const contactUsSlice = createSlice({
  name: "contactUs",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      const { key, value } = action.payload;
      state.loading = {
        ...state.loading,
        [key]: value,
      };
    },
  },
});

export const { setLoading } = contactUsSlice.actions;
export default contactUsSlice.reducer;

import i18next from 'i18next';
import { lazy } from 'react';
import en from './i18n/en';
import ms from './i18n/ms';
import { authRoles } from 'src/app/auth';

i18next.addResourceBundle('en', 'contactUsPage', en);
i18next.addResourceBundle('ms', 'contactUsPage', ms);

const ContactUs = lazy(() => import('./ContactUs'));

/**
 * The Home page config.
 */
const ContactUsConfig = {
	settings: {
		layout: {
		}
	},
	auth: authRoles.public,
	routes: [
		{
			path: 'contact-us',
			element: <ContactUs />
		}
	]
};

export default ContactUsConfig;

const locale = {
  DASHBOARD_WELCOME_MESSAGE: "Hai, {{name}}! Selamat Kembali.",
  DASHBOARD_DESC: "Gambaran Keseluruhan Papan Pemuka Peribadi Anda",
  FINANCED_AMOUNT: "Jumlah Pembiayaan",
  CUSTOMERS: "Pelanggan",
  APPLICATIONS: "Permohonan",
  TOTAL: "Jumlah",
  LAST_WEEK: "Minggu Lepas",
  LAST_MONTH: "Bulan Lepas",
  LAST_YEAR: "Tahun Lepas",
  TODAY: "Hari Ini",
  VIEW_ALL: "Lihat Semua",
  LAST_MODIFIED_APPLICATION: "Permohonan Terakhir Diperbaharui",
  APPLICATION_ID: "ID Permohonan",
  KYC_COMPLETE: "Verifikasi eKYC Berjaya",
  FINANCE_PRODUCT: "Produk Pembiayaan",
  AMOUNT: "Jumlah",
  INTEREST_RATE: "Kadar Untung (%)",
  MANAGER_CODE: "Kod Pengurus",
  STATUS: "Status",
  CATEGORY_NAME: "Nama Kategori",
  PRODUCT_NAME: "Nama Produk",
  PRODUCT_VARIANT_NAME: "Varian Produk",
  CREATED_DATE: "Tarikh Dicipta",
  LAST_MODIFIED_DATE: "Tarikh Terakhir Diubah Suai",
  MEMBER_NAME: "Nama Ahli",
  APPROVED_AND_TOTAL_APPLICATION: "Permohonan Pengurus",
  TOTAL_APPLICATION: "Jumlah Permohonan",
  APPROVED_APPLICATION: "Permohonan Diterima",
  NO_DATA_AVAILABLE: "Tiada data tersedia",
  APPLICATION_COUNT: "Kiraan Permohonan",
  TOP_PERFORMER_MANAGERS: "Pengurus Pencapaian Teratas",
  ACTION_REQUIRED_REMARK: "Komen Tindakan Diperlukan",
  NOTIFICATION: "Pemberitahuan",
  THERE_ARE_NO_NOTIFICATION_FOR_NOW: "Tiada pemberitahuan buat masa ini.",
  IN_PROGRESS_APPLICATION: "Permohonan Kewangan Dalam Proses",
  RECENT_TRANSACTIONS: "Transaksi Terkini",
  NO_TRANSACTIONS_AVAILABLE: "Anda tiada urus niaga tersedia sekarang.",
  ACTIVE_FINANCE: "Butiran Kewangan Aktif",
  GO_TO_DETAIL_TOOLTIP: "Pergi ke Halaman Butiran",
  APPLY_FOR_FINANCE: "Memohon Pembiayaan",
  NO_ACTIVE_FINANCE_FOUND: "Tiada rekod kewangan aktif ditemui.",
};

export default locale;

import { HubConnectionBuilder, LogLevel, HttpTransportType } from "@microsoft/signalr";

const getToken = () => {
  return localStorage.getItem("jwt_access_token");
};

const connection = new HubConnectionBuilder()
  .withUrl(process.env.REACT_APP_SIGNAL_R_HUB_URL, {
    accessTokenFactory: () => getToken(),
    transport: HttpTransportType.WebSockets // Force the use of WebSockets
  })
  .configureLogging(LogLevel.Information)
  .withAutomaticReconnect()
  .build();

export default connection;

import { FuseRouteConfigType } from "@fuse/utils/FuseUtils";
import authRoles from "../../auth/authRoles";
import EmailVerification from "./EmailVerification";

const EmailVerificationConfig: FuseRouteConfigType = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.public,
  routes: [
    {
      path: "email-verification/:token",
      element: <EmailVerification />,
    },
  ],
};

export default EmailVerificationConfig;

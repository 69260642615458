import i18next from 'i18next';
import { lazy } from 'react';
import en from './i18n/en';
import ms from './i18n/ms';
import { authRoles } from 'src/app/auth';

i18next.addResourceBundle('en', 'calculatorPage', en);
i18next.addResourceBundle('ms', 'calculatorPage', ms);

const Calculator = lazy(() => import('./Calculator'));

/**
 * The Home page config.
 */
const CalculatorConfig = {
	settings: {
		layout: {
		}
	},
	auth: authRoles.public,
	routes: [
		{
			path: 'calculator',
			element: <Calculator />
		}
	]
};

export default CalculatorConfig;

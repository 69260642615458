import { Grid } from "@mui/material";
import ActionRequiredRemarksList from "app/shared-components/Dashboard/ActionRequiredRemarksList";
import FinanceApplicationCard from "app/shared-components/Dashboard/FinanceApplicationCard";
import RecentTransactionsWidget from "app/shared-components/Dashboard/RecentTransactionsWidget";
import React from "react";

const CustomerDashboard = () => {
  return (
    <>
      <Grid container spacing={2} className="mb-10">
        <Grid item xs={12}>
          <FinanceApplicationCard />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ActionRequiredRemarksList />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RecentTransactionsWidget />
        </Grid>
      </Grid>
    </>
  );
};

export default CustomerDashboard;

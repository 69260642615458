import React from "react";
import { Box, Container } from "@mui/material";
import { StyledTypography } from "app/shared-components/Styles/CustomStyle";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation("privacyPolicy");

  return (
    <>
      <Box className="pt-24  md:p-[40px]">
        <Container maxWidth="lg" className="md:pl-76 md:pr-76 flex flex-col">
          <StyledTypography variant="h5">
            {t("PRIVACY_POLICY")}
          </StyledTypography>
        </Container>
      </Box>
    </>
  );
};

export default PrivacyPolicy;

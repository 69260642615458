import { Box, Tooltip, Typography } from "@mui/material";
import { decodeUrlAndGetFileType, hasPermission } from "src/app/services/utils";
import { useDispatch } from "react-redux";
import { setDocumentDetail } from "../Document/store/documentCardSlice";
import VerifiedIcon from "@mui/icons-material/Verified";
import CancelIcon from "@mui/icons-material/Cancel";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useTranslation } from "react-i18next";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import ItemIcon from "./ItemIcon";

const DocumentCardDemo = (props) => {
  const { document } = props;

  const { t } = useTranslation("documentCard");

  const dispatch: any = useDispatch();

  const allowDocumentDetailShow = hasPermission(
    FeaturePermissions?.UserDocuments?.View
  );

  return (
    <Box
      sx={{ backgroundColor: "background.paper" }}
      className={`flex flex-col relative w-full sm:w-160 h-160 m-8 p-16 shadow rounded-16 ${allowDocumentDetailShow && "cursor-pointer"}`}
      onClick={() =>
        allowDocumentDetailShow && dispatch(setDocumentDetail(document))
      }
    >
      <div className="absolute top-10 right-10">
        {document?.documentStatus?.toLowerCase() === "pending" ? (
          <Tooltip title={t("PENDING")}>
            <AccessTimeIcon color="warning" />
          </Tooltip>
        ) : document?.documentStatus?.toLowerCase() === "approved" ? (
          <Tooltip title={t("APPROVED")}>
            <VerifiedIcon color="success" />
          </Tooltip>
        ) : document?.documentStatus?.toLowerCase() === "reject" ? (
          <Tooltip title={t("REJECTED")}>
            <CancelIcon color="error" />
          </Tooltip>
        ) : null}
      </div>

      <div className="flex flex-auto w-full items-center justify-center">
        <ItemIcon type={decodeUrlAndGetFileType(document.documentFilePath)} />
      </div>
      <div className="flex shrink flex-col justify-center text-center">
        <Typography className="truncate text-12 font-medium">
          {document.documentTypeName?.toLowerCase() === "other"
            ? document.documentName || document.documentTypeName
            : document.documentTypeName}
        </Typography>
      </div>
    </Box>
  );
};

export default DocumentCardDemo;

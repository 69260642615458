import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import DetailItem from "app/shared-components/DetailItem/DetailItem";
import CommonAccordion from "app/shared-components/Accordion/CommonAccordion";
import history from "@history";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsBankEditDialogOpen,
  setBankDetailDialogOpen,
} from "app/store/application-form/applicationFormSlice";
import { useParams } from "react-router";
import CustomDialog from "app/shared-components/Dialog/customDialog";
import BankDetails from "src/app/main/application-form/component/BankDetails";
import ChangeDetectDialog from "../form/ChangeDetectDialog";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import { isEmpty } from "lodash";
import { getCurrentFinanceApplicationStatus } from "app/store/user/userSlice";

const bankingDetailAccordion = ({ bankingDetail, remarksList }) => {
  const { t } = useTranslation("user");
  const dispatch: any = useDispatch();
  const isOpen = useSelector(getIsBankEditDialogOpen);
  const currentFinanceStatus = useSelector(getCurrentFinanceApplicationStatus);
  const { applicationId } = useParams<{ applicationId: string }>();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const handleClose = () => {
    dispatch(setBankDetailDialogOpen(false));
  };
  const handleEditSectionOpen = () => {
    dispatch(setBankDetailDialogOpen(true));
  };

  const handleDialogClose = () => {
    if (isDirty) {
      setConfirmOpen(true);
    } else {
      handleClose();
    }
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    handleClose();
  };

  const bankingDetailContent = (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <DetailItem
            label={t("NAME_OF_BANK")}
            value={bankingDetail?.bankName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DetailItem
            label={t("BANK_BRANCH")}
            value={bankingDetail?.bankBranch}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <DetailItem
            label={t("ACCOUNT_NUMBER")}
            value={bankingDetail?.accountNumber}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <DetailItem
            label={t("TYPE_OF_ACCOUNT")}
            value={bankingDetail?.typeOfAccount}
          />
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      <CommonAccordion
        title={t("BANK_DETAILS")}
        content={bankingDetailContent}
        remarksList={remarksList}
        isApproved={bankingDetail?.isApproved}
        handleEditOpen={handleEditSectionOpen}
        defaultExpanded={currentFinanceStatus === 'draft' && isEmpty(bankingDetail)}
        permission={FeaturePermissions.UserBankingDetail.Update}
      />
      <CustomDialog
        open={isOpen}
        onClose={handleDialogClose}
        content={{
          title: t("BANK_DETAILS"),
          component: (
            <BankDetails
              financeApplicationId={applicationId}
              isEdit={true}
              setIsDirty={setIsDirty}
            />
          ),
        }}
      ></CustomDialog>
      <ChangeDetectDialog
        open={confirmOpen}
        onClose={handleConfirmClose}
        onButtonClick={() => setConfirmOpen(false)}
      />
    </>
  );
};

export default bankingDetailAccordion;

const locale = {
  TERMS_AND_CONDITION: "Terma Dan Syarat",
  TERMS_AND_CONDITION_DESCRIPTION:
    "Selamat datang ke FKC.COOP! Terma dan Syarat ini mengawal penggunaan laman web kami. Dengan mengakses atau menggunakan laman web kami, anda bersetuju untuk terikat dengan Terma dan Syarat ini. Sila baca dengan teliti sebelum menggunakan laman web kami.",
  CONTENT: [
    {
      title: "Penerimaan Terma",
      message:
        "Dengan mengakses atau menggunakan laman web ini, anda bersetuju untuk terikat dengan Terma dan Syarat ini. Jika anda tidak bersetuju dengan mana-mana bahagian daripada terma ini, anda tidak boleh mengakses laman web ini.",
    },
    {
      title: "Terma dan Syarat Pembiayaan",
      message:
        "Sebarang terma dan syarat pembiayaan yang disediakan di laman web ini tertakluk kepada kelulusan dan boleh berubah tanpa notis. Terma yang dipaparkan adalah untuk tujuan maklumat sahaja dan tidak merupakan perjanjian yang mengikat.",
    },
    {
      title: "Tiada Jaminan Kelulusan",
      message:
        "Memohon pembiayaan melalui laman web ini tidak menjamin kelulusan. Semua permohonan adalah tertakluk kepada semakan dan kelulusan berdasarkan kriteria dan dasar kami.",
    },
    {
      title: "Kesilapan dan Kesalahan",
      message:
        "Walaupun kami berusaha untuk menyediakan maklumat yang tepat dan terkini, kesilapan dan kesalahan mungkin berlaku. Kami tidak menjamin ketepatan, kelengkapan, atau kebolehpercayaan sebarang kandungan di laman web ini. Sebarang pergantungan yang anda letakkan pada maklumat tersebut adalah atas risiko anda sendiri.",
    },
    {
      title: "Had Liabiliti",
      message:
        "Dalam apa jua keadaan, Koperasi FK Capital Berhad (FKC.COOP) tidak akan bertanggungjawab ke atas sebarang kerugian langsung, tidak langsung, insidental, khas, atau konsekuen yang timbul daripada atau berkaitan dengan penggunaan laman web ini atau maklumat yang disediakan di laman web ini.",
    },
    {
      title: "Indemnifikasi",
      message:
        "Anda bersetuju untuk menanggung rugi, mempertahankan, dan melepaskan Koperasi FK Capital Berhad, afiliasinya, pegawai, pengarah, pekerja, ejen, pemberi lesen, dan pembekal daripada dan terhadap semua kerugian, perbelanjaan, kerosakan, dan kos, termasuk yuran peguam yang munasabah, yang berpunca daripada sebarang pelanggaran Terma dan Syarat ini atau sebarang aktiviti yang berkaitan dengan akaun anda (termasuk kelakuan yang cuai atau salah) oleh anda atau mana-mana orang lain yang mengakses laman web ini menggunakan akaun Internet anda.",
    },
    {
      title: "Perubahan Terma",
      message:
        "Kami berhak untuk mengemas kini atau mengubah Terma dan Syarat ini pada bila-bila masa tanpa notis terdahulu. Penggunaan berterusan anda terhadap laman web ini selepas sebarang perubahan sedemikian merupakan penerimaan anda terhadap Terma dan Syarat yang baru.",
    },
    {
      title: "Undang-undang Yang Mengawal",
      message:
        "Terma dan Syarat ini akan dikawal oleh dan ditafsirkan mengikut undang-undang Malaysia, tanpa mengambil kira prinsip konflik undang-undangnya.",
    },
    {
      title: "Hubungi Kami",
      message:
        "Jika anda mempunyai sebarang soalan mengenai Terma dan Syarat ini, sila layari halaman hubungi kami di laman web kami.",
    },
  ],
  ACKNOWLEDGE:
    "Dengan menggunakan laman web ini, anda mengakui bahawa anda telah membaca, memahami, dan bersetuju untuk terikat dengan Terma dan Syarat ini.",
};

export default locale;

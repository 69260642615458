import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CommonAccordion from "app/shared-components/Accordion/CommonAccordion";
import ExistingFinanceTable from "./ExistingFinanceTable";
import { isEmpty } from "lodash";
import { getCurrentFinanceApplicationStatus } from "app/store/user/userSlice";
import { useSelector } from "react-redux";

const FinanceDetailAccordion = ({ financeDetail, remarksList }) => {
  const { t } = useTranslation("user");
  const currentFinanceStatus = useSelector(getCurrentFinanceApplicationStatus);
  const [allApproved, setAllApproved] = useState(false);

  useEffect(() => {
    const areAllApproved =
      financeDetail?.length > 0 &&
      financeDetail?.every((finance) => finance.isApproved);
    setAllApproved(areAllApproved);
  }, [financeDetail]);

  return (
    <>
      <CommonAccordion
        title={t("FINANCE_DETAILS")}
        content={
          <ExistingFinanceTable
            financeDetail={financeDetail}
            allApproved={allApproved}
            isEdit={true}
          />
        }
        defaultExpanded={currentFinanceStatus === 'draft' && isEmpty(financeDetail)}
        remarksList={remarksList}
        isApproved={allApproved}
        editIcon={false}
      />
    </>
  );
};

export default FinanceDetailAccordion;

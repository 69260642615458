import React, { useEffect, useState } from "react";
import { StyledTypography } from "app/shared-components/Styles/CustomStyle";
import { Grid, Box, Button, FormGroup } from "@mui/material";
import CustomTextField from "app/shared-components/Form/CustomTextField";
import CustomAutocomplete from "app/shared-components/Form/CustomAutoComplete";
import CustomDatePicker from "app/shared-components/Form/CustomDatePicker";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import history from "@history";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getCurrentStep,
  getRelativeDetails,
  getRelativeDetailsData,
  selectIsButtonLoading,
  setCurrentStep,
  updateUserRelativeDetailsInForm,
  userRelativeDetailsInForm,
  getUserAddressDataInFinanceForm,
  getUserFinanceDetailsDataInFinanceForm,
  getUserAdditionalInfoDataInFinanceForm,
} from "app/store/application-form/applicationFormSlice";
import {
  maritalStatusEnum,
  relationship,
  sexs,
  tenantId,
  titles,
} from "src/app/common/constant";
import ColoredSubmitButton from "app/shared-components/Button/ColoredSubmitButton";
import {
  calculateMinimumDOBForAdult,
  removeLeadingZero,
} from "src/app/services/utils";
import CustomCheckbox from "app/shared-components/Form/CustomCheckbox";
import { isEmpty } from "lodash";
import commonPhoneNumberInputProps from "app/shared-components/Form/commonPhoneNumberInputProps";
import moment from "moment";
import { formatDateForForm } from "src/app/services/utils";
import { dateOfBirthValidation, emailValidation, phoneNumberValidation } from "src/app/services/ValidationHelper/validationHelpers";

export default function SiblingDetails({
  financeApplicationId = null,
  isEdit = false,
  setIsDirty = (value) => {},
}) {
  const { t } = useTranslation("applicationForm");
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const [search] = useSearchParams();
  const activeStep = useSelector(getCurrentStep);
  const relativeDetails = useSelector(getRelativeDetailsData);
  const address = useSelector(getUserAddressDataInFinanceForm);
  const applicationData = useSelector(getUserFinanceDetailsDataInFinanceForm);
  const isLoading = useSelector(selectIsButtonLoading);
  const additionalDetail = useSelector(getUserAdditionalInfoDataInFinanceForm);
  const financeId = search.get("id") || null;
  const userId = search.get("userId") || null;

  const [addDetail, setAddDetail] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const schema = yup.object().shape({
    title: yup.string().required(t("SELECT_FIELD_REQUIRED")),
    other: yup.string().when(["title"], (title, schema) => {
      if (title[0] === "OTHER") {
        return schema.required(
          t("TEXT_FIELD_REQUIRED_VALIDATION", {
            fieldName: t("OTHER"),
          })
        );
      }
      return schema.notRequired();
    }),
    name: yup
      .string()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("FULL_NAME") })
      ),
    sex: yup.string().required(t("SELECT_FIELD_REQUIRED")),
    dateOfBirth: dateOfBirthValidation(),
    nationalId: yup
      .string()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("NATIONAL_ID_OR_PASSPORT") })
      ),
    relationship: yup
      .string()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("RELATIONSHIP") })
      ),
    hpNumber: phoneNumberValidation(true),
    houseNumber: phoneNumberValidation(),
    emailAddress: emailValidation(true),
    houseAddress: yup
      .string()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("HOUSE_ADDRESS") })
      ),
    housePostalCode: yup
      .string()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("POST_CODE") })
      ),
    houseCity: yup
      .string()
      .required(t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("CITY") })),
    houseState: yup
      .string()
      .required(t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("STATE") })),
  });

  useEffect(() => {
    const idToUse = financeId || financeApplicationId;
    if (idToUse) {
      dispatch(getRelativeDetails(idToUse));
    }
  }, [dispatch, financeId, financeApplicationId]);

  const methods = useForm();
  const {
    handleSubmit,
    formState,
    control,
    reset,
    getValues,
    setValue,
    trigger,
    resetField,
    clearErrors,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const { errors, isValid, isDirty } = formState;

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty, setIsDirty]);

  function onSubmit(model, event) {
    event.preventDefault();

    const { other, ...rest } = model;
    const finalTitle = model.title === "OTHER" ? model.other : model.title;

    const request_data = {
      ...rest,
      tenantId: tenantId,
      financeApplicationId: financeId || financeApplicationId,
      title: finalTitle,
      dateOfBirth: formatDateForForm(model.dateOfBirth),
    };
    if (
      Object.keys(relativeDetails).length !== 0 &&
      relativeDetails?.isDefault !== true
    ) {
      const Update_data = {
        ...rest,
        id: relativeDetails?.id,
        title: finalTitle,
        dateOfBirth: formatDateForForm(model.dateOfBirth),
      };
      dispatch(
        updateUserRelativeDetailsInForm(
          Update_data,
          relativeDetails?.financeApplicationId,
          isEdit
        )
      );
    } else {
      dispatch(userRelativeDetailsInForm(request_data, isEdit));
    }
  }

  useEffect(() => {
    if (!isEmpty(relativeDetails)) {
      setValue("name", relativeDetails?.name);
      const dateOfBirth = moment(
        relativeDetails?.dateOfBirth,
        "DD/MMM/YYYY"
      ).toDate();
      setValue("dateOfBirth", dateOfBirth);
      setValue("sex", relativeDetails?.sex);
      setValue("nationalId", relativeDetails?.nationalId);
      setValue("hpNumber", relativeDetails?.hpNumber);
      setValue("emailAddress", relativeDetails?.emailAddress);
      setValue("houseNumber", relativeDetails?.houseNumber);
      setValue("relationship", relativeDetails?.relationship);
      setValue("houseAddress", relativeDetails?.houseAddress);
      setValue("houseState", relativeDetails?.houseState);
      setValue("houseCity", relativeDetails?.houseCity);
      setValue("housePostalCode", relativeDetails?.housePostalCode);

      const title = relativeDetails?.title || titles[0].id;
      if (!titles.map((n) => n.id).includes(title)) {
        setValue("title", "OTHER");
        setValue("other", title || "");
      } else {
        setValue("title", title);
        setValue("other", "");
      }
      reset(getValues());
    }
  }, [relativeDetails, setValue]);

  useEffect(() => {
    // Determine if the form should be shown based on marital status
    const userMaritalStatus =
      additionalDetail?.maritalStatus ||
      applicationData?.userAdditionalInformation?.maritalStatus;

    if (userMaritalStatus === maritalStatusEnum[0].id) {
      // User is married, show form
      setShowForm(true);
    } else {
      // User is not married, show checkbox to add details
      setShowForm(false);
    }
  }, [additionalDetail, applicationData]);

  const handleBack = () => {
    dispatch(setCurrentStep(activeStep - 1));
    navigate(
      `/application-form/${activeStep - 1}?userId=${userId}&id=${financeId}`
    );
  };

  function handleChange(value) {
    setAddDetail(value);
  }
  function handleNext() {
    history.push(
      `/application-form/${activeStep + 1}?userId=${userId}&id=${financeId}`
    );
  }

  function handleSameChange(sameAddress) {
    if (address && sameAddress && Object.keys(address).length !== 0) {
      setValue("houseAddress", address?.houseAddress, { shouldValidate: true });
      setValue("houseState", address?.houseState, { shouldValidate: true });
      setValue("houseCity", address?.houseCity, { shouldValidate: true });
      setValue("housePostalCode", address?.housePostalCode, {
        shouldValidate: true,
      });
    } else if (
      applicationData?.userAddress &&
      sameAddress &&
      Object.keys(applicationData?.userAddress).length !== 0
    ) {
      const address = applicationData?.userAddress;
      setValue("houseAddress", address?.houseAddress, { shouldValidate: true });
      setValue("houseState", address?.houseState, { shouldValidate: true });
      setValue("houseCity", address?.houseCity, { shouldValidate: true });
      setValue("housePostalCode", address?.housePostalCode, {
        shouldValidate: true,
      });
    } else {
      resetField("houseAddress");
      resetField("houseCity");
      resetField("houseState");
      resetField("housePostalCode");
    }
    clearErrors(["houseAddress", "houseCity", "houseState", "housePostalCode"]);
  }

  return (
    <>
      <FormProvider {...methods}>
        {isEmpty(relativeDetails) && !isEdit && !showForm && (
          <>
            <Grid container spacing={2} className="mt-6">
              <Grid item xs={12}>
                <CustomCheckbox
                  label={t("DO_YOU_WANT_TO_ADD_SPOUSE_OR_SIBLINGS_DETAILS")}
                  onChange={handleChange}
                  data-test-id="add-application-spouse-form-is-married-checkbox"
                />
              </Grid>
            </Grid>
            {!addDetail && (
              <Box>
                <Box className="flex justify-end items-end gap-20 mt-10">
                  <Button
                    variant="outlined"
                    className="capitalize w-120"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {t("PREVIOUS")}
                  </Button>
                  <ColoredSubmitButton
                    className="w-120"
                    text={t("NEXT")}
                    onClick={handleNext}
                    isLoading={isLoading}
                  ></ColoredSubmitButton>
                </Box>
              </Box>
            )}
          </>
        )}
        {(!isEmpty(relativeDetails) || addDetail || isEdit || showForm) && (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-10"
            data-test-id="add-application-spouse-form"
          >
            <StyledTypography variant="body1" className="mt-20">
              {t("DETAILS_OF_SPOUSE")}
            </StyledTypography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={2} className="mb-8">
                    <Controller
                      name="title"
                      control={control}
                      defaultValue={titles[0].id}
                      render={({ field }) => (
                        <CustomAutocomplete
                          label={t("TITLE")}
                          required
                          onChange={(value: any) => {
                            if (value && value.id) {
                              field.onChange(value.id);
                            } else {
                              field.onChange(null);
                            }
                          }}
                          options={titles?.map((item) => ({
                            label: t(item.label),
                            id: item.id,
                          }))}
                          value={field.value || ""}
                          error={errors.title && t(errors.title.message)}
                          data-test-id="add-application-spouse-form-title-field"
                        />
                      )}
                    />
                  </Grid>
                  {watch("title") === "OTHER" && (
                    <Grid item xs={12} sm={2}>
                      <Controller
                        name="other"
                        control={control}
                        render={({ field }) => (
                          <CustomTextField
                            label={t("OTHER")}
                            fullWidth
                            required
                            value={field.value}
                            onChange={field.onChange}
                            error={errors?.other?.message}
                            inputProps={{
                              "data-test-id": "add-application-spouse-form-other-title-field",
                            }}
                          />
                        )}
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sm={watch("title") === "OTHER" ? 8 : 10}
                    className="mb-8"
                  >
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          label={t("FULL_NAME")}
                          required
                          onChange={field.onChange}
                          value={field.value || ""}
                          fullWidth
                          error={errors.name && t(errors.name.message)}
                          inputProps={{
                            "data-test-id": "add-application-spouse-form-name-field",
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3} className="mb-8">
                    <Controller
                      name="sex"
                      control={control}
                      render={({ field }) => (
                        <CustomAutocomplete
                          label={t("SEX")}
                          required
                          onChange={(value: any) => {
                            if (value && value.id) {
                              field.onChange(value.id);
                            } else {
                              field.onChange(null);
                            }
                          }}
                          value={field.value || ""}
                          options={sexs?.map((item) => ({
                            label: t(item.label),
                            id: item.id,
                          }))}
                          error={errors.sex && t(errors.sex.message)}
                          data-test-id="add-application-spouse-form-sex-field"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} className="mb-8">
                    <Controller
                      name="dateOfBirth"
                      control={control}
                      render={({ field }) => (
                        <CustomDatePicker
                          label={t("DOB")}
                          required
                          maxDate={calculateMinimumDOBForAdult()}
                          onChange={field.onChange}
                          value={field.value}
                          error={
                            errors.dateOfBirth && errors.dateOfBirth.message
                          }
                          datatestid="add-application-spouse-form-date-of-birth-field"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Controller
                      name="nationalId"
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          label={t("NATIONAL_ID_OR_PASSPORT")}
                          onChange={field.onChange}
                          value={field.value || ""}
                          required
                          fullWidth
                          isUpperCase={false}
                          inputProps={{ maxLength: 12 , "data-test-id": "add-application-spouse-form-national-id-field",}}
                          error={
                            errors.nationalId && t(errors.nationalId.message)
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Controller
                      name="relationship"
                      control={control}
                      render={({ field }) => (
                        <CustomAutocomplete
                          label={t("RELATIONSHIP")}
                          data-test-id="add-application-spouse-form-relationship-field"
                          required
                          onChange={(value: any) => {
                            if (value && value.id) {
                              field.onChange(value.id);
                            } else {
                              field.onChange(null);
                            }
                          }}
                          value={field.value || ""}
                          fullWidth
                          options={relationship?.map((item) => ({
                            label: t(item.label),
                            id: item.id,
                          }))}
                          error={
                            errors.relationship &&
                            t(errors.relationship.message)
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <StyledTypography variant="body1" className="mt-20">
              {t("CONTACT_DETAILS")}
            </StyledTypography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} className="mb-8">
                    <Controller
                      name="hpNumber"
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          label={t("MOBILE_PHONE")}
                          type="number"
                          required
                          fullWidth
                          onChange={(value: any) => {
                            field.onChange(removeLeadingZero(value));
                          }}
                          value={field.value || ""}
                          InputProps={commonPhoneNumberInputProps}
                          error={errors.hpNumber && errors.hpNumber.message}
                          inputProps={{
                            "data-test-id": "add-application-spouse-form-hp-number-field",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} className="mb-8">
                    <Controller
                      name="houseNumber"
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          label={t("HOME_PHONE")}
                          type="number"
                          onChange={(value: any) => {
                            field.onChange(removeLeadingZero(value));
                          }}
                          value={field.value || ""}
                          InputProps={commonPhoneNumberInputProps}
                          fullWidth
                          error={
                            errors.houseNumber && errors.houseNumber.message
                          }
                          inputProps={{
                            "data-test-id": "add-application-spouse-form-house-number-field",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} className="mb-8">
                    <Controller
                      name="emailAddress"
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          label={t("PERSONAL_EMAIL")}
                          required
                          fullWidth
                          onChange={field.onChange}
                          value={field.value || ""}
                          error={
                            errors.emailAddress &&
                            errors.emailAddress.message
                          }
                          inputProps={{
                            "data-test-id": "add-application-spouse-form-email-field",
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container className="mt-6">
              <Grid item xs={12}>
                <FormGroup>
                  <CustomCheckbox
                    label={t("SAME_AS_MY_ADDRESS")}
                    onChange={(e) => {
                      handleSameChange(e);
                    }}
                    data-test-id="add-application-spouse-form-same-address-checkbox"
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <StyledTypography variant="body1">
              {t("HOUSE_ADDRESS")}
            </StyledTypography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} className="mb-8">
                    <Controller
                      name="houseAddress"
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          label={t("HOUSE_ADDRESS")}
                          required
                          fullWidth
                          onChange={field.onChange}
                          value={field.value || ""}
                          error={
                            errors.houseAddress &&
                            t(errors.houseAddress.message)
                          }
                          inputProps={{
                            "data-test-id": "add-application-spouse-form-house-address-field",
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} className="mb-8">
                    <Controller
                      name="houseCity"
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          label={t("CITY")}
                          onChange={field.onChange}
                          value={field.value || ""}
                          required
                          fullWidth
                          error={
                            errors.houseCity && t(errors.houseCity.message)
                          }
                          inputProps={{
                            "data-test-id": "add-application-spouse-form-house-city-field",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} className="mb-8">
                    <Controller
                      name="houseState"
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          label={t("STATE")}
                          onChange={field.onChange}
                          value={field.value || ""}
                          required
                          fullWidth
                          error={
                            errors.houseState && t(errors.houseState.message)
                          }
                          inputProps={{
                            "data-test-id": "add-application-spouse-form-house-state-field",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} className="mb-8">
                    <Controller
                      name="housePostalCode"
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          label={t("POST_CODE")}
                          onChange={field.onChange}
                          value={field.value || ""}
                          required
                          fullWidth
                          error={
                            errors.housePostalCode &&
                            t(errors.housePostalCode.message)
                          }
                          inputProps={{
                            "data-test-id": "add-application-spouse-form-house-postal-code-field",
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box>
              <Box className="flex justify-end items-end gap-20 mt-10">
                {isEdit ? (
                  <ColoredSubmitButton
                    className="w-120"
                    disabled={!isValid}
                    text={t("SAVE")}
                    isLoading={isLoading}
                    data-test-id="add-application-spouse-form-submit-btn"
                  ></ColoredSubmitButton>
                ) : (
                  <>
                    <Button
                      variant="outlined"
                      className="capitalize w-120"
                      onClick={handleBack}
                      disabled={activeStep === 0}
                    >
                      {t("PREVIOUS")}
                    </Button>
                    <ColoredSubmitButton
                      className="w-120"
                      disabled={!isValid}
                      text={t("NEXT")}
                      isLoading={isLoading}
                      data-test-id="add-application-spouse-form-submit-btn"
                    ></ColoredSubmitButton>
                  </>
                )}
              </Box>
            </Box>
          </form>
        )}
      </FormProvider>
    </>
  );
}

const locale = {
  LOAN_AMOUNT: "Loan Amount",
  NUMBER_OF_YEAR: "Number Of Years",
  INTEREST_RATE: "Profit Rate",
  MONTHLY_PAYMENT: "Monthly Payments",
  TITLE: "Your Monthly Payment",
  DESCRIPTION: "Base Calculation",
  CALCULATE_BUTTON: "Calculate",
  GET_STARTED_BUTTON: "Get Started",
};

export default locale;

const locale = {
  CONTACT_US_TITLE: "Contact Us",
  CONTACT_US_DESCRIPTION:
    "Connect with our financial and membership services team! Whether you have inquiries about our offerings, need assistance with your membership, or want to discuss financial matters, we're here to assist you. Reach out to us using the form below or via email. Our team is committed to providing timely and helpful responses to all inquiries. Thank you for reaching out, and we're excited to hear from you!",
  LETS_TALK: "Let's Talk",
  FULL_NAME: "Full Name",
  EMAIL_ADDRESS: "Email Address",
  MESSAGE: "Message",
  SEND_BUTTON: "Send",
  GET_IN_TOUCH: "Get in Touch",
  CONTACT_INFORMATION: "Contact Information",
  LOCAL_PHONE: "(Local & Overseas)",
  HUNTING_PHONE: "(Hunting Line)",
  FULLNAME_REQUIRED: "Full Name is required",
  EMAIL_REQUIRED: "Email is required",
  EMAIL_FORMAT_VALIDATION: "Invalid email format",
  MESSAGE_REQUIRED: "Message is required",
  PHONE_NUMBER: "Phone Number",

  // standart validation messages
  TEXT_FIELD_REQUIRED_VALIDATION: "{{fieldName}} is required",
  SELECT_FIELD_REQUIRED: "Please select a valid option",
  EMAIL_INVALID_VALIDATION: "Please enter a valid email address",
  NUMBER_INVALID_VALIDATION: "Please enter a valid number",
  MINIMUM_VALIDATION: "{{fieldName}} must be greater than or equal to  {{min}}",
  MAXIMUM_VALIDATION: "{{fieldName}} must be less than or equal to  {{max}}",
  VALID_DATE_VALIDATION: "Please enter a valid date",
 
};

export default locale;

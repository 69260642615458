import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import themesConfig from "app/configs/themesConfig";

const CustomTooltipWithWhitePaper = ({ tooltipContent, title }) => {
  const [openTooltip, setOpenTooltip] = useState(false);
  const handleTooltipOpen = () => setOpenTooltip(true);
  const handleTooltipClose = () => setOpenTooltip(false);

  return (
    <>
      <Tooltip
        title={tooltipContent}
        open={openTooltip}
        onOpen={handleTooltipOpen}
        onClose={handleTooltipClose}
        slotProps={{
          tooltip: {
            sx: {
              backgroundColor: themesConfig.default.palette.background.paper,
              boxShadow: "0px -1px 10px rgba(0, 0, 0, 0.3)",
              borderRadius: "8px",
              padding: "8px",
              width: "auto",
              maxWidth: "100%",
            },
          },
          arrow: {
            sx: {
              color: themesConfig.default.palette.background.paper,
              height: "1.5rem",
              width: "2rem",
              // top: "-6px !important",
            },
          },
        }}
        arrow
      >
        <span
          aria-owns={openTooltip ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
        >
          {title}
        </span>
      </Tooltip>
    </>
  );
};

export default CustomTooltipWithWhitePaper;

const locale = {
  HOW_CAN_WE_HELP: "How can we help you?",
  DESCRIPTION_TEXT: "You'll find answers to common questions and helpful resources to assist you. If you can't find the answer you're looking for, feel free to",
  REST: "for further assistance.",
  CONTACT_US: "contact us",
  
  // question & answers
  QUESTION_1: "What is my financing eligibility limit?",
  ANSWER_1:
    "Monthly installments should not exceed 60% of the basic salary and fixed allowances.",
  QUESTION_2: "What is meant by net income?",
  ANSWER_2: "Basic Salary + Eligible Fixed Allowances – Mandatory Deductions.",
  QUESTION_3: "What types of fixed allowances are considered?",
  ANSWER_3:
    "1. Fixed Housing Allowance (ITP).\n2. Public Service Fixed Allowance (ITKA).\n3. Entertainment Fixed Allowance (ITK).\n4. Military Service Fixed Allowance (ITKT).\n5. Executive Fixed Allowance (ITJU).\n6. Regional Housing Allowance (EPW).\n7. Acting Allowance.\n8. Cost of Living Assistance (COLA).\n9. Regional Incentive Payment (BIW).\n10. Peninsular Malaysia Incentive Payment (BISM).\n11. Sabah/Sarawak Incentive Payment (BISS).\n12. Other: Please consult our staff for more information.",
  QUESTION_4: "What types of mandatory deductions are considered?",
  ANSWER_4:
    "1. EPF (Employees Provident Fund).\n2. SOCSO (Social Security Organization).\n3. Income Tax.\n4. Court Order.\n5. Shariah Court Order.\n6. Salary Repayment.\n7. Repayment of Federal/State Government/Statutory Body Housing Loans.\n8. Other: Please consult our staff for more information.",
  QUESTION_5: "How long is the approval process?",
  ANSWER_5:
    "The approval period is from 3 to 14 working days (subject to complete documentation).",
  QUESTION_6: "How long is the payment process?",
  ANSWER_6: "The payment process takes 3 working days after approval.",
  QUESTION_7: "Are contract employees eligible to apply for financing?",
  ANSWER_7: "Not Eligible.",
  QUESTION_8:
    "What is the deadline for a financing applicant before retirement?",
  ANSWER_8: "1 year before the retirement year.",
  QUESTION_9:
    "Is it necessary for the applicant to purchase takaful coverage for this financing?",
  ANSWER_9:
    "Yes, all approved financing must include takaful coverage from our panel.",
};

export default locale;

import * as React from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Hidden,
  Typography,
  StepContent,
} from "@mui/material";
import { styled } from "@mui/system";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { roleId, stepsForApplicationForm } from "../../../common/constant";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import {
  getCompletionStatus,
  getCurrentStep,
  getUserFinanceApplicationForFinanceForm,
  getUserFinanceDetailsDataInFinanceForm,
  setCurrentStep,
  setStepCompletionStatus,
} from "app/store/application-form/applicationFormSlice";
import { StyledTypography } from "app/shared-components/Styles/CustomStyle";
import themesConfig from "app/configs/themesConfig";
import Can from "src/app/services/FeaturePermission/Can";
import {
  getMemberDetail,
  getMemberDetailsById,
  getSlug,
  setMemberData,
} from "app/store/user/userSlice";
import history from "@history";
import InfoDialog from "app/shared-components/Dialog/InfoDialog";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { isEmpty } from "lodash";

export const StyledStepper = styled(Stepper)<{
  orientation?: "horizontal" | "vertical";
}>(({ theme, orientation }) => ({
  "& .MuiStepConnector-root": {
    left: "calc(-50% + 11px)",
    right: "calc(50% + 11px)",
  },
  "& .MuiStepContent-root": {
    color: theme.palette.secondary.main,
    borderLeft: "3px solid",
  },
  "& .MuiStepIcon-root.Mui-active, & .MuiStepIcon-root.Mui-completed": {
    color: theme.palette.secondary.main,
  },
  "& .MuiStepIcon-root": {
    color: theme.palette.primary.contrastText,
  },
  "& .MuiStepIcon-text": {
    fill: theme.palette.common.white,
  },
  "@media (min-width: 1150px)": {
    marginLeft: "-27px",
    marginRight: "-15px",
  },
  "@media (min-width: 900px) and (max-width: 1150px)": {
    marginLeft: "-20px",
    marginRight: "-15px",
  },
  "@media (min-width: 680px) and (max-width: 900px)": {
    marginLeft: "-10px",
    marginRight: "-5px",
  },
  ...(orientation === "vertical" && {
    marginLeft: "0",
    marginRight: "0",
  }),
}));

export const StyledStepConnector = styled(StepConnector)<{
  orientation?: "horizontal" | "vertical";
}>(({ theme, orientation }) => ({
  [`&.${stepConnectorClasses.active}, &.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.secondary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.primary.contrastText,
    borderTopWidth: orientation === "vertical" ? 0 : 3,
    borderLeftWidth: orientation === "vertical" ? 3 : 0,
    marginTop: orientation === "vertical" ? 0 : 0,
    height: orientation === "vertical" ? "100%" : "2px",
  },
  ...(orientation === "vertical" && {
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.secondary.main,
      },
    },
  }),
}));

export const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontWeight: "bold",
  cursor: "pointer",
}));

export default function CommonStepper() {
  const { t } = useTranslation("applicationForm");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const [search] = useSearchParams();
  const financeId = search.get("id");
  const userId = search.get("userId");
  const activeStep = useSelector(getCurrentStep);
  const applicationData = useSelector(getUserFinanceDetailsDataInFinanceForm);
  const stepCompletionStatus = useSelector(getCompletionStatus);
  const slug = useSelector(getSlug);
  const memberData = useSelector(getMemberDetail);
  const [isUser, setIsUser] = React.useState<boolean>(true);

  // State to control the Not Draft dialog visibility
  const [isDialogOpen, setDialogOpen] = React.useState(false);

  React.useEffect(() => {
    setIsUser(slug === "customer-user");
  }, [slug]);

  // // FOR NOT GET USER_ID AND FINANCE_ID FROM URL THEN MOVE TO FIRST STEP
  // React.useEffect(() => {
  //   if (!financeId && !userId) {
  //     navigate(`/application-form/0`);
  //   }
  // }, [userId, financeId]);

  React.useEffect(() => {
    if (financeId) {
      dispatch(getUserFinanceApplicationForFinanceForm(financeId));
    }
  }, [financeId, dispatch]);

  React.useEffect(() => {
    if (userId && !financeId) {
      dispatch(getMemberDetailsById(userId));
    }

    return () => {
      dispatch(setMemberData(null));
    };
  }, [financeId, userId]);

  React.useEffect(() => {
    const pathParts = location.pathname.split("/");
    const step = parseInt(pathParts[pathParts.length - 1]);
    if (!isNaN(step) && step >= 0 && step < stepsForApplicationForm.length) {
      dispatch(setCurrentStep(step));
    } else {
      navigate(`/application-form/${activeStep}`);
    }
  }, [location.pathname, navigate, dispatch, activeStep]);

  React.useEffect(() => {
    if (isEmpty(applicationData) || !financeId) return;
    if (
      applicationData &&
      applicationData?.financeApplicationStatus?.toLowerCase() !== "draft"
    ) {
      setDialogOpen(true);
      return;
    }

    const stepCompletionStatuses = [
      !!financeId,
      applicationData.isUserAdditionalInformationCompleted,
      applicationData.isUserAddressCompleted,
      applicationData.isUserRelativeDetailCompleted,
      applicationData.isUserReferralDetailCompleted,
      applicationData.isUserNextofkinCompleted,
      applicationData.isUserEmploymentDetailCompleted,
      applicationData.isUserIncomeDetailCompleted,
      applicationData.isUserExistingFinanceCompleted,
      applicationData.isUserBankingDetailCompleted,
      applicationData.isUserDocumentsCompleted,
    ];

    const updatedSteps: { index: number; isComplete: boolean }[] =
      stepsForApplicationForm.map((step, index) => ({
        index,
        isComplete: !!stepCompletionStatuses[index],
      }));

    dispatch(setStepCompletionStatus({ statuses: updatedSteps }));
  }, [applicationData, financeId, dispatch]);

  const handleStep = (step: number) => () => {
    dispatch(setCurrentStep(step));
    navigate(`/application-form/${step}?userId=${userId}&id=${financeId}`);
  };

  const mappingStep = (orientation = "horizontal") =>
    stepsForApplicationForm.map((step, index) => (
      <Step key={step.label} completed={stepCompletionStatus[index]}>
        <StyledStepLabel
          style={{
            cursor: stepCompletionStatus[index] ? "pointer" : "default",
          }}
          onClick={stepCompletionStatus[index] ? handleStep(index) : undefined}
        >
          <Typography
            style={{
              color:
                activeStep === index
                  ? themesConfig.legacy.palette.error.dark
                  : themesConfig.default.palette.common.black,
            }}
          >
            {t(step.label)}
          </Typography>
        </StyledStepLabel>
        {orientation === "vertical" && activeStep === index && (
          <StepContent>
            <Outlet />
          </StepContent>
        )}
      </Step>
    ));

  return (
    <>
      <Can
        permission={FeaturePermissions.FinanceApplication.Create}
        errorMessage="ACCESS_DENIED"
      >
        <>
          <StyledTypography variant="h5" className="sm:mb-40">
            {t("FORM_TITLE")}{" "}
            {!isUser && !financeId && userId
              ? `: ${memberData?.fullName}`
              : applicationData?.userName
              ? `: ${applicationData.userName}`
              : ""}
          </StyledTypography>
          <Hidden mdUp>
            <Box className="mb-20 ml-8 flex flex-col justify-center items-start w-full">
              <StyledStepper
                activeStep={activeStep}
                orientation="vertical"
                className="w-full"
                connector={<StyledStepConnector orientation="vertical" />}
              >
                {mappingStep("vertical")}
              </StyledStepper>
            </Box>
          </Hidden>
          <Hidden mdDown>
            <StyledStepper
              activeStep={activeStep}
              alternativeLabel
              connector={<StyledStepConnector />}
            >
              {mappingStep()}
            </StyledStepper>
          </Hidden>
          {activeStep < stepsForApplicationForm.length && (
            <Hidden mdDown>
              <Outlet />
            </Hidden>
          )}
        </>
      </Can>

      {isDialogOpen && (
        <InfoDialog
          open={isDialogOpen}
          title={t("MODIFY_APPLICATION_TITLE")}
          message={t("MODIFY_APPLICATION_DIS")}
          buttonText={t("MODIFY_APPLICATION_BUTTON")}
          onButtonClick={() =>
            history.replace(`user/my-application/${applicationData?.id}`)
          }
          icon={InfoOutlinedIcon}
          color="warning"
        />
      )}
    </>
  );
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { showMessage } from "app/store/fuse/messageSlice";
import api from "src/app/services/apiService";
import { getApplicationDetails } from "app/store/user/userSlice";

interface DocumentCardState {
  documentList: any;
  documentDetail: any;
  updateDocumentDetail: any;
  documentTypeList: any;
  documentTypeFilters: Record<string, any>;
  loading: {
    isButtonLoading: boolean;
    isDocumentDetailCardOpen: boolean;
    isDocumentDialogOpen: boolean;
    isDeleteDialogOpen: boolean;
    isRequiredDocsAlertIsOpen: boolean;
  };
}

// GET DOCUMENT LIST
export const getUserDocumentsById =
  (document) => async (dispatch, getState) => {
    try {
      const filters = getFiltersForDocumentType(getState());
      let request: any = `?UserId=${document.userId}`;
      if (document.financeApplicationId) {
        request = `?FinanceApplicationId=${document.financeApplicationId}`;
      }
      if (filters.type) {
        request += `&Type=${filters.type}`;
      }
      const response: any = await api.get(`/v1/UserDocuments${request}`);
      dispatch(setDocumentsList(response.result.items));
    } catch (error) {
      console.error(error);
    }
  };

export const getDocumentTypeList = () => async (dispatch, getState) => {
  try {
    const filters = getFiltersForDocumentType(getState());
    const queryParams = [filters.type && `Type=${filters.type}`]
      .filter((param) => param)
      .join("&");

    const response: any = await api.get(`/v1/DocumentType?${queryParams}`);
    if (response && response.result) {
      const documentSelectTypes = response.result.items.map((item) => ({
        id: item.id,
        name: item.name,
        require: item.isRequired,
      }));
      dispatch(setDocumentSelectTypeList(documentSelectTypes));
    }
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: "error" }));
    console.error(error);
  }
};

//ADD DOCUMENT
export const UploadDocument = (document) => async (dispatch, getState) => {
  dispatch(setIsButtonLoading(true));
  try {
    const response: any = await api.post("/v1/UserDocuments", document);
    if (response.status) {
      dispatch(showMessage({ message: response.message, variant: "success" }));
      if (document.financeApplicationId) {
        dispatch(
          getUserDocumentsById({
            financeApplicationId: document.financeApplicationId,
          })
        );
        dispatch(getApplicationDetails( document.financeApplicationId));
      } else {
        dispatch(
          getUserDocumentsById({
            userId: document.userId,
          })
        );
      }
      dispatch(setIsButtonLoading(false));
      dispatch(setIsDocumentDialogOpen(false));
    }
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: "error" }));
    dispatch(setIsButtonLoading(false));
    console.error(error);
  }
};

// UPDATE DOCUMENT
export const UpdateUploadedDocument =
  (document) => async (dispatch, getState) => {
    dispatch(setIsButtonLoading(true));
    try {
      const response: any = await api.put(
        "/v1/UserDocuments/UpdateUserDocument",
        document
      );

      dispatch(
        showMessage({
          message: "Document Update Successfully",
          variant: "success",
        })
      );

      dispatch(setUpdateDocumentDetail(null));
      if (document.financeApplicationId) {
        dispatch(
          getUserDocumentsById({
            financeApplicationId: document.financeApplicationId,
          })
        );
      } else {
        dispatch(
          getUserDocumentsById({
            userId: document.userId,
          })
        );
      }
      dispatch(setIsButtonLoading(false));
      dispatch(setIsDocumentDialogOpen(false));
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      dispatch(setIsButtonLoading(false));
      console.error(error);
    }
  };

// DELETE DOCUMENT
export const deleteDocument =
  (id, idToUse = null) =>
  async (dispatch, getState) => {
    try {
      const response = await api.delete(`/v1/UserDocuments?id=${id}`);
      if (idToUse.financeApplicationId) {
        dispatch(
          getUserDocumentsById({
            financeApplicationId: idToUse.financeApplicationId,
          })
        );
        dispatch(getApplicationDetails(idToUse.financeApplicationId));
      } else if (idToUse.userId) {
        dispatch(
          getUserDocumentsById({
            userId: idToUse.userId,
          })
        );
      }
      dispatch(
        showMessage({ message: "Deleted Successfully", variant: "success" })
      );
    } catch (error) {
      console.error(error);
      dispatch(
        showMessage({ message: "Failed to delete document", variant: "error" })
      );
    }
  };

// Loading selectors start
const setLoadingState = (key, value) => async (dispatch) => {
  dispatch(setLoading({ key, value }));
};
export const setIsButtonLoading = (value) =>
  setLoadingState("isButtonLoading", value);
export const setIsDocumentDetailCardOpen = (value) =>
  setLoadingState("isDocumentDetailCardOpen", value);
export const setIsDocumentDialogOpen = (value) =>
  setLoadingState("isDocumentDialogOpen", value);
export const setIsDeleteDialogOpen = (value) =>
  setLoadingState("isDeleteDialogOpen", value);
export const setIsRequiredDocsAlertIsOpen = (value) => setLoadingState("isRequiredDocsAlertIsOpen", value);

export const getLoadingStateFactory = (loader) => (state) => {
  return state.documentCard.loading[loader] || false;
};

export const getIsButtonLoading = getLoadingStateFactory("isButtonLoading");
export const getIsDocumentDetailCardOpen = getLoadingStateFactory(
  "isDocumentDetailCardOpen"
);
export const getIsDocumentDialogOpen = getLoadingStateFactory(
  "isDocumentDialogOpen"
);
export const getIsDeleteDialogOpen =
  getLoadingStateFactory("isDeleteDialogOpen");

export const getIsRequiredDocsAlertIsOpen  = getLoadingStateFactory("isRequiredDocsAlertIsOpen");
// Loading selectors end

// GETSTATE

export const getDocumentTypeListData = (state) =>
  state.documentCard.documentTypeList;
export const getDocumentList = (state) => state.documentCard.documentList;
export const getDocumentDetail = (state) => state.documentCard.documentDetail;
export const getUpdateDocumentDetail = (state) => state.documentCard.updateDocumentDetail;

export const getFiltersForDocumentType = (state) =>
  state.documentCard.documentTypeFilters;

const initialState: DocumentCardState = {
  documentDetail: null,
  documentList: null,
  documentTypeList: null,
  updateDocumentDetail: null,
  documentTypeFilters: {
    type: null,
  },
  loading: {
    isButtonLoading: false,
    isDocumentDetailCardOpen: false,
    isDeleteDialogOpen: false,
    isDocumentDialogOpen: false,
    isRequiredDocsAlertIsOpen: false,
  },
};

const documentCardSlice = createSlice({
  name: "documentCard",
  initialState,
  reducers: {
    setDocumentsList: (state, action: PayloadAction<any[]>) => {
      state.documentList = action.payload;
    },
    setDocumentDetail: (state, action: PayloadAction<any>) => {
      state.documentDetail = action.payload;
    },
    setUpdateDocumentDetail: (state, action: PayloadAction<any>) => {
      state.updateDocumentDetail = action.payload;
    },
    setDocumentTypeFilters: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      state.documentTypeFilters = {
        ...state.documentTypeFilters,
        ...action.payload,
      };
    },
    setDocumentSelectTypeList: (
      state,
      action: PayloadAction<DocumentType[]>
    ) => {
      state.documentTypeList = action.payload;
    },
    setLoading: (state, action) => {
      const { key, value } = action.payload;
      state.loading = {
        ...state.loading,
        [key]: value,
      };
    },
    resetDocumentDetail: (state) => {
      state.documentDetail = null;
    },
  },
});

export const {
  setDocumentsList,
  setDocumentDetail,
  setUpdateDocumentDetail,
  setDocumentSelectTypeList,
  setDocumentTypeFilters,
  setLoading,
  resetDocumentDetail,
} = documentCardSlice.actions;
export default documentCardSlice;

import { Outlet } from "react-router";

function ProductPage() {
    return(
        <>
            <Outlet/>
        </>
    )
}

export default ProductPage;
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
	changeLanguage,
	selectCurrentLanguage,
	selectLanguages,
	LanguageType,
	getIsLanguageDialogOpen,
	setIsLanguageDialogOpen,
} from "app/store/i18nSlice";
import { useAppDispatch } from "app/store";
import Language from "app/shared-components/Language/Language";

/**
 * The language switcher.
 */
function LanguageSwitcher() {
	const currentLanguage = useSelector(selectCurrentLanguage);
	const languages = useSelector(selectLanguages);
	const [menu, setMenu] = useState<null | HTMLElement>(null);
	const isOpenLanguageDialog = useSelector(getIsLanguageDialogOpen);
	const dispatch: any = useAppDispatch();

	useEffect(() => {
		const localStorageLanguage = localStorage.getItem("language");
		if (localStorageLanguage) {
			dispatch(changeLanguage(localStorageLanguage));
		} else {
			dispatch(setIsLanguageDialogOpen(true));
		}
	}, [dispatch]);

	const langMenuClick = (event: React.MouseEvent<HTMLElement>) => {
		setMenu(event.currentTarget);
	};

	const langMenuClose = () => {
		setMenu(null);
	};

	const handleLanguageChange = (lng: LanguageType) => {
		dispatch(changeLanguage(lng.id));
		langMenuClose();
	};

	const handleDialogClose = () => {
		dispatch(setIsLanguageDialogOpen(false));
	};

	return (
		<>
			<Button className="h-40 w-64" onClick={langMenuClick}>
				<img
					className="mx-4 min-w-20"
					src={`assets/images/flags/${currentLanguage.flag}.svg`}
					alt={currentLanguage.title}
				/>
				<Typography
					className="mx-4 font-semibold uppercase"
					color="text.secondary"
				>
					{currentLanguage.id}
				</Typography>
			</Button>

			<Popover
				open={Boolean(menu)}
				anchorEl={menu}
				onClose={langMenuClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				classes={{
					paper: "py-8",
				}}
			>
				{languages.map((lng) => (
					<MenuItem key={lng.id} onClick={() => handleLanguageChange(lng)}>
						<ListItemIcon className="min-w-40">
							<img
								className="min-w-20"
								width={24}
								src={`assets/images/flags/${lng.flag}.svg`}
								alt={lng.title}
							/>
						</ListItemIcon>
						<ListItemText primary={lng.title} />
					</MenuItem>
				))}
			</Popover>
			<Language open={isOpenLanguageDialog} onClose={handleDialogClose} />
		</>
	);
}

export default LanguageSwitcher;

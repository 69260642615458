import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import KYCGuideline from "./KYCGuideline";
import { CommonBreadcrumbs } from "app/shared-components/Breadcrumbs/CommonBreadcrumbs";
import { useTranslation } from "react-i18next";
import MyKadFinance from "src/app/main/user/component/finance-detail-page/e-KYC/MyKadFinance";
import FinanceKYCQRCode from "./FinanceKYCQRCode";
import { isMobileOnly } from "react-device-detect";
import KYCResult from "./KYCResult";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsShowGuideline,
  kycProcessStart,
} from "app/store/application-form/applicationFormSlice";
import { useParams } from "react-router";
import {
  getApplicationDetailData,
  getApplicationDetails,
} from "app/store/user/userSlice";
import history from "@history";

const KYCProcess: React.FC = () => {
  const { t } = useTranslation("user");
  const dispatch: any = useDispatch();
  const applicationDetailsData = useSelector(getApplicationDetailData);
  const showGuideline = useSelector(getIsShowGuideline);
  const [showResult, setShowResult] = useState<boolean>(false);
  const params = useParams();
  const { applicationId } = params;

  useEffect(() => {
    dispatch(getApplicationDetails(applicationId));
  }, [dispatch]);

  useEffect(() => {
    if (applicationDetailsData?.ekycStatus?.toLowerCase() === "success") {
      history.push(`/user/my-application/${encodeURIComponent(applicationId)}`);
    }
  }, [applicationDetailsData]);

  const startKYC = () => {
    dispatch(kycProcessStart(applicationId));
    window.scrollTo(0, 0);
  };

  const handleRestart = () => {
    window.location.reload();
  };

  const handleShowResult = () => {
    setShowResult(true);
  };

  if (applicationDetailsData?.ekycStatus?.toLowerCase() === "success") {
    return null; // Don't render anything if status is success
  }

  return (
    <>
      {!isMobileOnly ? (
        <Container
          maxWidth="lg"
          className="pl-10 mt-10 md:pl-76 pr-10 md:pr-76 mb-64"
        >
          <CommonBreadcrumbs
            pages={[
              {
                to: "/user/my-application",
                name: t("MY_APPLICATION"),
              },
            ]}
            currentPageName={t("KYC_PROCESS")}
          />
          <FinanceKYCQRCode />
        </Container>
      ) : !showGuideline ? (
        <Container
          maxWidth="lg"
          className="pl-10 mt-10 md:pl-76 pr-10 md:pr-76 mb-64"
        >
          <CommonBreadcrumbs
            pages={[
              {
                to: "/user/my-application",
                name: t("MY_APPLICATION"),
              },
            ]}
            currentPageName={t("KYC_PROCESS")}
          />
          <KYCGuideline startKYC={startKYC} />
        </Container>
      ) : showResult ? (
        <KYCResult onRetry={handleRestart} />
      ) : (
        <MyKadFinance showResult={handleShowResult} />
      )}
    </>
  );
};

export default KYCProcess;

const locale = {
  TITLE: "Log Masuk / Daftar",
  DESCRIPTION:
    "Masukkan maklumat akaun anda untuk meneruskan.",
  LOGIN: "Log Masuk",
  SIGN_UP: "Daftar",
  LOGIN_TITLE: "Selamat Kembali!",
  LOGIN_DESCRIPTION: "Log Masuk ke Akaun Anda",
  PHONE_LABEL: "Nombor Telefon",
  PASSWORD_LABEL: "Kata Laluan",
  FORGOT_PASSWORD: "Lupa Kata Laluan?",
  DONT_HAVE_ACCOUNT: "Tiada akaun?",
  CREATE_ACCOUNT: "Mulakan dengan mendaftar!",
  SIGN_UP_TITLE: "Cipta Akaun Anda",
  SIGN_UP_DESCRIPTION: "Isi Butiran untuk Daftar",
  FULL_NAME_LABEL: "Nama Penuh",
  EMAIL_LABEL: "Alamat Emel",
  REFERRAL_CODE: "Kod Rujukan",
  CONFIRM_PASSWORD_LABEL: "Pengesahan Kata Laluan",
  ALREADY_ACCOUNT: "Sudah mempunyai akaun",
  LOGIN_NOW: " Log Masuk Sekarang",
  USER_CREATE_TITLE: "Pendaftaran berjaya!",
  SIGN_UP_MESSAGE:
    "Terima Kasih kerana mendaftar! Akaun anda telah berjaya dibuat.",
  TERMS_AND_CONDITION: "Terma Dan Syarat",
  CANCEL: "Batal",
  AGREE: "Setuju",
  SEND_OTP: "Hantar OTP",
  I_AGREE_TO_THE_TERMS_AND_CONDITIONS:
    "Saya bersetuju dengan Terma dan Syarat.",

  // MESSAGES PENGESAHAN
  FULL_NAME_VALIDATION: "Anda mesti memasukkan nama penuh",
  PHONE_VALIDATION_REQUIRED: "Anda mesti memasukkan nombor telefon",
  PHONE_VALIDATION_PATTERN: "Nombor telefon mesti mengandungi hanya digit",
  PHONE_VALIDATION_LENGTH: "Nombor telefon mesti 10 digit",
  EMAIL_VALIDATION_REQUIRED: "Anda mesti memasukkan emel",
  EMAIL_VALIDATION_EMAIL: "Anda mesti memasukkan emel yang sah",
  PASSWORD_VALIDATION_REQUIRED: "Sila masukkan kata laluan anda.",
  PASSWORD_VALIDATION_MIN:
    "Kata laluan terlalu pendek - sekurang-kurangnya 8 aksara.",
  CONFIRM_PASSWORD_VALIDATION_MATCH: "Kata laluan mesti sepadan",
  REFERRAL_CODE_LENGTH: "Kod rujukan mestilah tepat 6 digit",
  NUMBER_VALIDATION: "Hanya nombor yang diterima",
};

export default locale;

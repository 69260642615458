import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { setMemberUserId } from "app/store/user/userSlice";

function TableMenu({ data, history }) {
  const dispatch: any = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuId = `action-menu-${data.id}`;

  const openMenu = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleProcessLoan = () => {
    history.push(`/application-form/0?userId=${data.id}`);
    closeMenu();
  };

  return (
    <>
      <IconButton
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={openMenu}
        disableRipple
      >
        <MoreVert color="secondary" />
      </IconButton>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        {/* <MenuItem onClick={handleViewDetails}>View Details</MenuItem> */}
        <MenuItem onClick={handleProcessLoan}>Process Loan</MenuItem>
      </Menu>
    </>
  );
}

export default TableMenu;

const locale = {
    THERE_ARE_NO_NOTIFICATION_FOR_NOW: "Tiada pemberitahuan buat masa ini.",
    SHOW_MORE: "Tunjukkan Lebih Banyak",
    MARK_ALL_AS_READ: "Tandakan Semua Sebagai Telah Dibaca",
    NOTIFICATION: "Pemberitahuan",
    DELETE_ALL: "Padam Semua",
    LISTS_ALL_NOTIFICATIONS: "Senarai Semua Pemberitahuan",
    NOTIFICATIONS: "Pemberitahuan",
    ARE_YOU_SURE_TO_DELETE:"Adakah anda pasti untuk memadamkan?",
    ARE_YOU_SURE_TO_DELETE_ALL_NOTIFICATIONS:"Adakah anda pasti akan memadamkan semua pemberitahuan?",
    CANCEL:"Batalkan",
    OK:"Okey",
    NOTIFICATION_WITH_WHITE_BACKGROUND_AND_BADGES_INDICATE_UNREAD_NOTIFICATION:"Pemberitahuan dengan latar belakang Lavender dan lencana menunjukkan Pemberitahuan yang belum dibaca",
};

export default locale;

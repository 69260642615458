const locale = {
  FORM_TITLE: "Financing Application Form",
  PERSONAL_INFO: "Personal Information",
  ADDRESS_INFO: "Address Information",
  SIBLINGS_DETAILS: "Details of Spouse",
  REFERRAL_DETAILS: "Family Reference (Not living together)",
  FAMILY_REFERRAL: "Family Reference",
  SPOUSE: "Spouse",
  NEXT_OF_KIN_DETAILS: "Details of Next of kin",
  EMPLOYMENT_DETAILS: "Employment Details",
  INCOME_DETAILS: "Income details",
  EXISTING_LOAN: "Settlement Of Existing Loans (if Any)",
  BANK_DETAILS: "Bank Details",
  PRODUCT_DETAILS: "Product Details",
  UPLOAD_DOCUMENTS: "Upload Documents",
  NEXT: "Next",
  PREVIOUS: "Previous",
  SUBMIT: "Submit",
  TITLE: "Title",
  FULL_NAME: "Full Name",
  SEX: "Sex",
  DOB: "Date Of Birth",
  AGE: "Age",
  MOTHER_FULL_NAME: "Mother Name",
  JOB_TYPE: "Job Type",
  JOB_UNIQUE_CODE: "Position",
  MEMBER_LIST: "Select Member",
  MEMBERSHIP_NUMBER: "Membership Number",
  OTHER_ID: "Other ID No",
  NATIONAL_ID: "New IC No",
  NATIONAL_ID_OR_PASSPORT: "New IC No / Passport",
  RELIGION: "Religion",
  CHILD: "Child",
  RACE: "Race",
  STATUS: "Status",
  DEPENDENCY: "Dependency",
  CONTACT_DETAILS: "Contact Details",
  HOME_PHONE: "Home Phone Number",
  MOBILE_PHONE: "Mobile Number",
  PERSONAL_EMAIL: "Personal Email",
  WORK_EMAIL: "Work Email",
  QUALIFICATION: "Qualification",
  HIGHEST_QUALIFICATION: "Highest Qualification",
  HOUSE_ADDRESS: "Residential Address",
  POST_CODE: "Postcode",
  CITY: "City",
  STATE: "State",
  SAME_ADDRESS: "Is Mailing address same as Residential Address?",
  SAME_AS_MY_ADDRESS: "Is your address the same as your house address?",
  MAILING_ADDRESS: "Mailing Address",
  RESIDENTIAL_LEVEL: "Residential Ownership Type",
  OWN: "Own",
  RENT: "Rented",
  PARENTS: "Parents",
  FATHER_OWN: "Father's Own",
  RELATIONSHIP: "Relationship",
  BROTHER: "Brother",
  OTHER: "Other",
  HUSBAND: "Husband",
  WIFE: "Wife",
  MALE: "Male",
  FEMALE: "Female",
  MR: "Mr.",
  MRS: "Mrs.",
  MISS: "Miss.",
  SAVING: "Saving",
  CURRENT: "Current",
  ARMY: "Army",
  POLICE: "Police",
  DO_YOU_WANT_TO_ADD_SPOUSE_OR_SIBLINGS_DETAILS:
    "Are you married? Add only their spouse details here",
  DO_YOU_WANT_TO_ADD_REFERRAL_DETAILS: "Do you want to add referral details?",
  DO_YOU_WANT_TO_ADD_NEXT_OF_KIN_DETAILS:
    "Do you want to add Next of kin details?",
  DETAILS_OF_SPOUSE: "Details of Spouse",
  EMPLOYER_NAME: "Employer Name",
  JOB: "Job",
  GRADE: "Grade",
  OFFICE_PHONE: "Office Phone",
  DEPARTMENT: "Department",
  RETIREMENT_AGE: "Retirement Option",
  EMPLOYMENT_DATE: "Employment Date:",
  START_DATE: "Start Date Of Work",
  END_DATE: "End Date of Work",
  SERVICE_PERIOD: "Period Of Service",
  JOB_STATUS: "Job Status",
  EXPERIMENT: "Experiment",
  TEMPORARY: "Temporary",
  CONTRACT: "Contract",
  PERMANENT: "Permanent",
  OFFICE_ADDRESS: "Office Address",
  OTHER_DETAIL: "Other Detail",
  SALARY_DETAIL: "Salary Detail",
  BASIC_SALARY: "Basic Salary",
  SALARY_INCREMENT: "Salary Increment",
  FIXED_ALLOWANCES: "Fixed  Allowances",
  INCREMENT_DATE: "Date Of Increment",
  TOTAL_DEDUCTION: "Total Deduction",
  NET_SALARY: "Net Salary",
  REMARK: "Remark (If Any)",
  FINANCE_NAME: "Name Of Financial Institution",
  LOAN_BALANCE: "Balance of Finance",
  MONTHLY_COMMITMENTS: "Monthly Repayment",
  LOAN_TENURE: "Balance Financing Tenure(Months)",
  SETTLEMENT_LETTER: "Do you have a Settlement Letter?",
  VALIDITY_PERIOD: "Validity Period",
  FINANCE_FILE: "Upload file",
  ADD_ROW: "Add Row",
  BANK_NAME: "Name Of Bank",
  BANK_BRANCH: "Bank's Branch",
  ACCOUNT_NUMBER: "Account Number",
  ACCOUNT_TYPE: "Type Of Account",
  CATEGORY: "Category",
  SELECT_PRODUCT: "Select Product",
  PRODUCT_VARIANT: "Select Product Variant",
  APPLICATION_NUMBER: "Application Number",
  LOAN_RATE: "Loan Rate Type",
  FINANCE_AMOUNT: "Amount Of Finance",
  APPLICATION_TENURE: "Tenure (Months)*",
  DOCUMENT: "Document",
  SELECT_FILE: "Select File",
  PASSWORD: "Password If Any",
  UPLOAD: "Upload",
  UPLOADED: "Uploaded",
  PREVIEW: "Preview",
  REQUIRED_DOCUMENT: "Please upload required documents",
  PREVIEW_ALERT: "Please First Upload Document",
  UPDATE: "Update",
  SAVE: "Save",
  CONFIRM_SUBMIT_APPLICATION_TITLE: "Confirm Submission",
  CONFIRM_SUBMIT_APPLICATION_MESSAGE:
    "Are you sure you want to submit your application?",
  SERIAL_NO: "No.",
  FORM: "Form",
  NOTE_ENTER_NAME_AS_PER_IC: "Note: Enter name as per your IC",

  // ERROR MESSAGE OF APPLICATION FORM
  TITLE_VALIDATION: "Please Select Title",
  FULL_NAME_VALIDATION: "Please Enter Full Name",
  SEX_VALIDATION: "Please Select Sex",
  DATE_FUTURE: "Date cannot be in the future",
  DATE_PAST_VALIDATION: "Date cannot be earlier than 1900-01-01",
  DATE_VALIDATION: "Date must be valid",
  AGE_VALIDATION: "Age is required",
  AGE_TYPE_VALIDATION: "Age must be a number",
  AGE_POSITIVE_VALIDATION: "Age must be positive",
  AGE_INTEGER_VALIDATION: "Age must be an integer",
  NATIONAL_ID_VALIDATION: "New IC No. is required",
  RELIGION_VALIDATION: "Religion is required",
  CHILD_VALIDATION: "Child count is required",
  MOTHER_FULL_NAME_VALIDATION: "Mother Name  is required",
  MEMBERSHIP_VALIDATION: "Membership Number  is required if any",
  MEMBER_VALIDATION: "Select Member for apply to finance",
  JOB_TYPE_VALIDATION: "Job Type is required",
  JOB_UNIQUE_CODE_VALIDATION: "Job  Position",
  RACE_VALIDATION: "Race is required",
  MARITAL_STATUS_VALIDATION: "Marital status is required",
  DEPENDENCY_VALIDATION: "Dependency is required",
  MOBILE_PHONE_REQUIRED: "Mobile phone is required",
  MOBILE_PHONE_DIGIT_VALIDATION: "Phone number must contain only digits",
  HOME_PHONE_DIGIT_VALIDATION: "Phone number must contain only digits",
  PERSONAL_EMAIL_REQUIRED: "Email is required",
  EMAIL_FORMAT_VALIDATION: "Invalid email format",
  WORK_EMAIL_FORMAT_VALIDATION: "Invalid work email format",
  HIGHEST_QUALIFICATION_VALIDATION: "Highest qualification is required",
  RELATIONSHIP_VALIDATION: "Relationship is required",

  HOUSE_ADDRESS_VALIDATION: "Please enter Residential Address",
  POSTCODE_VALIDATION: "Postcode is required",
  POSTCODE_INVALID: "Postcode Must be 6 DIgit",
  CITY_VALIDATION: "City name is required",
  STATE_VALIDATION: "State is required",
  MAILING_ADDRESS_VALIDATION: "Mailing address is required",
  MAILING_POSTCODE_VALIDATION: "Mailing postcode is required",
  MAILING_CITY_VALIDATION: "Mailing city name is required",
  MAILING_STATE_VALIDATION: "Mailing state is required",
  RESIDENTIAL_LEVEL_VALIDATION: "Please select Residential Ownership Type",
  OTHER_VALUE_VALIDATION: "Please Enter Residential Ownership Type",

  EMPLOYER_NAME_VALIDATION: "Please enter Employer Name",
  JOB_VALIDATION: "Add enter job",
  PHONE_NUMBER_VALIDATION: "Enter Phone Number",
  PHONE_NUMBER_INVALID: "Please provide a valid phone number",
  DEPARTMENT_VALIDATION: "Department is required",
  RETIREMENT_AGE_VALIDATION: "Retirement Age is required",
  RETIREMENT_AGE_INVALID: "Only numbers are allowed",
  START_WORK_DATE_VALIDATION: "Start Work Date is Required",
  END_WORK_DATE_VALIDATION: "End Work Date is Required",
  END_DATE_NOT_SMALL_THAN_START_DATE:
    "End date must not be before the start date",
  DATE_INVALID: "Please Enter Valid the date",
  PERIOD_OF_SERVICE_VALIDATION: "Period of Service is required",
  PERIOD_OF_SERVICE_INVALID: "Invalid period of service",
  OPTION_VALIDATION: "Please select an Job Status",
  OFFICE_ADDRESS_VALIDATION: "Please enter Office address",
  START_DATE_REQUIRED: "Please first select start",

  BASIC_SALARY_VALIDATION: "Enter Your Basic Salary",
  BASIC_SALARY_INVALID: "Input must be a number",
  SALARY_INCREMENT_VALIDATION: "Enter Your Salary Increment Amount",
  SALARY_INCREMENT_INVALID: "Input must be a number",
  FIXED_ALLOWANCE_VALIDATION: "Enter Your Fixed Allowance",
  FIXED_ALLOWANCE_INVALID: "Input must be a number",
  DATE_OF_INCREMENT_VALIDATION: "Please select a date",
  DATE_OF_INCREMENT_INVALID: "Invalid date format",
  TOTAL_DEDUCTION_VALIDATION: "Total Deductions are required",
  TOTAL_DEDUCTION_INVALID: "Input Must Be A Number",
  NET_SALARY_VALIDATION: "Net salary field cannot be empty",
  NET_SALARY_INVALID: "Input must be a number",

  NAME_OF_BANK_VALIDATION: "Name Of Bank is required",
  BANKS_BRANCH_VALIDATION: "Banks Branch is required",
  ACCOUNT_NUMBER_VALIDATION: "Account Number is required",
  ACCOUNT_NUMBER_POSITIVE: "Account Number must be positive",
  ACCOUNT_NUMBER_INVALID: "Please enter a valid account number.",
  TYPE_OF_ACCOUNT_VALIDATION: "Please Select Type Of Account",

  CATEGORY_VALIDATION: "Please Select Category",
  PRODUCT_VALIDATION: "Please Select Product",
  SELECT_VARIANT: "Please Select Product  Variant",
  APPLICATION_NUMBER_VALIDATION: "Enter Your application  number",
  FINANCE_AMOUNT_VALIDATION: "Finance Amount is required",
  FINANCE_AMOUNT_POSITIVE: "Finance Amount must be positive",
  FINANCE_AMOUNT_INVALID: "Please enter a valid Finance Amount.",
  TENURE_OF_APPLICATION_VALIDATION: "Enter the Tenure of Application",
  NUMBER_VALIDATION: "Please enter a valid Application Numbers",
  LOAN_RATE_TYPE_REQUIRED: "Select Loan rate type",
  INTEREST_RATE_POSITIVE: "Profit Rate should be greater than zero",
  TENURE_OF_APPLICATION_POSITIVE: "Tenure of application must be positive",
  TENURE_OF_APPLICATION_INVALID: "Please enter a valid tenure of application",
  INTEREST_RATE_INVALID: "Please enter a valid Profit Rate",

  FINANCE_NAME_VALIDATION: "Finance Name is Required",
  LOAN_AMOUNT_VALIDATION: "Loan Amount Is Required",
  MONTHLY_COMMIT_VALIDATION: "Monthly Deduction Required",
  LOAN_TENURE_VALIDATION: "Finance Tenure Required",
  SETTLEMENT_VALIDATION:
    "If you have a settlement letter then select yes otherwise select no",
  ALL_NUMBER_VALIDATION: "Please enter only Numbers",
  VALIDITY_PERIOD_REQUIRED:
    "Validity period required when you have settlement letter",

  DELETE_EXISTING_FINANCE_TITLE: "Confirm",
  DELETE_EXISTING_FINANCE_DESCRIPTION: "Are you sure you want to delete?",
  DELETE: "Delete",

  PRIMARY: "Primary",
  SECONDARY: "Secondary",
  DIPLOMA: "Diploma",
  DEGREE: "Degree",
  MASTERS: "Masters",
  DOCTORATE_DEGREE: "Doctorate Degree",
  PROFESSOR: "Professor",
  SUPPORT_FILE: "Supported files",

  REMOVE: "Remove",
  LOAN_TYPE: "Loan type",
  UNSECURED_LOAN: "Unsecured Financing",
  FINANCE_PRODUCT: "Finance Product",
  HAS_CONSUMER_PRODUCT_ALLOW: "Do you want to add consumer product?",
  FINANCE_PRODUCT_REQUIRED: "Select Finance Product",
  FINANCE_AMOUNT_CANNOT_BE_LESS_THAN_MIN:
    "Finance amount should not be less than {{min}}",
  FINANCE_AMOUNT_CANNOT_BE_GREATER_THAN_MAX:
    "Finance amount cannot exceed {{max}}",
  MARITAL_STATUS: "Marital Status",
  MARITAL_STATUS_IS_REQUIRED: "Marital status is required",

  // standart validation messages
  TEXT_FIELD_REQUIRED_VALIDATION: "{{fieldName}} is required",
  SELECT_FIELD_REQUIRED: "Please select a valid option",
  MINIMUM_VALIDATION: "{{fieldName}} must be greater than or equal to  {{min}}",
  MAXIMUM_VALIDATION: "{{fieldName}} must be less than or equal to  {{max}}",
  VALID_DATE_VALIDATION: "Please enter a valid date",
  NUMBER_INVALID_VALIDATION: "Please enter a valid number",

  MAILING_CITY: "Mailing City",
  MAILING_POST_CODE: "Mailing Postal Code",
  MAILING_STATE: "Mailing State",
  ACTIVE_FINANCE_TITLE: "Active Finance Application",
  ACTIVE_FINANCE_BUTTON: "Go To Detail Page",

  // MODIFY APPLICATION INFO
  MODIFY_APPLICATION_TITLE: "Modify Application ",
  MODIFY_APPLICATION_DIS:
    "Click 'Detail Page' to make any changes to your application.",
  MODIFY_APPLICATION_BUTTON: "Detail Page",

  AGENT_CODE: "Agency/Manager Code",
  REFERRAL_CODE_LENGTH: "Agency code must be exactly 6 digits",
  NUMBER_VALIDATION_FOR_AGENT_CODE: "Only numbers are accepted",
  ADD_MEMBER: "Add Member",
  OTHER_DOCUMENT: "Other"
};

export default locale;

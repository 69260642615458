import React from "react";
import { InputAdornment } from "@mui/material";
import { StyledTypography } from "../Styles/CustomStyle";

const commonPhoneNumberInputProps = {
  startAdornment: (
    <InputAdornment position="start">
      <StyledTypography className="!font-normal mt-2"> +60</StyledTypography>
    </InputAdornment>
  ),
};

export default commonPhoneNumberInputProps;

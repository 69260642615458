const locale = {
  DOCUMENTS: "Documents",
  EDIT: "Edit",
  INFORMATION: "Information",
  TAB_MEMBER: "Member",
  DOCUMENTS_REMARKS: "Documents Remark",
  FINISH: "Finish",
  PENDING: "Pending",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  PASSWORD: "Password",
  UPLOAD_BY: "Uploaded By",
  CREATED_AT: "Created At",
  LAST_MODIFIED: "Last Modified",
  APPROVED_BY: "Approved By",
  DOWNLOAD: "Download",
  DELETE: "Delete",
  DELETE_DOCUMENT: "Delete Document",
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_DOCUMENT:
    "Are you sure you want to delete this document?",
  NO_DOCUMENTS_FOUND: "No Document Found",
  TAB_FINANCE_DOCS: "Finance Documents"
};

export default locale;

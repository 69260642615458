import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
  getExpandedRowModel,
  PaginationState,
} from "@tanstack/react-table";
import {
  TableCell,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  CircularProgress,
  TableFooter,
  TablePagination,
  createTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TablePaginationActions from "./TablePaginationActions";
import themesConfig from "app/configs/themesConfig";
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';

// interface in typescript is a way to define the structure of an object
interface Column {
  id: string;
  header: string;
  accessor: string;
  // Add more properties as needed
}

interface Row {
  id: string;
  // Add more properties as needed
}

interface ReactTableCustomProps {
  columns: Column[];
  data: Row[];
  isLoading: boolean;
  pageCount: number;
  customStyle?: React.CSSProperties;
  paginationState?: {
    pageIndex: number;
    pageSize: number,
    sortField: string | null;
    sortOrder: string | null;
  };
  fetchData: (params: {
    pageIndex: number; pageSize: number,
    sortField: string | null;
    sortOrder: string | null;
  }) => void;
  renderRowSubComponent?: (row: Row) => React.ReactNode;
}

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  ".table-pagination .MuiSelect-select": {
    paddingTop: "12px",
  },
  ".progress-overlay": {
    minHeight: "50px",
    verticalAlign: "middle",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const StyledRow = styled(TableRow)(({ theme }) => ({
  borderBottom: "2px solid " + theme.palette.primary.dark,
}));

const StyledHeaderRow = styled(TableRow)(({ theme }) => ({
  borderBottom: "2px solid " + theme.palette.secondary.main,
}));

const ReactTableCustom = forwardRef(({
  columns = [],
  data = [],
  isLoading,
  pageCount: controlledPageCount = 0,
  customStyle,
  fetchData,
  paginationState,
  renderRowSubComponent,
}: ReactTableCustomProps, ref): JSX.Element => {
  const theme = createTheme();

  const initialSorting = paginationState?.sortField
    ? [{ id: paginationState.sortField, desc: paginationState.sortOrder === "desc" }]
    : [];

  const [sorting, setSorting] = useState<any[]>(initialSorting);
  const [expanded, setExpanded] = useState<any>({});
  const [pagination, setPagination] = React.useState<PaginationState>(paginationState || {
    pageIndex: 0,
    pageSize: 10,
  });

  const tableInstance = useReactTable({
    columns,
    data,
    state: {
      sorting,
      expanded,
      pagination,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true, //we're doing manual "server-side" pagination
    rowCount: controlledPageCount,
    // getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  React.useEffect(() => {
    loadData();
  }, [fetchData, pagination, sorting]);

  const loadData = () => {
    let sortField: string | null = null;
    let sortOrder: string | null = null;

    if (sorting.length) {
      sortField = sorting[0].id;
      sortOrder = sorting[0].desc ? 'desc' : 'asc';
    }
    fetchData({
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sortField,
      sortOrder
    });
  }

  //Reload data
  useImperativeHandle(ref, () => ({
    reloadData() {
      loadData();
    }
  }));

  return (
    <StyledTableContainer>
      <Table className={`react-table`}>
        <TableHead>
          {tableInstance.getHeaderGroups().map((headerGroup) => (
            <StyledHeaderRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableCell
                  key={header.id}
                  colSpan={header.colSpan}
                  className="headerCell"
                >
                  {header.isPlaceholder ? null : (
                    <div
                      role="button"
                      tabIndex={0}
                      className={header.column.getCanSort() ? 'cursor-pointer' : ''}
                      onClick={header.column.getToggleSortingHandler()}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          e.preventDefault();
                          header.column.getToggleSortingHandler()(e);
                        }
                      }}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      <span
                        style={{
                          color: themesConfig.default.palette.secondary.main,
                        }}
                      >
                        {header.column.getIsSorted() !== false &&
                          ({
                            asc: <NorthIcon className="text-16"/>,
                            desc: <SouthIcon className="text-16"/>
                          }[
                            header.column.getIsSorted() as keyof {
                              asc: string;
                              desc: string;
                            }
                          ] ??
                            null)}
                      </span>
                    </div>
                  )}
                </TableCell>
              ))}
            </StyledHeaderRow>
          ))}
        </TableHead>
        <TableBody>
          {!isLoading && data?.length === 0 && (
            <StyledRow>
              <TableCell colSpan={columns.length}>No data found.</TableCell>
            </StyledRow>
          )}
          {!isLoading &&
            tableInstance.getRowModel().rows.map((row) => {
              return (
                <React.Fragment key={row.id}>
                  <StyledRow
                    key={row.id}
                    {...{
                      ...(renderRowSubComponent
                        ? {
                          onClick: () => row.toggleExpanded(),
                          style: { cursor: "pointer" },
                        }
                        : {}),
                      ...(row.getIsExpanded() && renderRowSubComponent
                        ? { className: "selected" }
                        : {}),
                    }}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <TableCell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      );
                    })}
                  </StyledRow>
                  {row.getIsExpanded() && renderRowSubComponent ? (
                    <StyledRow className="expanded-row">
                      <TableCell colSpan={row.getVisibleCells().length}>
                        {renderRowSubComponent(row)}
                      </TableCell>
                    </StyledRow>
                  ) : null}
                </React.Fragment>
              );
            })}
          {isLoading && (
            <StyledRow>
              <TableCell colSpan={columns.length} style={{ padding: 0 }}>
                <div className="progress-overlay">
                  <CircularProgress
                    size={24}
                    color="secondary"
                    className="button-progress"
                  />
                </div>
              </TableCell>
            </StyledRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              className="table-pagination"
              count={controlledPageCount}
              rowsPerPage={tableInstance.getState().pagination.pageSize}
              page={tableInstance.getState().pagination.pageIndex}
              onPageChange={(e, pageNumber) => {
                tableInstance.setPageIndex(pageNumber);
              }}
              onRowsPerPageChange={(e) => {
                tableInstance.setPageSize(Number(e.target.value));
              }}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </StyledTableContainer>
  );
})

export default ReactTableCustom;

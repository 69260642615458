const locale = {
  HOME: "Laman Utama",
  PRODUCT: "Produk",
  SERVICES: "Perkhidmatan",
  CALCULATOR: "Kalkulator",
  ABOUTUS: "Tentang Kami",
  CONTACTUS: "Hubungi Kami",
  TERMS_CONDITION: "Terma dan Syarat",
  PRIVACY_POLICY: "Dasar Privasi",
  COPYRIGHT_FOOTER:
    "Copyright © 2024 FKC.COOP Berhad Registration No. (201971601059) | All Rights Reserved",
  NEWSLETTER_FOOTER: "SUBSCRIBE NEWSLETTER",
  DONT_MISS_UPDATE_ON_EMAIL_FOOTER: "Dont miss new updates on your email",
  EMAIL_LABEL_FOOTER: "Enter email address",
  SEND_EMAIL_FOOTER: "Send",
  MOBILE_LOCAL_FOOTER: "(Local & Overseas)",
  MOBILE_HUNTING_FOOTER: "(Hunting Line)",
  FAQS: "Soalan Lazim"
};

export default locale;

import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  StyledButton,
  StyledTypography,
} from "app/shared-components/Styles/CustomStyle";
import { FormProvider, useForm, Controller } from "react-hook-form";
import {
  FormGroup,
  Grid,
  Box,
  Button,
  Typography,
  FormControlLabel,
} from "@mui/material";
import CustomAutocomplete from "app/shared-components/Form/CustomAutoComplete";
import CustomCheckbox from "app/shared-components/Form/CustomCheckbox";
import CustomTextField from "app/shared-components/Form/CustomTextField";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  UserAddressInForm,
  getCurrentStep,
  getUserAddressForFinanceForm,
  getUserAddressDataInFinanceForm,
  getUserFinanceId,
  setCurrentStep,
  updateUserAddressInForm,
  selectIsButtonLoading,
} from "app/store/application-form/applicationFormSlice";
import { residentialLevel, tenantId } from "src/app/common/constant";
import ColoredSubmitButton from "app/shared-components/Button/ColoredSubmitButton";

export default function AddressInformation({
  financeApplicationId = null,
  isEdit = false,
  setIsDirty = (value) => {},
}) {
  const { t } = useTranslation("applicationForm");
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const activeStep = useSelector(getCurrentStep);
  const [search] = useSearchParams();
  const financeId = search.get("id") || null;
  const userId = search.get("userId") || null;
  const address = useSelector(getUserAddressDataInFinanceForm);
  const isLoading = useSelector(selectIsButtonLoading);

  const schema = yup.object().shape({
    houseAddress: yup.string().required(t("TEXT_FIELD_REQUIRED_VALIDATION", {fieldName: t("HOUSE_ADDRESS")})),
    housePostalCode: yup.string().required(t("TEXT_FIELD_REQUIRED_VALIDATION", {fieldName: t("POST_CODE")})),
    houseCity: yup.string().required(t("TEXT_FIELD_REQUIRED_VALIDATION", {fieldName: t("CITY")})),
    houseState: yup.string().required(t("TEXT_FIELD_REQUIRED_VALIDATION", {fieldName: t("STATE")})),
    same: yup.boolean(),
    mailingAddress: yup.string().required(t("TEXT_FIELD_REQUIRED_VALIDATION", {fieldName: t("MAILING_ADDRESS")})),
    mailingPostalCode: yup.string().required(t("TEXT_FIELD_REQUIRED_VALIDATION", {fieldName: t("MAILING_POST_CODE")})),
    mailingCity: yup.string().required(t("TEXT_FIELD_REQUIRED_VALIDATION", {fieldName: t("MAILING_CITY")})),
    mailingState: yup.string().required(t("TEXT_FIELD_REQUIRED_VALIDATION", {fieldName: t("MAILING_STATE")})),
    houseStatus: yup.string().required(t("TEXT_FIELD_REQUIRED_VALIDATION", {fieldName: t("RESIDENTIAL_LEVEL")})),
    otherResidentialLevel: yup
      .string()
      .when(["houseStatus"], (houseStatus, schema) => {
        if (houseStatus[0] === "Other") {
          return schema.required(t("TEXT_FIELD_REQUIRED_VALIDATION", {fieldName: t("OTHER")}));
        }
        return schema.notRequired();
      }),
  });

  
  const methods = useForm();
  const {
    handleSubmit,
    formState,
    control,
    watch,
    reset,
    setValue,
    getValues,
    trigger,
    resetField,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const { errors, isValid, isDirty } = formState;

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty, setIsDirty]);

  function onSubmit(model, event) {
    event.preventDefault();
    const { houseStatus, ...rest } = model;
    let houseStatusValue = houseStatus;

    if (houseStatus === "Other") {
      houseStatusValue = rest.otherResidentialLevel;
    }

    const request_data = {
      ...rest,
      financeApplicationId: financeId || financeApplicationId,
      tenantId: tenantId,
      houseStatus: houseStatusValue,
    };
    const Update_data = {
      ...rest,
      id: address?.id,
      houseStatus: houseStatusValue,
    };

    if (Object.keys(address).length !== 0 && address?.isDefault !== true) {
      dispatch(
        updateUserAddressInForm(
          Update_data,
          financeId || financeApplicationId,
          isEdit
        )
      );
    } else {
      dispatch(UserAddressInForm(request_data, isEdit));
    }
  }

  useEffect(() => {
    const idToUse = financeId || financeApplicationId;
    if (idToUse) {
      dispatch(getUserAddressForFinanceForm(idToUse, userId));
    }
  }, [dispatch, financeId, financeApplicationId]);

  useEffect(() => {
    if (address) {
      setValue("houseAddress", address?.houseAddress || "");
      setValue("housePostalCode", address?.housePostalCode || "");
      setValue("houseCity", address?.houseCity || "");
      setValue("houseState", address?.houseState || "");
      setValue("mailingAddress", address?.mailingAddress || "");
      setValue("mailingPostalCode", address?.mailingPostalCode || "");
      setValue("mailingCity", address?.mailingCity || "");
      setValue("mailingState", address?.mailingState || "");

      const houseStatusValue = address?.houseStatus || "";
      if (!residentialLevel.map((n) => n.id).includes(houseStatusValue)) {
        setValue("houseStatus", "Other");
        setValue("otherResidentialLevel", houseStatusValue || "");
      } else {
        setValue("houseStatus", houseStatusValue);
        setValue("otherResidentialLevel", "");
      }
      reset(getValues());
    }
  }, [setValue, address]);

  function handleSameChange(sameAddress) {
    const houseAddress = getValues("houseAddress");
    const housePostalCode = getValues("housePostalCode");
    const houseCity = getValues("houseCity");
    const houseState = getValues("houseState");

    if (sameAddress) {
      setValue("mailingAddress", houseAddress, { shouldValidate: true });
      setValue("mailingPostalCode", housePostalCode, { shouldValidate: true });
      setValue("mailingCity", houseCity, { shouldValidate: true });
      setValue("mailingState", houseState, { shouldValidate: true });
    } else {
      resetField("mailingAddress");
      resetField("mailingPostalCode");
      resetField("mailingCity");
      resetField("mailingState");
    }
    clearErrors([
      "mailingAddress",
      "mailingPostalCode",
      "mailingCity",
      "mailingState",
    ]);
  }

  const handleBack = () => {
    dispatch(setCurrentStep(activeStep - 1));
    navigate(
      `/application-form/${activeStep - 1}?userId=${userId}&id=${financeId}`
    );
  };
  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-10"
          data-test-id="add-application-address-form"
        >
          <StyledTypography variant="body1" className="mt-20">
            {t("HOUSE_ADDRESS")}
          </StyledTypography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} className="mb-8">
                  <Controller
                    name="houseAddress"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("HOUSE_ADDRESS")}
                        fullWidth
                        required
                        onChange={field.onChange}
                        value={field.value || ""}
                        error={
                          errors.houseAddress && t(errors.houseAddress.message)
                        }
                        inputProps={{
                          "data-test-id": "address-form-house-address",
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} className="mb-8">
                  <Controller
                    name="houseCity"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("CITY")}
                        onChange={field.onChange}
                        value={field.value || ""}
                        fullWidth
                        required
                        error={errors.houseCity && t(errors.houseCity.message)}
                        inputProps={{
                          "data-test-id": "address-form-house-city",
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4} className="mb-8">
                  <Controller
                    name="houseState"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("STATE")}
                        value={field.value || ""}
                        onChange={field.onChange}
                        required
                        fullWidth
                        error={
                          errors.houseState && t(errors.houseState.message)
                        }
                        inputProps={{
                          "data-test-id": "address-form-house-state",
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4} className="mb-8">
                  <Controller
                    name="housePostalCode"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("POST_CODE")}
                        onChange={field.onChange}
                        value={field.value || ""}
                        fullWidth
                        required
                        error={
                          errors.housePostalCode &&
                          t(errors.housePostalCode.message)
                        }
                        inputProps={{
                          "data-test-id": "address-form-house-postal-code",
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="mt-6">
            <Grid item xs={12}>
              <FormGroup>
                <CustomCheckbox
                  label={t("SAME_ADDRESS")}
                  onChange={(e) => {
                    handleSameChange(e);
                  }}
                  data-test-id="address-form-same-address-checkbox"
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <StyledTypography variant="body1">
              {t("MAILING_ADDRESS")}
            </StyledTypography>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} className="mb-8">
                  <Controller
                    name="mailingAddress"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <CustomTextField
                        label={t("MAILING_ADDRESS")}
                        fullWidth
                        required
                        value={field.value}
                        onChange={field.onChange}
                        error={
                          errors.mailingAddress &&
                          t(errors.mailingAddress.message)
                        }
                        inputProps={{
                          "data-test-id": "address-form-mailing-address",
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} className="mb-8">
                  <Controller
                    name="mailingCity"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <CustomTextField
                        label={t("CITY")}
                        value={field.value || ""}
                        onChange={field.onChange}
                        fullWidth
                        required
                        error={
                          errors.mailingCity && t(errors.mailingCity.message)
                        }
                        inputProps={{
                          "data-test-id": "address-form-mailing-city",
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4} className="mb-8">
                  <Controller
                    name="mailingState"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <CustomTextField
                        label={t("STATE")}
                        value={field.value || ""}
                        onChange={field.onChange}
                        required
                        fullWidth
                        error={
                          errors.mailingState && t(errors.mailingState.message)
                        }
                        inputProps={{
                          "data-test-id": "address-form-mailing-state",
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4} className="mb-8">
                  <Controller
                    name="mailingPostalCode"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <CustomTextField
                        label={t("POST_CODE")}
                        required
                        value={field.value}
                        onChange={field.onChange}
                        fullWidth
                        error={
                          errors.mailingPostalCode &&
                          t(errors.mailingPostalCode.message)
                        }
                        inputProps={{
                          "data-test-id": "address-form-mailing-postal-code",
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} className="mb-8">
                  <Grid container spacing={2} className="flex items-center">
                    <Grid item xs={12} sm={8}>
                      <FormGroup className="flex flex-row justify-start items-center">
                        <StyledTypography variant="body1" className="mr-40">
                          {t("RESIDENTIAL_LEVEL")}*
                        </StyledTypography>
                        <Controller
                          name="houseStatus"
                          control={control}
                          defaultValue={address?.houseStatus || ""}
                          render={({ field }) => (
                            <>
                              {residentialLevel.map((item) => (
                                <CustomCheckbox
                                  key={item.id}
                                  label={t(item.label)}
                                  onChange={() => field.onChange(item.id)}
                                  checked={field.value === item.id}
                                  data-test-id="address-form-resident-level-checkbox"
                                />
                              ))}
                            </>
                          )}
                        />
                      </FormGroup>
                      {errors.houseStatus && (
                        <Typography
                          variant="subtitle2"
                          fontSize={"1.2rem"}
                          color="error"
                        >
                          {t(errors.houseStatus.message)}
                        </Typography>
                      )}
                    </Grid>
                    {watch("houseStatus") === "Other" && (
                      <Grid item xs={12} sm={4} className="mb-8">
                        <Controller
                          name="otherResidentialLevel"
                          control={control}
                          render={({ field }) => (
                            <CustomTextField
                              label={t("OTHER")}
                              fullWidth
                              required
                              value={field.value}
                              onChange={field.onChange}
                              error={
                                errors.otherResidentialLevel &&
                                t(errors.otherResidentialLevel.message)
                              }
                              inputProps={{
                                "data-test-id": "address-form-other-residential-level",
                              }}
                            />
                          )}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box>
            <Box className="flex justify-end items-end gap-20 mt-10">
              {isEdit ? (
                <ColoredSubmitButton
                  className="w-120"
                  disabled={!isValid || !isDirty}
                  text={t("SAVE")}
                  isLoading={isLoading}
                  data-test-id="address-form-submit-btn"
                ></ColoredSubmitButton>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    className="capitalize w-120"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {t("PREVIOUS")}
                  </Button>
                  <ColoredSubmitButton
                    className="w-120"
                    disabled={!isValid}
                    text={t("NEXT")}
                    isLoading={isLoading}
                    data-test-id="address-form-submit-btn"
                  ></ColoredSubmitButton>
                </>
              )}
            </Box>
          </Box>
        </form>
      </FormProvider>
    </>
  );
}

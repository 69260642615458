import i18next from 'i18next';
import { lazy } from 'react';
import en from './i18n/en';
import ms from './i18n/ms';
import { authRoles } from 'src/app/auth';
import ProductDetailPage from './ProductDetailPage';
import ProductPage from './ProductPage';

i18next.addResourceBundle('en', 'productPage', en);
i18next.addResourceBundle('ms', 'productPage', ms);

const Product = lazy(() => import('./Product'));

/**
 * The Product page config.
 */
const ProductConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.public,
	routes: [
		{
			path: 'product',
			element: <ProductPage />,
			children: [
				{
					path: "",
					element: <Product />
				},
				{
					path: ":id",
					element: <ProductDetailPage />
				}
			]
		},
	]
};

export default ProductConfig;

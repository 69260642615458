import { Typography,Button,Accordion, Stepper, StepConnector, stepConnectorClasses, TableCell, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import themesConfig from "app/configs/themesConfig";

export const StyledTypography = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",
    color: theme.palette.common.black ,
}));

export const StyledTypographyGrey = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",
    color: theme.palette.primary.dark ,
}));

export const StyledButton = styled(Button)`
    && {
        background-color: ${({ theme }) => theme.palette.secondary.main};
        color: white;
        text-transform: capitalize;

        &:hover {
            background-color: ${({ theme }) => theme.palette.secondary.main};
        }
    }
`;

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
    color: theme.palette.common.black,
    boxShadow: "none",
    backgroundColor: "transparent",
    borderBottom: `2px solid ${theme.palette.primary.dark}`,
  }));

  export const StyledStepper = styled(Stepper)(({ theme }) => ({
    "& .MuiStepContent-root": {
      color: theme.palette.secondary.main,
      borderLeft: "3px solid",
    },
    "& .MuiStepIcon-root.Mui-active, & .MuiStepIcon-root.Mui-completed": {
      color: theme.palette.secondary.main,
    },
    "& .MuiStepIcon-root": {
      color: theme.palette.primary.contrastText,
    },
    "& .MuiStepIcon-text": {
      fill: theme.palette.common.white,
    }
  }));
  
  export const StyledStepConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.active}, &.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.secondary.main,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.contrastText,
      borderTopWidth: 0,
      borderLeftWidth: 3,
      marginTop: 0,
      height: "100%",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.secondary.main,
      },
    },
  }));

  export const CustomTableHeaderCell = styled(TableCell)({
    backgroundColor: themesConfig.default.palette.secondary.main,
    color: themesConfig.default.palette.common.white,
    textAlign: "center",
    fontSize: "13px",
    fontWeight: "400",
    border: `1px solid ${themesConfig.default.palette.common.white}`,
    "@media (max-width: 650px)": {
      whiteSpace: "nowrap",
    },
  });
  
  export const CustomTableBodyCell = styled(TableCell)`
    background-color: ${themesConfig.legacy.palette.primary.main};
    border: 1px solid ${themesConfig.default.palette.common.white};
    padding: 8px;
  `;
export const useInputStyles = makeStyles((theme: Theme) => ({
  removeNumberInputSpinner: {
    "& input[type=number]": {
      "-moz-appearance": "textfield", // For Firefox
    },
    "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
      {
        "-webkit-appearance": "none", // For Chrome, Safari, Edge, Opera
        margin: 0,
      },
  },
}));
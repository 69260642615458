const locale = {
  TERMS_AND_CONDITION: "Terms And Condition",
  TERMS_AND_CONDITION_DESCRIPTION:
    "Welcome to FKC.COOP! These Terms and Conditions govern your use of our website. By accessing or using our website, you agree to be bound by these Terms and Conditions. Please read them carefully before using our website.",
  CONTENT: [
    {
      title: "Acceptance of Terms",
      message:
        "By accessing or using this website, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, you may not access the website.",
    },
    {
      title: "Financing Terms and Conditions",
      message:
        "Any financing terms and conditions provided on this website are subject to approval and may change without notice. The terms displayed are for informational purposes only and do not constitute a binding agreement.",
    },
    {
      title: "No Guarantee of Approval",
      message:
        "Applying for financing through this website does not guarantee approval. All applications are subject to review and approval based on our criteria and policies.",
    },
    {
      title: "Errors and Mistakes",
      message:
        "While we strive to provide accurate and up-to-date information, mistakes and errors may occur. We do not guarantee the accuracy, completeness, or reliability of any content on this website. Any reliance you place on such information is strictly at your own risk.",
    },
    {
      title: "Limitation of Liability",
      message:
        "In no event shall Koperasi FK Capital Berhad (FKC.COOP) be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with your use of this website or the information provided on this website.",
    },
    {
      title: "Indemnification",
      message:
        "You agree to indemnify, defend, and hold harmless Koperasi FK Capital Berhad, its affiliates, officers, directors, employees, agents, licensors, and suppliers from and against all losses, expenses, damages, and costs, including reasonable attorneys' fees, resulting from any violation of these Terms and Conditions or any activity related to your account (including negligent or wrongful conduct) by you or any other person accessing the website using your Internet account.",
    },
    {
      title: "Changes to Terms",
      message:
        "We reserve the right to update or modify these Terms and Conditions at any time without prior notice. Your continued use of the website after any such changes constitutes your acceptance of the new Terms and Conditions.",
    },
    {
      title: "Governing Law",
      message:
        "These Terms and Conditions shall be governed by and construed in accordance with the laws of Malaysia, without regard to its conflict of law principles.",
    },
    {
      title: "Contact Us",
      message:
        "If you have any questions about these Terms and Conditions, please visit our contact us page on our website.",
    },
  ],
  ACKNOWLEDGE:
    "By using this website, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.",
};

export default locale;

import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

const CustomCheckbox = ({ label, onChange, ...props }) => {
    const [checked, setChecked] = React.useState(false);

    const handleChange = (isChecked) => {
        setChecked(isChecked);
        if (onChange) {
            onChange(isChecked);
        }
    };

    return (
        <FormControlLabel
            control={<Checkbox checked={checked} onChange={(event) => handleChange(event.target.checked)} {...props} />}
            label={label}
        />
    );
};

export default CustomCheckbox;

import { useEffect, useState } from "react";
import { Grid, Box, Select, MenuItem, Typography, Hidden } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import { getProductDetail, getProductDetailData } from "./store/productSlice";
import { formatAmount } from "src/app/services/utils";
import { useTheme } from "@mui/system";
import { StyledButton } from "app/shared-components/Styles/CustomStyle";
import history from "@history";
import themesConfig from "app/configs/themesConfig";
import { makeStyles } from "@mui/styles";
import PreviewMedia from "app/shared-components/Dialog/PreviewMedia";
import FilePreview from "app/shared-components/preview-file/FilePreview";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  descriptionTitle: {
    color: themesConfig.status.statusColors.draft.backgroundColor,
  },
  imageContainer: {
    backgroundColor: themesConfig.light10.palette.background.default,
  },
  shortDescription: {
    color: themesConfig.defaultDark.palette.primary.light,
  },
  productSpecificationContainer: {
    borderColor: themesConfig.default.palette.divider,
  },
});
function ProductDetailPage() {
  const { t } = useTranslation("productPage");
  const dispatch: any = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const { id } = useParams();
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [price, setPrice] = useState(null);
  const [originalPrice, setOriginalPrice] = useState(null);

  const productDetails = useSelector(getProductDetailData);
  const {
    productSpecificationList,
    productVariantList = [],
    image,
    name,
    shortDescription,
    description,
    categoryId,
  } = productDetails || {};

  // Set the first variant as default on component mount
  useEffect(() => {
    if (productVariantList.length > 0) {
      const { id, price, originalPrice } = productVariantList[0];
      setSelectedVariant(id);
      setPrice(price);
      setOriginalPrice(originalPrice);
    } else {
      setPrice(productDetails?.price);
    }
  }, [productVariantList]);

  useEffect(() => {
    dispatch(getProductDetail(id));
  }, [dispatch, id]);

  const handleVariantChange = (event) => {
    const selectedId = event.target.value;
    const variant = productVariantList.find((v) => v.id === selectedId);
    if (variant) {
      setSelectedVariant(selectedId);
      setPrice(variant.price);
      setOriginalPrice(variant.originalPrice);
    }
  };

  const buyNow = () => {
    const productId = productDetails?.id;
    const variantId = selectedVariant || 0;

    history.push(
      `/application-form/0?categoryId=${categoryId}&productId=${productId}&productVariantId=${variantId}`
    );
  };

  return (
    <div className="container mx-auto mt-8 p-24">
      <Grid container spacing={4}>
        {/* Left Section: Product Image */}
        <Grid item xs={12} md={6}>
          <div className="sticky p-10  top-4 flex flex-col justify-center items-center">
            {/* Image container with background */}
            <div
              className={`flex justify-center w-full p-20 ${classes.imageContainer} rounded-lg`}
            >
              <PreviewMedia filePath={image} showDefaultView={false}>
                <FilePreview fileUrl={image} />
              </PreviewMedia>
            </div>
            <Hidden smDown>
              <StyledButton
                variant="contained"
                disableElevation
                onClick={buyNow}
                className="h-44 mt-10 rounded-md w-1/2 self-end"
              >
                {t("BUY_NOW")}
              </StyledButton>
            </Hidden>
          </div>
        </Grid>

        {/* Right Section: Product Details */}
        <Grid item xs={12} md={6}>
          <div>
            {/* Product Title */}
            <h1 className="text-2xl mb-4">{name}</h1>
            <p className={classes.shortDescription}>{shortDescription}</p>

            {/* Price Section */}
            <div className="flex items-center mb-4">
              {price && (
                <span className="text-3xl font-bold mr-2">
                  {price && formatAmount(price)}
                </span>
              )}
              {originalPrice > price && (
                <Typography
                  variant="caption"
                  fontWeight="bold"
                  color={theme.palette.primary.light}
                  className="ml-10"
                >
                  {t("SAVE")} {formatAmount(originalPrice - price)}
                </Typography>
              )}
            </div>

            {/* Variant Selector */}
            {productVariantList.length > 0 && (
              <Select
                fullWidth
                value={selectedVariant || ""}
                onChange={handleVariantChange}
              >
                {productVariantList.map((variant) => {
                  const variantLabel = [
                    variant.color,
                    variant.storage,
                    variant.screenSize,
                    variant.ram,
                  ]
                    .filter(Boolean)
                    .join(" - ");
                  return (
                    <MenuItem key={variant.id} value={variant.id}>
                      {variantLabel || t("NO_DETAILS_AVAILABLE")}
                    </MenuItem>
                  );
                })}
              </Select>
            )}

            <Hidden smUp>
              <StyledButton
                variant="contained"
                disableElevation
                onClick={buyNow}
                className="h-44 mt-10 rounded-md w-full"
              >
                {t("BUY_NOW")}
              </StyledButton>
            </Hidden>

            {/* Description Section */}
            <Grid container className="mt-8" direction="column">
              <Typography
                variant="h6"
                gutterBottom
                className={classes.descriptionTitle}
              >
                {t("DESCRIPTION")}
              </Typography>
              <Typography
                component="div"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </Grid>

            {/* Product Specifications Section */}
            {productSpecificationList &&
              productSpecificationList.length > 0 && (
                <Box
                  className={`border mt-16 ${classes.productSpecificationContainer}`}
                >
                  <div
                    className={`border-b-1 p-16 ${classes.productSpecificationContainer}`}
                  >
                    <h2 className="text-xl font-semibold mb-4">
                      {t("PRODUCT_SPECIFICATION_TITLE")}
                    </h2>
                  </div>
                  <Grid className="p-16" container spacing={2}>
                    {productSpecificationList.map((spec, index) => (
                      <Grid container item key={index} spacing={2}>
                        <Grid item xs={6}>
                          <span
                            className={`font-medium ${classes.shortDescription}`}
                          >
                            {spec.key}
                          </span>
                        </Grid>
                        <Grid item xs={6}>
                          <span>{spec.value}</span>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default ProductDetailPage;

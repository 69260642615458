const locale = {
  VALID_DATE_VALIDATION: "Please enter a valid date",
  DOB_FUTURE: "Date of Birth cannot be in the future",
  DOB_PAST_VALIDATION: "Date of Birth cannot be earlier than 1900-01-01",
  MOBILE_NUMBER_REQUIRED_VALIDATION: "Number is required.",
  NUMBER_INVALID_VALIDATION: "Please enter a valid number",
  EMAIL_REQUIRED_VALIDATION: "Email is required",
  EMAIL_INVALID_VALIDATION: "Please enter a valid email address",
};

export default locale;

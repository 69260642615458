const locale = {
  PRODUCT_PAGE_TITLE: "Our Products",
  PRODUCT_PAGE_DESCRIPTION:
    "Products/Services with flexible, finance-assisted options are available.",
  PRODUCT_PAGE_SEARCH_LABEL: "Search Your Products",
  PRODUCT_PAGE_VIEW_MORE: "View More",
  NO_PRODUCT_TITLE: "No Product Found",
  NO_PRODUCT_FOUND_MESSAGE: "Your search did not match any products.",
  BUY_NOW: "Buy Now",
  SAVE: "save",
  NO_DETAILS_AVAILABLE: "No details available",
  DESCRIPTION: "Description",
  PRODUCT_SPECIFICATION_TITLE: "Product Specifications"
};

export default locale;

import React, { useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDateRange } from "src/app/services/useDateRange";

interface CustomTabsProps {
  onTabChange: (startDate: string) => void;
}

const CustomTabs: React.FC<CustomTabsProps> = ({ onTabChange }) => {
  const { t } = useTranslation("dashboard");
  const [tabValue, setTabValue] = React.useState(0);

  const { getDateRange, dateRanges } = useDateRange();

  // Trigger date change when the selected tab changes
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    const currentRangeKey = Object.keys(dateRanges)[newValue];
    const { startDate } = getDateRange(currentRangeKey);
    onTabChange(startDate); // Pass the selected startDate to the parent component
  };

  // Trigger the initial default tab selection (Last Week, for example)
  useEffect(() => {
    const defaultRangeKey = Object.keys(dateRanges)[0]; // Assuming the first tab is the default (e.g., "Last Week")
    const { startDate } = getDateRange(defaultRangeKey);
    onTabChange(startDate); // Call API on mount with the default tab's date range
  }, []);

  return (
    <Tabs
      value={tabValue}
      onChange={handleTabChange}
      indicatorColor="secondary"
      textColor="inherit"
      variant="scrollable"
      scrollButtons={false}
      className="mb-8"
    >
      {Object.entries(dateRanges).map(([key, label]) => (
        <Tab
          key={key}
          label={t(label)}
          className="text-11 sm:text-13 font-semibold min-h-40 min-w-64"
        />
      ))}
    </Tabs>
  );
};

export default CustomTabs;

const locale = {
  VALID_DATE_VALIDATION: "Sila masukkan tarikh yang sah",
  DOB_FUTURE: "Tarikh Lahir tidak boleh pada masa hadapan",
  DOB_PAST_VALIDATION:
    "Tarikh Lahir tidak boleh lebih awal daripada 1900-01-01",
  MOBILE_NUMBER_REQUIRED_VALIDATION: "Nombor diperlukan.",
  NUMBER_INVALID_VALIDATION: "Sila masukkan nombor yang sah",
  EMAIL_REQUIRED_VALIDATION: "E-mel diperlukan",
  EMAIL_INVALID_VALIDATION: "Sila masukkan alamat e-mel yang sah",
};

export default locale;

const locale = {
  HOW_CAN_WE_HELP: "Bagaimana kami boleh membantu anda?",
  DESCRIPTION_TEXT:
    "Anda akan menemui jawapan kepada soalan biasa dan sumber yang berguna untuk membantu anda. Jika anda tidak menemui jawapan yang anda cari, sila hubungi kami untuk mendapatkan bantuan lanjut.",
  CONTACT_US: "hubungi kami",
  REST: "untuk bantuan selanjutnya.",

  QUESTION_1: "Apakah had kelayakan pembiayaan saya?",
  ANSWER_1:
    "Ansuran bulanan tidak boleh melebihi 60% daripada gaji pokok dan elaun tetap.",

  QUESTION_2: "Apakah yang dimaksudkan dengan pendapatan bersih?",
  ANSWER_2: "Gaji Pokok + Elaun Tetap Yang Layak – Potongan Mandatori.",

  QUESTION_3: "Apakah jenis elaun tetap yang dipertimbangkan?",
  ANSWER_3:
    "1. Elaun Tetap Perumahan (ITP).\n2. Elaun Tetap Perkhidmatan Awam (ITKA).\n3. Elaun Tetap Hiburan (ITK).\n4. Elaun Tetap Perkhidmatan Tentera (ITKT).\n5. Elaun Tetap Eksekutif (ITJU).\n6. Elaun Perumahan Wilayah (EPW).\n7. Elaun Memangku.\n8. Bantuan Sara Hidup (COLA).\n9. Bayaran Insentif Wilayah (BIW).\n10. Bayaran Insentif Semenanjung Malaysia (BISM).\n11. Bayaran Insentif Sabah/Sarawak (BISS).\n12. Lain-lain: Sila rujuk kakitangan kami untuk maklumat lanjut.",

  QUESTION_4: "Apakah jenis potongan mandatori yang dipertimbangkan?",
  ANSWER_4:
    "1. KWSP (Kumpulan Wang Simpanan Pekerja).\n" +
    "2. PERKESO (Pertubuhan Keselamatan Sosial).\n" +
    "3. Cukai Pendapatan.\n" +
    "4. Perintah Mahkamah.\n" +
    "5. Perintah Mahkamah Syariah.\n" +
    "6. Bayaran Balik Gaji.\n" +
    "7. Bayaran Balik Kerajaan Persekutuan/Negeri/Berkanun Pinjaman Perumahan Badan.\n" +
    "8. Lain-lain: Sila rujuk kakitangan kami untuk maklumat lanjut.",

  QUESTION_5: "Berapa lama proses kelulusan?",
  ANSWER_5:
    "Tempoh kelulusan adalah dari 3 hingga 14 hari bekerja (tertakluk kepada dokumentasi yang lengkap).",

  QUESTION_6: "Berapa lama proses pembayaran?",
  ANSWER_6:
    "Proses pembayaran mengambil masa 3 hari bekerja selepas kelulusan.",

  QUESTION_7: "Adakah pekerja kontrak layak memohon pembiayaan?",
  ANSWER_7: "Tidak Layak.",

  QUESTION_8: "Apakah tarikh akhir untuk pemohon pembiayaan sebelum bersara?",
  ANSWER_8: "1 tahun sebelum tahun persaraan.",

  QUESTION_9:
    "Adakah perlu bagi pemohon membeli perlindungan takaful untuk pembiayaan ini?",
  ANSWER_9:
    "Ya, semua pembiayaan yang diluluskan mesti termasuk perlindungan takaful daripada panel kami.",
};
  
  export default locale;
  
import { Grid } from "@mui/material";
import ActionRequiredRemarksList from "app/shared-components/Dashboard/ActionRequiredRemarksList";
import ManagerApplicationChart from "app/shared-components/Dashboard/ManagerApplicationChart";
import TopApplicationsList from "app/shared-components/Dashboard/TopApplicationsList";
import TopPerformerManagerList from "app/shared-components/Dashboard/TopPerformerManagerList";
import Widget from "app/shared-components/Dashboard/Widget";
import React from "react";

const AgentDashboard = () => {
  return (
    <>
      <Grid container spacing={2} className="mb-10">
        <Grid item xs={12}>
          <Widget />
        </Grid>
        <Grid item xs={12}>
          <TopApplicationsList />
        </Grid>
        <Grid item xs={12}>
          <ManagerApplicationChart />
        </Grid>
        <Grid item xs={12} md={6}>
          <TopPerformerManagerList />
        </Grid>
        <Grid item xs={12} md={6}>
          <ActionRequiredRemarksList />
        </Grid>
      </Grid>
    </>
  );
};

export default AgentDashboard;

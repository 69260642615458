import { Typography, IconButton, Grid } from "@mui/material";
import QRCodeComponent from "app/shared-components/QRCode/QRCodeComponent";
import { StyledTypography } from "app/shared-components/Styles/CustomStyle";
import { showMessage } from "app/store/fuse/messageSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DetailItem from "app/shared-components/DetailItem/DetailItem";
import { useParams } from "react-router";

const FinanceKYCQRCode = () => {
  const { t } = useTranslation("user");
  const dispatch: any = useDispatch();
  const params = useParams();
  const { applicationId } = params;

  // Base URL for sign-up with agent code
  const referralLink = `${process.env.REACT_APP_WEB_BASE_URL}user/my-application-kyc/${applicationId}`;

  const copyLinkToClipboard = (copyText) => {
    !!copyText
      ? navigator.clipboard.writeText(copyText).then(
          () => {
            dispatch(
              showMessage({
                message: t("COPY_TEXT"),
                variant: "success",
                autoHideDuration: 5000,
              })
            );
          },
          (err) => {
            console.error("FAIL_COPY", err);
          }
        )
      : dispatch(
          showMessage({
            message: t("FAIL_COPY"),
            variant: "error",
            autoHideDuration: 5000,
          })
        );
  };

  return (
    <div className="text-center">
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <StyledTypography variant="h5" fontWeight="bold" mb={2}>
            {t("COMPLETE_KYC_QR_TITLE")}
          </StyledTypography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">{t("COMPLETE_KYC_QR_DES")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <QRCodeComponent link={referralLink} />
        </Grid>
        <Grid item xs={12}>
          <DetailItem
            label={t("KYC_LINK")}
            value={
              <>
                <span className="text-md mr-10 font-bold">{referralLink}</span>
                <IconButton onClick={() => copyLinkToClipboard(referralLink)}>
                  <ContentCopyIcon color="secondary" />
                </IconButton>
              </>
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default FinanceKYCQRCode;

import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { decodeUrlAndGetFileType } from 'src/app/services/utils';

const useStyles = makeStyles({
    imgPreview: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
    audioVideoPreview: {
        width: '100%',
    },
    unsupportedText: {
        textAlign: 'center',
    },
});

interface FilePreviewProps {
    fileUrl: string;
}

const FilePreview: React.FC<FilePreviewProps> = ({ fileUrl="" }) => {
    const { t } = useTranslation('FinanceApplication');
    const classes = useStyles();

    let fileType: string;

    fileType = decodeUrlAndGetFileType(fileUrl);

    if (['jpg', 'jpeg', 'png'].includes(fileType)) {
        return <img src={fileUrl} alt="File preview" className={classes.imgPreview} />;
    } else if (fileType === 'mp4') {
        return <video src={fileUrl} controls className={classes.audioVideoPreview} />;
    } else if (['audio', 'mp3', 'wav'].includes(fileType)) {
        return <audio src={fileUrl} controls className={classes.audioVideoPreview} />;
    } else if (fileType === 'pdf') {
        return <img src='/assets/images/user/pdf.svg' alt='PDF FILE' className={classes.imgPreview} />;
    } else {
        return <Typography variant="body2" className={classes.unsupportedText}>{t('UNSUPPORTED_FILE')}</Typography>;
    }
};

export default FilePreview;

import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Container,
  Hidden,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/system";
import { Link } from "react-router-dom";
import BusinessIcon from "@mui/icons-material/Business";
import PhoneNumberLink from "app/shared-components/Link/PhoneNumberLink";
import EmailLink from "app/shared-components/Link/EmailLink";

const StyledBox = styled(Box)`
  background-color: ${({ theme }) => theme.palette.secondary.light};
`;

const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    border-color: ${({ theme }) => theme.palette.common.black};
    padding-right: 0px;
    border-radius: 10px;
  }
`;

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.common.white};
  padding: 15px 25px;
  border-radius: 0 10px 10px 0;
  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

const StyledTypographyGrey = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.dark};
`;

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "600",
  color: theme.palette.common.black,
}));

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none !important",
  color: theme.palette.common.black + "!important",
}));

const Footer = () => {
  const { t } = useTranslation("navigation");
  const theme = useTheme();

  const gridItems = [
    {
      title: <StyledLink to="/product">{t("PRODUCT")}</StyledLink>,
      links: [
        <StyledLink to="/about-us">{t("ABOUTUS")}</StyledLink>,
        <StyledLink to="/contact-us">{t("CONTACTUS")}</StyledLink>,
       
      ],
    },
    {
      title: (
        <StyledLink to="/calculator">{t("CALCULATOR")}</StyledLink>
      ),
      links: [
        <StyledLink to="/terms-and-conditions">
          {t("TERMS_CONDITION")}
        </StyledLink>,
        <StyledLink to="/privacy-policy">{t("PRIVACY_POLICY")}</StyledLink>,
      ],
    },
  ];

  const contactInfo = [
    {
      icon: <BusinessIcon />,
      text: `39-1, Suite A1, Jalan Tasik Selatan 8, Metro Centre,
             Bandar Tasik Selatan, 58200, Kuala Lumpur, Malaysia.`,
    },
    {
      icon: "/assets/images/footer/phone.svg",
      text: (
        <>
          <PhoneNumberLink
            phoneNumber={"39054 3977"}
            className="!no-underline !text-black"
          />
          {t("MOBILE_LOCAL_FOOTER")},
          <br />
          <PhoneNumberLink
            phoneNumber={"39054 9188"}
            className="!no-underline !text-black"
          />
          {t("MOBILE_HUNTING_FOOTER")}
        </>
      ),
    },
    {
      icon: "/assets/images/footer/Email.svg",
      text: (
        <EmailLink email={"info@fkc.coop"} className="!text-black"></EmailLink>
      ),
    },
  ];

  const addGrid = (
    <>
      {gridItems.map((item, index) => (
        <Grid key={index} item xs={6} sm={6} md={2}>
          <Box className="flex flex-col gap-12 md:gap-36">
            <StyledTypography variant="body1" className="text-13">
              {item.title}
            </StyledTypography>
            {item.links.map((link, linkIndex) => (
              <StyledTypography
                key={linkIndex}
                variant="body1"
                className="text-13"
              >
                {link}
              </StyledTypography>
            ))}
          </Box>
        </Grid>
      ))}
    </>
  );

  return (
    <StyledBox className="md:pt-24 pb-24 md:p-[40px]">
      <Container maxWidth="lg" className="pl-10 md:pl-76 pr-10 md:pr-76">
        <Grid container spacing={3} className="mt-12 mb-12">
          <Grid item xs={12} sm={6} md={4} className="flex flex-col gap-20">
            <img
              className="h-52 w-84"
              src="assets/images/logo/Logo.svg"
              alt="logo"
            />
            <Box className="flex flex-col gap-10">
              {contactInfo.map((item, index) => (
                <Box key={index} className="flex items-start gap-9">
                   {typeof item.icon === "string" ? (
                    <img
                      src={item.icon}
                      alt="icon"
                      style={{ width: "1.5em", height: "1.5em" }}
                      className="object-contain"
                    />
                  ) : (
                    <Box style={{ width: "1.5em", height: "1.5em" }}>{item.icon}</Box>
                  )}
                  <StyledTypography variant="body1" className="text-13">
                    {item.text}
                  </StyledTypography>
                </Box>
              ))}
            </Box>
          </Grid>
          <Hidden mdDown>{addGrid}</Hidden>
          <Hidden smUp>{addGrid}</Hidden>
          <Grid item xs={12} sm={6} md={4}>
            {/* sociial media links */}
            <Box className="flex items-center mt-20 gap-20 md:gap-32">
              <a href="#">
                <img
                  src="/assets/images/footer/linkedin.png"
                  alt="LinkedIn"
                />
              </a>
              <a href="#">
                <img
                  src="/assets/images/footer/facebook.png"
                  alt="Facebook"
                />
              </a>
              <a href="#">
                <img className="h-28 w-28" src="/assets/images/footer/twitter.png" alt="Twitter"></img>
              </a>
              <a href="#">
                <img src="/assets/images/footer/instagram.png" alt="Instagram"></img>
              </a>
            </Box>

            {/* download links */}
            {/* <div className="flex">
              <a href="#">
                <div className="h-[150px] w-[150px] mr-10">
                  <img src="/assets/images/footer/app-store-badge.svg" alt="download on app store"/>
                </div>
              </a>
              <a href="#">
                <div className="h-[150px] w-[150px]">
                  <img src="/assets/images/footer/google-play-badge.svg" alt="download on playstore"/>
                </div>
              </a>
            </div> */}
          </Grid>
          <Hidden smDown mdUp>
            {addGrid}
          </Hidden>
        </Grid>
        <Typography
          variant="body1"
          className="text-center pt-20 md:pt-40"
          color={theme.palette.common.black}
        >
          {t("COPYRIGHT_FOOTER")}
        </Typography>
      </Container>
    </StyledBox>
  );
};

export default Footer;

const locale = {
  BANNER_TITLE: "FKC.COOP ",
  BANNER_DESCRIPTION:
    "A Malaysian cooperative established in 2019 under Suruhanjaya Koperasi Malaysia (SKM), committed to providing credit facilities, driving digitalisation, and supporting e-commerce. Our mission is to uplift the underserved, enable digitalisation for all, and be among the best cooperative movement in Malaysia and the world.",
  BANNER_BUTTON_TEXT: "Apply For Finance",
  OURPRODUCT_TITLE: "Our Product",
  OURPRODUCT_DESCRIPTION:
    "Products/Services with flexible, finance-assisted options are available.",
  COMMONHEADER_BUTTON: "view all",
  OURSERVICE_TITLE: "Our Services",
  OURSERVICE_DESCRIPTION:
    "Comprehensive services with a community focus.",
  FINANCE_SERVICE_TITLE: "Financing",
  FINANCE_SERVICE_DESCRIPTION:
    "We offer cooperative members reliable financial services tailored to their needs.",
  INSURANCE_SERVICE_TITLE: "Insurance",
  INSURANCE_SERVICE_DESCRIPTION:
    "Our cooperative provides both Islamic and non-Islamic insurance packages to our members' diverse preferences.",
  WASIAT_SERVICE_TITLE: "Will, Wasiat & Hibah",
  WASIAT_SERVICE_DESCRIPTION:
    "Our cooperative provides both Islamic and non-Islamic insurance packages to our members' diverse preferences.",
  DIGITALIZATION_SERVICE_TITLE: "Digitalization",
  DIGITALIZATION_SERVICE_DESCRIPTION:
    "We provide our cooperative members with a variety of digital services, including cloudservers and more.",
  CONSUMER_SERVICE_TITLE: "Consumer Products",
  CONSUMER_SERVICE_DESCRIPTION:
    "We provide our cooperative members with a variety of consumer goods like Huawei,Samsung, Apple, Tomei Jewelry, Yes5G Postpaid and Prepaid Plans, mobilePostpaid and Prepaid Plans and many more!",
  MEMBERSHIP_TITLE: "Membership Benefits",
  MEMBERSHIP_DESCRIPTION:
    "Join our cooperative membership for numerous benefits, including financial services and community support.",
  DIVIDEND_BENEFIT_NAME: "Competitive dividends",
  DIVIDEND_BENEFIT_DESCRIPTION:
    "Our cooperative rewards members with Members great business ventures with competitive dividends and continued support.",
  EXCLUSIVE_INSURANCE_BENEFIT_NAME: "Exclusive Insurance & Will Rebates",
  EXCLUSIVE_INSURANCE_BENEFIT_DESCRIPTION:
    "Special Members discounts \n Auto Insurance (5%)\n CCM Insurance Packages\n Life Insurance and more",
  SPECIAL_FUND_BENEFIT_NAME: "Special Funds for  members",
  SPECIAL_FUND_BENEFIT_DESCRIPTION:
    "Emergency Funds Education Aid Medical Expenses And more.",
  WHY_KOPERASI_TITLE: "Why Koperasi FK Capital Berhad?",
  DIGITAL_COOPERATIVE_WHY_KOPERASI: " Digital Cooperative",
  SHARIAH_COMPLIANT_WHY_KOPERASI: "Shariah Compliant",
  REGISTERED_ICON_WHY_KOPERASI: "Registered with SKM Malaysia",
  MEMBERSHIP_ICON_WHY_KOPERASI: "Nationwide Membership",
  MARKETACCESS_ICON_WHY_KOPERASI: "Enhanced Market Access",
  PERSON_ICON_WHY_KOPERASI: "Great Members Benefits",
  HANDHOLDINGDOLLAR_ICON_WHY_KOPERASI: "Access to Financial Services",
  FEEDBACK_TITLE:'What Customer Say About Us',
};

export default locale;

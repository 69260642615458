import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useTheme } from "@mui/system";
import CustomTextFieldWithIcon from "app/shared-components/Form/CustomTextFieldWithIcon";
import PhoneIcon from "@mui/icons-material/Phone";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { generateAndVerifyOTPType, tenantId } from "src/app/common/constant";
import OtpVerification from "app/shared-components/OtpVerification/OtpVerification";
import JwtService from "src/app/auth/services/jwtService";
import { showMessage } from "app/store/fuse/messageSlice";
import ChangeDetectDialog from "../../user/component/form/ChangeDetectDialog";
import { removeLeadingZero } from "src/app/services/utils";
import { phoneNumberValidation } from "src/app/services/ValidationHelper/validationHelpers";

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.secondary.main,
  paddingRight: "96px",
  paddingLeft: "96px",
  marginTop: "20px",
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  marginTop: "20px",
}));

const defaultValues = {
  phoneNumberOrEmail: "",
};

export default function SignIn({ switchTab }) {
  const { t } = useTranslation("signInPage");
  const theme = useTheme();
  const methods = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);

  const schema = yup.object().shape({
    phoneNumberOrEmail: phoneNumberValidation(true),
  });

  const { handleSubmit, formState, control, setValue, getValues } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  useEffect(() => {
    setValue("phoneNumberOrEmail", "", {
      shouldDirty: false,
      shouldValidate: false,
    });
  }, [setValue]);

  const { errors, isDirty } = formState;
  const sendOTP = () => {
    const phoneNumberOrEmail = getValues("phoneNumberOrEmail");
    setLoading(true);

    JwtService.sendOTP({
      mobile: phoneNumberOrEmail,
      otpType: generateAndVerifyOTPType?.Login,
    })
      .then(() => {
        setLoading(false);
        setOtpSent(true);
      })
      .catch((error) => {
        dispatch(showMessage({ message: error, variant: "error" }));
        setLoading(false);
      });
  };

  const verifyOTP = (data) => {
    setLoading(true);
    JwtService.verifyOTP({
      mobile: getValues("phoneNumberOrEmail"),
      otp: data.otp,
      OTPType: generateAndVerifyOTPType?.Login,
    })
      .then((response) => {
        setLoading(false);
        JwtService.signInWithEmailAndPassword(response);
      })
      .catch((error) => {
        dispatch(showMessage({ message: error?.message, variant: "error" }));
        setLoading(false);
      });
  };

  const handleSwitchTab = () => {
    switchTab();
    navigate("/sign-up");
  };

  const handleChangePhoneNumber = () => {
    if (isDirty && otpSent) {
      setOtpSent(false);
    } else {
      return;
    }
  };

  return (
    <>
      <Box className="flex flex-col gap-20">
        <Box>
          <StyledTypography variant="h5" fontWeight={"bold"}>
            {t("LOGIN_TITLE")}
          </StyledTypography>
          <StyledTypography variant="caption" fontWeight={"bold"}>
            {t("LOGIN_DESCRIPTION")}
          </StyledTypography>
        </Box>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(sendOTP)} className="flex flex-col">
            <Controller
              name="phoneNumberOrEmail"
              control={control}
              render={({ field }) => (
                <CustomTextFieldWithIcon
                  id="phoneNumberOrEmail"
                  required
                  label={t("PHONE_LABEL")}
                  icon={<PhoneIcon />}
                  prefix="+60"
                  type="number"
                  color={theme.palette.common.white}
                  isUpperCase={false}
                  onChange={(e) => {
                    field.onChange(removeLeadingZero(e));
                    handleChangePhoneNumber();
                  }}
                  inputProps={{
                    "data-test-id": "login-input-email", // Add the data-testid attribute here
                  }}
                  value={field.value}
                  error={errors.phoneNumberOrEmail}
                />
              )}
            />

            {!otpSent && (
              <Box className="flex justify-center items-center mt-10">
                <StyledButton
                  theme={theme}
                  variant="contained"
                  className="capitalize"
                  type="submit"
                  data-test-id="submit-login-btn"
                >
                  {!loading ? (
                    t("SEND_OTP")
                  ) : (
                    <CircularProgress color="secondary" size={24} />
                  )}
                </StyledButton>
              </Box>
            )}
          </form>
        </FormProvider>
        {otpSent && (
          <OtpVerification
            onOtpSubmit={verifyOTP}
            onResendOtp={sendOTP}
            color={theme.palette.common.white}
            loading={loading}
            phoneNumber={getValues("phoneNumberOrEmail")}
            data-test-id="otp-verification"
          />
        )}
      </Box>

      <Box className="flex justify-center items-center mt-20">
        <StyledTypography variant="body2">
          {t("DONT_HAVE_ACCOUNT")}
          <span
            onClick={handleSwitchTab}
            className="underline ml-8 cursor-pointer"
          >
            {t("CREATE_ACCOUNT")}
          </span>
        </StyledTypography>
      </Box>
    </>
  );
}

const locale = {
  PRODUCT_PAGE_TITLE: "Produk Kami",
  PRODUCT_PAGE_DESCRIPTION:
    "Produk/Perkhidmatan dengan pilihan bantuan kewangan yang fleksibel tersedia.",
  PRODUCT_PAGE_SEARCH_LABEL: "Cari Produk Anda",
  PRODUCT_PAGE_VIEW_MORE: "Lihat Lebih Banyak",
  NO_PRODUCT_TITLE: "Tiada Produk Ditemui",
  NO_PRODUCT_FOUND_MESSAGE:
    "Carian anda tidak sepadan dengan mana-mana produk.",
  BUY_NOW: "Beli Sekarang",
  SAVE: "jimat",
  NO_DETAILS_AVAILABLE: "Tiada butiran tersedia",
  DESCRIPTION: "Penerangan",
  PRODUCT_SPECIFICATION_TITLE: "Spesifikasi Produk"
};

export default locale;

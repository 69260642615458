import React from "react";
import { formatPhoneNumber } from "src/app/services/utils";

const PhoneNumberLink = ({ phoneNumber, className = "" }) => {
  if (!phoneNumber) return <span>--</span>;

  const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

  return (
    <a href={`tel:${formattedPhoneNumber}`} className={className}>
      {formattedPhoneNumber}
    </a>
  );
};

export default PhoneNumberLink;

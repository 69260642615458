import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import themesConfig from "app/configs/themesConfig";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmailVerification,
  getError,
  getStatus,
} from "./store/emailVerificationSlice";
import { useParams } from "react-router";
import FuseLoading from "@fuse/core/FuseLoading";

const StyledContainer = styled("div")({
  backgroundColor: themesConfig.default.palette.background.default,
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  maxWidth: "400px",
  borderRadius: "16px",
  boxShadow: theme.shadows[5],
}));

const AnimatedIcon = styled(VerifiedOutlinedIcon)({
  fontSize: "8rem",
  display: "block",
  margin: "16px auto",
  animation: "bounce 2s infinite",
  "@keyframes bounce": {
    "0%, 20%, 50%, 80%, 100%": {
      transform: "translateY(0)",
    },
    "40%": {
      transform: "translateY(-30px)",
    },
    "60%": {
      transform: "translateY(-15px)",
    },
  },
});

const Heading = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const ErrorIcon = styled(ErrorOutlineIcon)({
  fontSize: "8rem",
  display: "block",
  margin: "16px auto",
  color: themesConfig.default.palette.error.main,
});

function EmailVerification() {
  const dispatch: any = useDispatch();
  const { token } = useParams<{ token: string }>();
  const status = useSelector(getStatus);
  const error = useSelector(getError);

  useEffect(() => {
    const request_data = {
      emailVerificationToken: token,
    };
    dispatch(getEmailVerification(request_data));
  }, [dispatch, token]);

  return (
    <StyledContainer className="min-w-0 p-10 h-screen flex justify-center items-center flex-col flex-auto">
      {status === "loading" ? (
        <>
          <FuseLoading />
        </>
      ) : (
        <StyledPaper className="flex flex-col items-center w-full h-auto p-20">
          <div className="flex w-full justify-end mb-4">
            <img
              src="assets/images/logo/logo.svg"
              alt="logo"
              className="w-60 ml-auto"
            />
          </div>
          <div className="mx-auto w-full sm:mx-0  mb-40 text-center">
            {status === "succeeded" && (
              <>
                <AnimatedIcon color="success" />
                <Heading className="text-lg sm:text-2xl mt-10 font-bold">
                  Email Verified Successfully!
                </Heading>
                <SubHeading className="text-base">
                  Your email has been verified.
                  <br /> Thank you!
                </SubHeading>
              </>
            )}
            {status === "failed" && (
              <>
                <ErrorIcon />
                <Heading className="font-medium text-lg sm:text-2xl">
                  {error}
                </Heading>
              </>
            )}
          </div>
        </StyledPaper>
      )}
    </StyledContainer>
  );
}

export default EmailVerification;

import React, { useEffect, useState } from "react";
import { Alert } from "@mui/material";
import { isEmpty } from "lodash";

const MissingDocumentsAlert = ({
  documentTypes,
  documentList,
  showRequireDocsAlert,
  setShowRequireDocsAlert, // To close the alert manually if needed
  t
}) => {
  const [missingDocuments, setMissingDocuments] = useState([]);

  useEffect(() => {
    const calculateMissingDocuments = () => {
      if (documentTypes && documentTypes.length > 0) {
        const requiredDocumentTypes = documentTypes.filter((doc) => doc.require === true);

        const missingDocs = requiredDocumentTypes
          ?.filter((requiredDoc) => {
            const userDoc = documentList?.find(
              (userDoc) => userDoc.documentTypeId === requiredDoc.id
            );

            // Check if the document is missing
            return !userDoc;
          })
          .map((requiredDoc) => requiredDoc.name);

        setMissingDocuments(missingDocs);
        return missingDocs.length === 0; // Return if all required documents are present
      }

      setMissingDocuments([]);
      return true; // Assume all are present if no documents are required
    };

    if (showRequireDocsAlert) {
      const allRequiredPresent = calculateMissingDocuments();
      if (allRequiredPresent) {
        setShowRequireDocsAlert(false); // Close alert if all documents are present
      }
    }
  }, [showRequireDocsAlert, documentTypes, documentList, setShowRequireDocsAlert]);

  return (
    showRequireDocsAlert &&
    !isEmpty(missingDocuments) && (
      <Alert className="mb-20" severity="error">
        {t("REQUIRED_DOCUMENT")}{" "}
        {missingDocuments.map((doc, index) => `${index + 1}. ${doc}`).join(", ")}
      </Alert>
    )
  );
};

export default MissingDocumentsAlert;

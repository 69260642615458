import * as React from "react";
import UserNavbar from "./component/UserNavbar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import withReducer from "app/store/withReducer";
import { userSlice } from "app/store/user/userSlice";

function User() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <UserNavbar />
      <Outlet />
    </>
  );
}

export default withReducer("user", userSlice.reducer)(User);

const locale = {
  LATER: "Later",
  PENDING_KYC: " Pending KYC",
  PENDING_KYC_DESC:
    "Please complete the KYC process to move forward with your application",
  AMOUNT: "Amount",
  CREATED: "Created",
  DONT_SHOW_AGAIN: "Do not show dialog again",
  KYC_PROCESS: "Process KYC",
  CANCEL: "Cancel",
};

export default locale;

import { memo } from "react";
import Footer from "./Footer";

type FooterLayout2Props = {
  className?: string;
};

/**
 * The footer layout 2.
 */
function FooterLayout2(props: FooterLayout2Props) {
  return <Footer />;
}

export default memo(FooterLayout2);

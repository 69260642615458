import { useTranslation } from "react-i18next";
import { Container } from "@mui/material";
import {
  StyledTypography,
} from "app/shared-components/Styles/CustomStyle";
import { CommonBreadcrumbs } from "app/shared-components/Breadcrumbs/CommonBreadcrumbs";
import Can from "src/app/services/FeaturePermission/Can";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import AddMemberStepper from "./AddMemberStepper";

export default function AddMember() {
  const { t } = useTranslation("user");

  return (
    <Can
      permission={FeaturePermissions.User.Create}
      errorMessage="ACCESS_DENIED_ADD_MEMBER"
    >
      <Container maxWidth="lg" className="pl-10 md:pl-76 pr-10 md:pr-76 mb-64 mt-10">
        <CommonBreadcrumbs
          pages={[
            {
              to: "/user/member",
              name: t("MEMBER"),
            },
          ]}
          currentPageName={t("REGISTER")}
        />
        <div className="px-16">
          <StyledTypography variant="h5" className="mb-20 mt-20">
            {t("REGISTER")}
          </StyledTypography>
        </div>
        <AddMemberStepper />
      </Container>
    </Can>
  );
}

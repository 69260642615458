import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { StyledTypography } from "app/shared-components/Styles/CustomStyle";
import { useTranslation } from "react-i18next";

export const termsAndConditionsContent = () => {
  const { t } = useTranslation("termsAndConditions");

  const content = t("CONTENT", { returnObjects: true }) as {
    title: string;
    message: string;
  }[];

  return (
    <>
      <Typography variant="body1" className="mb-16 text-16 mt-5">
        {t("TERMS_AND_CONDITION_DESCRIPTION")}
      </Typography>

      {content.map((section, index) => (
        <Box key={index} className="my-20">
          <Typography variant="body1" className="mb-5 font-bold ml-16 text-16">
            {index + 1} . {section.title}
          </Typography>
          <Typography variant="body1" className="text-16 text-justify">
            {section.message}
          </Typography>
        </Box>
      ))}

      <Typography variant="body1" className="text-16">
        {t("ACKNOWLEDGE")}
      </Typography>
    </>
  );
};

const TermsAndConditions = () => {
  const { t } = useTranslation("termsAndConditions");

  return (
    <>
      <Box className="pt-24  md:p-[40px]">
        <Container maxWidth="lg" className="md:pl-76 md:pr-76 flex flex-col">
          <StyledTypography variant="h5">
            {t("TERMS_AND_CONDITION")}
          </StyledTypography>
          {termsAndConditionsContent()}
        </Container>
      </Box>
    </>
  );
};

export default TermsAndConditions;

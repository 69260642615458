import React, { useState, useEffect } from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import themesConfig from 'app/configs/themesConfig';
import { styled } from '@mui/system';

const StyledTextField = styled(TextField)(({ color, multiline }) => ({
    '& fieldset': {
        borderColor: color,
        '.hover':{
            borderColor: color,
        }
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: color,
        },
        '&:hover fieldset': {
            borderColor: color,
            boxShadow: 'none',
        }
      },
    '& .MuiInputLabel-root':{
        color: color,
    },
    '& .MuiInputLabel-root.Mui-focused':{
        color: color,
    },
    '& .MuiIconButton-root': {
        color: color,
      },
    '& .MuiInputAdornment-root':{
        color:color,
        alignSelf: multiline ? 'flex-start' : 'center', 
        marginTop: multiline ? '1rem' : '0',
    },
    '& .MuiInputBase-input': {
        color: color 
    },
    '& .MuiInputBase-multiline': {
        '& .MuiInputBase-input::placeholder': {
            color: themesConfig.default.palette.text.secondary,
            opacity: 0.8
        },
        '& textarea': {
            color: color,
        },
    },
    'input': {
        '&::placeholder': {
            color: color + '!important',
            opacity: 0.60,
          },
        "&:-webkit-autofill": {
          WebkitBoxShadow: `0 0 0 1000px ${color ? themesConfig.default.palette.secondary.main : themesConfig.default.palette.common.white} inset`,
          WebkitTextFillColor: color
        }
    },
    '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',  // For Chrome, Safari, Edge, Opera
        margin: 0,
    },
}));

export default function CustomTextFieldWithIcon(props) {
    const { id, label, icon, color = themesConfig.default.palette.common.black, isUpperCase = true, type = "text", error = null, onChange,prefix=null, value="", required=false,...otherProps } = props;
    const [showPassword, setShowPassword] = useState(false);
    const [focused, setFocused] = useState(false);

    useEffect(() => {
        if (props.autofocus) {
            setFocused(true);
        } else if(
            value === '' && !props.autoFocus
        ) {
            setFocused(false);
        }
    }, [value]);

    const handleTogglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleFocus = () => {
        setFocused(true);
    };

    const handleBlur = (event) => {
        if (event.target.value === '') {
            setFocused(false);
        }else{
            const value = event.target.value;
            if (isUpperCase) {
                onChange(value.toUpperCase());
            }
        }
    };

    const handleChange = (event) => {
        const { value } = event.target;
        onChange(value);
    };
    

    return (
        <StyledTextField
            id={id}
            label={focused || value ? label : ""}
            placeholder={!focused && !value ? label : ""}
            type={type === "password" && !showPassword ? "password" : type}
            InputProps={{
                startAdornment: (
                    <>
                        {icon && (
                            <InputAdornment position="start" className='flex items-center gap-5'>
                                {icon}
                                {prefix}
                            </InputAdornment>
                        )}
                    </>
                ),
                endAdornment: type === "password" && (
                    <InputAdornment position="end">
                        <IconButton onClick={handleTogglePasswordVisibility}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            inputProps={{
                style: { textTransform: isUpperCase ? "uppercase" : "none" },
              }}
            variant="outlined"
            autoComplete='off'
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            error={!!error}
            helperText={error ? error.message : ''}
            color={color}
            value={value}
            required={required}
            {...otherProps}
        />
    );
}

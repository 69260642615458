import React from "react";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@mui/icons-material";
import { withStyles } from "@mui/styles";

const actionsStyles = (theme) => ({
  root: {
    flexShrink: 0,
    color: theme?.palette?.secondary?.light,
    marginLeft: theme?.spacing?.(2.5),
  },
});

class TablePaginationActions extends React.Component {
  public static propTypes = {};

  handleFirstPageButtonClick = (event) => {
    const { onPageChange } = this.props as {
      onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        page: number
      ) => void;
      page: number;
    };
    onPageChange(event, 0);
  };

  handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { onPageChange, page } = this.props as {
      onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        page: number
      ) => void;
      page: number;
    };
    onPageChange(event, page - 1);
  };

  handleNextButtonClick = (event) => {
    const { onPageChange, page } = this.props as {
      onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        page: number
      ) => void;
      page: number;
    };
    onPageChange(event, page + 1);
  };

  handleLastPageButtonClick = (event) => {
    const { onPageChange, count, rowsPerPage } = this.props as {
      onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        page: number
      ) => void;
      page: number;
      count: number;
      rowsPerPage: number;
    };
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props as {
      classes: any;
      count: number;
      page: number;
      rowsPerPage: number;
      theme?: any;
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme?.direction === "rtl" ? <LastPage /> : <FirstPage />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme?.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme?.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme?.direction === "rtl" ? <FirstPage /> : <LastPage />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

// Inject withTheme HOC to get the theme
export default withStyles(actionsStyles, { withTheme: true })(
  TablePaginationActions
);

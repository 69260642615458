const locale = {
  TITLE: "Login / Sign Up",
  DESCRIPTION:
    "Enter your account information to proceed.",
  LOGIN: "Login",
  SIGN_UP: "Sign Up",
  LOGIN_TITLE: " Welcome Back!",
  LOGIN_DESCRIPTION: "Log In to Your Account",
  PHONE_LABEL: "Phone Number",
  PASSWORD_LABEL: "Password",
  FORGOT_PASSWORD: " Forgot Password?",
  DONT_HAVE_ACCOUNT: "No account?",
  CREATE_ACCOUNT: "Get started by signing up!",
  SIGN_UP_TITLE: "Create Your Account",
  SIGN_UP_DESCRIPTION: "Fill Details for Sign Up",
  FULL_NAME_LABEL: "Full Name",
  EMAIL_LABEL: "Email Address",
  REFERRAL_CODE: "Referral Code",
  CONFIRM_PASSWORD_LABEL: "Confirm Password",
  ALREADY_ACCOUNT: "Already have account ",
  LOGIN_NOW: " Login Now",
  USER_CREATE_TITLE: "Registration successful!",
  SIGN_UP_MESSAGE:
    "Thank you for registering! Your account has been successfully created.",
  TERMS_AND_CONDITION: "Terms And Condition",
  CANCEL: "Cancel",
  AGREE: "Agree",
  SEND_OTP: "Send OTP",
  I_AGREE_TO_THE_TERMS_AND_CONDITIONS: "I agree to the terms and conditions.",

  // VALIDATION MESSAGE
  FULL_NAME_VALIDATION: "You must enter full name",
  PHONE_VALIDATION_REQUIRED: "You must enter a phone number",
  PHONE_VALIDATION_PATTERN: "Phone number must contain only digits",
  PHONE_VALIDATION_LENGTH: "Phone number must be 10 digits",
  EMAIL_VALIDATION_REQUIRED: "You must enter an email",
  EMAIL_VALIDATION_EMAIL: "You must enter a valid email",
  PASSWORD_VALIDATION_REQUIRED: "Please enter your password.",
  PASSWORD_VALIDATION_MIN: "Password is too short - should be 8 chars minimum.",
  CONFIRM_PASSWORD_VALIDATION_MATCH: "Passwords must match",
  REFERRAL_CODE_LENGTH: "Referral code must be exactly 6 digits",
  NUMBER_VALIDATION: "Only numbers are accepted",
};

export default locale;

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import themesConfig from "app/configs/themesConfig";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import CustomAutocomplete from "app/shared-components/Form/CustomAutoComplete";
import { acceptFileTypeImageAndPDF, tenantId } from "src/app/common/constant";
import {
  selectIsImageLoading,
  fileUpload,
  getSelectedDocumentPath,
  resetSelectedDocumentPath,
} from "app/store/user/userSlice";
import * as yup from "yup";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { StyledTypographyGrey } from "app/shared-components/Styles/CustomStyle";
import ColoredSubmitButton from "app/shared-components/Button/ColoredSubmitButton";
import FilePreview from "app/shared-components/preview-file/FilePreview";
import CustomTextField from "../Form/CustomTextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { isEmpty } from "lodash";
import {
  getDocumentTypeListData,
  getIsButtonLoading,
  getUpdateDocumentDetail,
  setUpdateDocumentDetail,
  UpdateUploadedDocument,
  UploadDocument,
} from "./store/documentCardSlice";

const StyledButton = styled(Button)`
  height: 150px;
  max-height: none;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  color: ${themesConfig.default.palette.primary.light};
  background-color: ${themesConfig.default.palette.background.paper};
  border: 1.5px dashed ${themesConfig.default.palette.secondary.main};
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${themesConfig.default.palette.background.paper};
  }
`;

const StyledSpan = styled.span`
  width: 50px;
  height: 50px;
  font-size: 30px;
  color: ${themesConfig.default.palette.primary.light};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background-color: ${themesConfig.default.palette.secondary.light};
`;

const UploadDocumentDialog = ({
  open,
  handleClose,
  userId = null,
  financeApplicationId = null,
  documentList = null,
}) => {
  const dispatch: any = useDispatch();
  const { t } = useTranslation("user");
  const isImageLoading = useSelector(selectIsImageLoading);
  const selectedDocumentPath = useSelector(getSelectedDocumentPath);
  const [filePreview, setFilePreview] = useState(null);
  const documentTypes = useSelector(getDocumentTypeListData);
  const fileInputRef = React.useRef(null);
  const isLoading = useSelector(getIsButtonLoading);
  const documentDetails = useSelector(getUpdateDocumentDetail);
  const [isSelectedDocumentTypeOther, setIsSelectedDocumentTypeOther] =
    useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for password visibility

  const schema = yup.object().shape({
    documentTypeId: yup
      .string()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("DOCUMENT_TYPE") })
      ),
    documentFilePath: yup.string().when("documentDetails", ([], sch) => {
      if (!isEmpty(documentDetails) && documentDetails?.documentFilePath) {
        return sch.notRequired();
      }
      return sch.required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", {
          fieldName: t("DOCUMENT_FILE_PATH"),
        })
      );
    }),
    documentName: yup
      .string()
      .test(
        "is-required",
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("DOCUMENT_NAME") }),
        function (value) {
          const { documentTypeId } = this.parent;
          const selectedDocumentType = mappedOptions.find(
            (option) => option.id === documentTypeId
          );

          // Check if the selected document type is "OTHER"
          if (selectedDocumentType?.label.toLowerCase() === "other") {
            return !!value; // Document Name is required if "OTHER" is selected
          }
          return true; // If not "OTHER", documentName is not required
        }
      ),
    password: yup.string(),
  });

  useEffect(() => {
    return () => {
      reset();
      dispatch(resetSelectedDocumentPath());
      setFilePreview(null);
      dispatch(setUpdateDocumentDetail(null));
    };
  }, []);

  useEffect(() => {
    if (selectedDocumentPath) {
      setFilePreview(selectedDocumentPath);
      setValue("documentFilePath", selectedDocumentPath, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [selectedDocumentPath]);

  const methods = useForm();
  const {
    handleSubmit,
    formState,
    control,
    reset,
    getValues,
    setValue,
    trigger,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const { errors, isValid, isDirty } = formState;

  useEffect(() => {
    if (documentDetails) {
      setValue("documentTypeId", documentDetails.documentTypeId);
      setValue("documentName", documentDetails?.documentName);
      setFilePreview(documentDetails.documentFilePath);
      setValue("password", documentDetails.password);
    }
  }, [documentDetails]);

  function onSubmit(model) {
    if (documentDetails) {
      const newDocument = {
        id: documentDetails.id,
        documentFilePath: selectedDocumentPath,
        financeApplicationId: documentDetails.financeApplicationId || null,
        userId: documentDetails.userId,
        tenantId: tenantId,
        password: model.password,
        documentName: setIsSelectedDocumentTypeOther
          ? model.documentName
          : null,
      };
      dispatch(UpdateUploadedDocument(newDocument));
    } else {
      const newDocument = {
        ...model,
        userId,
        financeApplicationId,
        tenantId: tenantId,
        documentName: setIsSelectedDocumentTypeOther
          ? model.documentName
          : null,
      };
      dispatch(UploadDocument(newDocument));
    }
  }

  const handleCancel = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    handleClose();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      dispatch(fileUpload(file));
      trigger("documentFilePath");
    }
  };

  const remainingDocumentTypes = documentTypes
    ? documentTypes.filter((docType) => {
        // Check if the document ID is provided and there is a matching document in the list
        if (documentDetails?.id !== null && documentList) {
          const editedDocument = documentList.find(
            (doc) => doc.id === documentDetails?.id
          );
          if (editedDocument) {
            // If the edited document's document type matches the current docType, include it
            return editedDocument.documentTypeId === docType.id;
          }
        }
        // If document ID is not provided or no matching document found, follow the original logic
        return (
          documentList &&
          !documentList.some(
            (doc) =>
              doc.documentTypeId === docType.id &&
              doc.documentStatus !== "Reject"
          )
        );
      })
    : [];

  const otherOption = documentTypes?.find(
    (docType) => docType?.name?.toLowerCase() === "other"
  );
  const isOtherSelected =
    watch("documentTypeId") === (!!otherOption && otherOption?.id);

  if (
    otherOption &&
    !remainingDocumentTypes.some((docType) => docType.id === otherOption.id)
  ) {
    remainingDocumentTypes.push(otherOption);
  }

  const mappedOptions = remainingDocumentTypes.map((docType) => ({
    id: docType.id,
    label: `${docType.name}`,
  }));

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="sm" fullWidth>
      <DialogTitle>{t("UPLOAD_DOCUMENT")}</DialogTitle>
      <DialogContent>
        <div className="mb-10"></div>
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-10"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} className="mb-8">
                <Controller
                  name="documentTypeId"
                  control={control}
                  render={({ field }) => (
                    <CustomAutocomplete
                      label={t("TITLE")}
                      required
                      onChange={(value: any) => {
                        if (value && value.id) {
                          // Check if the selected document type is 'OTHER' (case-insensitive)
                          setIsSelectedDocumentTypeOther(
                            value.label.toLowerCase() === "other"
                          );
                          field.onChange(value.id);
                        } else {
                          field.onChange(null);
                          setIsSelectedDocumentTypeOther(false); // Reset if no selection
                        }
                      }}
                      options={mappedOptions}
                      value={field.value || ""}
                      disabled={!!documentDetails?.documentTypeId}
                      error={
                        errors.documentTypeId &&
                        t(errors.documentTypeId.message)
                      }
                    />
                  )}
                />
              </Grid>

              {/* document name field renders based on the value of the document type selected */}
              {isOtherSelected && (
                <Grid item xs={12} sm={12} md={12} className="mb-8">
                  <Controller
                    name="documentName"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("DOCUMENT_NAME")}
                        onChange={field.onChange}
                        value={field.value || ""}
                        fullWidth
                        isUpperCase={false}
                        required
                        error={
                          errors.documentName && t(errors.documentName.message)
                        }
                      />
                    )}
                  />
                </Grid>
              )}

              {/* Add password field */}
              <Grid item xs={12} className="mb-8">
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      label={t("PASSWORD")}
                      onChange={field.onChange}
                      value={field.value || ""}
                      fullWidth
                      isUpperCase={false}
                      autoComplete="new-password"
                      type={showPassword ? "text" : "password"} // Show password based on state
                      error={errors.password && t(errors.password.message)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                              onMouseDown={(event) => event.preventDefault()}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="documentFilePath"
                  control={control}
                  render={({ field }) => (
                    <>
                      <input
                        required={isEmpty(documentDetails)}
                        type="file"
                        accept={acceptFileTypeImageAndPDF}
                        hidden
                        ref={fileInputRef}
                        onChange={handleFileChange}
                      />
                      <StyledButton
                        onClick={() => fileInputRef.current.click()}
                        className="w-full capitalize"
                      >
                        <StyledSpan>
                          <CloudUploadOutlinedIcon />
                        </StyledSpan>
                        {t("CHOOSE_FILE")}
                        <StyledTypographyGrey
                          variant="body2"
                          className="font-400 italic tracking-wide"
                        >
                          ({t("SUPPORT_FILE")}: {acceptFileTypeImageAndPDF})
                        </StyledTypographyGrey>
                      </StyledButton>
                    </>
                  )}
                />
                {isImageLoading ? (
                  <div className="flex justify-center">
                    <CircularProgress
                      size={24}
                      color="secondary"
                      className="button-progress"
                    />
                  </div>
                ) : (
                  filePreview && (
                    <label htmlFor="file">
                      <div className="flex justify-center object-cover w-full h-200 p-8">
                        <FilePreview fileUrl={filePreview} />
                      </div>
                    </label>
                  )
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <Button onClick={handleClose} className="capitalize">
                      {t("CANCEL")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <ColoredSubmitButton
                      disabled={!isValid || !isDirty}
                      className="capitalize"
                      text={t("UPLOAD_DOCUMENT")}
                      isLoading={isLoading}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default UploadDocumentDialog;

const locale = {
  OTP_SEND_MESSAGE: "We have send verification code on your mobile number:",
  OTP_ERROR_MESSAGE: "OTP is required and must be 6 digits.",
  OTP_SHOULD_BE_NUMERIC: "OTP must be 6 digits numeric value",
  VERIFY_BUTTON: "Verify",
  RESEND_OTP: "Resend OTP",
  RESEND_IN: "Resend In",
  ENTER_OTP: "Enter OTP",
};

export default locale;

/**
 * The authRoles object defines the authorization roles for the Fuse application.
 */
const authRoles = {
	/**
	 * The admin role grants access to users with the 'admin' role.
	 */
	admin: ['admin'],

	/**
	 * The staff role grants access to users with the 'admin' or 'staff' role.
	 */
	staff: ['admin', 'staff'],

	/**
	 * The staff role grants access to users with the 'agent'
	 */
	agent: ['b7db3bac-4347-4c51-a3fa-3215d1b30b75','bcf233e8-dc76-4b02-8f29-d5e6a18d6de3'],
	
	/**
	 * The user role grants access to users with the 'admin', 'staff', or 'user' role.
	 */
	user: ['b24a113f-b85d-4249-8238-4d0c7dc01a43'],

	/**
	 * The onlyGuest role grants access to unauthenticated users.
	 */
	onlyGuest: [],
	public: ['public']
};

export default authRoles;

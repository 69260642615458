import React from "react";
import { Select, MenuItem, FormControl } from "@mui/material";
import { useTranslation } from "react-i18next";

const DateRangeSelect = ({ currentRange, handleChangeRange, ranges }) => {
  const { t } = useTranslation("dashboard");

  return (
    <FormControl variant="standard" sx={{ m: 1 }} size="small" hiddenLabel>
      <Select
        value={currentRange}
        onChange={handleChangeRange}
        className="!leading-none !min-h-0"
        sx={{
          ".MuiInput-input.MuiSelect-select": {
            minHeight: 0,
          },
          ".MuiSelect-icon": {
            top: "-5px",
          },
        }}
      >
        {ranges.map((range) => (
          <MenuItem key={range.value} value={range.value}>
            {t(range.label)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DateRangeSelect;

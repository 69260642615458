import React, { useEffect } from "react";
import { Container } from "@mui/material";
import { StyledTypography } from "app/shared-components/Styles/CustomStyle";
import CommonStepper from "./component/CommonStepper";
import { useTranslation } from "react-i18next";
import withReducer from "app/store/withReducer";
import applicationFormSlice, {
  resetAll,
} from "app/store/application-form/applicationFormSlice";
import { useDispatch } from "react-redux";
import { CommonBreadcrumbs } from "app/shared-components/Breadcrumbs/CommonBreadcrumbs";

function ApplicationForm() {
  const { t } = useTranslation("applicationForm");
  const dispatch: any = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetAll());
    };
  }, []);

  return (
    <Container maxWidth="lg" className="pl-10 md:pl-76 pr-10 md:pr-76 mt-10 mb-10">
      <CommonBreadcrumbs
        pages={[
          {
            to: "/user/my-application",
            name: "Applications",
          }
        ]}
        currentPageName={t("FORM")}
      />
      <CommonStepper />
    </Container>
  );
}

export default withReducer(
  "applicationForm",
  applicationFormSlice.reducer
)(ApplicationForm);

import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
  IconButton,
  Avatar,
  Button,
} from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import history from "@history";
import { formatAmount } from "src/app/services/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  getTopPerformerManagerList,
  getTopPerformerManagerListFromApi,
} from "./store/dashboardSlice";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { isEmpty } from "lodash";
import { useDateRange } from "src/app/services/useDateRange";
import DateRangeSelect from "./DateRangeSelect";

const TopPerformerManagerList = () => {
  const { t } = useTranslation("dashboard");
  const dispatch: any = useDispatch();
  const topPerformerManagerList = useSelector(getTopPerformerManagerList);
  const { getDateRange } = useDateRange();
  const [currentRange, setCurrentRange] = useState("lastWeek");

  const selectRange = [
    { value: "lastYear", label: "LAST_YEAR" },
    { value: "lastMonth", label: "LAST_MONTH" },
    { value: "lastWeek", label: "LAST_WEEK" },
  ];

  // Fetch data whenever the range changes
  useEffect(() => {
    const { startDate } = getDateRange(currentRange); // Get the startDate for the selected range
    dispatch(
      getTopPerformerManagerListFromApi({
        startDate,
        endDate: moment().format("YYYY-MM-DD"), // End date is always the current day
        number: 5,
      })
    );
  }, [dispatch, currentRange]);

  // Handle range change
  const handleChangeRange = (event) => {
    setCurrentRange(event.target.value); // Update the selected range
  };

  return (
    <Paper className="flex flex-col flex-auto px-24 pt-24 pb-10 shadow rounded-2xl overflow-hidden h-full w-full">
      {/* Header with "View All" button */}
      <div className="flex justify-between items-center mb-6">
        <Typography className="text-lg font-medium tracking-tight leading-6">
          {t("TOP_PERFORMER_MANAGERS")}
        </Typography>
        <DateRangeSelect
          currentRange={currentRange}
          handleChangeRange={handleChangeRange}
          ranges={selectRange}
        />
      </div>

      {/* List of Manager Cards */}
      {!isEmpty(topPerformerManagerList) ? (
        <List className="py-0 mt-8 divide-y">
          {topPerformerManagerList?.map((manager) => (
            <ListItem key={manager?.userDetail?.id} className="px-0">
              {/* Avatar and Name/Email */}
              <Avatar className="mr-8" sx={{ bgcolor: "secondary.main" }}>
                {manager?.userDetail?.fullName?.charAt(0)}
              </Avatar>

              <ListItemText
                classes={{ root: "px-8", primary: "font-medium" }}
                primary={`${manager?.userDetail?.fullName} (${manager?.userDetail?.email})`}
                secondary={
                  <span className="flex flex-col sm:flex-row sm:items-center -ml-2 mt-8 sm:mt-4 space-y-4 sm:space-y-0 sm:space-x-12">
                    <Typography component="span" className="text-m">
                      {t("APPLICATIONS")}: {manager.applicationCount}
                    </Typography>
                    <Typography component="span" className="text-md">
                      {t("FINANCED_AMOUNT")}:{" "}
                      {formatAmount(manager.applicationAmount)}
                    </Typography>
                  </span>
                }
              />

              <ListItemSecondaryAction>
                {/* Email icon button */}
                <Tooltip title="View Details">
                  <IconButton
                    edge="end"
                    aria-label="details"
                    onClick={() =>
                      history.push(
                        `/user/mini-agent/${manager?.userDetail?.id}`
                      )
                    }
                  >
                    <ChevronRight />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography
          variant="body1"
          color="textSecondary"
          className="text-center my-20 text-xl"
        >
          {t("NO_DATA_AVAILABLE")}
        </Typography>
      )}
      {!isEmpty(topPerformerManagerList) && (
        <Button
          variant="text"
          color="secondary"
          onClick={() => {
            history.push("/user/mini-agent");
          }}
          className="mt-auto"
        >
          {t("VIEW_ALL")}
        </Button>
      )}
    </Paper>
  );
};

export default TopPerformerManagerList;

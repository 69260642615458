import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import IconButton from "@mui/material/IconButton";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { darken, lighten } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "@lodash";
import ItemIcon from "./ItemIcon";
import {
  deleteDocument,
  getDocumentDetail,
  getFiltersForDocumentType,
  getIsDeleteDialogOpen,
  resetDocumentDetail,
  setIsDeleteDialogOpen,
} from "../Document/store/documentCardSlice";
import { decodeUrlAndGetFileType, hasPermission } from "src/app/services/utils";
import { Visibility } from "@mui/icons-material";
import { VisibilityOff } from "@mui/icons-material";
import FilePreview from "../preview-file/FilePreview";
import ConfirmDialog from "../Dialog/confirmDialog";
import { makeStyles } from "@mui/styles";
import themesConfig from "app/configs/themesConfig";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import {
  getCurrentFinanceApplicationStatus,
  getIsFinanceApplicationApproved,
} from "app/store/user/userSlice";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import StatusChip from "../statusChip/StatusChip";
import EditButton from "../Button/EditButton";

const useStyles = makeStyles(() => ({
  download: {
    backgroundColor: `${themesConfig.default.palette.secondary.main} !important`,
    color: `${themesConfig.default?.palette.common.white} !important`,
    "&:hover": {
      backgroundColor: `${darken(
        themesConfig.default.palette.secondary.main,
        0.2
      )} !important`,
    },
  },
  delete: {
    backgroundColor: themesConfig.default.palette.error.main,
    color: themesConfig.default?.palette.common.white,
    "&:hover": {
      backgroundColor: darken(themesConfig.default.palette.error.main, 0.2),
    },
  },
}));

function DetailSidebarContent(props) {
  const { documentList, onEdit } = props;

  const { t } = useTranslation("documentCard");

  const classes = useStyles();

  const location = useLocation();
  const { pathname } = location;

  const dispatch: any = useDispatch();

  const documentTypeFilter = useSelector(getFiltersForDocumentType);
  const financeApplicationApproved = useSelector(
    getIsFinanceApplicationApproved
  );
  const currentApplicationStatus = useSelector(
    getCurrentFinanceApplicationStatus
  );
  const isDeleteDialogOpen = useSelector(getIsDeleteDialogOpen);
  const selectedDocument = useSelector(getDocumentDetail);
  const document = _.find(documentList, { id: selectedDocument?.id });

  const [showPassword, setShowPassword] = useState(false);
  const [deleteDocumentId, setDeleteDocumentId] = useState(null);

  // PERMISSIONS
  const allowEditDocument = hasPermission(
    FeaturePermissions?.UserDocuments?.Update
  );
  const allowDeleteDocument = hasPermission(
    FeaturePermissions?.UserDocuments?.Delete
  );

  useEffect(() => {
    dispatch(resetDocumentDetail());
  }, [pathname]);

  if (!document) {
    return null;
  }

  // Open delete document dialog
  const openDeleteDialog = (documentId) => {
    setDeleteDocumentId(documentId);
    dispatch(setIsDeleteDialogOpen(true));
  };

  // Close delete document dialog
  const closeDeleteDialog = () => {
    dispatch(setIsDeleteDialogOpen(false));
  };

  const handleDeleteConfirmed = () => {
    const idToSend = {
      financeApplicationId: document?.financeApplicationId,
      userId: document?.userId,
    };
    dispatch(deleteDocument(deleteDocumentId, idToSend));
    closeDeleteDialog();
  };

  const handleEdit = () => {
    if (onEdit) {
      onEdit({ documentDetails: document });
    }
  };
  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      transition={{ duration: 0.4, ease: "easeOut" }}
      animate={{ y: 0, opacity: 1 }}
      className="file-details p-24 sm:p-32"
    >
      <div className="flex items-center justify-between w-full">
        {allowEditDocument &&
          currentApplicationStatus !== "rejected" &&
          !["Approved", "Reject"].includes(document.documentStatus) &&
          !financeApplicationApproved &&
          documentTypeFilter?.type !== "Admin" && (
            <EditButton title={t("EDIT")} handleClick={handleEdit} />
          )}
        {/* Flex item with margin adjustment */}
        <div
          className={
            allowEditDocument &&
            currentApplicationStatus !== "rejected" &&
            !["Approved", "Reject"].includes(document.documentStatus) &&
            !financeApplicationApproved &&
            documentTypeFilter?.type !== "Admin"
              ? "ml-auto"
              : ""
          }
        >
          <IconButton
            size="large"
            onClick={() => dispatch(resetDocumentDetail())}
          >
            <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
          </IconButton>
        </div>
      </div>

      <Box
        className=" w-full rounded-8 border preview h-full sm:h-256 file-icon flex items-center justify-center my-20"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? lighten(theme.palette.background.default, 0.4)
              : lighten(theme.palette.background.default, 0.02),
        }}
      >
        {decodeUrlAndGetFileType(document.documentFilePath) === "pdf" ? (
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1, transition: { delay: 0.3 } }}
          >
            <ItemIcon
              type={decodeUrlAndGetFileType(document.documentFilePath)}
            />
          </motion.div>
        ) : (
          <FilePreview fileUrl={document.documentFilePath} />
        )}
      </Box>

      <div className="flex items-center gap-5">
        <Typography className="text-18 font-medium">
          {document.documentTypeName?.toLowerCase() === "other"
            ? document.documentName || document.documentTypeName
            : document.documentTypeName}
        </Typography>
        <div>
          <StatusChip
            label={document.documentStatus}
            status={
              document.documentStatus === "Reject"
                ? "Rejected"
                : document.documentStatus
            }
            size="small"
          />
        </div>
      </div>

      <div className="text-16 font-medium mt-32">{t("INFORMATION")}</div>
      <div className="flex flex-col mt-16 border-t border-b divide-y font-medium">
        {document.password && (
          <div className="flex items-center justify-between py-12">
            <div className="flex items-center gap-10">
              <Typography color="text.secondary">{t("PASSWORD")}</Typography>
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                size="small"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </div>
            <Typography>
              {showPassword ? document.password : "••••••••"}
            </Typography>
          </div>
        )}
        <div className="flex items-center justify-between py-12">
          <Typography color="text.secondary">{t("UPLOAD_BY")}</Typography>
          <Typography>{document.uploadedBy}</Typography>
        </div>
        <div className="flex items-center justify-between py-12">
          <Typography color="text.secondary">{t("CREATED_AT")}</Typography>
          <Typography>{document.created}</Typography>
        </div>
        <div className="flex items-center justify-between py-12">
          <Typography color="text.secondary">{t("LAST_MODIFIED")}</Typography>
          <Typography>{document.lastModified}</Typography>
        </div>
        {document.documentStatus === "Approved" && document.approvedByUser && (
          <div className="flex items-center justify-between py-12 gap-10">
            <Typography color="text.secondary" className="text-left">
              {t("APPROVED_BY")}
            </Typography>
            <Typography className="text-right">
              {document.approvedByUser.fullName}
            </Typography>
          </div>
        )}
      </div>

      <div className="grid grid-cols-2 gap-16 w-full mt-32">
        <Button
          className={`rounded ${classes.download}`}
          href={document.documentFilePath}
          startIcon={<DownloadIcon />}
          target="_blank" // Opens the link in a new tab
          rel="noopener noreferrer"
        >
          {t("DOWNLOAD")}
        </Button>
        {allowDeleteDocument &&
          currentApplicationStatus !== "rejected" &&
          !["Approved", "Reject"].includes(document.documentStatus) &&
          !financeApplicationApproved &&
          documentTypeFilter?.type !== "Admin" && (
            <Button
              className={`rounded ${classes.delete}`}
              startIcon={<DeleteIcon />}
              onClick={() => {
                openDeleteDialog(document.id);
              }}
            >
              {t("DELETE")}
            </Button>
          )}
      </div>
      {isDeleteDialogOpen &&
        deleteDocumentId === document.id && ( // Conditionally render the delete dialog
          <ConfirmDialog // Add ConfirmDialog component
            open={isDeleteDialogOpen}
            handleClose={closeDeleteDialog}
            handleConfirm={handleDeleteConfirmed}
            title={t("DELETE_DOCUMENT")}
            description={t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_DOCUMENT")}
          />
        )}
    </motion.div>
  );
}

export default DetailSidebarContent;

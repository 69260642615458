import React from "react";
import { hasPermission } from "../utils";
import i18next from "i18next";
import en from "./i18n/en";
import ms from "./i18n/ms";
import { useTranslation } from "react-i18next";
import ErrorMessage from "./ErrorMessage";

i18next.addResourceBundle("en", "Can", en);
i18next.addResourceBundle("ms", "Can", ms);

interface CanProps {
  permission: string;
  children: React.ReactNode;
  errorMessage?: string; // Optional error message to display
}

const Can: React.FC<CanProps> = ({ permission, children, errorMessage }) => {
  const { t } = useTranslation("Can");

  const isAllowed = hasPermission(permission);

  return isAllowed ? (
    <>{children}</>
  ) : errorMessage ? (
    <ErrorMessage errorMessage={errorMessage} />
  ) : null;
};

export default Can;

import React, { useEffect, useState } from "react";
import {Typography,Container,Grid,Tabs,Tab,Box,Divider} from "@mui/material";
import SignIn from "./component/SignIn";
import SignUp from "./component/SignUp";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import { StyledTypography } from "app/shared-components/Styles/CustomStyle";
import { useLocation, useNavigate } from "react-router";

const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.info.light,
  "&.Mui-selected": {
    color: theme.palette.common.white,
  },
}));


const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.common.white,
  },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.primary.light,
}));

const StyledBox = styled(Box)(({theme})=>({
  backgroundColor: theme.palette.secondary.main,
}))


function SignInPage({ tabIndex }) {
  const [value, setValue] = useState(tabIndex);
  const { t } = useTranslation("signInPage");
  const navigate = useNavigate();
  const location = useLocation( );

  useEffect(()=>{
    const path = location.pathname;
    if(path === "/sign-up"){
      setValue(1);
    }else{
      setValue(0);
    }
  },[location.pathname])

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      navigate("/sign-in"); // Navigate to the sign-in route
    } else if (newValue === 1) {
      navigate("/sign-up"); // Navigate to the sign-up route
    }
  };
  
  const switchTab = () => {
    setValue(value === 0 ? 1 : 0);
  };

  return (
    <Container maxWidth="lg" className="md:pl-76 md:pr-76 flex flex-col gap-60">
      <Grid container spacing={3}>
        <Grid item md={6} xs={12} sm={6} className="flex flex-col gap-10">
          <StyledTypography variant="h5" className="pt-24">
            {t("TITLE")}
          </StyledTypography>
          <StyledTypography variant="body2" >
            {t("DESCRIPTION")}
          </StyledTypography>
          <img
            src="assets/images/login/Welcome.svg"
            alt="Welcome"
            className="w-full h-full p-40"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <StyledBox
            className={`h-full w-full pt-14 p-32`}
          >
            <StyledTabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              aria-label="nav tabs example"
              role="navigation"
            >
              <StyledTab label={t("LOGIN")} value={0} />
              <StyledTab label={t("SIGN_UP")} value={1} />
            </StyledTabs>
            <StyledDivider variant="fullWidth" />
            {value === 0 && <SignIn switchTab={switchTab} />}
            {value === 1 && <SignUp switchTab={switchTab} />}
          </StyledBox>
        </Grid>
      </Grid>
    </Container>
  );
}

export default SignInPage;

import React from "react";
import InfoDialog from "app/shared-components/Dialog/InfoDialog";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useTranslation } from "react-i18next";

const ChangeDetectDialog = ({ open, onClose, onButtonClick }) => {
  const { t } = useTranslation("user");
  
  return (
    <InfoDialog
      open={open}
      onClose={onClose}
      color="warning"
      onButtonClick={onButtonClick}
      icon={WarningAmberIcon}
      title={t("CHANGE_DETECT_TITLE")}
      buttonText={t("CHANGE_DETECT_BUTTON")}
    />
  );
};

export default ChangeDetectDialog;

import QRCode from "react-qr-code";
import { Box, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import themesConfig from "app/configs/themesConfig";
import ColoredSubmitButton from "../Button/ColoredSubmitButton";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import en from "./i18n/en";
import ms from "./i18n/ms";

i18next.addResourceBundle("en", "qr-code", en);
i18next.addResourceBundle("ms", "qr-code", ms);

const QRCodeComponent = ({ link, download = null }) => {
  const { t } = useTranslation("qr-code");

  return (
    <Box mt={3} maxWidth={250}>
      {link ? (
        <>
          <div className="flex flex-col gap-10 items-center">
            <Box className="p-10 border rounded-2 border-grey-500">
              <QRCode
                value={link}
                size={220}
                fgColor={themesConfig.default.palette.secondary.main}
              />
            </Box>

            {/* Download Button */}
            {download && (
              <ColoredSubmitButton
                color="primary"
                startIcon={<DownloadIcon />}
                onClick={download}
                text={t("DOWNLOAD_QR_CODE")}
              />
            )}
          </div>
        </>
      ) : (
        <Typography variant="body1" color="error">
          {t("QR_CODE_NOT_AVAILABLE")}
        </Typography>
      )}
    </Box>
  );
};

export default QRCodeComponent;

import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import UpdatePersonalInfoForm from "../form/UpdatePersonalInfoForm";
import AdditionalInfoForm from "../form/AdditionalInfoForm";
import AddressForm from "../form/AddressForm";
import { useEffect } from "react";
import {
  StyledStepConnector,
  StyledStepper,
} from "app/shared-components/Styles/CustomStyle";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import { hasPermission } from "src/app/services/utils";
import { getCurrentStepForMemberAdd, setCurrentStepForMember } from "app/store/user/userSlice";
import { addFormStep } from "src/app/common/constant";

const AddMemberStepper = ({ isUseForFinance = false }) => {
  const { t } = useTranslation("user");
  const dispatch: any = useDispatch();
  const activeStep = useSelector(getCurrentStepForMemberAdd);

  // For reset current step when exit this page
  useEffect(() => {
    return () => {
      dispatch(setCurrentStepForMember(0));
    };
  }, []);

  const steps = [
    {
      label: addFormStep.PERSONAL_INFO,
      content: <UpdatePersonalInfoForm {...{ isUseForFinance }} />,
      permission: FeaturePermissions.User.Create,
    },
    {
      label: addFormStep.ADDITIONAL_INFORMATION,
      content: <AdditionalInfoForm />,
      permission: FeaturePermissions.UserAdditionalInformation.Create,
    },
    {
      label: addFormStep.ADDRESS_INFO,
      content: <AddressForm {...{ showFinishButton: true, isUseForFinance }} />,
      permission: FeaturePermissions.UserAddress.Create,
    },
  ];

  const filteredSteps = steps?.filter((step) => hasPermission(step.permission));

  return (
    <div className="p-16">
      <StyledStepper
        activeStep={activeStep}
        orientation="vertical"
        connector={<StyledStepConnector />}
      >
        {filteredSteps?.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>{t(step.label)}</StepLabel>
            <StepContent>{step.content}</StepContent>
          </Step>
        ))}
      </StyledStepper>
    </div>
  );
};

export default AddMemberStepper;

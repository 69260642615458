import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface ConfirmDialogProps {
  open: boolean;
  title: string;
  description?: string;
  cancelText?: string;
  okText?: string;
  handleClose: () => void;
  handleConfirm: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  title,
  description,
  cancelText,
  okText,
  handleClose,
  handleConfirm,
}) => {

  const handleDialogClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      {description && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleClose} className="capitalize">
          {cancelText || "Cancel"}
        </Button>
        <Button onClick={handleConfirm} autoFocus className="capitalize">
          {okText || "Ok"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;

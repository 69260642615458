import moment from "moment";

export const useDateRange = () => {
  // Define your date ranges
  const dateRanges = {
    lastWeek: "LAST_WEEK",
    lastMonth: "LAST_MONTH",
    lastYear: "LAST_YEAR",
  };

  // Get the startDate based on the selected tab
  const getDateRange = (range: string) => {
    const today = moment(); // Current date for endDate
    switch (range) {
      case "lastWeek":
        return {
          startDate: today.subtract(1, "week").format("YYYY-MM-DD"),
        };
      case "lastMonth":
        return {
          startDate: today.subtract(1, "month").format("YYYY-MM-DD"),
        };
      case "lastYear":
        return {
          startDate: today.subtract(1, "year").format("YYYY-MM-DD"),
        };
      default:
        return {
          startDate: today.format("YYYY-MM-DD"),
        };
    }
  };

  return {
    getDateRange,
    dateRanges,
  };
};

const locale = {
  LOAN_AMOUNT: "Jumlah Pinjaman",
  NUMBER_OF_YEAR: "Bilangan Tahun",
  INTEREST_RATE: "Kadar Faedah",
  MONTHLY_PAYMENT: "Bayaran Bulanan",
  TITLE: "Bayaran Bulanan Anda",
  DESCRIPTION: "Perhitungan Asas",
  CALCULATE_BUTTON: "Kira",
  GET_STARTED_BUTTON: "Mulakan",
};

export default locale;

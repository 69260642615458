import React, { useEffect, useState } from "react";
import {
  StyledTypography,
  StyledButton,
} from "app/shared-components/Styles/CustomStyle";
import { Grid, Box, Button } from "@mui/material";
import CustomTextField from "app/shared-components/Form/CustomTextField";
import CustomDatePicker from "app/shared-components/Form/CustomDatePicker";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getCurrentStep,
  getIncomeDetails,
  getIncomeDetailsData,
  selectIsButtonLoading,
  setCurrentStep,
  updateUserIncomeDetailsInForm,
  userIncomeDetailsInForm,
} from "app/store/application-form/applicationFormSlice";
import { tenantId } from "src/app/common/constant";
import moment from "moment";
import ColoredSubmitButton from "app/shared-components/Button/ColoredSubmitButton";

export default function IncomeDetails({
  financeApplicationId = null,
  isEdit = false,
  setIsDirty = (value) => {},
}) {
  const { t } = useTranslation("applicationForm");
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const activeStep = useSelector(getCurrentStep);
  const [search] = useSearchParams();
  const FinanceId = search.get("id") || null;
  const IncomeDetails = useSelector(getIncomeDetailsData);
  const isLoading = useSelector(selectIsButtonLoading);
  const userId = search.get("userId") || null;

  const schema = yup.object().shape({
    basicSalary: yup
      .number()
      .required(t("TEXT_FIELD_REQUIRED_VALIDATION", {fieldName: t("BASIC_SALARY")}))
      .typeError(t("NUMBER_INVALID_VALIDATION")),
    salaryIncrement: yup
      .number()
      .nullable()
      .transform((value, originalValue) => (originalValue === "" ? null : value))
      .typeError(t("NUMBER_INVALID_VALIDATION")),
    fixedAllowances: yup
      .number()
      .nullable()
      .transform((value, originalValue) => (originalValue === "" ? null : value))
      .typeError(t("NUMBER_INVALID_VALIDATION")),
    dateOfIncrement: yup
      .date()
      .nullable()
      .typeError("VALID_DATE_VALIDATION")
      .min(moment("1900-01-01", "YYYY-MM-DD").toDate(), "DATE_PAST_VALIDATION"),
    totalDeduction: yup
      .number()
      .required(t("TEXT_FIELD_REQUIRED_VALIDATION", {fieldName: t("TOTAL_DEDUCTION")}))
      .typeError(t("NUMBER_INVALID_VALIDATION")),
    netSalary: yup
      .number()
      .required(t("TEXT_FIELD_REQUIRED_VALIDATION", {fieldName: t("NET_SALARY")}))
      .typeError(t("NUMBER_INVALID_VALIDATION")),
    remarks: yup.string(),
  });

  useEffect(() => {
    const idToUse = FinanceId || financeApplicationId;
    if (idToUse) {
      dispatch(getIncomeDetails(idToUse));
    }
  }, [dispatch, FinanceId, financeApplicationId]);

  const methods = useForm();
  const { handleSubmit, formState, control, reset, getValues, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    });
  const { errors, isValid, isDirty } = formState;

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty, setIsDirty]);

  useEffect(() => {
    setValue("basicSalary", IncomeDetails?.basicSalary);
    setValue("salaryIncrement", IncomeDetails?.salaryIncrement || null);
    setValue("fixedAllowances", IncomeDetails?.fixedAllowances || null);
    setValue("netSalary", IncomeDetails?.netSalary);
    setValue("totalDeduction", IncomeDetails?.totalDeduction);

    const incrementDate = IncomeDetails?.dateOfIncrement
      ? moment(IncomeDetails?.dateOfIncrement, "DD/MMM/YYYY").toDate()
      : null;
    setValue("dateOfIncrement", incrementDate || null);
    setValue("remarks", IncomeDetails?.remarks);
    reset(getValues());
  }, [dispatch, setValue, IncomeDetails]);

  function onSubmit(model, event) {
    event.preventDefault();

    const request_data = {
      ...model,
      tenantId: tenantId,
      financeApplicationId: FinanceId || financeApplicationId,
      // dateOfIncrement: model.dateOfIncrement.toISOString(),
    };

    const update_data = {
      ...model,
      id: IncomeDetails?.id,
      // dateOfIncrement: model.dateOfIncrement.toISOString(),
    };

    if (
      Object.keys(IncomeDetails).length !== 0 &&
      IncomeDetails?.isDefault !== true
    ) {
      dispatch(
        updateUserIncomeDetailsInForm(
          update_data,
          IncomeDetails?.financeApplicationId,
          isEdit
        )
      );
    } else {
      dispatch(userIncomeDetailsInForm(request_data, isEdit));
    }
  }

  const handleBack = () => {
    dispatch(setCurrentStep(activeStep - 1));
    navigate(
      `/application-form/${activeStep - 1}?userId=${userId}&id=${FinanceId}`
    );
  };
  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-10"
          data-test-id="income-detail-form"
        >
          <StyledTypography variant="body1" className="mt-20">
            {t("INCOME_DETAILS")}
          </StyledTypography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} className="mb-8">
                  <Controller
                    name="basicSalary"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("BASIC_SALARY")}
                        fullWidth
                        required
                        onChange={field.onChange}
                        value={field.value || ""}
                        error={
                          errors.basicSalary && t(errors.basicSalary.message)
                        }
                        inputProps={{
                          "data-test-id": "income-detail-basic-salary",
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="mb-8">
                  <Controller
                    name="salaryIncrement"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("SALARY_INCREMENT")}
                        onChange={field.onChange}
                        value={field.value || ""}
                        fullWidth
                        // required
                        error={
                          errors.salaryIncrement &&
                          t(errors.salaryIncrement.message)
                        }
                        inputProps={{
                          "data-test-id": "income-detail-salary-increment",
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} className="mb-8">
                  <Controller
                    name="fixedAllowances"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("FIXED_ALLOWANCES")}
                        fullWidth
                        onChange={field.onChange}
                        value={field.value || ""}
                        error={
                          errors.fixedAllowances &&
                          t(errors.fixedAllowances.message)
                        }
                        inputProps={{
                          "data-test-id": "income-detail-fixed-allowance",
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="mb-8">
                  <Controller
                    name="dateOfIncrement"
                    control={control}
                    render={({ field }) => (
                      <CustomDatePicker
                        label={t("INCREMENT_DATE")}
                        onChange={field.onChange}
                        value={field.value}
                        // required
                        error={
                          errors.dateOfIncrement &&
                          t(errors.dateOfIncrement.message)
                        }
                        datatestid="income-detail-date-of-increment"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <StyledTypography variant="body1" className="mt-20">
            {t("SALARY_DETAIL")}
          </StyledTypography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} className="mb-8">
              <Controller
                name="totalDeduction"
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    label={t("TOTAL_DEDUCTION")}
                    fullWidth
                    required
                    onChange={field.onChange}
                    value={field.value || ""}
                    error={
                      errors.totalDeduction && t(errors.totalDeduction.message)
                    }
                    inputProps={{
                      "data-test-id": "income-detail-total-deduction",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="mb-8">
              <Controller
                name="netSalary"
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    label={t("NET_SALARY")}
                    onChange={field.onChange}
                    value={field.value || ""}
                    fullWidth
                    required
                    error={errors.netSalary && t(errors.netSalary.message)}
                    inputProps={{
                      "data-test-id": "income-detail-net-salary",
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <StyledTypography variant="body1" className="mt-20">
            {t("REMARK")}
          </StyledTypography>
          <Grid container spacing={2}>
            <Grid item xs={12} className="mb-8">
              <Controller
                name="remarks"
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    label={t("REMARK")}
                    fullWidth
                    multiline
                    rows={4}
                    onChange={field.onChange}
                    value={field.value || ""}
                    error={errors.remarks && t(errors.remarks.message)}
                    inputProps={{
                      "data-test-id": "income-detail-remarks",
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box>
            <Box className="flex justify-end items-end gap-20 mt-10">
              {isEdit ? (
                <ColoredSubmitButton
                  className="w-120"
                  disabled={!isValid}
                  text={t("SAVE")}
                  isLoading={isLoading}
                  data-test-id="income-detail-submit-btn"
                ></ColoredSubmitButton>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    className="capitalize w-120"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {t("PREVIOUS")}
                  </Button>
                  <ColoredSubmitButton
                    className="w-120"
                    disabled={!isValid}
                    text={t("NEXT")}
                    isLoading={isLoading}
                    data-test-id="income-detail-submit-btn"
                  ></ColoredSubmitButton>
                </>
              )}
            </Box>
          </Box>
        </form>
      </FormProvider>
    </>
  );
}

import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import DetailItem from "app/shared-components/DetailItem/DetailItem";
import { StyledTypography } from "app/shared-components/Styles/CustomStyle";
import CommonAccordion from "app/shared-components/Accordion/CommonAccordion";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsPersonalDetailDialogOpen,
  setPersonalDetailDialogOpen,
} from "app/store/application-form/applicationFormSlice";
import { useParams } from "react-router";
import PersonalInformation from "src/app/main/application-form/component/PersonalInformation";
import CustomDialog from "app/shared-components/Dialog/customDialog";
import ChangeDetectDialog from "../form/ChangeDetectDialog";
import EmailLink from "app/shared-components/Link/EmailLink";
import PhoneNumberLink from "app/shared-components/Link/PhoneNumberLink";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import { isEmpty } from "lodash";
import { getCurrentFinanceApplicationStatus } from "app/store/user/userSlice";

const PersonalInfoAccordion = ({ personalDetails, remarksList, financeUserId = null }) => {
  const { t } = useTranslation("user");
  const dispatch: any = useDispatch();
  const isOpen = useSelector(getIsPersonalDetailDialogOpen);
  const currentFinanceStatus = useSelector(getCurrentFinanceApplicationStatus);
  const { applicationId } = useParams<{ applicationId: string }>();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const handleClose = () => {
    dispatch(setPersonalDetailDialogOpen(false));
  };
  const handleEditSectionOpen = () => {
    dispatch(setPersonalDetailDialogOpen(true));
  };

  const handleDialogClose = () => {
    if (isDirty) {
      setConfirmOpen(true);
    } else {
      handleClose();
    }
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    handleClose();
  };

  const nameWithTitle = personalDetails?.title + personalDetails?.fullName;

  const personalInfoContent = (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={9}>
          <DetailItem label={t("NAME")} value={nameWithTitle} />
        </Grid>

        <Grid item xs={12} sm={3} md={3}>
          <DetailItem label={t("SEX")} value={personalDetails?.sex} />
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <DetailItem
            label={t("DATE_OF_BIRTH")}
            value={personalDetails?.dateOfBirth}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <DetailItem label={t("RELIGION")} value={personalDetails?.religion} />
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <DetailItem label={t("RACE")} value={personalDetails?.race} />
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <DetailItem
            label={t("MARITAL_STATUS")}
            value={personalDetails?.maritalStatus}
          />
        </Grid>
      </Grid>
      <StyledTypography variant="body1" className="mt-32 mb-6 text-lg font-600">
        {t("OTHER_DETAILS")}
      </StyledTypography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DetailItem
            label={t("MOTHER_FULL_NAME")}
            value={personalDetails?.motherFullName}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <DetailItem label={t("JOB_TYPE")} value={personalDetails?.jobType} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DetailItem
            label={t("JOB_UNIQUE_CODE")}
            value={personalDetails?.jobUniqueCode}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DetailItem
            label={t("NATIONAL_ID")}
            value={personalDetails?.nationalId}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DetailItem label={t("OTHER_ID")} value={personalDetails?.otherId} />
        </Grid>
      </Grid>
      <StyledTypography variant="body1" className="mt-32 mb-6 text-lg font-600">
        {t("CONTACT_DETAILS")}
      </StyledTypography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <DetailItem
            label={t("MOBILE_NUMBER")}
            value={
              <PhoneNumberLink
                phoneNumber={personalDetails?.phoneNumber}
              ></PhoneNumberLink>
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <DetailItem
            label={t("HOME_PHONE_NUMBER")}
            value={
              <PhoneNumberLink
                phoneNumber={personalDetails?.hpNumber}
              ></PhoneNumberLink>
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <DetailItem
            label={t("PERSONAL_EMAIL")}
            value={
              <EmailLink
                email={personalDetails?.personalEmailAddress}
              ></EmailLink>
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <DetailItem
            label={t("WORK_EMAIL")}
            value={
              <EmailLink email={personalDetails?.workEmailAddress}></EmailLink>
            }
          />
        </Grid>
      </Grid>
      <StyledTypography variant="body1" className="mt-32 mb-6 text-lg font-600">
        {t("QUALIFICATION")}
      </StyledTypography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={3}>
          <DetailItem
            label={t("HIGHEST_QUALIFICATION")}
            value={personalDetails?.highestQualification}
          />
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      <CommonAccordion
        title={t("PERSONAL_INFO")}
        content={personalInfoContent}
        remarksList={remarksList}
        isApproved={personalDetails?.isApproved}
        handleEditOpen={handleEditSectionOpen}
        permission={FeaturePermissions.UserAdditionalInformation.Update}
        defaultExpanded={
          currentFinanceStatus === "draft" && isEmpty(personalDetails)
        }
      />
      <CustomDialog
        open={isOpen}
        onClose={handleDialogClose}
        content={{
          title: t("PERSONAL_INFO"),
          component: (
            <PersonalInformation
              financeApplicationId={applicationId}
              financeUserId={financeUserId}
              isEdit={true}
              setIsDirty={setIsDirty}
            />
          ), // Personal Info component is passed as content
        }}
      ></CustomDialog>
      <ChangeDetectDialog
        open={confirmOpen}
        onClose={handleConfirmClose}
        onButtonClick={() => setConfirmOpen(false)}
      />
    </>
  );
};

export default PersonalInfoAccordion;

import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import DetailItem from "app/shared-components/DetailItem/DetailItem";
import { StyledTypography } from "app/shared-components/Styles/CustomStyle";
import CommonAccordion from "app/shared-components/Accordion/CommonAccordion";
import CustomDialog from "app/shared-components/Dialog/customDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsNextOfKinDetailEditDialogOpen,
  setNextOfKinDetailEditDialogOpen,
} from "app/store/application-form/applicationFormSlice";
import { useParams } from "react-router";
import ChangeDetectDialog from "../form/ChangeDetectDialog";
import NextOfKinDetails from "src/app/main/application-form/component/NextOfKinDetails";
import EmailLink from "app/shared-components/Link/EmailLink";
import PhoneNumberLink from "app/shared-components/Link/PhoneNumberLink";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import { isEmpty } from "lodash";
import { getCurrentFinanceApplicationStatus } from "app/store/user/userSlice";

const NextOfKinDetailAccordion = ({ nextOfKinDetail, remarksList }) => {
  const { t } = useTranslation("user");

  const dispatch: any = useDispatch();
  const isOpen = useSelector(getIsNextOfKinDetailEditDialogOpen);
  const currentFinanceStatus = useSelector(getCurrentFinanceApplicationStatus);
  const { applicationId } = useParams<{ applicationId: string }>();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const handleClose = () => {
    dispatch(setNextOfKinDetailEditDialogOpen(false));
  };
  const handleEditSectionOpen = () => {
    dispatch(setNextOfKinDetailEditDialogOpen(true));
  };

  const handleDialogClose = () => {
    if (isDirty) {
      setConfirmOpen(true);
    } else {
      handleClose();
    }
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    handleClose();
  };

  const nameWithTitle = nextOfKinDetail?.title + nextOfKinDetail?.name;

  const nextOfKinDetailContent = (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <DetailItem label={t("NAME")} value={nameWithTitle} />
        </Grid>

        <Grid item xs={12} sm={3} md={3}>
          <DetailItem label={t("SEX")} value={nextOfKinDetail?.sex} />
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <DetailItem
            label={t("DATE_OF_BIRTH")}
            value={nextOfKinDetail?.dateOfBirth}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <DetailItem
            label={t("NATIONAL_ID")}
            value={nextOfKinDetail?.nationalId}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <DetailItem
            label={t("RELATIONSHIP")}
            value={nextOfKinDetail?.relationship}
          />
        </Grid>
      </Grid>
      <StyledTypography variant="body1" className="mt-32 mb-6 text-lg font-600">
        {t("CONTACT_DETAILS")}
      </StyledTypography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <DetailItem
            label={t("MOBILE_NUMBER")}
            value={
              <PhoneNumberLink
                phoneNumber={nextOfKinDetail?.homeMobileNumber}
              ></PhoneNumberLink>
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <DetailItem
            label={t("HOME_PHONE_NUMBER")}
            value={
              <PhoneNumberLink
                phoneNumber={nextOfKinDetail?.homePhoneNumber}
              ></PhoneNumberLink>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <DetailItem
            label={t("PERSONAL_EMAIL")}
            value={
              <EmailLink email={nextOfKinDetail?.emailAddress}></EmailLink>
            }
          />
        </Grid>
      </Grid>
      <StyledTypography variant="body1" className="mt-32 mb-6 text-lg font-600">
        {t("ADDRESS")}
      </StyledTypography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DetailItem
            label={t("HOUSE_ADDRESS")}
            value={nextOfKinDetail?.houseAddress}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <DetailItem
            label={t("HOUSE_CITY")}
            value={nextOfKinDetail?.houseCity}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DetailItem
            label={t("HOUSE_STATE")}
            value={nextOfKinDetail?.houseState}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <DetailItem
            label={t("POST_CODE")}
            value={nextOfKinDetail?.housePostalCode}
          />
        </Grid>
      </Grid>
    </>
  );
  return (
    <>
      <CommonAccordion
        title={t("DETAILS_0F_NEXT_OF_KIN")}
        content={nextOfKinDetailContent}
        isApproved={nextOfKinDetail?.isApproved}
        remarksList={remarksList}
        handleEditOpen={handleEditSectionOpen}
        defaultExpanded={currentFinanceStatus === 'draft' && isEmpty(nextOfKinDetail)}
        permission={FeaturePermissions.UserNextofkin.Update}
      />

      <CustomDialog
        open={isOpen}
        onClose={handleDialogClose}
        content={{
          title: t("DETAILS_0F_NEXT_OF_KIN"),
          component: (
            <NextOfKinDetails
              financeApplicationId={applicationId}
              isEdit={true}
              setIsDirty={setIsDirty}
            />
          ),
        }}
      ></CustomDialog>
      <ChangeDetectDialog
        open={confirmOpen}
        onClose={handleConfirmClose}
        onButtonClick={() => setConfirmOpen(false)}
      />
    </>
  );
};

export default NextOfKinDetailAccordion;

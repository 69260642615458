import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import UpdatePersonalInfoForm from "../form/UpdatePersonalInfoForm";
import { Container, Paper } from "@mui/material";
import {
  getCurrentStep,
  setCurrentStep,
} from "app/store/application-form/applicationFormSlice";
import { useEffect } from "react";
import {
  StyledStepConnector,
  StyledStepper,
  StyledTypography,
} from "app/shared-components/Styles/CustomStyle";
import { CommonBreadcrumbs } from "app/shared-components/Breadcrumbs/CommonBreadcrumbs";
import Can from "src/app/services/FeaturePermission/Can";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import { hasPermission } from "src/app/services/utils";
import {
  getMemberUserId,
  setDocumentsList,
  setMemberUserId,
} from "app/store/user/userSlice";
import DocumentPage from "app/shared-components/Document/DocumentPage";
import { addFormStep, documentSelectType } from "src/app/common/constant";

export default function AddMiniAgent() {
  const { t } = useTranslation("user");
  const dispatch: any = useDispatch();
  const activeStep = useSelector(getCurrentStep);
  const id = useSelector(getMemberUserId);

  // For reset current step when exit this page
  useEffect(() => {
    return () => {
      dispatch(setCurrentStep(0));
      dispatch(setMemberUserId(null));
      dispatch(setDocumentsList(null));
    };
  }, []);
  const miniAgent = true;
  const steps = [
    {
      label: addFormStep.PERSONAL_INFO,
      content: <UpdatePersonalInfoForm {...{ miniAgent }} />,
      permission: FeaturePermissions.UserDocuments.Create,
    },
    {
      label: addFormStep.UPLOAD_DOCUMENT,
      content: (
        <Paper className="mb-20 px-10" variant="outlined">
          <DocumentPage
            {...{
              showFinishButton: true,
              pageToRedirect: "agent",
              documentType: documentSelectType.agent,
              userId: id,
            }}
          />
        </Paper>
      ),
      permission: FeaturePermissions.UserDocuments.Create,
    },
  ];

  const filteredSteps = steps?.filter((step) => hasPermission(step.permission));

  return (
    <Can
      permission={FeaturePermissions.MiniAgent.Create}
      errorMessage="ACCESS_DENIED_MINI_AGENT"
    >
      <Container maxWidth="lg" className="pl-10 md:pl-76 pr-10 md:pr-76 mb-64 mt-10">
        <CommonBreadcrumbs
          pages={[
            {
              to: "/user/mini-agent",
              name: t("MINI_AGENT"),
            },
          ]}
          currentPageName={t("ADD_MINI_AGENT")}
        />
        <div className="p-16">
          <StyledTypography variant="h5" className="mb-20 mt-20">
            {t("ADD_MINI_AGENT")}
          </StyledTypography>
          <Box>
            <StyledStepper
              activeStep={activeStep}
              orientation="vertical"
              connector={<StyledStepConnector />}
            >
              {filteredSteps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel>{t(step.label)}</StepLabel>
                  <StepContent>{step.content}</StepContent>
                </Step>
              ))}
            </StyledStepper>
          </Box>
        </div>
      </Container>
    </Can>
  );
}

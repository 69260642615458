import React from "react";
import { memo } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { format } from "date-fns";
import ReactTableSimple from "../ReactTable/ReactTableSimple"; // Assuming you have this component imported properly
import { formatAmount } from "src/app/services/utils";
import StatusChip from "../statusChip/StatusChip";
import history from "@history";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

// Mock data for transactions (replace this with actual data from Redux when available)
const mockTransactionData = [];

function RecentTransactionsWidget() {
  const transactionData = mockTransactionData;
  const { t } = useTranslation("dashboard");

  // Define table columns for ReactTableSimple
  const columns = React.useMemo<any[]>(
    () => [
      {
        header: "Transaction ID",
        accessorKey: "id",
        cell: (value) => <div>{value.getValue()}</div>,
      },
      {
        header: "Date",
        accessorKey: "date",
        cell: (value) => (
          <div>{format(new Date(value.getValue()), "MMM dd, yyyy")}</div>
        ),
      },
      {
        header: "Amount",
        accessorKey: "amount",
        cell: (value) => <div>{formatAmount(value.getValue())}</div>,
      },
      {
        header: "Status",
        accessorKey: "status",
        cell: (value) => {
          const status = value.getValue();
          return <StatusChip label={status} status={status} />;
        },
      },
    ],
    []
  );

  return (
    <Paper className="flex flex-col flex-auto px-24 py-20 shadow rounded-2xl overflow-hidden h-full w-full">
      <div className="flex justify-between items-center h-32">
        <Typography className="text-lg font-medium tracking-tight leading-6">
          {t("RECENT_TRANSACTIONS")}
        </Typography>
        {!isEmpty(transactionData) && (
          <Button
            variant="text"
            color="secondary"
            onClick={() => history.push("/user/all-transactions")}
          >
            {t("VIEW_ALL")}
          </Button>
        )}
      </div>
      {!isEmpty(transactionData) ? (
        <>
          {/* Render the table */}
          <ReactTableSimple columns={columns} data={transactionData} />
        </>
      ) : (
        <Typography
          variant="body1"
          color="textSecondary"
          className="text-center my-20 text-xl"
        >
          {t("NO_TRANSACTIONS_AVAILABLE")}
        </Typography>
      )}
    </Paper>
  );
}

export default memo(RecentTransactionsWidget);

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import api from "src/app/services/apiService";

interface WidgetData {
  totalFinancedAmount: {
    today: number;
    lastWeek: number;
    lastMonth: number;
    total: number;
  };
  totalCustomers: {
    today: number;
    lastWeek: number;
    lastMonth: number;
    total: number;
  };
  totalApplications: {
    today: number;
    lastWeek: number;
    lastMonth: number;
    total: number;
  };
}

interface DashboardState {
  widgetData: WidgetData | null;
  allNotification: any;
  latestFinanceApplication: any;
  inProgressFinanceApplication: any;
  activeFinanceDetail: any;
  managerApplicationCount: any;
  topPerformerManagerList: any;
  actionRequiredRemarksList: any;

  loading: {
    latestFinanceApplicationLoading: boolean;
    isProgressFinanceApplicationLoading: boolean;
  };
}

// Widget Data API
export const getWidgetDataFromApi = () => async (dispatch, getState) => {
  try {
    const response: any = await api.get("/v1/Dashboard/DashboardCountForAgent");
    if (response && response.result) {
      dispatch(setWidgetData(response.result));
    }
  } catch (error) {
    console.error(error);
  }
};

export const getLatestFinanceApplication = () => async (dispatch, getState) => {
  dispatch(setIsLatestFinanceApplicationLoading(true));
  try {
    const response: any = await api.get(
      "/v1/Dashboard/LatestFinanceApplication"
    );
    if (response && response.result) {
      dispatch(setLatestFinanceApplication(response.result));
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(setIsLatestFinanceApplicationLoading(false));
  }
};

export const getInProgressFinanceApplication =
  () => async (dispatch, getState) => {
    dispatch(setIsInProgressFinanceApplicationLoading(true));
    try {
      const response: any = await api.get(
        "/v1/Dashboard/InProgressApplication"
      );
      if (response && response.result) {
        dispatch(setInProgressFinanceApplication(response.result));
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setIsInProgressFinanceApplicationLoading(false));
    }
  };

export const getManagerApplicationCountListFromApi =
  (request_data) => async (dispatch, getState) => {
    try {
      const response: any = await api.post(
        "/v1/Dashboard/ManagerApplicationCount",
        request_data
      );
      if (response && response.result) {
        dispatch(setManagerApplicationCountList(response.result));
      }
    } catch (error) {
      console.error(error);
    }
  };

export const getTopPerformerManagerListFromApi =
  (request_data) => async (dispatch, getState) => {
    try {
      const response: any = await api.post(
        "/v1/Dashboard/TopPerformerManager",
        request_data
      );
      if (response && response.result) {
        dispatch(setTopPerformerManagerList(response.result));
      }
    } catch (error) {
      console.error(error);
    }
  };

export const getActionRequiredRemarksListFromApi =
  (request_data) => async (dispatch, getState) => {
    try {
      const response: any = await api.get(
        `/v1/Dashboard/ActionRequiredRemarksList?number=${request_data?.number}`
      );
      if (response && response.result) {
        dispatch(setActionRequiredRemarksList(response.result));
      }
    } catch (error) {
      console.error(error);
    }
  };

// Active Finance Application
export const getActiveFinanceDetailFromApi =
  () => async (dispatch, getState) => {
    try {
      const response: any = await api.get(
        `/v1/FinanceApplication/GetActiveFinanceDetail`
      );
      if (response && response.result) {
        dispatch(setActiveFinanceDetail(response.result));
      }
    } catch (error) {
      console.error(error);
    }
  };

export const getAllDashboardNotificationFromApi =
  () => async (dispatch, getState) => {
    try {
      const response: any = await api.get(`/v1/Dashboard/NotificationsList`);
      if (response && response.result) {
        dispatch(
          setAllDashboardNotificationList(response.result.notifications)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  export const dashboardNotificationReadAsMark =
  (notificationId = "") =>
  async (dispatch) => {
    try {
      const response = await api.put(
        `/v1/UserNotification/MarkNotificationAsRead`,
        notificationId ? { id: notificationId } : {}
      );
      dispatch(getAllDashboardNotificationFromApi());
    } catch (error) {
      console.error(error);
    }
  };

const initialState: DashboardState = {
  widgetData: {
    totalFinancedAmount: {
      today: 0,
      lastWeek: 0,
      lastMonth: 0,
      total: 0,
    },
    totalCustomers: { today: 0, lastWeek: 0, lastMonth: 0, total: 0 },
    totalApplications: { today: 0, lastWeek: 0, lastMonth: 0, total: 0 },
  },
  allNotification: null,
  latestFinanceApplication: [],
  inProgressFinanceApplication: [],
  activeFinanceDetail: null,
  managerApplicationCount: null,
  topPerformerManagerList: null,
  actionRequiredRemarksList: null,
  loading: {
    latestFinanceApplicationLoading: false,
    isProgressFinanceApplicationLoading: false,
  },
};

//  GET STATE
export const getWidgetData = (state) => state.dashboard.widgetData;

export const getActiveFinanceDetail = (state) =>
  state.dashboard.activeFinanceDetail;
export const getLatestFinanceApplicationList = (state) =>
  state.dashboard.latestFinanceApplication;
export const getInProgressFinanceApplicationList = (state) =>
  state.dashboard.inProgressFinanceApplication;
export const getTopPerformerManagerList = (state) =>
  state.dashboard.topPerformerManagerList;
export const getManagerApplicationCountList = (state) =>
  state.dashboard.managerApplicationCount;
export const getActionRequiredRemarksList = (state) =>
  state.dashboard.actionRequiredRemarksList;
export const getAllDashboardNotificationList = (state) =>
  state.dashboard.allNotification;

// Loading selectors start
const setLoadingState = (key, value) => async (dispatch) => {
  dispatch(setLoading({ key, value }));
};
export const setIsLatestFinanceApplicationLoading = (value) =>
  setLoadingState("latestFinanceApplicationLoading", value);
export const setIsInProgressFinanceApplicationLoading = (value) =>
  setLoadingState("isProgressFinanceApplicationLoading", value);

export const getLoadingStateFactory = (loader) => (state) => {
  return state.dashboard.loading[loader] || false;
};
export const getIsLatestFinanceApplicationLoading = getLoadingStateFactory(
  "latestFinanceApplicationLoading"
);
export const getIsInProgressFinanceApplicationLoading = getLoadingStateFactory(
  "isProgressFinanceApplicationLoading"
);
// Loading selectors end

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setWidgetData: (state, action: PayloadAction<WidgetData>) => {
      state.widgetData = action.payload;
    },
    setLatestFinanceApplication: (state, action: PayloadAction<any>) => {
      state.latestFinanceApplication = action.payload;
    },
    setInProgressFinanceApplication: (state, action: PayloadAction<any>) => {
      state.inProgressFinanceApplication = action.payload;
    },
    setActiveFinanceDetail: (state, action: PayloadAction<any>) => {
      state.activeFinanceDetail = action.payload;
    },
    setManagerApplicationCountList: (state, action: PayloadAction<any>) => {
      state.managerApplicationCount = action.payload;
    },
    setTopPerformerManagerList: (state, action: PayloadAction<any>) => {
      state.topPerformerManagerList = action.payload;
    },
    setActionRequiredRemarksList: (state, action: PayloadAction<any>) => {
      state.actionRequiredRemarksList = action.payload;
    },
    setAllDashboardNotificationList: (state, action: PayloadAction<any>) => {
      state.allNotification = action.payload;
    },
    setLoading: (state, action) => {
      const { key, value } = action.payload;
      state.loading = {
        ...state.loading,
        [key]: value,
      };
    },
  },
});

export const {
  setLoading,
  setActiveFinanceDetail,
  setLatestFinanceApplication,
  setInProgressFinanceApplication,
  setWidgetData,
  setManagerApplicationCountList,
  setActionRequiredRemarksList,
  setTopPerformerManagerList,
  setAllDashboardNotificationList,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;

import React from "react";
import QRCode from "react-qr-code";
import { Box, Typography } from "@mui/material";
import themesConfig from "app/configs/themesConfig";
import { useTranslation } from "react-i18next";

interface AgentQRBusinessCardProps {
  link: string;
  userData: any;
}

const AgentQRBusinessCard = React.forwardRef<
  HTMLDivElement,
  AgentQRBusinessCardProps
>(({ link, userData }, ref) => {
  const { t } = useTranslation("user");

  return (
    <div ref={ref} className="p-6 bg-white flex flex-col items-center gap-4">
      <Box className="w-full flex justify-center">
        <img
          className="w-40 h-24"
          src="assets/images/logo/logo.svg"
          alt="logo"
        />
      </Box>

      <div className="flex flex-col items-center justify-center text-center">
        <p className="text-md font-bold">{userData?.fullName}</p>
      </div>

      <div className="flex flex-col items-center gap-4">
        <Typography variant="body2" className="text-sm text-center">
          {t("REFERRAL_INSTRUCTIONS")}
        </Typography>
        <Box className="p-4 border rounded border-gray-500">
          <QRCode
            value={link}
            size={180}
            fgColor={themesConfig.default.palette.secondary.main}
          />
        </Box>
      </div>

      <div className="flex flex-col items-center justify-center text-center">
        <p className="text-sm font-bold">{t("REFERRAL_CODE")}</p>
        <p className="text-md font-bold">{userData?.userUniqueCode}</p>
      </div>
    </div>
  );
});

export default AgentQRBusinessCard;

import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { MoreVert } from "@mui/icons-material";

interface MenuItem {
  label: string;
  onClick: () => void;
}

interface TableMenuProps {
  menuItems: MenuItem[];
}

const CommonTableMenu: React.FC<TableMenuProps> = ({ menuItems }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openMenu = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
    {menuItems.length > 0 &&
      <IconButton
        aria-controls="action-menu"
        aria-haspopup="true"
        onClick={openMenu}
        disableRipple
      >
        <MoreVert color="secondary" />
      </IconButton>
      }
      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              item.onClick();
              closeMenu();
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default CommonTableMenu;

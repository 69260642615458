const locale = {
  CONTACT_US_TITLE: "Hubungi Kami",
  CONTACT_US_DESCRIPTION:
    "Berhubung dengan pasukan perkhidmatan kewangan dan keahlian kami! Sama ada anda mempunyai pertanyaan tentang tawaran kami, memerlukan bantuan dengan keahlian anda atau ingin membincangkan hal kewangan, kami di sini untuk membantu anda. Hubungi kami menggunakan borang di bawah atau melalui e-mel. Pasukan kami komited untuk memberikan respons yang tepat pada masanya dan membantu untuk semua pertanyaan. Terima kasih kerana menghubungi anda, dan kami teruja untuk mendengar daripada anda!",
  LETS_TALK: "Mari Berbincang",
  FULL_NAME: "Nama Penuh",
  EMAIL_ADDRESS: "Alamat Emel",
  MESSAGE: "Mesej",
  SEND_BUTTON: "Hantar",
  GET_IN_TOUCH: "Berhubunglah",
  CONTACT_INFORMATION: "Maklumat Hubungan",
  LOCAL_PHONE: "(Tempatan & Luar Negara)",
  HUNTING_PHONE: "(Talian Pemburu)",
  FULLNAME_REQUIRED: "Nama Penuh diperlukan",
  EMAIL_REQUIRED: "E-mel diperlukan",
  EMAIL_FORMAT_VALIDATION: "Format e-mel tidak sah",
  MESSAGE_REQUIRED: "Mesej diperlukan",
  PHONE_NUMBER: "Nombor Telefon"
};

export default locale;

import i18next from 'i18next';
import { lazy } from 'react';
import en from './i18n/en';
import ms from './i18n/ms';
import { authRoles } from 'src/app/auth';

i18next.addResourceBundle('en', 'homePage', en);
i18next.addResourceBundle('ms', 'homePage', ms);

const Home = lazy(() => import('./Home'));

/**
 * The Home page config.
 */
const HomeConfig = {
	settings: {
		layout: {
		}
	},
	auth: authRoles.public,
	routes: [
		{
			path: 'home',
			element: <Home />,
		}
	]
};

export default HomeConfig;

import { useState } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import DateRangeSelect from "./DateRangeSelect";

function WidgetItem({ data }) {
  const { t } = useTranslation("dashboard");
  const [currentRange, setCurrentRange] = useState("today");

  const handleChangeRange = (ev) => {
    setCurrentRange(ev.target.value);
  };

  const getFontSize = (value) => {
    const length = value ? value.toString().length : 0;
    if (length > 10) return "text-xl sm:text-2xl my-10";
    if (length > 6) return "text-2xl sm:text-3xl my-8";
    return "text-5xl sm:text-6xl";
  };

  const displayValue = () => {
    switch (currentRange) {
      case "total":
        return data?.total;
      case "lastWeek":
        return data?.lastWeek;
      case "lastMonth":
        return data?.lastMonth;
      case "today":
        return data?.today;
      default:
        return "";
    }
  };

  const displayedValue = displayValue();
  const fontSizeClass = getFontSize(displayedValue);

  const selectRange = [
    { value: "total", label: "TOTAL" },
    { value: "lastMonth", label: "LAST_MONTH" },
    { value: "lastWeek", label: "LAST_WEEK" },
    { value: "today", label: "TODAY" },
  ];

  return (
    <Paper className="flex flex-col w-full shadow rounded-2xl overflow-hidden">
      <div className="flex items-center justify-between px-12 pt-12">
        <DateRangeSelect
          currentRange={currentRange}
          handleChangeRange={handleChangeRange}
          ranges={selectRange}
        />
      </div>
      <div className="text-center mt-8">
        <Typography
          className={`font-bold tracking-tight leading-none ${fontSizeClass} ${data?.color}`}
        >
          {displayedValue}
        </Typography>
      </div>
      <Typography
        className={`text-md mt-5 pb-40 text-center whitespace-nowrap font-medium ${data?.color}`}
        color="textSecondary"
      >
        {data?.title}
      </Typography>
    </Paper>
  );
}

export default WidgetItem;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Grid, Box, Card, lighten, Tabs, Tab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import history from "@history";
import { isEmpty } from "lodash";
import { documentSelectType } from "src/app/common/constant";
import DocumentCardDemo from "app/shared-components/Card/DocumentCardDemo";
import { hasPermission } from "src/app/services/utils";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import {
  getDocumentList,
  getDocumentTypeList,
  getFiltersForDocumentType,
  getUserDocumentsById,
  setDocumentTypeFilters,
  setIsDocumentDialogOpen,
} from "./store/documentCardSlice";
import {
  getCurrentFinanceApplicationStatus,
  getIsFinanceApplicationApproved,
} from "app/store/user/userSlice";
import ColoredSubmitButton from "../Button/ColoredSubmitButton";
import RemarkDialog from "../Dialog/RemarkDialog";
import { StyledTypography } from "../Styles/CustomStyle";

function documentTabProps(index: number) {
  return {
    id: `document-tab-${index}`,
    "aria-controls": `document-tabpanel-${index}`,
  };
}

function UploadDocuments({
  showFinishButton,
  pageToRedirect,
  financeApplicationId = null,
  type = null,
  remarksList = null,
  userId = null,
}) {
  const { t } = useTranslation("documentCard");
  const dispatch: any = useDispatch();
  const documentList = useSelector(getDocumentList);
  const currentApplicationStatus = useSelector(
    getCurrentFinanceApplicationStatus
  );
  const financeApplicationApproved = useSelector(
    getIsFinanceApplicationApproved
  );
  const documentTypeFilter = useSelector(getFiltersForDocumentType);
  const [tabValue, setTabValue] = useState(0);

  const addDocumentPermission = hasPermission(
    FeaturePermissions?.UserDocuments?.Create
  );

  // get document type list
  useEffect(() => {
    if (!!financeApplicationId) {
      // Set the filters based on tabValue when financeApplicationId is present
      dispatch(
        setDocumentTypeFilters({
          type:
            tabValue === 0
              ? documentSelectType.finance
              : documentSelectType.admin,
        })
      );
    } else {
      // Set the filters based on the provided 'type' when financeApplicationId is not present
      dispatch(setDocumentTypeFilters({ type: type }));
      dispatch(getDocumentTypeList());
    }
  }, [tabValue, financeApplicationId, type, dispatch]);

  useEffect(() => {
    if (!!financeApplicationId) {
      dispatch(
        getUserDocumentsById({
          financeApplicationId: financeApplicationId,
        })
      );
    } else if (!!userId) {
      dispatch(
        getUserDocumentsById({
          userId: userId,
        })
      );
    }
  }, [financeApplicationId, userId, tabValue]);

  // open document upload dialog
  const openDialog = () => {
    dispatch(setIsDocumentDialogOpen(true));
  };

  const handleNext = () => {
    if (pageToRedirect === "member") {
      history.replace("/user/member");
    } else if (pageToRedirect === "agent") {
      history.replace("/user/mini-agent");
    }
  };

  // document card
  const renderDocuments = () => {
    if (
      isEmpty(documentList) &&
      (!addDocumentPermission ||
        currentApplicationStatus === "rejected" ||
        financeApplicationApproved ||
        documentTypeFilter?.type === "Admin")
    ) {
      return (
        <div className="flex justify-center items-center h-full min-h-[160px]">
          {t("NO_DOCUMENTS_FOUND")}
        </div>
      ); // Message when no documents and no permission
    }

    return documentList?.map((document, index) => (
      <DocumentCardDemo key={index} document={document} />
    ));
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <div className="px-20 h-full">
        {!!financeApplicationId ? (
          <div className="flex justify-between items-center mb-10">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                textColor="secondary"
                indicatorColor="secondary"
                variant="scrollable"
                allowScrollButtonsMobile
                scrollButtons="auto"
              >
                <Tab label={t("TAB_MEMBER")} {...documentTabProps(0)} />
                <Tab label={t("TAB_FINANCE_DOCS")} {...documentTabProps(1)} />
              </Tabs>
            </Box>
            {!!remarksList && (
              <RemarkDialog
                sectionTitle={t("DOCUMENTS")}
                remarksList={remarksList}
              />
            )}
          </div>
        ) :
          <StyledTypography variant="h5" className="py-10">
            {t("DOCUMENTS")}
          </StyledTypography>
        }
        <Box
          className="p-16 w-full rounded-16 mb-24 border"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? lighten(theme.palette.background.default, 0.4)
                : lighten(theme.palette.background.default, 0.02),
          }}
        >
          <div>
            <Grid
              container
              spacing={2}
              justifyContent={
                isEmpty(documentList) &&
                  (!addDocumentPermission ||
                    currentApplicationStatus === "rejected" ||
                    financeApplicationApproved ||
                    documentTypeFilter?.type === "Admin")
                  ? "center"
                  : "flex-start"
              }
            >
              {renderDocuments()}
              {addDocumentPermission &&
                currentApplicationStatus !== "rejected" &&
                !financeApplicationApproved &&
                documentTypeFilter?.type !== "Admin" && (
                  <Card
                    variant="outlined"
                    onClick={openDialog}
                    className="flex flex-col justify-center items-center border-1 border-dotted border-grey-500 relative w-full sm:w-160 h-160 m-8 p-16 rounded-16 cursor-pointer"
                  >
                    <AddIcon fontSize="medium" />
                  </Card>
                )}
            </Grid>
          </div>
          <div></div>
          {/* Navigation Buttons */}
          {showFinishButton && (
            <Box className="mt-10">
              <div>
                <ColoredSubmitButton
                  onClick={handleNext}
                  disabled={false}
                  isLoading={false}
                  text={t("FINISH")}
                />
              </div>
            </Box>
          )}
        </Box>
      </div>
    </>
  );
}

export default UploadDocuments;

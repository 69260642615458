import { StyledTypography } from "app/shared-components/Styles/CustomStyle";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const CenteredMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ErrorMessage = ({ errorMessage }) => {
  const { t } = useTranslation("Can");

  return (
    <CenteredMessage>
      <StyledTypography variant="h6">{t(errorMessage)}</StyledTypography>
    </CenteredMessage>
  );
};

export default ErrorMessage;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "src/app/services/apiService";
import { showMessage } from "app/store/fuse/messageSlice";

interface productState {
  categoryList: any;
  productList: any;
  productListPageData: any;
  productDetail: Record<string, any>;
  productListPagination:{
    pageIndex: number;
    pageSize: number;
    totalCount: number;
  }
  searchKey: string;
  loading: {
    productListLoading: boolean;
  };
}

export const getCategory = () => async (dispatch) => {
  try {
    const response: any = await api.get(`/v1/Category`);
    const CategoryList = response.result.items.map((item) => ({
      id: item.id,
      name: item.categoryName,
    }));
    if (response && response.result) {
      dispatch(setCategoryList(CategoryList));
    }
  } catch (error) {
    console.error(error);
  }
};

export const getProduct =
  (module = "") =>
  async (dispatch, getState) => {
    dispatch(setProductListLoading(true));
    try {
      const pagination = getProductListPaginationData(getState()); 
      const existingProcucts = getProductListPageData(getState()); // full list
      const searchKeyword = getSearchKey(getState());
      const queryParams = [
        pagination.pageSize && `PageSize=${pagination.pageSize}`,
        pagination.pageIndex && `PageIndex=${pagination.pageIndex + 1}`,
        searchKeyword && `Search=${searchKeyword}`
      ]
        .filter((param) => param)
        .join("&");

      const response: any = await api.get(
        `/v1/Product?${queryParams}`
      );
      if (response && response.result) {
        dispatch(setProductList(response.result.items))
          const totalProduct = existingProcucts
            ? [...existingProcucts, ...response.result.items]
            : response.result.items;
          if(module === "listPage"){
            dispatch(
              setProductListPagination({
                pageIndex: response.result.page - 1,
                totalCount: response.result.totalCount,
                pageSize: response.result.pageSize,
              })
            );
            dispatch(setProductListPageData(totalProduct));
          }
      }
    } catch (error) {
      console.error(error);
      dispatch(showMessage({ message: error.message, variant: "error" }));
    } finally {
      dispatch(setProductListLoading(false));
    }
  };


export const getProductDetail = (id: string) => async (dispatch) => {
  try {
    const response: any = await api.get(`/v1/Product/${id}`);
    dispatch(setProductDetail(response.result));
  } catch (error) {
    console.error(error);
  }
};

export const getCategoryList = (state) => state.product.categoryList;
export const getProductList = (state) => state.product.productList;
export const getSearchKey = (state) => state.product.searchKey;
export const getProductDetailData = (state) => state.product.productDetail;
export const getProductListPaginationData = (state) =>
  state.product.productListPagination;
export const getProductListPageData = (state) => state.product.productListPageData;

// Loading selectors start
const setLoadingState = (key, value) => async (dispatch) => {
  dispatch(setLoading({ key, value }));
};
export const setProductListLoading = (value) =>
  setLoadingState("productListLoading", value);

export const getLoadingStateFactory = (loader) => (state) => {
  return state.product.loading[loader] || false;
};

export const getProductListLoading =
  getLoadingStateFactory("productListLoading");
// Loading selectors end

const initialState: productState = {
  categoryList: [],
  productDetail: {},
  productList: [],
  productListPageData: [],
  productListPagination: {
    pageIndex: 0,
    pageSize: 15,
    totalCount: 0,
  },
  searchKey: "",
  loading: {
    productListLoading: false,
  },
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setCategoryList: (state, action: PayloadAction<any[]>) => {
      state.categoryList = action.payload;
    },
    setProductList: (state, action: PayloadAction<any[]>) => {
      state.productList = action.payload;
    },
    setSearchKey: (state, action: PayloadAction<string>) => {
      state.searchKey = action.payload;
    },
    setProductDetail: (state, action: PayloadAction<Record<string, any>>) => {
      state.productDetail = action.payload;
    },
    setProductListPageData: (state, action: PayloadAction<any>) => {
      state.productListPageData = action.payload;
    },
    setProductListPagination: (state, action: PayloadAction<any>) => {
      state.productListPagination = {
        ...state.productListPagination,
        ...action.payload,
      };
    },
    // New reset action to reset state to initial state
    resetProductState: (state) => {
      return initialState;
    },
    setLoading: (state, action) => {
      const { key, value } = action.payload;
      state.loading = {
        ...state.loading,
        [key]: value,
      };
    },
  },
});

export const { setCategoryList, setProductList, setLoading, setSearchKey, setProductDetail, setProductListPageData, setProductListPagination, resetProductState} =
  productSlice.actions;
export default productSlice.reducer;

import { FuseRouteConfigType } from "@fuse/utils/FuseUtils";
import authRoles from "../../auth/authRoles";
import i18next from "i18next";
import en from "./i18n/en";
import ms from "./i18n/ms";
import ApplicationForm from "./ApplicationForm";
import PersonalInformation from "./component/PersonalInformation";
import AddressInformation from "./component/AddressInformation";
import SiblingDetails from "./component/SiblingDetails";
import EmploymentDetails from "./component/EmploymentDetails";
import IncomeDetails from "./component/IncomeDetails";
import ExistingLoans from "./component/ExistingLoans";
import BankDetails from "./component/BankDetails";
import ProductDetails from "./component/ProductDetails";
import UploadDocument from "./component/UploadDocument";
import ReferralDetails from "./component/ReferralDetails";
import NextOfKinDetails from "./component/NextOfKinDetails";

i18next.addResourceBundle("en", "applicationForm", en);
i18next.addResourceBundle("ms", "applicationForm", ms);

const ApplicationFormConfig: FuseRouteConfigType = {
  settings: {
    layout: {},
  },
  auth: authRoles.admin,
  routes: [
    {
      path: "application-form/*",
      element: <ApplicationForm />, 
      children: [
        {
          path: "0",
          element: <ProductDetails/>,
        },
        {
          path: "1",
          element: <PersonalInformation/>,
        },
        {
          path: "2",
          element: <AddressInformation/>,
        },
        {
          path: "3",
          element: <SiblingDetails/>,
        },
        {
          path: "4",
          element: <ReferralDetails/>,
        },
        {
          path: "5",
          element: <NextOfKinDetails/>,
        },
        {
          path: "6",
          element: <EmploymentDetails/>,
        },
        {
          path: "7",
          element: <IncomeDetails />,
        },
        {
          path: "8",
          element: <ExistingLoans/>,
        },
        {
          path: "9",
          element: <BankDetails/>,
        },
        {
          path: "10",
          element: <UploadDocument/>,
        },
      ],
    },
  ],
};

export default ApplicationFormConfig;

import { FuseRouteConfigType } from "@fuse/utils/FuseUtils";
import authRoles from "../../auth/authRoles";
import i18next from "i18next";
import en from "./i18n/en";
import ms from "./i18n/ms";
import PrivacyPolicy from "./PrivacyPolicy";

i18next.addResourceBundle("en", "privacyPolicy", en);
i18next.addResourceBundle("ms", "privacyPolicy", ms);

const PrivacyPolicyConfig: FuseRouteConfigType = {
  settings: {
    layout: {},
  },
  auth: authRoles.public,
  routes: [
    {
      path: "privacy-policy",
      element: <PrivacyPolicy />,
    },
  ],
};

export default PrivacyPolicyConfig;

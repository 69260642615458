import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import DetailItem from "app/shared-components/DetailItem/DetailItem";
import { StyledTypography } from "app/shared-components/Styles/CustomStyle";
import CommonAccordion from "app/shared-components/Accordion/CommonAccordion";
import CustomDialog from "app/shared-components/Dialog/customDialog";
import AddressInformation from "src/app/main/application-form/component/AddressInformation";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsAddressEditDialogOpen,
  setAddressEditDialogOpen,
} from "app/store/application-form/applicationFormSlice";
import { useParams } from "react-router";
import ChangeDetectDialog from "../form/ChangeDetectDialog";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import { isEmpty } from "lodash";
import { getCurrentFinanceApplicationStatus } from "app/store/user/userSlice";

export default function AddressInfoAccordion({ addressDetail, remarksList }) {
  const { t } = useTranslation("user");
  const dispatch: any = useDispatch();
  const isOpen = useSelector(getIsAddressEditDialogOpen);
  const currentFinanceStatus = useSelector(getCurrentFinanceApplicationStatus);
  const { applicationId } = useParams<{ applicationId: string }>();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const addressContent = (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DetailItem
            label={t("HOUSE_ADDRESS")}
            value={addressDetail?.houseAddress}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DetailItem
            label={t("HOUSE_CITY")}
            value={addressDetail?.houseCity}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <DetailItem
            label={t("HOUSE_STATE")}
            value={addressDetail?.houseState}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DetailItem
            label={t("POST_CODE")}
            value={addressDetail?.housePostalCode}
          />
        </Grid>
      </Grid>
      <StyledTypography variant="body1" className="mt-32 mb-6 text-lg font-600">
        {t("MAILING_ADDRESS")}
      </StyledTypography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DetailItem
            label={t("MAILING_ADDRESS")}
            value={addressDetail?.mailingAddress}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <DetailItem
            label={t("MAILING_CITY")}
            value={addressDetail?.mailingCity}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DetailItem
            label={t("MAILING_STATE")}
            value={addressDetail?.mailingState}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <DetailItem
            label={t("POST_CODE")}
            value={addressDetail?.mailingPostalCode}
          />
        </Grid>

        <Grid item xs={12} sm={4} className="mt-10 mb-6">
          <DetailItem
            label={t("RESIDENTIAL_LEVEL")}
            value={addressDetail?.houseStatus}
          />
        </Grid>
      </Grid>
    </>
  );

  const handleEditSectionOpen = () => {
    dispatch(setAddressEditDialogOpen(true));
  };

  const handleDialogClose = () => {
    if (isDirty) {
      setConfirmOpen(true);
    } else {
      handleClose();
    }
  };

  const handleClose = () => {
    dispatch(setAddressEditDialogOpen(false));
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    handleClose();
  };

  return (
    <>
      <CommonAccordion
        title={t("ADDRESS_INFO")}
        content={addressContent}
        remarksList={remarksList}
        isApproved={addressDetail?.isApproved}
        handleEditOpen={handleEditSectionOpen}
        permission={FeaturePermissions.UserAddress.Update}
        defaultExpanded={currentFinanceStatus === "draft" && isEmpty(addressDetail)}
      />
      <CustomDialog
        open={isOpen}
        onClose={handleDialogClose}
        content={{
          title: t("ADDRESS_INFO"),
          component: (
            <AddressInformation
              financeApplicationId={applicationId}
              isEdit={true}
              setIsDirty={setIsDirty}
            />
          ), // AddressForm component is passed as content
        }}
      ></CustomDialog>
      <ChangeDetectDialog
        open={confirmOpen}
        onClose={handleConfirmClose}
        onButtonClick={() => setConfirmOpen(false)}
      />
    </>
  );
}

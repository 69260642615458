import { ApexOptions } from "apexcharts";
import themesConfig from "app/configs/themesConfig";
import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  getManagerApplicationCountList,
  getManagerApplicationCountListFromApi,
} from "./store/dashboardSlice";
import CustomTabs from "./CustomTabs";

const ManagerApplicationChart = () => {
  const dispatch: any = useDispatch();
  const { t } = useTranslation("dashboard");
  const topPerformerManagerList = useSelector(getManagerApplicationCountList);

  // Fetch data whenever the date range changes
  const handleTabChange = (startDate: string) => {
    dispatch(
      getManagerApplicationCountListFromApi({
        startDate: startDate,
        endDate: moment().format("YYYY-MM-DD"),
        number: 10
      })
    );
  };

  const managerData = topPerformerManagerList?.manager || [];

  const categories = managerData.map(
    (manager) => manager.managerName || "Unknown Manager"
  );

  const totalApplicationsData = managerData.map(
    (manager) => manager.totalApplications
  );

  const approvedApplicationsData = managerData.map(
    (manager) => manager.approvedApplications
  );

  const series = [
    {
      name: t("TOTAL_APPLICATION"),
      data: totalApplicationsData,
    },
    {
      name: t("APPROVED_APPLICATION"),
      data: approvedApplicationsData,
    },
  ];

  const options: ApexOptions = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      title: {
        text: t("APPLICATION_COUNT"),
      },
      min: 0,
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return `${val} ${t("APPLICATIONS")}`;
        },
      },
    },
    colors: [
      themesConfig.default.palette.secondary.main,
      themesConfig.status.statusColors.approved.backgroundColor,
    ],
    legend: {
      position: "top",
    },
    noData: {
      text: t("NO_DATA_AVAILABLE"),
    },
  };

  return (
    <div className="w-full mx-auto px-24 pb-24 pt-10 bg-white rounded-2xl shadow-md">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
        <Typography className="mr-16 text-2xl md:text-3xl font-semibold tracking-tight leading-7">
          {t("APPROVED_AND_TOTAL_APPLICATION")}
        </Typography>
        <CustomTabs onTabChange={handleTabChange} />
      </div>

      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default ManagerApplicationChart;

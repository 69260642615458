import { lazy } from "react";
import authRoles from "../../auth/authRoles";
import i18next from "i18next";
import en from "./i18n/en";
import ms from "./i18n/ms";
import AddressInfo from "./component/AddressInfo";
import MyApplication from "./component/MyApplication";
import MemberListTable from "./component/member/MemberListTable";
import AddMember from "./component/member/AddMember";
import MemberDetailPage from "./component/member/MemberDetailPage";
import PersonalDetailPage from "./component/PersonalDetailPage";
import MiniAgentListTable from "./component/mini-Agent/MiniAgentListTable";
import AddMiniAgent from "./component/mini-Agent/AddMiniAgent";
import MiniAgentDetailPage from "./component/mini-Agent/MiniAgentDetailPage";
import KYCProcess from "./component/finance-detail-page/e-KYC/KYCProcess";
import Dashboard from "./component/UserDashboard/Dashboard";

i18next.addResourceBundle("en", "user", en);
i18next.addResourceBundle("ms", "user", ms);

const User = lazy(() => import("./User"));
const MyApplicationDetail = lazy(
  () => import("./component/finance-detail-page/MyApplicationDetail")
);
const UserConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.admin,
  routes: [
    {
      path: "user/*",
      element: <User />,
      children: [
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "personal-info",
          element: <PersonalDetailPage />,
        },
        {
          path: "address-information",
          element: <AddressInfo />,
        },
        {
          path: "my-application/*",
          element: <MyApplication />,
        },
        {
          path: "my-application/:applicationId",
          element: <MyApplicationDetail />,
        },
        {
          path: "my-application-kyc/:applicationId",
          element: <KYCProcess />,
        },
        {
          path: "member",
          element: <MemberListTable />,
        },
        {
          path: "member/add-member",
          element: <AddMember />,
        },
        {
          path: "member/:id",
          element: <MemberDetailPage />,
        },
        {
          path: "mini-agent",
          element: <MiniAgentListTable />,
        },
        {
          path: "mini-agent/add-mini-agent",
          element: <AddMiniAgent />,
        },
        {
          path: "mini-agent/:id",
          element: <MiniAgentDetailPage />,
        },
      ],
    },
  ],
};

export default UserConfig;

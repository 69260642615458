import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import ColoredSubmitButton from "app/shared-components/Button/ColoredSubmitButton";
import { CheckCircle, Cancel } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { StyledTypography } from "app/shared-components/Styles/CustomStyle";
import { useSelector } from "react-redux";
import { getIseKYCStartLoading } from "app/store/application-form/applicationFormSlice";

const GuidelineSection = ({
  title,
  items,
  isDo = true,
}: {
  title: string;
  items: Array<{ do: string; dont: string }>;
  isDo?: boolean;
}) => (
  <Box>
    <StyledTypography variant="h6" className="font-semibold text-xl mb-8">
      {title}
    </StyledTypography>
    <ul className="list-disc pl-5">
      {items.map((item, index) => (
        <li key={index} className="text-lg mb-2 flex items-start gap-10">
          {isDo ? (
            <CheckCircle className="mr-2 text-green-600" />
          ) : (
            <Cancel className="mr-2 text-red-600" />
          )}
          <span>{isDo ? item.do : item.dont}</span>
        </li>
      ))}
    </ul>
  </Box>
);

const KYCGuideline = ({ startKYC }) => {
  const { t } = useTranslation("user");
  const isLoading = useSelector(getIseKYCStartLoading);

  const icItems = t("DO_AND_DONT_FOR_FONT_BACK_BEST", {
    returnObjects: true,
  }) as Array<{ do: string; dont: string }>;

  const selfieItems = t("DO_AND_DONT_FOR_IMAGE_BEST", {
    returnObjects: true,
  }) as Array<{ do: string; dont: string }>;

  return (
    <Box className="mt-5 px-5">
      <Typography variant="h5" className="mt-5 text-2xl">
        {t("HOW_IT_WORK")}
      </Typography>

      <Typography variant="h6" className="font-normal mb-10 text-xl">
        {t("IDENTITY")}
      </Typography>

      <div className="flex gap-40 w-full justify-around">
        <div className="text-center">
          <img
            src="assets/images/KYC/font.svg"
            className="w-144 h-144"
            alt="Front IC"
          />
          <Typography variant="body1" className="mt-2 text-lg">
            {t("FRONT_IC")}
          </Typography>
        </div>
        <div className="text-center">
          <img
            src="assets/images/KYC/back.svg"
            className="w-144 h-144"
            alt="Back IC"
          />
          <Typography variant="body1" className="mt-2 text-lg">
            {t("BACK_IC")}
          </Typography>
        </div>
        <div className="text-center">
          <img
            src="assets/images/KYC/selfie.svg"
            className="w-144 h-144"
            alt="Selfie"
          />
          <Typography variant="body1" className="mt-2 text-lg">
            {t("SELFIE")}
          </Typography>
        </div>
      </div>

      <Grid container spacing={5} className="mt-8">
        <Grid item xs={12} md={6}>
          <GuidelineSection
            title={`${t("FONT_BACK_IC")} - ${t("DOS")}`}
            items={icItems}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <GuidelineSection
            title={`${t("FONT_BACK_IC")} - ${t("DONTS")}`}
            items={icItems}
            isDo={false}
          />
        </Grid>
      </Grid>
      <Divider className="my-20" />
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <GuidelineSection
            title={`${t("SELFIE")} - ${t("DOS")}`}
            items={selfieItems}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <GuidelineSection
            title={`${t("SELFIE")} - ${t("DONTS")}`}
            items={selfieItems}
            isDo={false}
          />
        </Grid>
      </Grid>

      <div className="flex justify-end my-16">
        <ColoredSubmitButton
          text={t("START_KYC")}
          onClick={startKYC}
          isLoading={isLoading}
        />
      </div>
    </Box>
  );
};

export default KYCGuideline;

import React, { useEffect } from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getActionRequiredRemarksList,
  getActionRequiredRemarksListFromApi,
} from "./store/dashboardSlice";
import history from "@history";
import { isEmpty } from "lodash";
import { formatDate } from "src/app/services/utils";

const ActionRequiredRemarksList = () => {
  const { t } = useTranslation("dashboard");
  const dispatch: any = useDispatch();
  const remarksList = useSelector(getActionRequiredRemarksList);

  useEffect(() => {
    dispatch(getActionRequiredRemarksListFromApi({ number: 5 }));
  }, [dispatch]);

  return (
    <Paper className="flex flex-col flex-auto px-24 py-20 shadow rounded-2xl overflow-hidden h-full w-full">
      <div className="flex justify-between items-center h-32">
        <Typography className="text-lg font-medium tracking-tight leading-6">
          {t("ACTION_REQUIRED_REMARK")}
        </Typography>
      </div>
      <div className="space-y-4 flex flex-col gap-8 py-10">
        {isEmpty(remarksList) ? (
          <Typography
            variant="body1"
            color="textSecondary"
            className="text-center my-20 text-xl"
          >
            {t("NO_DATA_AVAILABLE")}
          </Typography>
        ) : (
          remarksList?.map((remark) => (
            <Box
              key={remark.id}
              className="flex items-center justify-between gap-10 p-8 rounded-md shadow-sm hover:shadow-md transition-shadow cursor-pointer"
              sx={{
                backgroundColor: (theme) => theme.palette.secondary.light,
              }}
              onClick={() =>
                history.push(
                  `/user/my-application/${remark.financeApplicationId}`
                )
              }
            >
              <div>
                {remark.remark && (
                  <div
                    className="text-justify mr-5 line-clamp-3 overflow-hidden text-ellipsis"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: remark.remark,
                    }}
                  />
                )}
              </div>
              <div>
                <p className="text-sm text-gray-600">
                  {formatDate(remark.created)}
                </p>
              </div>
            </Box>
          ))
        )}
      </div>
    </Paper>
  );
};

export default ActionRequiredRemarksList;

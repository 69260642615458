import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import themesConfig from "app/configs/themesConfig";
import PreviewMedia from "app/shared-components/Dialog/PreviewMedia";
import FilePreview from "app/shared-components/preview-file/FilePreview";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import { formatAmount, hasPermission } from "src/app/services/utils";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CommonTableMenu from "app/shared-components/TableMenu/CommonTableMenu";
import Can from "src/app/services/FeaturePermission/Can";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUserExistingFinance,
  getIsExistingLoanDetailEditDialogOpen,
  setExistingLoanDetailDialogOpen,
} from "app/store/application-form/applicationFormSlice";
import CustomDialog from "app/shared-components/Dialog/customDialog";
import ChangeDetectDialog from "../form/ChangeDetectDialog";
import ExistingFinanceForm from "src/app/main/application-form/component/ExistingFinanceForm";
import ConfirmDialog from "app/shared-components/Dialog/confirmDialog";
import { getCurrentFinanceApplicationStatus } from "app/store/user/userSlice";
import CustomTooltip from "app/shared-components/Tooltip/CustomTooltip";
import { CustomTableBodyCell, CustomTableHeaderCell } from "app/shared-components/Styles/CustomStyle";

const ExistingFinanceTable = ({
  financeDetail,
  allApproved = false,
  financeId = null,
  isEdit = false,
}) => {
  const { t } = useTranslation("user");
  const dispatch: any = useDispatch();
  const isOpen = useSelector(getIsExistingLoanDetailEditDialogOpen);
  const currentFinanceStatus = useSelector(getCurrentFinanceApplicationStatus);
  const { applicationId } = useParams<{ applicationId: string }>();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [updateFinanceDetail, setUpdateFinanceDetail] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const hasPermissionToUpdate = hasPermission(
    FeaturePermissions.UserExistingFinance.Update
  );
  const hasPermissionToDelete = hasPermission(
    FeaturePermissions.UserExistingFinance.Delete
  );

  const handleUpdateClick = async (finance) => {
    await setUpdateFinanceDetail(finance);
    dispatch(setExistingLoanDetailDialogOpen(true));
  };

  const handleDeleteClick = async (id) => {
    await setDeleteId(id);
    setIsDeleteDialogOpen(true);
  };

  const handleDialogClose = () => {
    if (isDirty) {
      setConfirmOpen(true);
    } else {
      handleClose();
    }
  };

  const handleClose = () => {
    dispatch(setExistingLoanDetailDialogOpen(false));
    setUpdateFinanceDetail(null);
  };

  const handleEditSectionOpen = async () => {
    await setUpdateFinanceDetail(null);
    dispatch(setExistingLoanDetailDialogOpen(true));
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    handleClose();
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteConfirmed = () => {
    if (deleteId) {
      dispatch(deleteUserExistingFinance(deleteId, applicationId || financeId));
    }
    closeDeleteDialog();
  };

  const renderFilePreview = (filePath) => {
    if (!filePath) return null;
    return (
      <div className="flex justify-start items-start">
        <div className="flex justify-center object-cover w-80">
          <PreviewMedia filePath={filePath}>
            <FilePreview fileUrl={filePath} />
          </PreviewMedia>
        </div>
        <IconButton
          aria-label="download"
          onClick={() => window.open(filePath, "_blank")}
        >
          <FileDownloadOutlinedIcon />
        </IconButton>
      </div>
    );
  };

  return (
    <>
      <Table className="w-full overflow-auto">
        <TableHead>
          <TableRow>
            <CustomTableHeaderCell>{t("SERIAL_NO")}</CustomTableHeaderCell>
            <CustomTableHeaderCell>{t("FINANCE_NAME")}</CustomTableHeaderCell>
            <CustomTableHeaderCell>
              {t("MONTHLY_COMMITMENTS")}
            </CustomTableHeaderCell>
            <CustomTableHeaderCell>{t("LOAN_BALANCE")}</CustomTableHeaderCell>
            <CustomTableHeaderCell>{t("LOAN_TENURE")}</CustomTableHeaderCell>
            <CustomTableHeaderCell>
              {t("SETTLEMENT_LETTER")}
            </CustomTableHeaderCell>
            <CustomTableHeaderCell>
              {t("VALIDITY_PERIOD")}
            </CustomTableHeaderCell>
            <CustomTableHeaderCell>{t("FINANCE_FILE")}</CustomTableHeaderCell>
            {(hasPermissionToUpdate || hasPermissionToDelete) &&
              !allApproved && currentFinanceStatus !== "rejected" &&
              financeDetail?.length !== 0 && (
                <CustomTableHeaderCell>{t("ACTIONS")}</CustomTableHeaderCell>
              )}
          </TableRow>
        </TableHead>
        {financeDetail?.length === 0 ? (
          <TableBody>
            <TableRow>
              <CustomTableBodyCell colSpan={8} className="text-center">
                {t("NO_FINANCE")}
              </CustomTableBodyCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {financeDetail?.map((finance, index) => (
              <TableRow key={index}>
                <CustomTableBodyCell className="text-center">
                  {index + 1}
                </CustomTableBodyCell>
                <CustomTableBodyCell>
                  {finance.nameOfFinanceInstitution || "--"}
                </CustomTableBodyCell>
                <CustomTableBodyCell>
                  {formatAmount(finance.monthlyDeduction) || "--"}
                </CustomTableBodyCell>
                <CustomTableBodyCell>
                  {formatAmount(finance.balanceOfLoan) || "--"}
                </CustomTableBodyCell>
                <CustomTableBodyCell>
                  {finance.balanceLoanTenure}
                </CustomTableBodyCell>
                <CustomTableBodyCell>
                  {finance.hasSettlementLetter === true ? "Yes" : "No" || "--"}
                </CustomTableBodyCell>
                <CustomTableBodyCell>
                  {finance.validityPeriod || "--"}
                </CustomTableBodyCell>
                <CustomTableBodyCell>
                  {renderFilePreview(finance.existingFinanceFilePath)}
                </CustomTableBodyCell>
                {(hasPermissionToUpdate || hasPermissionToDelete) &&
                  !allApproved && currentFinanceStatus !== "rejected" && (
                    <CustomTableBodyCell className="text-center">
                      <CommonTableMenu
                        menuItems={[
                          hasPermissionToUpdate && {
                            label: t("EDIT"),
                            onClick: () => handleUpdateClick(finance),
                          },
                          hasPermissionToDelete && {
                            label: t("DELETE"),
                            onClick: () => handleDeleteClick(finance.id),
                          },
                        ].filter(Boolean)}
                      />
                    </CustomTableBodyCell>
                  )}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      {!allApproved && (
        <Can permission={FeaturePermissions.UserExistingFinance.Create}>
          <CustomTooltip
            title={
              currentFinanceStatus === "rejected"
                ? t("REJECTED_FINANCE_TOOLTIP")
                : ""
            }
          >
            <Box className='w-200'>
              <Button
                className="mt-10 capitalize"
                onClick={handleEditSectionOpen}
                disabled={currentFinanceStatus === "rejected"}
                data-test-id="existing-finance-form-add-new-finance-btn"
              >
                + {t("ADD_ROW")}
              </Button>
            </Box>
          </CustomTooltip>
        </Can>
      )}

      {isOpen && (
        <CustomDialog
          open={isOpen}
          onClose={handleDialogClose}
          content={{
            title: t("FINANCE_DETAILS"),
            component: (
              <ExistingFinanceForm
                financeApplicationId={applicationId || financeId}
                isEdit={isEdit}
                setIsDirty={setIsDirty}
                financeDetail={updateFinanceDetail}
              />
            ),
          }}
        ></CustomDialog>
      )}
      {confirmOpen && (
        <ChangeDetectDialog
          open={confirmOpen}
          onClose={handleConfirmClose}
          onButtonClick={() => setConfirmOpen(false)}
        />
      )}
      {isDeleteDialogOpen && (
        <ConfirmDialog
          open={isDeleteDialogOpen}
          handleClose={closeDeleteDialog}
          handleConfirm={handleDeleteConfirmed}
          title={t("DELETE_EXISTING_FINANCE_TITLE")}
          description={t("DELETE_EXISTING_FINANCE_DESCRIPTION")}
          okText={t("DELETE")}
        />
      )}
    </>
  );
};

export default ExistingFinanceTable;

import React, { useState } from "react";
import {
  Dialog,
  Badge,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
} from "@mui/material";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import CustomIconButton from "../Button/CustomIconButton";
import CustomCheckbox from "../Form/CustomCheckbox";
import {
  getCurrentFinanceApplicationStatus,
  updateFinanceApplicationSectionRemark,
} from "app/store/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import CustomTooltip from "../Tooltip/CustomTooltip";

const RemarkDialog = ({ sectionTitle, remarksList }) => {
  const { t } = useTranslation("user");
  const dispatch: any = useDispatch();
  const currentFinanceStatus = useSelector(getCurrentFinanceApplicationStatus);

  const [open, setOpen] = useState(false);

  // Check if message array has length 0 or less
  const noRemark = !Array.isArray(remarksList) || remarksList.length <= 0;

  const handleIconButtonClick = (e) => {
    setOpen(true);
    e.stopPropagation();
  };

  const handleClose = (e) => {
    setOpen(false);
    e.stopPropagation();
  };
  const handleDialogContentClick = (e) => {
    e.stopPropagation();
  };

  const handleCheckboxChange = (remark) => (event) => {
    const updatedRemark = {
      ...remark,
      hasActionTakenByCustomer: !remark.hasActionTakenByCustomer,
    };
    dispatch(updateFinanceApplicationSectionRemark(updatedRemark));
  };

  return (
    <>
      {remarksList.length > 0 && (
        <CustomIconButton onClick={handleIconButtonClick} className="m-0 p-3">
          <Badge
            badgeContent={remarksList.length}
            color="error"
            className="text-white"
          >
            <MessageOutlinedIcon color="warning" />
          </Badge>
        </CustomIconButton>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        onClick={handleDialogContentClick}
      >
        <DialogTitle>
          {sectionTitle} {t("REMARKS")}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {noRemark ? (
            <Typography variant="body1">{t("NO_REMARK")}</Typography>
          ) : (
            remarksList.map((remark, index) => (
              <div key={index} className="mb-8">
                <div className="flex items-center">
                  <CustomTooltip
                    title={
                      currentFinanceStatus === "rejected"
                        ? t("REJECTED_FINANCE_TOOLTIP")
                        : ""
                    }
                  >
                    <div>
                      <CustomCheckbox
                        label=""
                        onChange={handleCheckboxChange(remark)}
                        checked={remark.hasActionTakenByCustomer}
                        disabled={currentFinanceStatus === "rejected"}
                      />
                    </div>
                  </CustomTooltip>
                  <Typography variant="body1">
                    {remark.hasActionTaken ||
                    remark.hasActionTakenByCustomer ? (
                      <s>{remark.remark}</s>
                    ) : (
                      remark.remark
                    )}
                  </Typography>
                  <Typography variant="body2" className="ml-auto">
                    {remark.created}
                  </Typography>
                </div>
                <Divider />
              </div>
            ))
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RemarkDialog;

import React, { useEffect, useState } from "react";
import KYCGuideline from "../user/component/finance-detail-page/e-KYC/KYCGuideline";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsShowGuideline,
  kycUsingMobile,
  setIseKYCResultSuccess,
  setIsShowGuideline,
} from "app/store/application-form/applicationFormSlice";
import { useLocation, useNavigate } from "react-router";
import {
  getApplicationDetailData,
  getApplicationDetailsForMobile,
} from "app/store/user/userSlice";
import KYCResult from "../user/component/finance-detail-page/e-KYC/KYCResult";
import MyKadFinance from "../user/component/finance-detail-page/e-KYC/MyKadFinance";
import { showMessage } from "app/store/fuse/messageSlice";

const MobileFinanceKYC = () => {
  const showGuideline = useSelector(getIsShowGuideline);
  const [showResult, setShowResult] = useState<boolean>(false);
  const dispatch: any = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const applicationDetailsData = useSelector(getApplicationDetailData);
  const financeApplicationId = searchParams.get("financeApplicationId");
  const token = searchParams.get("token");

  const handleRestart = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (financeApplicationId && token) {
      dispatch(getApplicationDetailsForMobile(financeApplicationId, token));
    }
  }, [dispatch]);
 
  useEffect(() => {
    setTimeout(() => {
      const widgetElements: any = document?.getElementsByClassName("widget-visible");
  
      if (widgetElements.length > 0) {
        const widgetElement = widgetElements[0];
        widgetElement.style.setProperty("display", "none", "important");
      } else {
        console.error("No widget element found after delay");
      }
    }, 1000); // Delay by 1 second for element load
  }, []);
  

  const startKYC = () => {
    if (financeApplicationId && token) {
      dispatch(kycUsingMobile(financeApplicationId, token));
    } else {
      dispatch(
        showMessage({
          message: "Something wrong!! we can't get finance detail!",
          variant: "error",
        })
      );
    }
    window.scrollTo(0, 0);
  };

  const handleShowResult = () => {
    setShowResult(true);
  };

  useEffect(() => {
    if (
      applicationDetailsData &&
      applicationDetailsData?.ekycStatus?.toLowerCase() === "success"
    ) {
      dispatch(setIsShowGuideline(true));
      dispatch(setIseKYCResultSuccess(true));
      setShowResult(true);
      searchParams.set(
        "ekycStatus",
        applicationDetailsData?.ekycStatus?.toLowerCase()
      );
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
      window.history.pushState(null, "", newUrl);
    }
  }, [applicationDetailsData, dispatch]);

  return (
    <>
      {!showGuideline ? (
        <KYCGuideline startKYC={startKYC} />
      ) : showResult ? (
        <KYCResult onRetry={handleRestart} isMobile={true} />
      ) : (
        <MyKadFinance
          showResult={handleShowResult}
          isMobile={true}
          token={token}
        />
      )}
    </>
  );
};

export default MobileFinanceKYC;

const locale = {
  DASHBOARD_WELCOME_MESSAGE: "Hi, {{name}}! Welcome Back.",
  DASHBOARD_DESC: "Your Personal Dashboard Overview",
  FINANCED_AMOUNT: "Finance Amount",
  CUSTOMERS: "Customers",
  APPLICATIONS: "Applications",
  TOTAL: "Total",
  LAST_WEEK: "Last Week",
  LAST_MONTH: "Last Month",
  LAST_YEAR: "Last Year",
  TODAY: "Today",
  VIEW_ALL: "View All",
  LAST_MODIFIED_APPLICATION: "Last Modified Application",
  APPLICATION_ID: "Application Id",
  KYC_COMPLETE: "eKYC Verification Successful",
  FINANCE_PRODUCT: "Finance Product",
  AMOUNT: "Amount",
  INTEREST_RATE: "Profit Rate(%)",
  MANAGER_CODE: "Manager Code",
  STATUS: "Status",
  CREATED_DATE: "Created Date",
  CATEGORY_NAME: "Category Name",
  PRODUCT_NAME: "Product Name",
  PRODUCT_VARIANT_NAME: "Product Variant",
  LAST_MODIFIED_DATE: "Last Modified Date",
  MEMBER_NAME: "Member Name",
  APPROVED_AND_TOTAL_APPLICATION: "Manager Application",
  TOTAL_APPLICATION: "Total Applications",
  APPROVED_APPLICATION: "Approved Applications",
  NO_DATA_AVAILABLE: "No data available",
  APPLICATION_COUNT: "Applications Count",
  TOP_PERFORMER_MANAGERS: "Top Performer Managers",
  ACTION_REQUIRED_REMARK: "Action Required Remarks",
  NOTIFICATION: "Notification",
  THERE_ARE_NO_NOTIFICATION_FOR_NOW: "There are no notification for now.",
  IN_PROGRESS_APPLICATION: "In Progress Finance Application",
  RECENT_TRANSACTIONS: "Recent Transactions",
  NO_TRANSACTIONS_AVAILABLE: "You have no transactions available right now.",
  ACTIVE_FINANCE: "Active Finance Detail",
  GO_TO_DETAIL_TOOLTIP: "Go to Detail Page",
  NO_ACTIVE_FINANCE_FOUND:
    "No active finance records found.",
    APPLY_FOR_FINANCE: "Apply For Finance",
};

export default locale;

import { Grid } from "@mui/material";
import ActionRequiredRemarksList from "app/shared-components/Dashboard/ActionRequiredRemarksList";
import DashboardNotification from "app/shared-components/Dashboard/DashboardNotification";
import InProgressFinanceApplication from "app/shared-components/Dashboard/InProgressFinanceApplication";
import Widget from "app/shared-components/Dashboard/Widget";
import React from "react";

const MiniAgentDashboard = () => {
  return (
    <>
      <Grid container spacing={2} className="mb-10">
        <Grid item xs={12}>
          <Widget />
        </Grid>
        <Grid item xs={12}>
          <InProgressFinanceApplication />
        </Grid>
        <Grid item xs={12} md={6}>
          <DashboardNotification />
        </Grid>
        <Grid item xs={12} md={6}>
          <ActionRequiredRemarksList />
        </Grid>
      </Grid>
    </>
  );
};

export default MiniAgentDashboard;

import React, { useEffect, useState } from "react";
import { StyledTypography } from "app/shared-components/Styles/CustomStyle";
import { Grid, Box, Button } from "@mui/material";
import CustomTextField from "app/shared-components/Form/CustomTextField";
import CustomAutocomplete from "app/shared-components/Form/CustomAutoComplete";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getBankingDetails,
  getCurrentStep,
  getUserBankingDetailData,
  selectIsButtonLoading,
  setCurrentStep,
  updateUserBankingDetailsInForm,
  userBankingDetailsInForm,
} from "app/store/application-form/applicationFormSlice";
import { accountType, tenantId } from "src/app/common/constant";
import ColoredSubmitButton from "app/shared-components/Button/ColoredSubmitButton";

export default function BankDetails({
  financeApplicationId = null,
  isEdit = false,
  setIsDirty = (value) => {},
}) {
  const { t } = useTranslation("applicationForm");
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const [search] = useSearchParams();
  const financeId = search.get("id") || null;
  const userId = search.get("userId") || null;
  const activeStep = useSelector(getCurrentStep);
  const bankingDetails = useSelector(getUserBankingDetailData);
  const isLoading = useSelector(selectIsButtonLoading);

  const schema = yup.object().shape({
    bankName: yup
      .string()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("BANK_NAME") })
      ),
    bankBranch: yup
      .string()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("BANK_BRANCH") })
      ),
    accountNumber: yup
      .string()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("ACCOUNT_NUMBER") })
      )
      .matches(/^[0-9]+$/, t("NUMBER_INVALID_VALIDATION")),
    typeOfAccount: yup.string().required(t("SELECT_FIELD_REQUIRED")),
  });

  useEffect(() => {
    const idToUse = financeId || financeApplicationId;
    if (idToUse) {
      dispatch(getBankingDetails(idToUse));
    }
  }, [dispatch, financeId, financeApplicationId]);

  const methods = useForm();
  const { handleSubmit, formState, control, reset, getValues, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    });
  const { errors, isValid, isDirty } = formState;

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty, setIsDirty]);

  useEffect(() => {
    setValue("bankName", bankingDetails?.bankName);
    setValue("accountNumber", bankingDetails?.accountNumber);
    setValue("bankBranch", bankingDetails?.bankBranch);
    setValue("typeOfAccount", bankingDetails?.typeOfAccount);
    reset(getValues());
  }, [setValue, bankingDetails]);

  function onSubmit(model, event) {
    event.preventDefault();

    const request_data = {
      ...model,
      tenantId: tenantId,
      financeApplicationId: financeId || financeApplicationId,
    };

    const UpdateData = {
      ...model,
      id: bankingDetails?.id,
    };

    if (
      Object.keys(bankingDetails).length !== 0 &&
      bankingDetails?.isDefault !== true
    ) {
      dispatch(
        updateUserBankingDetailsInForm(
          UpdateData,
          bankingDetails?.financeApplicationId,
          isEdit
        )
      );
    } else {
      dispatch(userBankingDetailsInForm(request_data, isEdit));
    }
  }

  const handleBack = () => {
    dispatch(setCurrentStep(activeStep - 1));
    navigate(
      `/application-form/${activeStep - 1}?userId=${userId}&id=${financeId}`
    );
  };
  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-10"
          data-test-id="banking-detail-form"
        >
          <StyledTypography variant="body1" className="mt-20">
            {t("BANK_DETAILS")}
          </StyledTypography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} className="mb-8">
                  <Controller
                    name="bankName"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("BANK_NAME")}
                        onChange={field.onChange}
                        value={field.value || ""}
                        fullWidth
                        required
                        error={errors.bankName && t(errors.bankName.message)}
                        inputProps={{
                          "data-test-id": "banking-detail-form-bank-name"
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="mb-8">
                  <Controller
                    name="bankBranch"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("BANK_BRANCH")}
                        onChange={field.onChange}
                        value={field.value || ""}
                        fullWidth
                        required
                        error={
                          errors.bankBranch && t(errors.bankBranch.message)
                        }
                        inputProps={{
                          "data-test-id": "banking-detail-form-bank-branch"
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} className="mb-8">
                  <Controller
                    name="accountNumber"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("ACCOUNT_NUMBER")}
                        onChange={field.onChange}
                        value={field.value || ""}
                        fullWidth
                        required
                        error={
                          errors.accountNumber &&
                          t(errors.accountNumber.message)
                        }
                        inputProps={{
                          "data-test-id": "banking-detail-form-bank-account-number"
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="mb-8">
                  <Controller
                    name="typeOfAccount"
                    control={control}
                    render={({ field }) => (
                      <CustomAutocomplete
                        label={t("ACCOUNT_TYPE")}
                        data-test-id="banking-detail-form-account-type"
                        onChange={(value: any) => {
                          if (value && value.id) {
                            field.onChange(value.id);
                          } else {
                            field.onChange(null);
                          }
                        }}
                        value={field.value || ""}
                        required
                        options={accountType?.map((item) => ({
                          label: t(item.label),
                          id: item.id,
                        }))}
                        error={
                          errors.typeOfAccount &&
                          t(errors.typeOfAccount.message)
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box>
            <Box className="flex justify-end items-end gap-20 mt-10">
              {isEdit ? (
                <ColoredSubmitButton
                  className="w-120"
                  disabled={!isValid}
                  text={t("SAVE")}
                  isLoading={isLoading}
                  data-test-id="banking-detail-form-submit-btn"
                ></ColoredSubmitButton>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    className="capitalize w-120"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {t("PREVIOUS")}
                  </Button>
                  <ColoredSubmitButton
                    className="w-120"
                    disabled={!isValid}
                    text={t("NEXT")}
                    isLoading={isLoading}
                    data-test-id="banking-detail-form-submit-btn"
                  ></ColoredSubmitButton>
                </>
              )}
            </Box>
          </Box>
        </form>
      </FormProvider>
    </>
  );
}

import React from "react";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import { IconButton, Typography } from "@mui/material";
import { copyToClipBoard, formatPhoneNumber } from "src/app/services/utils";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EmailLink from "../Link/EmailLink";
import PhoneNumberLink from "../Link/PhoneNumberLink";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import en from "./i18n/en";
import ms from "./i18n/ms";
import CustomTooltipWithWhitePaper from "./CustomTooltipWithWhitePaper";
import { useDispatch } from "react-redux";

i18next.addResourceBundle("en", "tooltip", en);
i18next.addResourceBundle("ms", "tooltip", ms);

const AgentDetailTooltip = ({ agentDetail }) => {
  const { t } = useTranslation("tooltip");
  const dispatch: any = useDispatch();

  const agentDetailContent = (
    <>
      <div className="flex gap-5 justify-center flex-col px-8 py-5">
        <div className="flex items-start justify-start gap-5">
          <PersonOutlineOutlinedIcon color="action" fontSize="medium" />
          <Typography
            variant="body1"
            className="text-14 !font-normal"
            color="GrayText"
          >
            {agentDetail?.fullName || "--"}
          </Typography>
        </div>
        <div className="flex items-center justify-start gap-5">
          <EmailOutlinedIcon color="action" fontSize="medium" />

          <Typography
            variant="body1"
            className="text-14 !font-normal"
            color="GrayText"
          >
            <EmailLink email={agentDetail?.email} />
          </Typography>
          <IconButton
            className="!p-0 ml-5"
            onClick={() => copyToClipBoard(agentDetail?.email, t, dispatch)}
          >
            <ContentCopyIcon color="action" fontSize="small" />
          </IconButton>
        </div>
        <div className="flex items-center justify-start gap-5">
          <PhoneOutlinedIcon color="action" fontSize="small" />
          <Typography
            variant="body1"
            className="text-14 !font-normal"
            color="GrayText"
          >
            <PhoneNumberLink phoneNumber={agentDetail?.phoneNumber} />
          </Typography>

          <IconButton
            className="!p-0 ml-5"
            onClick={() =>
              copyToClipBoard(formatPhoneNumber(agentDetail?.phoneNumber), t, dispatch)
            }
          >
            <ContentCopyIcon color="action" fontSize="small" />
          </IconButton>
        </div>
      </div>
    </>
  );

  return (
    <>
      <CustomTooltipWithWhitePaper
        tooltipContent={agentDetailContent}
        title={agentDetail?.userUniqueCode}
      />
    </>
  );
};

export default AgentDetailTooltip;

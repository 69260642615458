import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { formatAmount } from "src/app/services/utils";
import StatusChip from "../statusChip/StatusChip";
import history from "@history";
import ReactTableSimple from "../ReactTable/ReactTableSimple";
import { Button, Paper, Typography } from "@mui/material";
import {
  getInProgressFinanceApplication,
  getInProgressFinanceApplicationList,
  getIsInProgressFinanceApplicationLoading,
} from "./store/dashboardSlice";
import MemberDetailTooltip from "../Tooltip/MemberDetailTooltip";

const InProgressFinanceApplication = () => {
  const { t } = useTranslation("dashboard");
  const dispatch: any = useDispatch();
  const isLatestFinanceApplicationLoading = useSelector(
    getIsInProgressFinanceApplicationLoading
  );
  const applicationListData = useSelector(getInProgressFinanceApplicationList);

  useEffect(() => {
    dispatch(getInProgressFinanceApplication());
  }, [dispatch]);

  const columns = React.useMemo<any[]>(
    () => [
      {
        header: () => t("APPLICATION_ID"),
        accessorKey: "applicationNumber",
        cell: (value) => {
          return (
            <div className="flex justify-start items-center gap-5">
              <Link
                to={`/user/my-application/${encodeURIComponent(
                  value.row.original.id
                )}`}
              >
                {value.getValue()}
              </Link>
            </div>
          );
        },
      },
      {
        header: () => t("MEMBER_NAME"),
        accessorKey: "userDetail",
        cell: (value) =>
          value.getValue() ? (
            <MemberDetailTooltip memberDetail={value.getValue()} />
          ) : (
            "--"
          ),
      },
      {
        header: () => t("FINANCE_PRODUCT"),
        accessorKey: "financeProductName",
      },
      {
        header: () => {
          return <div className="text-right">{t("AMOUNT")}</div>;
        },
        accessorKey: "financeAmount",
        cell: (value) => {
          return (
            <div className="text-right">{formatAmount(value.getValue())}</div>
          );
        },
      },
      {
        header: () => t("STATUS"),
        accessorKey: "financeApplicationStatus",
        cell: (value) => {
          const statusValue = value.getValue();
          return statusValue ? (
            <StatusChip label={statusValue} status={statusValue} />
          ) : (
            <span>--</span>
          );
        },
      },
      {
        header: () => t("LAST_MODIFIED_DATE"),
        accessorKey: "lastModified",
        cell: (value) => {
          return <div>{value.getValue()}</div>;
        },
      },
    ],
    [t]
  );

  return (
    <>
      <Paper className="flex flex-col flex-auto px-24 py-20 shadow rounded-2xl overflow-hidden">
        {/* Header with "View All" button */}
        <div className="flex justify-between items-center h-32">
          <Typography className="text-lg font-medium tracking-tight leading-6">
            {t("IN_PROGRESS_APPLICATION")}
          </Typography>
          <Button
            variant="text"
            color="secondary"
            onClick={() => {
              history.push("/user/my-application");
            }}
            className="capitalize"
          >
            {t("VIEW_ALL")}
          </Button>
        </div>
        <ReactTableSimple
          isLoading={isLatestFinanceApplicationLoading}
          columns={columns}
          data={applicationListData}
        />
      </Paper>
    </>
  );
};

export default InProgressFinanceApplication;

const locale = {
  ABOUT_PAGE_TITLE: "Tentang Kami",
  ABOUT_PAGE_DESCRIPTION:
    'Koperasi FK Capital Berhad ("fkc.coop") mempunyai sejarah yang kaya selama beberapa tahun. Ia ditubuhkan pada 15 Julai 2019, dengan tujuan untuk memberikan pemerkasaan kewangan dan sokongan kepada ahlinya. Sejak penubuhannya, koperasi telah berkembang dengan mantap dan mendapat pengiktirafan sebagai koperasi yang dipercayai.',
  IMAGE_DESCRIPTION_ABOUT_PAGE:
    "Koperasi ini ditubuhkan berdasarkan prinsip kerjasama dan sokongan bersama. Pengasasnya menyedari keperluan untuk platform yang membolehkan individu mengakses pelbagai perkhidmatan kewangan dan peluang untuk meningkatkan kesejahteraan kewangan mereka. Selama bertahun-tahun, fkc.coop telah menumpukan pada membina asas yang kukuh yang berteraskan kepercayaan, ketelusan dan integriti. Ia telah berusaha untuk mewujudkan persekitaran yang inklusif dan menyokong di mana ahli boleh mendapat manfaat daripada pelbagai produk kewangan, perkhidmatan dan sumber pendidikan.",
  STATUS_DESC:
    "Melalui komitmennya untuk memperkasakan ahli, fkc.coop telah memainkan peranan penting dalam meningkatkan literasi bahasa, meningkatkan sosioekonomi dan keupayaan membuat keputusan kewangan di kalangan ahlinya. Dengan menyediakan akses kepada akaun simpanan, pinjaman dan pilihan pembiayaan, peluang pelaburan dan program pendidikan, fkc.coop telah membantu ahlinya mencapai matlamat kewangan mereka. <br><br> fkc.coop juga telah memupuk perkongsian strategik dengan organisasi ternama dalam pelbagai industri. Perkongsian ini telah membolehkan koperasi menawarkan nilai dan faedah tambahan kepada ahlinya, memastikan permintaan mereka dipenuhi.",
  OUR_MISSION_TITLE: "Misi Kami",
  OUR_MISSION_DESCRIPTION:
    "Untuk menyediakan perkhidmatan kewangan dan penyelesaian yang menyeluruh yang memberi kuasa kepada individu dan perniagaan untuk mencapai matlamat kewangan mereka, menyokong pertumbuhan ekonomi yang mampan dan kemakmuran.",
  OUR_VISION_TITLE: "Visi Kami",
  OUR_VISION_DESCRIPTION:
    "Untuk menjadi pemimpin yang dipercayai dan inovatif dalam industri kewangan, mendorong perubahan positif dan mencipta nilai yang berkekalan untuk ahli dan komuniti kami.",
  VALUE: "Nilai",
  OBJECTIVE: "Objektif",
  VALUES: [
    "Tanggungjawab Menegakkan akauntabiliti dan tingkah laku etika dalam operasi.",
    "Demokrasi Merangkul prinsip demokratik dengan penyertaan ahli dalam membuat keputusan.",
    "Kesamarataan Menyediakan peluang dan faedah yang sama untuk semua ahli.",
    "Perpaduan Membangunkan kerjasama untuk matlamat bersama dan kemakmuran.",
    "Ketelusan Mengekalkan keterbukaan melalui perkongsian maklumat yang jelas.",
    "Tanggungjawab Sosial Terlibat secara aktif dalam inisiatif komuniti untuk kesejahteraan dan kelestarian.",
  ],
  OBJECTIVES: [
    "Mendorong Penglibatan Kewangan Membesarkan akses kepada perkhidmatan kewangan yang berpatutan, mempromosikan kesamarataan ekonomi dan pemberdayaan",
    "Menyampaikan Pulangan yang Lebih Tinggi Menghasilkan pulangan pelaburan yang menarik dan konsisten bagi ahli, memastikan kestabilan kewangan jangka panjang dan pertumbuhan",
    "Mendorong Inovasi Merangkul kemajuan teknologi dan pendekatan inovatif untuk meningkatkan produk, perkhidmatan, dan kecekapan operasi",
    "Membina Hubungan Ahli Menanamkan hubungan kerjasama dengan ahli, menyampaikan penyelesaian kewangan yang dipersonalisasi yang direka khas untuk keperluan mereka",
    "Memperjuangkan Amalan Etika Menegakkan standard etika, integriti, dan ketelusan yang tertinggi, memupuk kepercayaan dan keyakinan dalam operasi kami",
  ],
  STATS_DATA: [
    { Key: "START_SINCE", value: "2019", label: "Bermula Sejak" },
    {
      Key: "INSURANCE_COVERAGE",
      value: "2900++",
      label: "Perlindungan Insurans",
    },
    { Key: "PARTNERS", value: "15", label: "rakan kongsi" },
    { Key: "MEMBERSHIP", value: "Seluruh Negara", label: "Keahlian" },
  ],
};

export default locale;

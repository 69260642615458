import { combineReducers, ReducersMapObject } from '@reduxjs/toolkit';
import user from './user/userSlice';
import i18n from './i18nSlice';
import fuse from './fuse';
import product from '../main/product/store/productSlice';
import email from '../main/email-verification/store/emailVerificationSlice';
import contactUs from '../main/contact-us/store/contactUsSlice';
import dashboard from 'app/shared-components/Dashboard/store/dashboardSlice';
/**
 * Creates a reducer function that combines the provided reducers with the async reducers.
 */
const createReducer = (asyncReducers: ReducersMapObject) =>
	combineReducers({
		fuse,
		user,
		i18n,
		product,
		email,
		contactUs,
		dashboard,
		...asyncReducers
	} as ReducersMapObject);

/* Reset the redux store when user logged out */
/**
	if (action.type === 'user/userLoggedOut') {
		// state = undefined;
	}
	return combinedReducer(state, action);
*/

export default createReducer;

import { Container, Typography, IconButton, Grid, Hidden } from "@mui/material";
import QRCodeComponent from "app/shared-components/QRCode/QRCodeComponent";
import { StyledTypography } from "app/shared-components/Styles/CustomStyle";
import { getUserData } from "app/store/user/userSlice";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DetailItem from "app/shared-components/DetailItem/DetailItem";
import html2canvas from "html2canvas";
import AgentQRBusinessCard from "src/app/main/user/component/AgentQRBusinessCard";
import { copyToClipBoard } from "src/app/services/utils";

const AgentQRCode = () => {
  const { t } = useTranslation("user");
  const dispatch: any = useDispatch();
  const userData = useSelector(getUserData);
  const businessCardRef = useRef(null);

  // Base URL for sign-up with agent code
  const referralLink = `${process.env.REACT_APP_WEB_BASE_URL}sign-up?agent-code=${userData?.userUniqueCode}`;

  const downloadQRCode = () => {
    if (businessCardRef.current) {
      html2canvas(businessCardRef.current, {
        scale: 2, // Increase the scale to improve resolution
      })
        .then((canvas) => {
          const dataURL = canvas.toDataURL("image/png");

          // Create a link element and trigger a download
          const link = document.createElement("a");
          link.href = dataURL;
          link.download = "AGENT_REFERRAL.png";
          link.click();
        })
        .catch((error) => {
          console.error("Error capturing QR code:", error);
        });
    }
  };

  const showReferralDetail = (
    <>
      <Grid item xs={12} sm={6} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DetailItem
              label={t("REFERRAL_CODE")}
              value={
                <>
                  <span className="text-2xl mr-10 font-bold">
                    {userData?.userUniqueCode}
                  </span>
                  <IconButton
                    onClick={() =>
                      copyToClipBoard(userData?.userUniqueCode, t, dispatch)
                    }
                  >
                    <ContentCopyIcon color="secondary" />
                  </IconButton>
                </>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <DetailItem
              label={t("REFERRAL_LINK")}
              value={
                <>
                  <span className="text-md mr-10 font-bold">
                    {referralLink}
                  </span>
                  <IconButton
                    onClick={() => copyToClipBoard(referralLink, t, dispatch)}
                  >
                    <ContentCopyIcon color="secondary" />
                  </IconButton>
                </>
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Container
      maxWidth="lg"
      className="pl-10 mt-40 mb-10 md:pl-76 pr-10 md:pr-76"
    >
      <StyledTypography variant="h5" fontWeight="bold" mb={2}>
        {t("REFERRAL_DETAILS")}
      </StyledTypography>
      <Grid container spacing={2}>
        <Hidden smUp>{showReferralDetail}</Hidden>
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant="body1">{t("REFERRAL_INSTRUCTIONS")}:</Typography>
          <QRCodeComponent link={referralLink} download={downloadQRCode} />
          <div
            style={{
              position: "absolute",
              top: "-9999px",
              left: "-9999px",
            }}
          >
            <AgentQRBusinessCard
              ref={businessCardRef}
              userData={userData}
              link={referralLink}
            />
          </div>
        </Grid>
        <Hidden smDown>{showReferralDetail}</Hidden>
      </Grid>
    </Container>
  );
};

export default AgentQRCode;

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/system";
import themesConfig from "app/configs/themesConfig";

interface ColorButtonProps {
  disabled?: boolean;
  isLoading?: boolean;
  text?: string;
  className?: string;
  variant?: 'contained' | 'outlined';
  [key: string]: any;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const StyledButton = styled(Button)(
  ({ theme, disabled, variant }) => `
      && {
          background-color: ${
            variant === 'contained'
              ? (disabled
                  ? themesConfig.legacy.palette.background.default
                  : theme.palette.secondary.main)
              : 'transparent'
          };
          color: ${
            variant === 'contained'
              ? 'white'
              : theme.palette.secondary.main
          };
          border: ${
            variant === 'outlined'
              ? `1px solid ${theme.palette.secondary.main}`
              : 'none'
          };
          text-transform: capitalize;
  
          &:hover {
              background-color: ${
                variant === 'contained'
                  ? (disabled
                      ? themesConfig.legacy.palette.background.default
                      : theme.palette.secondary.dark)
                  : 'rgba(0, 0, 0, 0.1)'
              };
              border: ${
                variant === 'outlined'
                  ? `1px solid ${theme.palette.secondary.dark}`
                  : 'none'
              };
          }
      }
    `
);

const ColoredSubmitButton = ({
  disabled,
  isLoading,
  onClick,
  text,
  className,
  variant = 'contained',
  ...props
}: ColorButtonProps) => {
  return (
    <StyledButton
      type="submit"
      disabled={disabled || isLoading}
      onClick={onClick}
      variant={variant}
      className={`capitalize ${className}`}
      {...props}
    >
      {isLoading ? (
        <CircularProgress size={24} className="button-progress" />
      ) : (
        text
      )}
    </StyledButton>
  );
};

export default ColoredSubmitButton;

import i18next from 'i18next';
import { lazy } from 'react';
import en from './i18n/en';
import ms from './i18n/ms';
import { authRoles } from 'src/app/auth';

i18next.addResourceBundle('en', 'aboutUsPage', en);
i18next.addResourceBundle('ms', 'aboutUsPage', ms);

const AboutUs = lazy(() => import('./AboutUs'));

/**
 * The Home page config.
 */
const AboutUsConfig = {
	settings: {
		layout: {
		}
	},
	auth: authRoles.public,
	routes: [
		{
			path: 'about-us',
			element: <AboutUs />
		}
	]
};

export default AboutUsConfig;

import FuseUtils from '@fuse/utils';
import FuseLoading from '@fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';
import settingsConfig from 'app/configs/settingsConfig';
import { FuseRouteConfigsType, FuseRoutesType } from '@fuse/utils/FuseUtils';
import SignInConfig from '../main/sign-in/SignInConfig';
import SignOutConfig from '../main/sign-out/SignOutConfig';
import Error404Page from '../main/404/Error404Page';
import ProductConfig from '../main/product/ProductConfig';
import HomeConfig from '../main/home/HomeConfig';
import CalculatorConfig from '../main/calculator/CalculatorConfig';
import AboutUsConfig from '../main/about-us/AboutUsConfig';
import ContactUsConfig from '../main/contact-us/ContactUsConfig';
import UserConfig from '../main/user/UserConfig';
import { authRoles } from '../auth';
import ApplicationFormConfig from '../main/application-form/ApplicationFormConfig';
import NotificationConfig from '../main/notification/NotiticationConfig';
import EmailVerificationConfig from '../main/email-verification/EmailVerificationConfig';
import TermsAndConditionsConfig from '../main/terms-condition/TermsAndConditionsConfig';
import PrivacyPolicyConfig from '../main/privacy-policy/PrivacyPolicyConfig';
import MobileFinanceKYCConfig from '../main/mobile-kyc/MobileFinanceKYCConfig';
import FaqsConfig from '../main/Faqs/FaqsConfig';

const routeConfigs: FuseRouteConfigsType = [ProductConfig, SignOutConfig, SignInConfig, HomeConfig, CalculatorConfig, AboutUsConfig, ContactUsConfig,UserConfig,ApplicationFormConfig,NotificationConfig,EmailVerificationConfig, TermsAndConditionsConfig,PrivacyPolicyConfig, MobileFinanceKYCConfig, FaqsConfig];

/**
 * The routes of the application.
 */
const routes: FuseRoutesType = [
	...FuseUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),
	{
		path: '/',
		element: <Navigate to="/home" />,
		auth: authRoles.public
	},
	{
		path: 'loading',
		element: <FuseLoading />
	},
	{
		path: '404',
		element: <Error404Page />
	},
	{
		path: '*',
		element: <Navigate to="404" />
	}
];

export default routes;
